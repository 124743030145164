import { Select } from "antd";
import CONSTANTS, { CHANELLIST } from "../../../utils/const";
import styles from "./CampaignHistoryHeaderOptions.module.css";
import useHeaderOptions from "../../../Hooks/useHeaderOptions";
import TYPES from "../../../reducers/types";
import { useState } from "react";

const { Option } = Select;

const CampaignHistoryHeaderOptions = () => {

  const { headerOptionsDispacth } = useHeaderOptions();
  const [ defaultSelected , setDefaultSelected ] = useState(CHANELLIST.DEFAULT.key);

  const handleChanelChange = (chanelId) => {
    setDefaultSelected(chanelId);
    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.CAMPAIGN_HISTORY_MODEL,
      payload: { chanelId: chanelId }
    })
  }

  return (
    <div className={styles['container']}>
      <Select
        className={styles['account-select']}
        placeholder="Please select chanel"
        onChange={handleChanelChange}
        defaultValue={defaultSelected}
      >
        {
          CONSTANTS.LIST.CHANELS.map(item =>
            <Option key={item.key} value={item.key}>{item.value}</Option>
          )
        }
      </Select>
    </div>
  );
}
 
export default CampaignHistoryHeaderOptions;