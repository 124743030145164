import { useEffect, useRef, useState } from "react";
import { faPlay, faPause, faVolume, faVolumeMute } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from "./AudioPlayer.module.css";
import { timeFormat } from "../../utils/helpers";

const AudioPlayer = ({ src }) => {

  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMute, setIsMute] = useState(false);

  useEffect(() => {
    if(src) {
      setDuration(audioRef.current.duration);
      handlePlay();
    }
  }, [src])

  const audioRef = useRef();

  const handlePlay = () => {
    if(src) {
      audioRef.current.play();
      setPlaying(true);
    }
  }

  const handlePause = () => {
    if(src) {
      audioRef.current.pause()
      setPlaying(false);
    }
  }

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);

    if(audioRef.current.currentTime === audioRef.current.duration) {
      setCurrentTime(0);
      setPlaying(false);
    }
  }

  const handleVolume = () => {
    let value = isMute ? 1 : 0; 
    setIsMute(!isMute)
    audioRef.current.volume = value;
  }

  return (
    <div>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
      />
      <div className={styles['audio-content']}>
        <a onClick={playing ? handlePause : handlePlay}>
          {
            playing ? <FontAwesomeIcon style={{fontSize:"18px"}} icon={ faPause } /> : 
            <FontAwesomeIcon style={{fontSize:"18px"}} icon={ faPlay } />
          }
        </a>
        <p>{timeFormat(currentTime)} / {timeFormat(duration)}</p>
        <a onClick={handleVolume}>
          {
            isMute ? <FontAwesomeIcon style={{fontSize:"28px"}} icon={ faVolumeMute } /> : 
            <FontAwesomeIcon style={{fontSize:"28px"}} icon={ faVolume } />
          }
        </a>
      </div>
    </div>
  );
}
 
export default AudioPlayer;