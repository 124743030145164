import { Col, Form, Row } from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import CONSTANTS from "../../../utils/const";
import encodingSevenBits from "../../../utils/helpers";
import HelperButton from "../../HelperButton/HelperButton";
import MessageWildcard from "../../MessageWildcard/MessageWildcard";
import styles from "./ContentMessage.module.css";

const ContentMessage = ({
  customFields,
  defaultMessage,
  defaultWildCards,
  onChangeMessage,
  onChangeWildCards,
}) => {
  const [t] = useTranslation("campaign-bulk");

  const [isBasicEncoding, setIsBasicEncoding] = useState(true);
  const [specialCharacters, setSpecialCharacters] = useState([]);
    

  useEffect(() => {
    if(defaultMessage.message) {
      onChangeWildcardMessage(defaultMessage);
    }
  }, [defaultMessage])

  const onChangeWildcardMessage = message => {
    let ms = message.message.replaceAll(CONSTANTS.WILDCARD, '');
    onChangeMessage(message);

    var isBasic = isBasicEncoding;
    var hasSpecialCharacters = false;

    if (ms.length === 0) {
      setIsBasicEncoding(true);
    } else {

      hasSpecialCharacters = specialCharacters.some(v => ms.includes(v));

      if (hasSpecialCharacters) {
        setIsBasicEncoding(false);
      }
      else {

        let chart = ms.charAt(ms.length - 1);
        isBasic = encodingSevenBits.includes(chart) ? true : false;

        if (isBasic && !hasSpecialCharacters) {
          setIsBasicEncoding(true);
        } else {

          if (isBasic) {
            setSpecialCharacters([...specialCharacters, chart])
          }
          setIsBasicEncoding(false);
        }

        isBasic ? setIsBasicEncoding(true) : setSpecialCharacters([...specialCharacters, chart]);

      }
    }
  }

  return (
    <>
      <div className={styles['message-form']}>
        <div>
          <Form
            layout="vertical"
          >            
            <Form.Item
              label={
                <div>
                  {t("content-message.label-message")}
                  <HelperButton 
                    message={t("content-message.tooltip-message")}
                    color={getGradientColor(purpleColor)}
                  />
                </div>
              }
            >
              <Row>
                <Col>
                  <MessageWildcard
                    contentEditable={true}
                    wildcardsFields={customFields}
                    defaultMessage={defaultMessage}
                    defaultWildCards={defaultWildCards}
                    onChangeMessage={onChangeWildcardMessage}
                    onChangeWildCards={onChangeWildCards}
                    disabled={false}
                  >
                  </MessageWildcard>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}
 
export default ContentMessage;