import CardCustom from "../../CardCustom/CardCustom";
import { Row, Col, Switch, Avatar } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAstronaut } from '@fortawesome/pro-solid-svg-icons'
import styles from "./UserCard.module.css";
import { orangeColor, grayColorOpacity } from '../../../utils/colorsCss'
import { useState } from "react";
import { useTranslation } from 'react-i18next'
import UserApi from "../../../Api/UserApi";
import { notificationError, notificationOk } from "../../../utils/helpers";
import useSpinner from "../../../Hooks/useSpinner";
import TYPES from "../../../reducers/types";
import CONSTANTS from "../../../utils/const";

const UserCard = ({user}) => {
  const [t] = useTranslation("users");
  const [ enableUser, setEnableUser ] = useState(user.initialState);

  const { spinnerDispacth } = useSpinner();
  const { updateAvailableUser } = UserApi();

  const onClickUserSettings = (switchValue) => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.USERS }
    });

    updateAvailableUser(user.id, switchValue)
    .then(() => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.USERS }
      });
      setEnableUser(switchValue);
      notificationOk(t("update-user.notification-available-user-ok"));
    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.USERS }
      });
      console.error(error);
      notificationError(t("update-user.notification-available-user-error"));
    })
    
  }
    
  return (
    <CardCustom
      key={user.id}
      minWidth="250px"
      width="100%"
      extra={
        <Switch
          checked={enableUser}
          onClick={onClickUserSettings} />
      }
    >
      <Row>
        <Col span={24}>
          <div className={styles['avatar']}>
            <Avatar
              style={{ backgroundColor: enableUser ? orangeColor : grayColorOpacity }}
              shape='circle'
              size={60}
              icon={<FontAwesomeIcon icon={ faUserAstronaut }/>}
            />
            <p className='yp-title'>{ enableUser ? t("exist-user-card.title-enabled") : t("exist-user-card.title-disabled")}</p>
          </div>
          <p className={`yp-subtitle ${styles['title']}`}>{user.firstname} {user.lastname}</p>
          <p>{user.email}</p>
          <p>{user.description}</p>
          <p className="yp-subtitle">{t("account-title-card")}:</p>
          <ul>
          {
            user.accountsList.map((item) => (
              <li key={item.accountId}>{item.accountName}</li>
            ))
          }
          </ul>
        </Col>
      </Row>
    </CardCustom>
  );
}

export default UserCard;
