import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SmsDetails.module.css";
import { faTag, faHourglassHalf, faBars, faDonate, faGlobe, faCalendarCheck, faInbox, faCommentAlt, faCommentsAlt, faCalendarWeek } from "@fortawesome/pro-solid-svg-icons";
import { currencyFormat, dateFormat, notificationError, notificationOk } from "../../../../utils/helpers";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import CONSTANTS from "../../../../utils/const";
import { Button, Popconfirm } from 'antd';
import DataApi from "../../../../Api/DataApi";
import useSpinner from "../../../../Hooks/useSpinner";
import TYPES from "../../../../reducers/types";

const SmsDetails = ({data, onReloadCampaignDetails }) => {

  const [t] = useTranslation("campaign-history");
  const { cancelCampaign } = DataApi();
  const { spinnerDispacth } = useSpinner();

  const rebuildHtmlMessage = (message) => {
    var messageCopy = message;
    var html = message;
    var regex = /\@#(.*?)\@#/;
    var matched = regex.exec(messageCopy);
    var hasWildcard = matched?.length > 0;
    var messageWildcards = [];

    while (hasWildcard) {
      messageWildcards.push({
        content: matched[0],
        value:  matched[1]
      });
      messageCopy = messageCopy.replace(matched[0], '');
      matched = regex.exec(messageCopy);
      hasWildcard = matched?.length > 0;
    }

    for (let i = 0; i < messageWildcards.length; i++) {
      let newValue = getHtmlWildCard(messageWildcards[i].value);
      html = html.replace(messageWildcards[i].content, newValue)
    }
    
    return html;
  }

  const getHtmlWildCard = (value) => {
    let stl = value === CONSTANTS.SHORTLINK ? `${styles["message-area-wildcard"]} ${styles["message-area-wildcard-shortlink"]}` : styles["message-area-wildcard"];
    return `<div class="${stl}" contenteditable="false">${value}</div>`
  }

  const cancelCampaignByCampaignId = () => {
    try {
      
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNDETAILS }
      });

      if (data.status === 'Completed' || data.status === 'Canceled') {
        notificationError('An ocurred a error: The campaign is already cancel');
      }

      cancelCampaign(data.campaignId).then( (response) => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNDETAILS }
        });

        if(data.schedules.length > 1 ){
          notificationOk(t('Your cancel the Campaign by phases'));  
        }else{
          notificationOk(t('Your cancel Campaign'));
        }

        onReloadCampaignDetails();
      }).catch(err =>{
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNDETAILS }
        });
        console.log('Error: ' + err)
        notificationError(`An ocurred a error: ${err}`);
      })
    } catch (err) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNDETAILS }
      });
      notificationError(`An ocurred a error: ${err}`);
    }
  }

  return (
    <>
      <div className={styles['summary-content']}>
        <div className={styles['summary-info']}>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.sms-chanel.label-name")}</p>
              <p className={styles['message-information-text-value']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faTag} /> {data.campaignName}
              </p>
            </div>
          </div>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.sms-chanel.label-status")}</p>
              <p className={styles['message-information-text-value']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faHourglassHalf} /> {data.status}
              </p>
            </div>
          </div>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.sms-chanel.label-total-cost")}</p>
              <p className={styles['message-information-text-value']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faDonate} /> {currencyFormat(data.campaignTotalCost, 4)}
              </p>
            </div>
          </div>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.sms-chanel.label-message-parts")}</p>
              <p className={styles['message-information-text-value']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faBars} /> {data.messageParts}
              </p>
            </div>
          </div>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.sms-chanel.label-countries")}</p>
              <p className={styles['message-information-text-value']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faGlobe} /> 
                {
                  data.countries.map(country => (
                    <ReactCountryFlag
                      key={country}
                      countryCode={country}
                      svg
                      style={{
                        width: '1.5em',
                        height: '1.5em',
                        marginRight: 5
                      }}
                      title={country}
                    />
                  ))
                }
              </p>
            </div>
          </div>
        </div>
        <div className={styles['summary-info']}>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.sms-chanel.label-channel")}</p>
              <p className={styles['message-information-text-value']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faInbox} /> {data.channel}
              </p>
            </div>
          </div>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.sms-chanel.label-schedule")}</p>
              {
                data.schedules.map((item, index) => (
                  <p key={index} className={styles['message-information-text-value']}>
                    <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCalendarWeek} /> {dateFormat(item.dateScheduled, true, 'UTC')} - {item.percentage}%
                  </p>
                ))
              }
            </div>
          </div>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.sms-chanel.label-start-date")}</p>
              <p className={styles['message-information-text-value']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCalendarCheck} /> {dateFormat(data.schedules[0].dateScheduled, true, 'UTC')}
              </p>
            </div>
          </div>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.sms-chanel.label-total-messages")}</p>
              <p className={styles['message-information-text-value']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCommentsAlt} /> {data.totalMessages}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['message-information-details-text']}>
        <div className={styles['message-information-text']}>
          <p><FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCommentAlt} /> {t("campaign-details.sms-chanel.label-message")}</p>
          <div 
            className={styles['message-information-messages']} 
            dangerouslySetInnerHTML={{__html: rebuildHtmlMessage(data.message)}}
          >
          </div>
        </div>
      </div>
      {(data.status !== 'Completed' && data.status !== 'Canceled' && data.status !== 'Created' && data.status !== 'Ongoing') && (
        <div style={{ textAlign: 'center', marginTop: '20px' }} >
          <Popconfirm title={t("Are you sure you want to cancel Campaign")}
            okButtonProps= {{ "data-testid": "okButtonConfirm" }}
            onConfirm={cancelCampaignByCampaignId}
            okText="yes"
            cancelText="no"
          >
          <Button type="secondary" dataname="btnSave">
            {t("campaign-details.sms-chanel.cancel-campaign")}
          </Button>
          </Popconfirm>
        </div>
      )}
    </>
  );
}
 
export default SmsDetails;