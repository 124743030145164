import useAuth from "../Hooks/useAuth";
import { userRequest } from "../utils/authConfig";

const UserApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    SetUser: async (user) => {
      
      let accessToken = await passTokenToApi(userRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;

        var requestOptions = {
          method: 'POST',
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
          redirect: 'follow'
        };

        const resource = "/api/CreateUser";
        const url = `${process.env.REACT_APP_USER_API_URL}${resource}`;

        let response = await fetch(url, requestOptions);
        let result = await response.json();

        if (response.status !== 200) {
          let errorMessage = "";
          if (result.message && !result.listErrors) {
            errorMessage = result.message;
          } else if (result.message && result.listErrors) {
            errorMessage = result.listErrors.join(', ');
          }
  
          throw new Error(errorMessage);
        }

        return result;
      } else {
        console.error("User Error");
        return [];
      }

    },
    getUsers: async () => {
      try {
        let accessToken = await passTokenToApi(userRequest);
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;

          var requestOptions = {
            method: 'GET',
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            }
          };

          const resource = "/api/GetUsers";
          const url = `${process.env.REACT_APP_USER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the users. Status Code: ${response.status}`);
          }
          let result = await response.json();
          return result;
        } else {
          console.error("User Error");
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the users.");
      }
    },
    getUsersByCompany: async (companyId) => {
      try {
        let accessToken = await passTokenToApi(userRequest);
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;

          var requestOptions = {
            method: 'GET',
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            }
          };

          const resource = `/api/GetUsers/${companyId}`;
          const url = `${process.env.REACT_APP_USER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the users. Status Code: ${response.status}`);
          }
          let result = await response.json();
          return result;
        } else {
          console.error("User Error");
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the users.");
      }
    },
    updateAvailableUser: async (userId, enabled) => {
      try {
        let accessToken = await passTokenToApi(userRequest);
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;

          var requestOptions = {
            method: 'PUT',
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              UserId: userId,
              State: enabled
            })
          };

          const resource = `/api/UpdateInitialStateUser/${userId}`;
          const url = `${process.env.REACT_APP_USER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);
          let result = await response.json();
          
          if (response.status !== 200) {
            let errorMessage = "";
            if (result.message && !result.listErrors) {
              errorMessage = result.message;
            } else if (result.message && result.listErrors) {
              errorMessage = result.listErrors.join(', ');
            }
            throw new Error(errorMessage);
          }
        } else {
          console.error("User Error");
          return [];
        }
      } catch (error) {
        console.error(error.message);
        throw new Error(error.message);
      }
    },
    getUserData: async (userId) => {
      try {
        let accessToken = await passTokenToApi(userRequest);
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;

          var requestOptions = {
            method: 'GET',
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            }
          };

          const resource = `/api/GetUserData/${userId}`;
          const url = `${process.env.REACT_APP_USER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the user data. Status Code: ${response.status}`);
          }
          let result = await response.json();
          return result;
        } else {
          console.error("User Error");
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the user data.");
      }
    },
    getUsersByIds: async (users) => {
      try {
        let accessToken = await passTokenToApi(userRequest);
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          
          var requestOptions = {
            method: 'POST',
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(users.accountUsers)
          };

          const resource = `/api/GetUsersByIds`;
          const url = `${process.env.REACT_APP_USER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          let result = await response.json();

          if (response.status !== 200) {
            let errorMessage = "";
            if (result.message && !result.listErrors) {
              errorMessage = result.message;
            } else if (result.message && result.listErrors) {
              errorMessage = result.listErrors.join(', ');
            }
          
            throw new Error(errorMessage);
          }

          return result;
        } else {
          console.error("User Error");
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the users data.");
      }
    },
    UpdateCustomAttributes: async (user) => {
      try {
        let accessToken = await passTokenToApi(userRequest);
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;

          var requestOptions = {
            method: 'POST',
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(user)
          };

          const resource = `/api/UpdateCustomAttributes`;
          const url = `${process.env.REACT_APP_USER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the user data. Status Code: ${response.status}`);
          }

          let result = await response.text();
          return result;
        } else {
          console.error("User Error");
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error);
      }
    },
  };
}

export default UserApi;
