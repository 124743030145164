import TYPES from './types'

const initialSpinnerState = {
  show: false,
  items: []
}

const spinerReducer = (state, action) => {
  switch (action.type) {
    case TYPES.SPINNER.SHOW:
      if(action.payload.item) {

        let items = [...state.items];

        if(action.payload.show === true) {
          items.push(action.payload.item)
        } else {
          items = items.filter(x => x !== action.payload.item);
        }

        const newState = {
          ...state,
          show: items.length > 0,
          items: items
        }

        return newState;       
      } else {
        return { ...state }
      }
  }
}

export { initialSpinnerState }
export default spinerReducer;