import React, { useState } from 'react';
import { Button, Tabs, Typography } from 'antd';
import { DeleteFilled, LeftOutlined, PlusCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Dragger from 'antd/lib/upload/Dragger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import { faImage } from '@fortawesome/pro-light-svg-icons';
import "./MobilePreview.css"

const { Text } = Typography;

const MobilePreview = ({ type, previewValues, setMessageData, messageData }) => {
  const [t] = useTranslation("rcs-channel");

  return (
    <div className="rcs-device-mockup">
      <div className="device-frame">
        <div className="notch"></div>
        <div className="device-header">
          <div className='contact'>
            <LeftOutlined className='icon' />
            {type === "basic-message" && previewValues?.displayName ? <Text strong>{previewValues?.displayName}</Text> : <Text strong>{t("step1.mobile-preview.info-options")}</Text>}
          </div>
        </div>
        {previewValues?.bannerBase64 || previewValues?.banner ? 
          <img
            className='banner'
            alt="banner"
            src={previewValues?.previewbannerBase64 || previewValues?.bannerBase64 || previewValues?.banner || ""}
          /> :
          <div className="banner placeholder" style={{ height: "70px", display: "flex", justifyContent: "center", paddingTop: "20px", backgroundColor: "white" }}>
            <FontAwesomeIcon icon={faImage} />
          </div>
        }
        {previewValues?.logoBase64 || previewValues?.logo ?
          <img
            className='logo'
            alt="logo"
            src={previewValues?.previewlogoBase64 || previewValues?.logoBase64 || previewValues?.logo || ""}
          /> :
          <div className='logo placeholder'>
            <FontAwesomeIcon icon={faImage} />
          </div>
        }
        {type === "rich-card" ? <RichCardContent previewValues={previewValues} setMessageData={setMessageData} messageData={messageData} />: null}
        {type === "basic-message" ? <BasicMessageContent previewValues={previewValues} setMessageData={setMessageData} /> : null}
        {type === "campaign-rich-card" ? <CampaignRichCardContent previewValues={previewValues} /> : null}
        {type === "campaign-basic-message" ? <CampaignBasicMessageContent previewValues={previewValues} /> : null}
        {type === "business-info" ? <BusinessInfoContent previewValues={previewValues} />: null}
        {type !== "business-info" ? <div className={`chat-input ${type === "rich-card" ? "top-spacing": ""}`}>
          <PlusCircleFilled className='icon' />
          <div className='input'>
            <span>
              {t("step2.placeholder.rcs-message")}
            </span>
          </div>
        </div> : null}
      </div>
    </div>
  );
};

export default MobilePreview;

const RichCardContent = ({ previewValues, setMessageData, messageData }) => {
  const [t] = useTranslation("rcs-channel");
  const [ fileList, setFileList] = useState([]);
  const [ fileName, setFileName] = useState("");
  const [imagePreview, setImagePreview] = useState("")

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const extension = file?.name?.split(".")[1]
      setImagePreview(reader.result)
      const base64 = reader.result.split(',')[1]
      setMessageData({ ...messageData, imageBase64: base64, imageExtension: `.${extension}` })
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const props = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFileName("");
    },
    beforeUpload: file => {
      getBase64(file)
      setFileList([file]);
      setFileName(file.name);

      return false;
    },
    accept: ".jpeg,.jpg,.png"
  };

  return (
    <Fragment>
      <h4 className='display-name'>{previewValues?.name || t("step2.placeholder.enter-display-name")}</h4>
      <p className='description'>{previewValues?.description || t("step2.placeholder.enter-description")}</p>
        {!messageData?.imageBase64 ? <div className='mobile-preview-dragger-container'>
          <Dragger {...props} className='mobile-preview-dragger'>
            <Button className='file-dragger-button' shape="circle" icon={<FontAwesomeIcon icon={ faUpload } />} size="small" />
            <div className='text-content'>
              <span className="ant-upload-text"><strong>{t("step2.upload-file.label-drag-file-title")}</strong></span>
              <span className="ant-upload-hint">
                {t("step2.upload-file.label-drag-file-subtitle-one")}
              </span>
            </div>
          </Dragger>
        </div> :
        <div style={{ position: "relative", display: "flex", justifyContent: "center", maxHeight: "52px" }}>
          <img
            style={{ maxWidth: "234px", maxHeight: "52px" }}
            alt="logo"
            src={imagePreview || ""}
          />
          <DeleteFilled
            style={{ position: "absolute", right: "1px", top: "40px", color: "lightcoral" }}
            onClick={() => setMessageData({ ...messageData, imageBase64: null, imageExtension: null })}
          />
        </div>
      }
      <div className="chat-preview">
        <div className="message-card">
          <input
            style={{ outline: "none", border: "none", maxHeight: "115px" }}
            placeholder={t("step2.placeholder.type-a-message-title")}
            onChange={e => setMessageData({ ...messageData, title: e.target.value })}
          />
          <div className="inner-message-card">
            <textarea
              style={{ outline: "none", border: "none", minHeight: "30px", maxHeight: "62px", fontSize: "13px" }}
              placeholder={t("step2.placeholder.type-a-message")}
              onChange={e => setMessageData({ ...messageData, text: e.target.value })}
            />
            <div className='message-footer'>
              <div className="footer-text">{previewValues?.footerText || ""}</div>
            </div>
          </div>
          <input
            style={{ outline: "none", border: "none", maxHeight: "115px", fontSize: "12px" }}
            placeholder={t("step2.placeholder.enter-a-url")}
            onChange={e => setMessageData({ ...messageData, url: e.target.value })}
          />
        </div>
      </div>
    </Fragment>
  )
}

const CampaignRichCardContent = ({ previewValues }) => {
  const [t] = useTranslation("rcs-channel");

  return (
    <Fragment>
      <h4 className='display-name'>{previewValues?.name || t("step2.placeholder.enter-display-name")}</h4>
      <p className='description'>{previewValues?.description || t("step2.placeholder.enter-description")}</p>
      {previewValues?.imageBase64 ? <div style={{ position: "relative", display: "flex", justifyContent: "center", maxHeight: "52px" }}>
        <img
          style={{ maxWidth: "234px", maxHeight: "52px" }}
          alt="logo"
          src={previewValues?.imageBase64 || ""}
        />
      </div> : null}
      <div className="chat-preview">
        <div className="message-card">
          <input
            style={{ outline: "none", border: "none" }}
            placeholder={t("step2.placeholder.type-a-message-title")}
            value={previewValues.title}
            readOnly
          />
          <div className="inner-message-card">
            <textarea
              style={{ outline: "none", border: "none", maxHeight: "78px", fontSize: "13px", resize: "none" }}
              placeholder={t("step2.placeholder.type-a-message")}
              value={previewValues.message}
              readOnly
            />
          </div>
          {previewValues?.buttons?.type === "URL" ?
            <div style={{ textAlign: "center", overflow: "hidden", textOverflow: "ellipsis" }}>
              <FontAwesomeIcon icon={faEarthAmericas} style={{ marginRight: "5px" }} />
              <a href={previewValues?.buttons?.action?.data?.[0]?.url} target="_blank" rel="noopener noreferrer">
                {previewValues?.buttons?.action?.data?.[0]?.text}
              </a>
            </div> : previewValues?.buttons?.type === "QUICK_REPLY" ?
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
              <div>{t("brands-list.action-buttons.yes")}</div>
              <hr style={{ width: "90%" }} />
              <div>{t("brands-list.action-buttons.no")}</div>
            </div> : null
          }
        </div>
      </div>
    </Fragment>
  )
}

const BasicMessageContent = ({ previewValues, setMessageData }) => {
  const [t] = useTranslation("rcs-channel");

  return (
    <Fragment>
      <p className='description'>{previewValues?.description || ""}</p>
      <div className="basic-message-bubble-container">
        <img
          className='logo'
          alt="logo"
          src={previewValues?.logoBase64 || previewValues?.logo || ""}
        />
        <div className='basic-message-bubble'>
          <textarea
            style={{ outline: "none", border: "none", maxHeight: "115px" }}
            placeholder={t("step2.placeholder.type-a-message")}
            onChange={e => setMessageData(e.target.value)}
          />
        </div>
      </div>
    </Fragment>
  )
}

const CampaignBasicMessageContent = ({ previewValues }) => {
  const [t] = useTranslation("rcs-channel");

  return (
    <Fragment>
      <p className='description'>{previewValues?.description || ""}</p>
      <div className="basic-message-bubble-container">
        <img
          className='logo'
          alt="logo"
          src={previewValues?.logoBase64 || previewValues?.logo || ""}
        />
        <div className='basic-message-bubble'>
          <textarea
            style={{ outline: "none", border: "none", height: "115px", resize: "none", fontStyle: "italic" }}
            placeholder={t("step2.placeholder.type-a-message-from-left-box")}
            value={previewValues.message}
            readOnly
          />
        </div>
      </div>
    </Fragment>
  )
}

const BusinessInfoContent = ({ previewValues }) => {
  const [t] = useTranslation("rcs-channel");
  const [activeKey, setActiveKey] = useState("info");

  return (
    <Fragment>
      <h4 className='display-name'>{previewValues?.name || t("step1.placeholder.enter-display-name")}</h4>
      <p className='description'>{previewValues?.description || t("step1.placeholder.enter-description")}</p>
      <div style={{ display: "flex", justifyContent: "space-evenly", gap: "10px" }}>
        <div style={{ textAlign: "center", color: previewValues?.phone ? previewValues?.color ? previewValues?.color : "#0978d0" : "" }}>
          <FontAwesomeIcon icon={faPhone} />
          <p>{t("step1.mobile-preview.call")}</p>
        </div>
        <div style={{ textAlign: "center", color: previewValues?.website ? previewValues?.color ? previewValues?.color : "#0978d0" : "" }}>
          <FontAwesomeIcon icon={faEarthAmericas} />
          <p>{t("step1.mobile-preview.website")}</p>
        </div>
        <div style={{ textAlign: "center", color: previewValues?.email ? previewValues?.color ? previewValues?.color : "#0978d0" : "" }}>
          <FontAwesomeIcon icon={faEnvelope} />
          <p>{t("step1.mobile-preview.email")}</p>
        </div>
      </div>
      <Tabs className='business-info-tabs' defaultActiveKey="info" activeKey={activeKey} onChange={key => setActiveKey(key)}>
        <Tabs.TabPane
          key="info"
          tab={
            <div style={{ color: previewValues?.color || "#0978d0", position: "relative" }}>
              {t("step1.mobile-preview.info")}
              {activeKey === "info" ? <div style={{ position: "absolute", border: `1px solid ${previewValues?.color || "#0978d0"}`, bottom: "-12px" }}></div> : null}
            </div>
          }
        >
          <div style={{ padding: "0px 10px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <FontAwesomeIcon icon={faPhone} />
              <div>
              <p style={{ fontSize: "14px" }}>{previewValues?.phone || t("step1.placeholder.enter-phone-number")}</p>
                <p style={{ fontSize: "10px" }}>{t("step1.mobile-preview.phone-number")}</p>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <FontAwesomeIcon icon={faEarthAmericas} />
              <div>
              <p style={{ fontSize: "14px" }}>{previewValues?.website || t("step1.placeholder.enter-website-url")}</p>
                <p style={{ fontSize: "10px" }}>{t("step1.mobile-preview.website")}</p>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <FontAwesomeIcon icon={faEnvelope} />
              <div>
              <p style={{ fontSize: "14px" }}>{previewValues?.email || t("step1.placeholder.enter-email")}</p>
                <p style={{ fontSize: "10px" }}>{t("step1.mobile-preview.email")}</p>
              </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          key="options"
          tab={
            <div style={{ color: previewValues?.color || "#0978d0", position: "relative" }}>
              {t("step1.mobile-preview.options")}
              {activeKey === "options" ? <div style={{ position: "absolute", border: `1px solid ${previewValues?.color || "#0978d0"}`, bottom: "-12px" }}></div> : null}
            </div>
          }
        >
          <div style={{ padding: "0px 10px" }}>
            <a href={previewValues?.privacy} target="_blank" rel="noopener noreferrer">
              {t("step1.mobile-preview.view-privacy-policy")}
            </a>
            <hr />
            <a href={previewValues?.termsConditions} target="_blank" rel="noopener noreferrer">
              {t("step1.mobile-preview.view-tos")}
            </a>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Fragment>
  )
}