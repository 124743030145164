import { useState } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./TopCompanyConsumptions.module.css";
import { useEffect } from "react";
import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import CONSTANTS, { CONSTANPERIODREPORTLIST } from "../../../../utils/const";
import { currencyFormat } from "../../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle, faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import DataApi from "../../../../Api/DataApi";
import { Spin } from "antd";

const TopCompanyConsumptions = ({filter}) => {

  const [t] = useTranslation("home-carrier");
  const {getConsumptionTopCompanies} = DataApi()

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [period, setPiriod] = useState("");

  useEffect(() => {
    setLoading(true);

    getTopCompanyConsumptionsData()
    .then(response => {
      setLoading(false);

      const {chartData} = response;

      setData(chartData);

      if(filter && filter.time) {
        let resultPeriod = CONSTANTS.LIST.PERIODREPORT.find(x => x.key === filter.time);
        setPiriod(resultPeriod.value);
      }
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    })
    
  }, [filter])

  const getTopCompanyConsumptionsData = async () => {

    let data = {
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    }

    let responseData = await getConsumptionTopCompanies(filter.time, data);
    return {chartData: responseData}; 
  }

  const getVariationColor = (variation) => {
    if(variation <= 20) {
      return styles['variation-red'];
    }

    if(variation <= 80) {
      return styles['variation-orange'];
    }

    if(variation > 80) {
      return styles['variation-green'];
    }
  }

  const getVariationIcon = (variation) => {
    if(variation <= 20) {
      return <FontAwesomeIcon style={{ marginRight: "5px", fontSize: "1.2em" }} icon={faCaretDown} />;
    }

    if(variation <= 80) {
      return <FontAwesomeIcon style={{ marginRight: "5px", fontSize: "0.8em" }} icon={faExclamationTriangle} />;
    }

    if(variation > 80) {
      return <FontAwesomeIcon style={{ marginRight: "5px", fontSize: "0.9em" }} icon={faCheckCircle} />;
    }
  }

  return (
    <div className={styles['dashboard-body-card-double']}>
      <CardCustom
        style={{height: "100%", widt:"100%"}}
        title={
        <div className={styles['card-title']}>
          <p>{t("card-company-consumption.label-title")}</p>
          <HelperButton
            message={t("card-company-consumption.tooltip-title")}
            color={getGradientColor(purpleColor)}
          />
        </div>
        }
      >
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              <div className={styles['dashboard-card-body-title']}>
                <p className={styles['dashboard-card-body-period']}>{period}</p>
              </div>
              <table className={styles['dashboard-card-body-table']}>
                <thead>
                  <tr>
                    <th>{t("card-company-consumption.table-head-label-company")}</th>
                    <th>{t("card-company-consumption.table-head-label-sms")}</th>
                    <th>{t("card-company-consumption.table-head-label-voice")}</th>
                    <th>{t("card-company-consumption.table-head-label-balance")}</th>
                    <th>{t("card-company-consumption.table-head-label-total-campaigns")}</th>
                  </tr>
                </thead>
                <tbody>
                {
                  data.map((data, index) =>
                    <tr key={index}>
                      <td>{data.companyName}</td>
                      <td>{currencyFormat(data.smsExpenses, 2)} USD</td>
                      <td>{currencyFormat(data.voiceExpenses, 2)} USD</td>
                      <td>{currencyFormat(data.availableBalance, 2)} USD</td>
                      <td>
                      <div className={`${styles['dashboard-card-body-table-variation']} ${getVariationColor(data.percentage)}`}>
                          <p>{getVariationIcon(data.percentage)}</p>
                          <p>{data.percentage} %</p>
                        </div>
                      </td>
                    </tr>
                  ) 
                }
                </tbody>
              </table>
            </div>
          </Spin>
        </div>
      </CardCustom>
    </div>
  );
}
 
export default TopCompanyConsumptions;