import { Button, Card, Tag, Tooltip, Typography } from "antd";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import HelperButton from "../../../../components/HelperButton/HelperButton";
import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import useSpinner from '../../../../Hooks/useSpinner';
import TYPES from '../../../../reducers/types';
import { AuthContext } from "../../../../contexts/authRedirect";
import CONSTANTS from "../../../../utils/const";
import AgentDetailsStepper from "../AgentDetailsStepper";
import AgentCarriers from "../AgentCarriers";
import RcsApi from "../../../../Api/RcsApi";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import AccountApi from "../../../../Api/AccountApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./RCSAgent.css"

const { Meta } = Card;
const { Title } = Typography;

const RCSAgent = () => {
  const { spinnerDispacth } = useSpinner();
  const [agentDetails, setAgentDetails] = useState({ visible: false, agentId: null });
  const [showCarriers, setShowCarriers] = useState({ visible: false, agentId: null });
  const [shouldRefetch, setShouldRefetch] = useState(true)
  const [agents, setAgents] = useState(null)
  const [t] = useTranslation("rcs-channel");
  const { auth } = useContext(AuthContext);
  const params = useParams()
  const { getBrandAgents } = RcsApi();
  const { GetAccountsByCompanyId } = AccountApi();
  const history = useHistory()

  useEffect(() => {
    if(auth.companyId && shouldRefetch) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.COMPANYACCOUNTS }
      });
      getInitialData()
        .then(() => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.COMPANYACCOUNTS }
          });
          setShouldRefetch(false)
        })
    }
  }, [auth.companyId, shouldRefetch]);

  const addAccountNameToAgents = (accounts, agents) => {
    return agents.map(agent => {
        const matchingAccount = accounts.find(account => account.accountId === agent.accountId);
        if (matchingAccount) {
            agent.accountName = matchingAccount.name;
        }
        return agent;
    });
  }

  const getInitialData = async () => {
    try {
      if (auth.companyId) {
        const _agents = await getBrandAgents(params?.brand || "");
        const _accounts = await GetAccountsByCompanyId(auth.companyId, auth.accessToken)
        const updatedAgents = addAccountNameToAgents(_accounts, _agents?.data || []);
        setAgents(updatedAgents || [])
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  const getStatus = (status) => {
    switch (status) {
      case "VERIFICATION_STATE_UNSPECIFIED":
        return {
          tag: t("agents-list.status-tag.unspecified"),
          tooltip: t("agents-list.status-tooltip.unspecified")
        }
      case "VERIFICATION_STATE_UNVERIFIED":
        return {
          tag: t("agents-list.status-tag.unverified"),
          tooltip: t("agents-list.status-tooltip.unverified")
        }
      case "VERIFICATION_STATE_PENDING":
        return {
          tag: t("agents-list.status-tag.pending"),
          tooltip: t("agents-list.status-tooltip.pending")
        }
      case "VERIFICATION_STATE_VERIFIED":
        return {
          tag: t("agents-list.status-tag.verified"),
          tooltip: t("agents-list.status-tooltip.verified")
        }
      case "UNKNOWN":
        return {
          tag: t("agents-list.status-tag.unknown"),
          tooltip: t("agents-list.status-tooltip.unknown")
        }
      default:
        return {
          tag: t("agents-list.status-tag.unknown"),
          tooltip: t("agents-list.status-tooltip.unknown")
        }
    }
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "VERIFICATION_STATE_VERIFIED":
        return "#006978"
      case "VERIFICATION_STATE_PENDING":
        return "#fa8c16"
      default:
        return "gray"
    }
  }

  return (
    <Fragment>
      {!agentDetails.visible ?
        <div className="yp-workspace">
          <Title level={4} style={{ textAlign: "center", color: "#5E475E", marginBottom: "20px" }}>
            {history?.location?.state?.brandName}
          </Title>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            <Card
              id='create-new-agent'
              key='new'
              style={{ width: 400 }}
              hoverable
              onClick={() => setAgentDetails({ visible: true, agentId: null })}
              cover={
                <div>
                  <div style={{ backgroundColor: "#a28aa2", height: "10px" }} />
                  <div style={{ backgroundColor: "#5E475E", minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ backgroundColor: "#a28aa2", padding: "10px", borderRadius: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <PlusOutlined style={{ fontSize: '42px', color: "white" }} />
                    </div>
                  </div>
                </div>
              }
            >
              <Meta className="create-card-meta" title={<p style={{ textAlign: "center" }}>{t("agents-list.label.create-new-agent")}</p>} />
            </Card>
            {(agents || []).map(a => (
              <Card
                className="waba-card"
                key={a.rcsAgentId}
                style={{ minWidth: "400px", width: "400px", padding: "0px" }}
                headStyle={{ background: "#a28aa2", minHeight: "30px", padding: "0px 10px", color: "white", fontSize: "14px", height: "44px", }}
                bodyStyle={{ display: "flex", flexDirection: "column", padding: "10px", height: "calc(100% - 44px)" }}
                title={
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%", height: "100%", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                      <span style={{ paddingRight: "8px" }}>{t("agents-list.label.status")}:</span>
                      <Tooltip id="template-list-cannot-update-pending-tooltip" title={getStatus(a.status).tooltip} color={getGradientColor(purpleColor)} overlayInnerStyle={{padding: 20}}>
                        <Tag color={getStatusColor(a.status)}>{getStatus(a.status).tag}</Tag>
                      </Tooltip>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Button
                        id='edit-account-button'
                        onClick={() => setAgentDetails({
                          visible: true,
                          agentId: a.rcsAgentId,
                        })}
                        icon={<EyeOutlined />}
                        style={{ marginRight: "0px" }}
                      />
                    </div>
                  </div>
                }
              >
                <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                  <h3 style={{ maxWidth: "50%", wordBreak: "break-all" }}>{a.name}</h3>
                </div>
                <div style={{ paddingTop: "16px", marginBottom: "10px" }}>
                  <p>{t("agents-list.label.hosting-region")}: {a.hostingRegion}</p>
                  <p>{t("agents-list.label.billing-category")}: {a.billingCategory}</p>
                  <p>{t("agents-list.label.use-case")}: {a.agentUseCase}</p>
                  <p>{t("agents-list.label.yp-account")}: {a.accountName}</p>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "auto", alignItems: "center" }}>
                  <Button
                    id='view-carriers-button'
                    type="primary"
                    size="small"
                    style={{ color: "black", fontSize: "12px", marginRight: "0px" }}
                    onClick={() => setShowCarriers({ visible: true, agentId: a.rcsAgentId })}
                  >
                    {t("agents-list.button.view-carriers")}
                  </Button>
                  <span>
                    <Button
                      id='go-to-create-campaign-button'
                      type="primary"
                      size="small"
                      style={{ color: "black", fontSize: "12px", marginRight: "0px" }}
                      disabled={a.status !== "VERIFICATION_STATE_VERIFIED"}
                      onClick={() => history.push({
                        pathname: "/Campaigns/RCS",
                        state: {
                          agentName: a.name,
                          brandName: a.rcsBrandName,
                          accountName: a.accountName,
                          agentId: a.rcsAgentId,
                          brandId: a.rcsBrandId,
                          accountId: a.accountId,
                        }
                      })}
                    >
                      {t("agents-list.button.go-to-create-campaign")}
                    </Button>
                    <HelperButton id='create-campaign-button-tooltip' message={t("agents-list.tooltip.go-to-create-campaign")} color={getGradientColor(purpleColor)} />
                  </span>
                </div>
              </Card>
            ))}
          </div>
        </div> :
        <AgentDetailsStepper agentId={agentDetails.agentId} setAgentDetails={setAgentDetails} setShouldRefetch={setShouldRefetch} />
      }
      {showCarriers.visible && <AgentCarriers
        visible={showCarriers.visible}
        onClose={() => setShowCarriers({ visible: false, agentId: null })}
        agentId={showCarriers.agentId}
      />}
    </Fragment>
  );
}
 
export default RCSAgent;