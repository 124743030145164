import { faTag, faAddressBook, faAddressCard, faPhone } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styles from "./Summary.module.css";

const Summary = ({groupName,
  totalContacts,
  totalWildcards,
  mobileNumberColumn}) => {

  const [t] = useTranslation("create-contacts");

  const MAX_CHARACTERS = 30;

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  return (
    <div className={styles['summary-info']}>
      <div className={styles['message-information']}>
        <div className={styles['message-information-text']}>
          <p>{t("summary.label-group-name")}</p>
          <p className={styles['message-information-text-value']}>
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faTag} /> 
            {truncateText(groupName, MAX_CHARACTERS)}
          </p>
        </div>
      </div>
      <div className={styles['message-information']}>
        <div className={`${styles['message-information-text']} ${styles['orange-color']}`}>
        <p>{t("summary.label-total-contacts")}</p>
          <p className={styles['message-information-text-value']}>
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faAddressBook} /> {totalContacts}
          </p>
        </div>
      </div>
      <div className={styles['message-information']}>
        <div className={styles['message-information-text']}>
        <p>{t("summary.label-total-wildcards")}</p>
          <p className={styles['message-information-text-value']}>
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faAddressCard} /> {totalWildcards}
          </p>
        </div>
      </div>
      <div className={styles['message-information']}>
        <div className={styles['message-information-text']}>
        <p>{t("summary.label-column-title-phone")}</p>
          <p className={styles['message-information-text-value']}>
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faPhone} /> {mobileNumberColumn}
          </p>
        </div>
      </div>
    </div>
  );
}
 
export default Summary;