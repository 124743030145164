import TYPES from './types'

const initialContactsState = {
  listGroupName: "",
  accountsSelected: [],
  file: null,
  fileData: [],
  fileHeaders: [],
  fileRows: 0,
  headersMap: {
    mobileNumberColumn: null,
    customFields: [],
    selectedHeaders: []
  },
  wildcards: []
}

const contactsReducer = (state, action) => {
  switch (action.type) {
    case TYPES.CONTACTS.ADD_LISTGROUP_NAME:
      return {
        ...state,
        listGroupName: action.payload
      }
    case TYPES.CONTACTS.ADD_ACCOUNTS:
      return {
        ...state,
        accountsSelected: action.payload
      }
    case TYPES.CONTACTS.ADD_FILE:
      return {
        ...state,
        file: action.payload
      }
    case TYPES.CONTACTS.ADD_FILEDATA:
      return {
        ...state,
        fileData: action.payload
      }
    case TYPES.CONTACTS.ADD_FILEHEADERS:
      return {
        ...state,
        fileHeaders: action.payload
      }
    case TYPES.CONTACTS.ADD_FILEROWS:
      return {
        ...state,
        fileRows: action.payload
      }
    case TYPES.CONTACTS.ADD_SELECTEDHEADERS:
      return {
        ...state,
        headersMap: {
          ...state.headersMap,
          selectedHeaders: action.payload
        }
      }
    case TYPES.CONTACTS.ADD_MOBILENUMBERCOLUMN:
      return {
        ...state,
        headersMap: {
          ...state.headersMap,
          mobileNumberColumn: action.payload
        }
      }
    case TYPES.CONTACTS.ADD_CUSTOMEFIELDS:
      return {
        ...state,
        headersMap: {
          ...state.headersMap,
          customFields: action.payload
        }
      }
  }
}

export { initialContactsState }
export default contactsReducer;