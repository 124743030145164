import React from 'react';
import { Row, Card, Col } from 'antd';
import { StarFilled } from '@ant-design/icons';
import { currencyFormat, dateFormat } from "../../../../utils/helpers";
import styles from "./AccountBalance.module.css";
import MiniCard from "../../../MiniCard/MiniCard";
import propTypes from 'prop-types'
import Button from '../../../Button/Button'
import { useTranslation } from 'react-i18next'

const AccountBalance = ({data, showAssingBalance}) => {
  console.log('Datos recibidos:', data);
  const [t] = useTranslation("balance");
  const { id,
    accountName,
    lastAddedBalance,
    isDefaultAccount,
    totalBalance,
    availableBalance,
    onHoldBalance,
    createdDate,
  } =  data;

    // Console para ver la fecha de creación
    console.log('Fecha de creación:', createdDate);

  // Determina qué fecha mostrar
  const displayedDate = lastAddedBalance && lastAddedBalance !== '0001-01-01T00:00:00Z'
    ? dateFormat(lastAddedBalance)
    : createdDate
    ? dateFormat(createdDate)
    : 'Fecha no disponible';

  // Console para ver las fechas que recibo y que muestro
  console.log('Fecha original:', lastAddedBalance);
  console.log('Fecha formateada:', dateFormat(lastAddedBalance));

  const assingBalance = () => {
    showAssingBalance(true, id);
  }

  return (
    <Card
      data-testid="account-balance-card"
      className="card-metrics"
    >
      <Row gutter={[0, 8]}>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={6}
        >
          <div>
            {
              isDefaultAccount ? (
                <p className='yp-title'> {accountName} <span className={styles['title-star']}><StarFilled /></span></p>
              ) : (
                <p className='yp-title'> {accountName}</p>
              )
            }
            <p><strong>{t("accounts-balance-card.label-last-date")}</strong> {displayedDate}</p>
            {/* <p><strong>{t("accounts-balance-card.label-last-date")}</strong> {lastAddedBalance && lastAddedBalance !== '0001-01-01' ? dateFormat(lastAddedBalance) : t("accounts-balance-card.no-balance-assigned")}</p> */}
          </div>
          {
            isDefaultAccount ? (
              <Button dataName={`button-assing-credit-${id}`} style={{ width: 210 }} type="optional" onClick={() => assingBalance(true)}>
                {t("accounts-balance-card.button-assing-credit")}
              </Button>
            ) : (
              <Button dataName={`button-assing-credit-${id}`} style={{ width: 210 }} type="primary" onClick={() => assingBalance(true)}>
                {t("accounts-balance-card.button-assing-balance")}
              </Button>
            )
          }
        </Col>
        <Col
          className={styles['text-aling-right']}
          xs={24}
          sm={24}
          md={18}
          lg={18}
          xl={18}
        >
          <div className={styles['details']}>
            <MiniCard
              title={t("accounts-balance-card.label-available")}
              value={currencyFormat(availableBalance) }
            />
            <MiniCard
              title={t("accounts-balance-card.label-onhold")}
              value={currencyFormat(onHoldBalance)}
            />
            <MiniCard
              title={t("accounts-balance-card.label-total")}
              value={currencyFormat(totalBalance)}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
}

AccountBalance.propTypes = {
  data: propTypes.object.isRequired
}

export default AccountBalance;