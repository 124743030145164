import { Button, Checkbox, Col, Form, Input, Row, Upload } from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import CONSTANTS from "../../../utils/const";
import encodingSevenBits, { isValidImageFileType, notificationError } from "../../../utils/helpers";
import HelperButton from "../../HelperButton/HelperButton";
import MessageWildcard from "../../MessageWildcard/MessageWildcard";
import styles from "./ContentMessage.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/pro-solid-svg-icons";

const { Dragger } = Upload

const ContentRichMessage = ({
  customFields,
  defaultTitle,
  defaultMessage,
  defaultWildCards,
  onChangeMessage,
  onChangeWildCards,
  rcsCampaignData,
  setRcsCampaignData,
}) => {


  const [t] = useTranslation("rcs-campaign");

  const [title, setTitle] = useState(defaultTitle.length > 0 ? defaultTitle : "");
  const [isBasicEncoding, setIsBasicEncoding] = useState(true);
  const [specialCharacters, setSpecialCharacters] = useState([]);
  const [fileData, setFileData] = useState(null)
  const [selectedAction, setSelectedAction] = useState(rcsCampaignData?.buttons ? { type: rcsCampaignData?.buttons?.type, data: rcsCampaignData?.buttons?.action?.data } : null)

  useEffect(() => {
    if(defaultMessage.message) {
      onChangeWildcardMessage(defaultMessage);
    }
  }, [defaultMessage])

  useEffect(() => {
    if(selectedAction) {
      setRcsCampaignData({ ...rcsCampaignData, buttons: { type: selectedAction.type, action: selectedAction.type === "URL" ? { data: [{ ...selectedAction.data, type: "URL" }] } : selectedAction }})
    }
  }, [selectedAction])

  const handleTitleChange = e => {
    const value = e.target.value;
    if (value.length <= 50) {
      setTitle(value);
      setRcsCampaignData({ ...rcsCampaignData, title: value })
    }
  };

  const onChangeWildcardMessage = message => {
    let ms = message.message.replaceAll(CONSTANTS.WILDCARD, '');
    onChangeMessage(message);

    var isBasic = isBasicEncoding;
    var hasSpecialCharacters = false;

    if (ms.length === 0) {
      setIsBasicEncoding(true);
    } else {

      hasSpecialCharacters = specialCharacters.some(v => ms.includes(v));

      if (hasSpecialCharacters) {
        setIsBasicEncoding(false);
      }
      else {

        let chart = ms.charAt(ms.length - 1);
        isBasic = encodingSevenBits.includes(chart) ? true : false;

        if (isBasic && !hasSpecialCharacters) {
          setIsBasicEncoding(true);
        } else {

          if (isBasic) {
            setSpecialCharacters([...specialCharacters, chart])
          }
          setIsBasicEncoding(false);
        }

        isBasic ? setIsBasicEncoding(true) : setSpecialCharacters([...specialCharacters, chart]);

      }
    }
  }

  const getBase64 = (file, field) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const extension = file?.name?.split(".")[1]
      setRcsCampaignData({ ...rcsCampaignData, [field]: reader.result, [`preview${field}`]: reader.result, imageExtension: `.${extension}` })
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const bannerProps = {
    key: "banner",
    onRemove: file => {
      const index = fileData?.banner?.fileList.indexOf(file);
      const newFileList = fileData?.banner?.fileList.slice();
      newFileList.splice(index, 1);
      setFileData({ ...fileData, banner: { fileList: newFileList, name: "" }})
      setRcsCampaignData({ ...rcsCampaignData, imageBase64: null, [`previewimageBase64`]: null, imageExtension: null })
    },
    beforeUpload: file => {
      getBase64(file, "imageBase64")
      setFileData({ ...fileData, banner: { fileList: [file], name: file.name }})
      return false;
    },
    onDrop(e) {
      const file = e.dataTransfer.files[0];
      if(!isValidImageFileType(file.type)) {
        let text = t("step1.rules.valid-image-file-type");
        notificationError(text);
      }
    },
    fileList: fileData?.banner?.fileList || [],
    accept: ".jpeg,.jpg,.png",
  };

  return (
    <>
      <div className={styles['message-form']}>
        <div>
          <Form layout="vertical">
            <Form.Item
              label={
                <div>
                  {t("content-message.label-title")}
                  <HelperButton 
                    message={t("content-message.tooltip-title")}
                    color={getGradientColor(purpleColor)}
                  />
                </div>
              }
            >
              <Row>
                <Col>
                  <Input
                    style={{ width: 500 }}
                    placeholder={t("content-message.placeholder-title")}
                    value={title}
                    onChange={handleTitleChange}
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              label={
                <div>
                  {t("content-message.label-message")}
                  <HelperButton 
                    message={t("content-message.tooltip-message")}
                    color={getGradientColor(purpleColor)}
                  />
                </div>
              }
            >
              <Row>
                <Col>
                  <MessageWildcard
                    contentEditable={true}
                    wildcardsFields={customFields}
                    defaultMessage={defaultMessage}
                    defaultWildCards={defaultWildCards}
                    onChangeMessage={onChangeWildcardMessage}
                    onChangeWildCards={onChangeWildCards}
                    disabled={false}
                  >
                  </MessageWildcard>
                </Col>
              </Row>
            </Form.Item>
            <div style={{ marginBottom: "16px" }}>
              <Dragger {...bannerProps}>
                <Button
                  className='file-dragger-button' shape="circle" icon={<FontAwesomeIcon icon={ faUpload } />} size="large"
                />
                <p className="ant-upload-hint">
                  {t("upload-file.label-drag-file-subtitle-one")}
                </p>
              </Dragger>
            </div>
            <label style={{ marginTop: "10px" }}>{t("content-message.suggested-actions")}</label>
            <Row>
              <Col span={12} className='template-suggested-replies-field'>
                <div className='action-container'>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Form.Item
                      name="suggestedReplies"
                      label={t("content-message.suggested-replies")}
                    >
                      <Checkbox
                        id="create-template-suggested-replies-input"
                        onChange={() => setSelectedAction({
                          type: "QUICK_REPLY",
                          data: [
                            { type: "QUICK_REPLY", text: t("options.yes") },
                            { type: "QUICK_REPLY", text: t("options.no") }
                          ]
                        })}
                        checked={selectedAction?.type === "QUICK_REPLY"}
                        />
                    </Form.Item>
                    <div className='group-options'>
                      <Checkbox.Group
                        disabled
                        options={[t("options.yes"), t("options.no")]}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={12} className='template-url-field'>
                <div className='action-container'>
                  <Form.Item
                    name="url"
                    label={t("content-message.url")}
                  >
                    <Checkbox
                      id="create-template-url-input"
                      onChange={() => setSelectedAction({ type: "URL" })}
                      checked={selectedAction?.type === "URL"}
                    />
                  </Form.Item>
                  <div className='inputs'>
                    <Form.Item
                      name="url"
                      rules={[
                        {
                          type: 'url',
                          message: t("rules.valid-url"),
                        },
                        {
                          required: selectedAction?.type === "URL",
                          message: t("rules.valid-url"),
                        },
                      ]}
                      style={{ width: "100%", marginBottom: "0px" }}
                    >
                      <Input
                        id="create-template-url-url-input"
                        style={{ width: "100%" }}
                        disabled={selectedAction?.type !== "URL"}
                        onChange={(e) => setSelectedAction({ ...selectedAction, data: { ...selectedAction?.data, url: e.target.value } })}
                        value={selectedAction?.data?.url || ""}
                        placeholder={t("content-message.url-placeholder")}
                        type='url'
                      />
                    </Form.Item>
                    <Form.Item
                      name="urltext"
                      rules={[
                        {
                          required: selectedAction?.type === "URL",
                          message: t("rules.url-text-required"),
                        },
                      ]}
                      style={{ width: "100%", marginBottom: "0px" }}
                    >
                      <Input
                        id="create-template-url-text-input"
                        disabled={selectedAction?.type !== "URL"}
                        onChange={(e) => setSelectedAction({ ...selectedAction, data: { ...selectedAction?.data, text: e.target.value } })}
                        placeholder={t("content-message.url-text-placeholder")}
                        value={selectedAction?.data?.text || ""}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}
 
export default ContentRichMessage;