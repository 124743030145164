import useAuth from "../Hooks/useAuth";
import { rcsRequest, campaignManagerRequest } from "../utils/authConfig";

const RcsApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    getBrands: async (companyId) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'GET',
            headers
          };
    
          const resource = `/api/RCS/Company/${companyId}/Brands`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
    
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred getting the brands by company id.");
          }
    
          let result = await response.json();
          return result;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the brands by company id.");
      }
    },
    getBrandById: async (brandId) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'GET',
            headers
          };
    
          const resource = `/api/RCS/Brand/${brandId}`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
    
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred getting the brand.");
          }
    
          let result = await response.json();
          return result;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the brand.");
      }
    },
    getBrandsByAccountId: async (accountId) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'GET',
            headers
          };
    
          const resource = `/api/RCS/Account/${accountId}/Brands`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
    
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred getting the account brands.");
          }
    
          let result = await response.json();
          return result;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the account brands.");
      }
    },
    getBrandAgents: async (brandId) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'GET',
            headers
          };
    
          const resource = `/api/RCS/Brand/${brandId}/Agents`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
    
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred getting the brand agents.");
          }
    
          let result = await response.json();
          return result;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the brand agents.");
      }
    },
    getApprovedBrandAgents: async (brandId) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'GET',
            headers
          };
    
          const resource = `/api/RCS/Brand/${brandId}/Approved/Agents`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
    
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred getting the brand agents.");
          }
    
          let result = await response.json();
          return result;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the brand agents.");
      }
    },
    createBrand: async (data) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);
  
          var requestOptions = {
            method: "POST",
            headers,
            body: JSON.stringify(data)
          };
  
          const resource = `/api/RCS/Brand`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
          
          let response = await fetch(url, requestOptions);
  
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred creating the brand.");
          }
  
          let result = await response.text();
          return result;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred creating the brand.");
      }
    },
    createAgent: async (data) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);
  
          var requestOptions = {
            method: "POST",
            headers,
            body: JSON.stringify(data)
          };
  
          const resource = `/api/RCS/Brand/Agent`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
          
          let response = await fetch(url, requestOptions);
  
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred creating the agent.");
          }
  
          let result = await response.text();
          return result;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred creating the agent.");
      }
    },
    getAgentById: async (agentId) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'GET',
            headers
          };
    
          const resource = `/api/RCS/Brand/Agent/${agentId}`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
    
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred getting the agent.");
          }
    
          let result = await response.json();
          return result;   
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the agent.");
      }
    },
    createAgentTestDevice: async (data) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: "POST",
          headers,
          body: JSON.stringify(data)
        };

        const resource = `/api/RCS/Brand/Agent/Tester`;
        const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
        
        let response = await fetch(url, requestOptions);

        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred creating the test device.");
        }

        let result = await response.text();
        return result;
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred creating the test device.");
      }
    },
    getAgentTestDevices: async (agentId) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);
  
          var requestOptions = {
            method: 'GET',
            headers
          };
    
          const resource = `/api/RCS/Brand/Agent/${agentId}/Testers`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
    
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred getting the agent test devices.");
          }
    
          let result = await response.json();
          return result;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the agent test devices.");
      }
    },
    createVerification: async (data) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${accessToken}`);
  
          var requestOptions = {
            method: "POST",
            headers,
            body: JSON.stringify(data)
          };
  
          const resource = `/api/RCS/Brand/Agent/Verify`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
          
          let response = await fetch(url, requestOptions);
  
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred submitting the verification form.");
          }
  
          let result = await response.text();
          return result;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred submitting the verification form.");
      }
    },
    createLaunch: async (data) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);
  
          var requestOptions = {
            method: "POST",
            headers,
            body: JSON.stringify(data)
          };
  
          const resource = `/api/RCS/Brand/Agent/Launch`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
          
          let response = await fetch(url, requestOptions);
  
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred submitting the launch form.");
          }
  
          let result = await response.text();
          return result;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred submitting the launch form.");
      }
    },
    getAgentCarriers: async (agentId) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);
  
          var requestOptions = {
            method: 'GET',
            headers
          };
    
          const resource = `/api/RCS/Brand/Agent/${agentId}/Carriers`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
    
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred getting the agent carriers.");
          }
    
          let result = await response.json();
          return result;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the agent carriers.");
      }
    },
    getCarriers: async () => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'GET',
            headers
          };
    
          const resource = `/api/RCS/Carriers`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
    
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred getting the carriers.");
          }
    
          let result = await response.json();
          return result;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the carriers.");
      }
    },

    sendRCSTestMessage: async (data) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);
  
          var requestOptions = {
            method: "POST",
            headers,
            body: JSON.stringify(data)
          };
  
          const resource = `/api/Campaign/SendIndividualRCSMessage`;
          const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;
          
          let response = await fetch(url, requestOptions);
  
          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred sending the test message.");
          }
  
          let result = await response.text();
          return result;
        } else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred sending the test message.");
      }
    },
    getMessageCost: async (data) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if(accessToken)
        {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: "POST",
          headers,
          body: JSON.stringify(data)
        };

        const resource = `/api/Campaign/RCSMessageCost`;
        const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;
        
        let response = await fetch(url, requestOptions);

        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred getting the message cost.");
        }

        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the message cost.");
      }
    },
    createCampaignWithFile: async (formData) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if(accessToken)
        {
        var headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: "POST",
          headers,
          body: formData
        };

        const resource = `/api/Campaing/CreateCampaignWithFile`;
        const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;
        
        let response = await fetch(url, requestOptions);

        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred creating the campaign.");
        }

        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred creating the campaign.");
      }
    },
    createCampaignWithContacts: async (formData) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if(accessToken)
        {
        var headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: "POST",
          headers,
          body: formData
        };

        const resource = `/api/Campaing/CreateCampaignWithContacts`;
        const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;
        
        let response = await fetch(url, requestOptions);

        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred creating the campaign.");
        }

        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred creating the campaign.");
      }
    },
    getDataFileByPrefix: async (campaignId, prefix) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'GET',
            headers,
          };

          const resource = `/api/campaign/${campaignId}/datafile/${prefix}`;
          const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred get data file by prefix.");
          }

          let result = await response.json();
          return result;
       }
        else {
          console.log('Authentication Error');
          return null;
        } 
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred get data file by prefix.");
      }
    },
    sendCampaign: async (campaign) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify(campaign)
          };

          const resource = "/api/Campaign/SendRCSCampaign";
          const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred sending the campaign.");
          }
  
          let result = await response.json();
          return result;
        }else{
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred sending the campaign.");
      }
    },
    deleteCampaign: async (campaignId) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'DELETE',
            headers,
          };

          const resource = `/api/Campaign/Delete/${campaignId}`;
          const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred deleting the campaign.");
          }
  
          let result = await response.json();
          return result;
         }
        else {
          console.log('Authentication Error');
          return null;
        } 
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred deleting campaign.");
      }
    },
    
    schedule: async (campaignId, data) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if (accessToken) {
          var headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
          };

          const resource = `/api/campaign/${campaignId}/scheduled`;
          const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred generating the schedule");
          }

          let result = await response.text();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred generating the schedule");
      }
    },
  }
}




export default RcsApi ;