import TYPES from './types'

const initialCampaignState = {
  campaignName: "",
  account: null,
  template: null,
  file: null,
  fileData: [],
  fileHeaders: [],
  fileRows: 0,
  loadContacts: false,
  contactSelected: null,
  headersMap: {
    mobileNumberColumn: null,
    customFields: [],
    selectedHeaders: []
  },
  senderId: "",
  message: {
    message: "",
    html: ""
  },
  voice: null,
  wildcards: [],
  campaignId: null,
  scheduleData: null,
  summary: null
}

const campaignReducer = (state, action) => {
  switch (action.type) {
    case TYPES.CAMPAIGN.ADD_CAMPAINGNAME:
      return {
        ...state,
        campaignName: action.payload
      }
    case TYPES.CAMPAIGN.ADD_ACCOUNT:
      return {
        ...state,
        account: action.payload
      }
    case TYPES.CAMPAIGN.ADD_FILE:
      return {
        ...state,
        file: action.payload
      }
    case TYPES.CAMPAIGN.ADD_FILEDATA:
      return {
        ...state,
        fileData: action.payload
      }
    case TYPES.CAMPAIGN.ADD_FILEHEADERS:
      return {
        ...state,
        fileHeaders: action.payload
      }
    case TYPES.CAMPAIGN.ADD_FILEHEADERS:
      return {
        ...state,
        fileHeaders: action.payload
      }
    case TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN:
      return {
        ...state,
        headersMap: {
          ...state.headersMap,
          mobileNumberColumn: action.payload
        }
      }
    case TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS:
      return {
        ...state,
        headersMap: {
          ...state.headersMap,
          customFields: action.payload
        }
      }
    case TYPES.CAMPAIGN.ADD_SELECTEDHEADERS:
      return {
        ...state,
        headersMap: {
          ...state.headersMap,
          selectedHeaders: action.payload
        }
      }
    case TYPES.CAMPAIGN.ADD_MESSAGE:
      return {
        ...state,
        message: action.payload
      }
    case TYPES.CAMPAIGN.ADD_WILDCARDS:
      return {
        ...state,
        wildcards: action.payload
      }
    case TYPES.CAMPAIGN.ADD_SENDERID:
      return {
        ...state,
        senderId: action.payload
      }
    case TYPES.CAMPAIGN.ADD_FILEROWS:
      return {
        ...state,
        fileRows: action.payload
      }
    case TYPES.CAMPAIGN.ADD_CAMPAIGNID:
      return {
        ...state,
        campaignId: action.payload
      }
    case TYPES.CAMPAIGN.ADD_SCHEDULE:
      return {
        ...state,
        scheduleData: action.payload
      }
    case TYPES.CAMPAIGN.ADD_SUMMARY:
      return {
        ...state,
        summary: action.payload
      }
    case TYPES.CAMPAIGN.LOAD_CONTACTS:
      return {
        ...state,
        loadContacts: action.payload
      }
    case TYPES.CAMPAIGN.ADD_CONTACT:
      return {
        ...state,
        contactSelected: action.payload
      }
    case TYPES.CAMPAIGN.ADD_VOICE:
      return {
        ...state,
        voice: action.payload
      }
    case TYPES.CAMPAIGN.SELECT_TEMPLATE:
      return {
        ...state,
        template: action.payload
      }
    default:
      return state;
  }
}

export { initialCampaignState }
export default campaignReducer;