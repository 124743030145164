import { useEffect, useState } from "react";
import styles from "./ShortlinksReport.module.css";
import ShortlinkApi from "../../Api/ShortlinkApi";
import useAuth from "../../Hooks/useAuth";
import useSpinner from "../../Hooks/useSpinner";
import TYPES from "../../reducers/types";
import CONSTANTS from "../../utils/const";
import TableCustom from "../../components/TableCustom/TableCustom";
import TableHeadCustom from "../../components/TableCustom/TableHeadCustom/TableHeadCustom";
import TableBodyCustom from "../../components/TableCustom/TableBodyCustom/TableBodyCustom";
import TableBodyTrCustom from "../../components/TableCustom/TableBodyTrCustom/TableBodyTrCustom";
import { dateFormat } from "../../utils/helpers";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";

const ShortlinksReport = () => {

  const { auth } =  useAuth();
  const { spinnerDispacth } = useSpinner();
  const { getReport } = ShortlinkApi();

  const [t] = useTranslation("shortlink-report");

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(CONSTANTS.PAGINATION);
  const [totalPage, setTotalPage] = useState(0);
  const [dataFiltered, setDataFiltered] = useState([]);

  useEffect(() => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
    });

    getReport(auth.companyId)
    .then(response => {
      setData(response);
      setTotalPage(response.length);
      setDataFilteredByPage(response, 1);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
      });
    })
    .catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
      });
    })
  }, [])

  const onChangePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    setDataFilteredByPage(data, pageNumber);
  }

  const setDataFilteredByPage = (items, page) => {
    const index = (page - 1) * itemsPerPage;
    const currentData = items.slice(index, index + itemsPerPage);
    setDataFiltered(currentData);
  }

  return (
    <div className={styles['container']}>
      <div className={styles['pagination']}>
        <Pagination 
          defaultCurrent={currentPage}
          current={currentPage}
          onChange={(pageNumber) => onChangePagination(pageNumber)}
          total={totalPage}
          pageSize={itemsPerPage}
          showSizeChanger={false}
        />
      </div>
      <TableCustom>
        <TableHeadCustom>
          <th>
            {t("grid.header-label-campaign-name")}
          </th>
          <th>
            {t("grid.header-label-campaign-date")}
          </th>
          <th>
            {t("grid.header-label-link")}
          </th>
          <th>
            {t("grid.header-label-messages-number")}
          </th>
          <th>
            {t("grid.header-label-total-openings")}
          </th>
          <th>
            {t("grid.header-label-convertion-rates")}
          </th>
        </TableHeadCustom>
        <TableBodyCustom>
          {
            dataFiltered.map((data) =>
              <TableBodyTrCustom key={data.campaignId}>
                <td>
                  {data.campaignName}
                </td>
                <td>
                  {dateFormat(data.campaignDate, true)}
                </td>
                <td>
                  {data.link}
                </td>
                <td>
                  {data.numberMessages}
                </td>
                <td>
                  {data.totalOpenings}
                </td>
                <td>
                  {data.openingRate}%
                </td>
              </TableBodyTrCustom>
            )
          }
        </TableBodyCustom>
      </TableCustom>
    </div>
  );
}
 
export default ShortlinksReport;