import useAuth from "../Hooks/useAuth";
import { textToSpeechRequest } from "../utils/authConfig";

const TextToSpeechApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    getAudio: async (raw) => {
      try {
        let accessToken = await passTokenToApi(textToSpeechRequest);
        if (accessToken) {

          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(raw)
          };

          const resource = `/api/Speech/Text`;
          const url = `${process.env.REACT_APP_TEXT_TO_SPEECH_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);
          return await response.arrayBuffer();
        } else {
          console.error("Text to speech Error");
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the audio.");
      }
    }
  };
}

export default TextToSpeechApi;
