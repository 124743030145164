import styles from "./TableCardBodyTrCustom.module.css";

const TableCardBodyTrCustom = ({children, isSelected}) => {
  return (
    <tr className={isSelected ? styles['selected'] : styles['unselected']}>
      {children}
    </tr>
  );
}
 
export default TableCardBodyTrCustom;