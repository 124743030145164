import { Input } from 'antd';
import CardFilter from "../../components/CardFilter/CardFilter";
import CardCreate from '../../components/CardCreate/CardCreate';
import { useEffect, useState } from 'react';
import SmsApiKeyForm from '../../components/Developers/SmsApiKeyForm/SmsApiKeyForm';
import ApiKeyCard from '../../components/Developers/ApiKeyCard/ApiKeyCard';
import ApiKeyApi from '../../Api/ApiKeyApi';
import useAuth from '../../Hooks/useAuth';
import useSpinner from '../../Hooks/useSpinner';
import TYPES from '../../reducers/types';
import CONSTANTS from '../../utils/const';
import { filterdAccountsByUser, notificationError, notificationOk } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import AccountApi from '../../Api/AccountApi';

const { Search } = Input;

const ApiKeys = ({match}) => {

  const [visible, setVisible] = useState(false);
  const [apikeys, setApikeys] = useState([]);
  const [apikeysFiltered, setApikeysFiltered] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const { getApiKeys, addApiKey } = ApiKeyApi();
  const { GetAccountsByCompanyId } = AccountApi();
  const { auth } = useAuth();
  const { spinnerDispacth } = useSpinner();
  const [t] = useTranslation("sms-api-key");

  useEffect(() => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.GETAPYKEYS }
    });

    getData()
    .then(response => {
      setApikeys(response.apiKeys);
      setApikeysFiltered(response.apiKeys);
      setAccounts(response.accounts);

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETAPYKEYS }
      });
    })
    .catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETAPYKEYS }
      });
      notificationError(t("list-api-keys.error-get-api-keys"));
    })
  }, [match.params.channelId])

  const getData = async () => {
    let listApiKeys = await getApiKeys(auth.userName);
    let listAccounts = await GetAccountsByCompanyId(auth.companyId);

    let accountByUser =  filterdAccountsByUser(listAccounts, auth.userAccounts, auth.role);

    return { apiKeys: listApiKeys, accounts: accountByUser }
  }
 
  const changeFilter = (e) => {
    let value = e.target.value;
    let result = apikeys.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
    setApikeysFiltered(result);
  }

  const onVisibleUserForm = (isVisible) => {
    setVisible(isVisible);
  }

  const onClose = () => {
    setVisible(false);
  };

  const onCreate = (values) => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.ADDAPYKEY }
    });

    addApiKey(values.apikeyName, auth.userName, auth.companyId, values.account)
    .then(() => {

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.GETAPYKEYS }
      });
  
      getApiKeys(auth.userName)
      .then(response => {
        
        setApikeys(response);
        setApikeysFiltered(response);
  
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.GETAPYKEYS }
        });

        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.ADDAPYKEY }
        });
        
        notificationOk(t("list-api-keys.ok-add-api-key"));
        onClose();
      })
      .catch(error => {
        console.error(error);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.GETAPYKEYS }
        });
        notificationError(t("list-api-keys.error-get-api-keys"));
      })

    })
    .catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.ADDAPYKEY }
      });
      onClose();
      notificationError(t("list-api-keys.error-add-api-key"));
    })
  }

  return (
    <>
      <CardFilter
        title={t("list-api-keys.card-filter-title")}
        filter={
          <Search
            placeholder={t("list-api-keys.card-filter-placeholder")}
            onChange={changeFilter}
          />
        }
        body={
          <div className="yp-grid-cards">
            <CardCreate onClick={onVisibleUserForm}
              title={t("list-api-keys.card-create-title")}
            />
            {
              apikeysFiltered.map(apikey => {
                let filteredAccounts = accounts.filter(account => account.accountId === apikey.accountId);
                return <ApiKeyCard key={apikey.id} apikey={apikey} accounts={filteredAccounts} />
              })
            }
            <SmsApiKeyForm
              accounts={accounts}
              visible={visible}
              onClose={onClose}
              onCreate={onCreate}
            />
          </div>
        }
      />
    </>
  );
}
 
export default ApiKeys;