import { Col, Row, Select, Input } from "antd";
import { Fragment, useEffect, useState } from "react";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import HelperButton from "../../HelperButton/HelperButton";
import { useTranslation } from 'react-i18next'
import propTypes from 'prop-types'
import WhatsappApi from "../../../Api/WhatsappApi";

const { Option } = Select;

const SelectAccount = ({accounts, onYPAccountChange, onAccountChange, onCampaignNameChange, defaultSelected, defaultSelectedYP, defaultCampaignName, handleMessageData}) => {
  const [selectedAccount, setSelectedAccount] = useState("")
  const [wabas, setWabas] = useState([])
  const [loading, setLoading] = useState(false)
  const { getWabaAccountsByYPAccount } = WhatsappApi();
  const [t] = useTranslation("campaign-bulk");

  const handleYPAccountChange = async (value) => {
    setSelectedAccount(value)
    onAccountChange(null);
    onYPAccountChange(value)
    setLoading(true)
    const res = await getWabaAccountsByYPAccount(value)
    setLoading(false)
    setWabas(res?.data)
  };

  const fetchWabas = async (value) => {
    setSelectedAccount(value)
    setLoading(true)
    const res = await getWabaAccountsByYPAccount(value)
    setLoading(false)
    setWabas(res?.data)
  };
  
  const handleAccountChange = (value) => {
    onAccountChange(value);
    handleMessageData({ wabaId: value, wabaPhoneId: wabas?.[0]?.wabaPhoneId, limit: wabas?.[0]?.limit })
  };

  const handleCampaignNameChange = (e) => {
    let value = e.target.value;
    if(value) {
      onCampaignNameChange(value);
    } else {
      onCampaignNameChange(null);
    }
  };

  useEffect(() => {
    if (defaultSelected) {
      fetchWabas(defaultSelectedYP)
    }
  }, [defaultSelected, defaultSelectedYP]);

  return (
    <Fragment>
      <Row>
        <Col>
          <p>{t("account.title")}</p>
        </Col>
      </Row>
      <div className="content-form">
        <Row style={{width: "100%"}}>
          <Col>
            {t("account.label-select-account-yp")}
          </Col>
        </Row>
        <Row style={{width: "100%", marginBottom: "20px", flexWrap: "nowrap", justifyContent: "center"}}>
          <Col>
            <Select
              name="Account"
              style={{ width: 320 }}
              placeholder={t("account.place-holder-select-account-yp")}
              onChange={handleYPAccountChange}
              value={defaultSelectedYP}
            >
              {
                accounts.map(item =>
                  <Option key={item.accountId} value={item.accountId}>{item.name}</Option>
                )
              }
            </Select>
          </Col>
          <Col>
            <div>
              <HelperButton
                message={t("account.tooltip-account")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="content-form">
        <Row style={{width: "100%"}}>
          <Col>
            {t("account.label-select-account-waba")}
          </Col>
        </Row>
        <Row style={{width: "100%", marginBottom: "20px", flexWrap: "nowrap", justifyContent: "center"}}>
          <Col>
            <Select
              name="Account"
              style={{ width: 320 }}
              placeholder={t("account.place-holder-select-account-waba")}
              onChange={handleAccountChange}
              disabled={selectedAccount === "" || loading}
              loading={loading}
              value={defaultSelected}
            >
              {
                wabas.map(item =>
                  <Option key={item.wabaId} value={item.wabaId}>{item.name}</Option>
                )
              }
            </Select>
          </Col>
          <Col>
            <div>
              <HelperButton
                message={t("account.tooltip-account")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="content-form">
        <Row style={{width: "100%"}}>
          <Col>
            {t("account.label-text-campaign-name")}
          </Col>
        </Row>
        <Row style={{width: "100%", marginBottom: "20px", flexWrap: "nowrap", justifyContent: "center"}}>
          <Col>
            <Input
              name="CampaignName"
                style={{ width: 320 }}
                placeholder={t("account.place-holder-text-campaign-name")}
                onChange={handleCampaignNameChange}
                value={defaultCampaignName}
              />
          </Col>
          <Col>
            <div>
              <HelperButton
                message={t("account.tooltip-campaign-name")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

SelectAccount.propTypes = {
  accounts: propTypes.array.isRequired,
  onChange: propTypes.func,
  showValidations: propTypes.bool
}
 
export default SelectAccount;