import React, { useEffect, useState } from "react";
import { Alert, Drawer, Form, List, Tag, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { orangeColor, getGradientColor, purpleColor } from "../../../utils/colorsCss";
import { cleanErrorMessage } from '../../../utils/helpers';
import { notificationError } from "../../../utils/helpers";
import "./AgentCarriers.css"
import RcsApi from "../../../Api/RcsApi";

const AgentCarriers = ({ visible, onClose, agentId }) => {
  const [t] = useTranslation("rcs-channel");
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false)
  const [carriers, setCarriers] = useState([])
  const { getAgentCarriers } = RcsApi();

  useEffect(() => {
    setIsLoading(true)
    getAgentCarriers(agentId)
      .then((response) => {
        setCarriers(response?.data || []);
        setIsLoading(false)
      })
      .catch((e) => {
        notificationError(cleanErrorMessage(e), 15)
        setIsLoading(false)
      })
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      displayName: null,
      description: null,
    })
  }, []);

  const getStatus = (status) => {
    switch (status) {
      case "LAUNCH_STATE_UNSPECIFIED":
        return {
          tag: t("agents-list.carriers.launch-state-tag.unspecified"),
          tooltip: t("agents-list.carriers.launch-state-tooltip.unspecified")
        }
      case "LAUNCH_STATE_UNLAUNCHED":
        return {
          tag: t("agents-list.carriers.launch-state-tag.unlaunched"),
          tooltip: t("agents-list.carriers.launch-state-tooltip.unlaunched")
        }
      case "LAUNCH_STATE_PENDING":
        return {
          tag: t("agents-list.carriers.launch-state-tag.pending"),
          tooltip: t("agents-list.carriers.launch-state-tooltip.pending")
        }
      case "LAUNCH_STATE_LAUNCHED":
        return {
          tag: t("agents-list.carriers.launch-state-tag.launched"),
          tooltip: t("agents-list.carriers.launch-state-tooltip.launched")
        }
      case "LAUNCH_STATE_REJECTED":
        return {
          tag: t("agents-list.carriers.launch-state-tag.rejected"),
          tooltip: t("agents-list.carriers.launch-state-tooltip.rejected")
        }
      case "LAUNCH_STATE_SUSPENDED":
        return {
          tag: t("agents-list.carriers.launch-state-tag.suspended"),
          tooltip: t("agents-list.carriers.launch-state-tooltip.suspended")
        }
      case "LAUNCH_STATE_PENDING_UNLAUNCH":
        return {
          tag: t("agents-list.carriers.launch-state-tag.pending-unlaunch"),
          tooltip: t("agents-list.carriers.launch-state-tag.pendtooltip-unlaunch")
        }
      case "LAUNCH_STATE_INVALID_IN_GMB":
        return {
          tag: t("agents-list.carriers.launch-state-tag.invalid"),
          tooltip: t("agents-list.carriers.launch-state-tooltip.invalid")
        }
      default:
        return {
          tag: t("unknown"),
          tooltip: t("unknown")
        }
    }
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "LAUNCH_STATE_LAUNCHED":
        return "#006978"
      case "LAUNCH_STATE_PENDING":
      case "LAUNCH_STATE_PENDING_UNLAUNCH":
        return "#fa8c16"
      case "LAUNCH_STATE_REJECTED":
      case "LAUNCH_STATE_SUSPENDED":
        return "#cf1322"
      default:
        return "gray"
    }
  }

  return (
    <Drawer
      visible={visible}
      title={t("agents-list.carriers.title")}
      className="carriers-drawer"
      headerStyle={{ background: getGradientColor(orangeColor) }}
      onClose={() => onClose()}
      maskClosable
      mask
    >
      <Alert
        style={{ marginBottom: "16px" }}
        className="custom-info-alert small-font-size"
        type="info"
        description={
          <>
            {t("agents-list.carriers.alert")}
            <a href={t("agents-list.carriers.alert-learn-more-url")} target="_blank" rel="noopener noreferrer">
              {t("agents-list.carriers.alert-learn-more")}
            </a>
          </>
        }
      />
      <List
        bordered
        loading={isLoading}
        dataSource={carriers}
        renderItem={(item) => (
          <List.Item style={{ width: "100%" }}>
            {item.name}{" "}
            <Tooltip id="template-list-cannot-update-pending-tooltip" title={getStatus(item.status).tooltip} color={getGradientColor(purpleColor)} overlayInnerStyle={{padding: 20}}>
              <Tag color={getStatusColor(item.status)}>{getStatus(item.status).tag}</Tag>
            </Tooltip>
          </List.Item>
        )}
      />
    </Drawer>
  );
};

export default AgentCarriers;
