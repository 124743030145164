const TableCardHeadCustom = ({children}) => {
  return (
    <thead>
      <tr>
        {children}
      </tr>
    </thead>
  );
}
 
export default TableCardHeadCustom;