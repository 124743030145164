import { useState } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./TotalCampaigns.module.css";
import { useEffect } from "react";
import { Spin } from "antd";
import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST } from "../../../../utils/const";
import { Link } from "react-router-dom";
import { numberFormat } from "../../../../utils/helpers";
import DataApi from "../../../../Api/DataApi";

const TotalCampaigns = ({filter}) => {

  const [t] = useTranslation("home-carrier");
  const {getTotalCampaigns} = DataApi()

  const [loading, setLoading] = useState(true);
  const [sms, setSms] = useState(0);
  const [voice, setVoice] = useState(0);
  const [shortlink, setShortlink] = useState(0);
  const [period, setPiriod] = useState("");

  useEffect(() => {
    setLoading(true);
    
    getSmsStatusData()
    .then(response => {
      setLoading(false);

      const {smsValue, voiceValue, shortlinkValue} = response;

      setSms(smsValue);
      setVoice(voiceValue);
      setShortlink(shortlinkValue);

      if(filter && filter.time) {
        let resultPeriod = CONSTANTS.LIST.PERIODREPORT.find(x => x.key === filter.time);
        setPiriod(resultPeriod.value);
      }
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    })
    
  }, [filter])

  const getSmsStatusData = async () => {

    let data = {
      CompanyId: filter.id === CONSTANTS.COMPANYDEFAULT.companyId ? null : filter.id,
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    }
    
    let response = await getTotalCampaigns(filter.time, data);
    
    let smsValue = response.find(x => x.channel.toUpperCase() === CHANELLIST.SMS.value.toUpperCase())?.count;
    let voiceValue = response.find(x => x.channel.toUpperCase() === CHANELLIST.VOICE.value.toUpperCase())?.count;
    let shortlinkValue = response.find(x => x.channel.toUpperCase() === CHANELLIST.SHORTLINK.value.toUpperCase())?.count;

    smsValue = smsValue ? smsValue : 0; 
    voiceValue = voiceValue ? voiceValue : 0; 
    shortlinkValue = shortlinkValue ? shortlinkValue : 0; 

    return {smsValue, voiceValue, shortlinkValue}; 
  }

  const render = () => {
    switch (filter.channel.key) {
      case CHANELLIST.DEFAULT.key:
        return <>
          <div className={styles['dashboard-card-body-metric']}>
            <p>{t("card-total-campaigns.label-sms")}</p>
            <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-sms']}`}>{numberFormat(sms, 0)}</div>
          </div>
          <div className={styles['dashboard-card-body-metric']}>
            <p>{t("card-total-campaigns.label-shortlink")}</p>
            <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-shortlink']}`}>{numberFormat(shortlink, 0)}</div>
          </div>
          <div className={styles['dashboard-card-body-metric']}>
            <p>{t("card-total-campaigns.label-voice")}</p>
            <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-voice']}`}>{numberFormat(voice, 0)}</div>
          </div>
        </>;
      case CHANELLIST.SMS.key:
        return <>
          <div className={styles['dashboard-card-body-metric']}>
            <p>{t("card-total-campaigns.label-sms")}</p>
            <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-sms']}`}>{numberFormat(sms, 0)}</div>
          </div>
          <div className={styles['dashboard-card-body-metric']}>
            <p>{t("card-total-campaigns.label-shortlink")}</p>
            <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-shortlink']}`}>{numberFormat(shortlink, 0)}</div>
          </div>
        </>;
      case CHANELLIST.VOICE.key:
        return <>
          <div className={styles['dashboard-card-body-metric']}>
            <p>{t("card-total-campaigns.label-voice")}</p>
            <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-voice']}`}>{numberFormat(voice, 0)}</div>
          </div>
        </>;   
      default:
        return <></>;
    }
  }

  return (
    <div className={styles['dashboard-body-card-single']}>
      <CardCustom
        style={{height: "100%", widt:"100%"}}
        title={
        <div className={styles['card-title']}>
          <p>{t("card-total-campaigns.label-title")}</p>
          <HelperButton
            message={t("card-total-campaigns.tooltip-title")}
            color={getGradientColor(purpleColor)}
          />
        </div>
        }
      >
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              {
                render()
              }
              <p className={styles['dashboard-card-body-period']}>{period}</p>
              {
                // Hotfix-id-84
                /*<Link to="/Campaigns/BulkCampaign" className={styles['dashboard-card-body-balance-button']}>{t("dashboard-header-buttons.add-balance")}</Link> */
              }
            </div>
          </Spin>
        </div>
      </CardCustom>
    </div>
  );
}
 
export default TotalCampaigns;