import React, { useState, useEffect, useContext } from 'react';
import { Button, Checkbox, Form, Input, Select, Card, Row, Col, Typography } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import HelperButton from "../../../../components/HelperButton/HelperButton";
import styles from "./Create.module.css";
import { AuthContext } from '../../../../contexts/authRedirect';
import WhatsappApi from '../../../../Api/WhatsappApi';
import { cleanErrorMessage, notificationError, notificationOk } from '../../../../utils/helpers';
import "./Create.css"
import MessageWildcard from '../../../../components/MessageWildcard/MessageWildcard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import useSpinner from '../../../../Hooks/useSpinner';
import TYPES from '../../../../reducers/types';
import CONSTANTS, { auth_footer_en, auth_footer_es, auth_header_en, auth_header_es, auth_message_en, auth_message_es, auth_url, auth_url_text } from '../../../../utils/const';

const { Option } = Select;
const { Text, Title } = Typography;

const CreateWhatsAppTemplate = () => {
  const [form] = Form.useForm();
  const [t] = useTranslation("whatsapp-templates");
  const [selectedActions, setSelectedActions] = useState([])
  const [buttons, setButtons] = useState([])
  const [quickReplies, setQuickReplies] = useState([]);
  const [urlInputs, setUrlInputs] = useState({ url: '', text: '' });
  const [formValues, setFormValues] = useState({})
  const [previewMessageBody, setPreviewMessageBody] = useState(t("placeholder.preview"))
  const [accounts, setAccounts] = useState(null)
  const [wcList, setwcList] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("")
  const [selectedLang, setSelectedLang] = useState("")
  const [messageBody, setMessageBody] = useState("")
  const history = useHistory()
  const { spinnerDispacth } = useSpinner();

  const { auth } = useContext(AuthContext);
  const { getAccounts, createUpdateTemplate } = WhatsappApi();

  const handleCheckboxChange = (action) => {
    let updatedActions;
    if (selectedActions.includes(action)) {
      updatedActions = selectedActions.filter(act => act !== action);
      if (action === "QUICK_REPLY") {
        setButtons(buttons.filter(button => button.type !== "QUICK_REPLY"));
        setQuickReplies([]);
      } else if (action === "URL") {
        form.validateFields();
        setButtons(buttons.filter(button => button.type !== "URL"));
        form.resetFields(['url', 'urltext']);
        setUrlInputs({ url: '', text: '' });
      }
    } else {
      updatedActions = [...selectedActions, action];
      if (action === "QUICK_REPLY") {
        setQuickReplies([t("options.yes"), t("options.no")]);
        handleQuickReplyChange([t("options.yes"), t("options.no")]);
      }
    }
    setSelectedActions(updatedActions);
  };

  const handleQuickReplyChange = (values) => {
    if (values.length === 0) {
      setSelectedActions(selectedActions.filter((act) => act !== "QUICK_REPLY"));
    }
    
    const quickReplies = values.map((text) => ({ type: "QUICK_REPLY", text }));
    setButtons([
      ...buttons.filter((button) => button.type !== "QUICK_REPLY"),
      ...quickReplies,
    ]);
    setQuickReplies(values);
  };

  const handleURLChange = (e, field) => {
    const newUrlInputs = { ...urlInputs, [field]: e.target.value };
    setUrlInputs(newUrlInputs);
    const urlButton = { type: "URL", url: newUrlInputs.url, text: newUrlInputs.text };
    setButtons([
      ...buttons.filter((button) => button.type !== "URL"),
      urlButton,
    ]);
  };

  const getInitialData = async () => {
    try {
      const _accounts = await getAccounts(auth.companyId);
      setAccounts((_accounts.data || []).filter(a => a.status === "APPROVED"))
    }
    catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitialData()
  }, []);

  useEffect(() => {
    if (selectedCategory === "AUTHENTICATION" && selectedLang !== "") {
      setPreviewMessageBody(cleanMessage(replacePlaceholders(selectedLang === "es" ? auth_message_es : auth_message_en, ["123456"])));
      setMessageBody(replacePlaceholders(selectedLang === "es" ? auth_message_es : auth_message_en, ["123456"]));
      setFormValues({
        headerText: selectedLang === "es" ? auth_header_es : auth_header_en,
        footerText: selectedLang === "es" ? auth_footer_es : auth_footer_en,
        bodyExample: ["123456"],
        url: auth_url,
        urltext: auth_url_text
      });

      form.setFieldsValue({
        headerText: selectedLang === "es" ? auth_header_es : auth_header_en,
        footerText: selectedLang === "es" ? auth_footer_es : auth_footer_en,
        bodyExample: ["123456"],
        url: auth_url,
        urltext: auth_url_text
      });

      setSelectedActions([...selectedActions, "URL"]);

      setButtons([
        ...buttons.filter(button => button.type !== "URL"),
        { type: "URL", url: auth_url, text: auth_url_text }
      ]);

      setUrlInputs({ url: auth_url, text: auth_url_text });
    }
  }, [selectedCategory, selectedLang]);

  const onFinish = () => {
    form
      .validateFields()
      .then((v) => {
        const payload = v.category === "AUTHENTICATION" ? 
        {
          wabaId: accounts?.[v.wabaId]?.wabaId,
          companyId: auth.companyId,
          lastModifiedBy: auth.userName,
          category: v.category,
          name: v.name,
          language: v.language,
        } :
        {
          wabaId: accounts?.[v.wabaId]?.wabaId,
          companyId: auth.companyId,
          lastModifiedBy: auth.userName,
          category: v.category,
          name: v.name,
          language: v.language,
          allowCategoryChange: v.allowCategoryChange ? true : false,
          headerText: v.headerText,
          headerExample: [],
          bodyText: v.bodyText,
          bodyExample: wcList.map(v => v.value),
          footerText: v.footerText,
          buttons: buttons
        }
        if (selectedActions.includes("URL")) {
          if (!urlInputs.url || !urlInputs.text) {
            notificationError(t("url-text-cannot-be-empty"));
            return
          }
        }
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: true, item: CONSTANTS.SPINNERITEM.CREATEWABA }
        });
        createUpdateTemplate(payload, "POST")
          .then((v) => {
            const wabaphone = accounts.find(a => a.wabaId === payload.wabaId)?.phone
            notificationOk(t("notification-ok"));
            form.resetFields();
            setSelectedActions([])
            setButtons([])
            setFormValues({})
            setPreviewMessageBody("")
            setAccounts(null)
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.CREATEWABA }
            });
            sessionStorage.setItem("SELECTED_PHONE_FOR_WA_TEMPLATE", JSON.stringify(wabaphone));
            history.push("/WhatsAppTemplates/List")
          })
          .catch(e => {
            notificationError(cleanErrorMessage(e) || t("error-creating-template"), 15)
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.CREATEWABA }
            });
          })
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleChangeMessage = value => {
    const _message = replaceWithOrder(value?.message)
    if(value.message) {
      setPreviewMessageBody(cleanMessage(value.message));
      form.setFieldsValue({ bodyText: _message })
    } else {
      setPreviewMessageBody("");
      form.setFieldsValue({ bodyText: "" })
    }
  };

  const replaceWithOrder = (inputString) => {
    const regex = /@#(.*?)@#/g;
    const occurrences = {};
    let counter = 1;
  
    const resultString = inputString.replace(regex, (_, match) => {
      if (!(match in occurrences)) {
        occurrences[match] = counter++;
      }
      return `{{${occurrences[match]}}}`;
    });
  
    return resultString;
  };

  const cleanMessage = (message) => {
    return message.replace(/@#/g, "")
  }

  function replacePlaceholders(template, values) {
    if (!template || !values) return
    const message = template.replace(/{{(\d+)}}/g, (_, index) => `@#${values[parseInt(index) - 1] || ''}@#`)
    return message;
  }

  return (
    <Form form={form} layout="vertical" onValuesChange={(field) => setFormValues(prev => ({ ...prev, ...field }))}>
      <div>
        <div className='yp-workspace'>
          <Title level={4} type="secondary" style={{ textAlign: "center" }}>{t("title")}</Title>
          <Row>
            <Col span={6} style={{ padding: "0px 15px" }}>
              <Form.Item
                name="category"
                label={t("label.category")}
                rules={[{ required: true, message: t("rules.category-required") }]}
                style={{ width: '100%' }}
              >
                <Select
                  id="create-template-category-input"
                  placeholder={t("placeholder.select-category")}
                  onChange={(v) => setSelectedCategory(v)}
                  value={selectedCategory}
                >
                  <Option value="UTILITY">{t("options.utility")}</Option>
                  <Option value="MARKETING">{t("options.marketing")}</Option>
                  <Option value="AUTHENTICATION">{t("options.authentication")}</Option>
                </Select>
              </Form.Item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="allowCategoryChange" valuePropName='checked'>
                  <Checkbox id="create-template-category-assign-input" defaultChecked={false} disabled={selectedCategory === "" || selectedCategory === "AUTHENTICATION"}>{t("automatic-category-assign")}</Checkbox>
                </Form.Item>
                <HelperButton
                  id="create-template-category-tooltip"
                  message={t("tooltip.allow-category-change")}
                  color={getGradientColor(purpleColor)}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <Card className="category-card" title={t("options.utility")}>
                  <Text>{(t("card.utilityText"))}</Text>
                </Card>
                <Card className="category-card" title={t("options.marketing")}>
                  <Text>{(t("card.marketingText"))}</Text>
                </Card>
                <Card className="category-card" title={t("options.authentication")}>
                  <Text>{(t("card.authenticationText"))}</Text>
                </Card>
              </div>
            </Col>
            <Col span={12} style={{ padding: "0px" }}>
              <Row>
                <Col span={12} style={{ padding: "0px 10px 0px 0px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Item
                      name="name"
                      label={t("label.template-name")}
                      rules={[{ required: true, message: t("rules.template-name-required") }]}
                      style={{ width: '100%' }}
                    >
                      <Input id="create-template-name-input" placeholder={t("placeholder.choose-template-name")} disabled={selectedCategory === ""} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Item
                      name="language"
                      label={t("label.language")}
                      rules={[{ required: true, message: t("rules.language-required") }]}
                      style={{ width: '100%' }}
                    >
                      <Select
                        id="create-template-language-input"
                        placeholder={t("placeholder.select-language")}
                        disabled={selectedCategory === ""}
                        onChange={(v) => setSelectedLang(v)}
                        value={selectedLang}
                      >
                        <Option value="es">{t("options.spanish")}</Option>
                        <Option value="en">{t("options.english")}</Option>
                      </Select>
                    </Form.Item>

                  </div>
                  <Form.Item
                    name="bodyExample"
                    label={
                      <>
                        {t("label.example-values")}
                        <HelperButton
                          id="create-template-example-values-tooltip"
                          message={t("tooltip.example-values")}
                          color={getGradientColor(purpleColor)}
                          placement='right'
                        />
                      </>
                    }
                  >
                    <Select
                      id="create-template-example-values-input"
                      mode="tags"
                      style={{ width: '100%' }}
                      placeholder={t("placeholder.type-example-values")}
                      options={wcList}
                      onChange={v => setwcList(v.map((_v) => ({ value: _v, label: _v })))}
                      allowClear
                      disabled={selectedCategory === "" || selectedCategory === "AUTHENTICATION"}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="wabaId"
                    label={t("label.whatsapp-account")}
                    rules={[{ required: true, message: t("rules.whatsapp-account-required") }]}
                  >
                    <Select
                      id="create-template-wabas-input"
                      style={{ width: '100%' }}
                      options={(accounts || []).filter(a => a.status === "APPROVED").map((a, i) => ({ value: i, label: a.name, wabaId: a.wabaId }))}
                      placeholder={t("placeholder.select-whatsapp-account")}
                      allowClear
                      disabled={selectedCategory === ""}
                    />
                  </Form.Item>
                  <Form.Item
                    name="headerText"
                    label={t("label.header")}
                  >
                    <Input id="create-template-header-input" placeholder={t("placeholder.header")} disabled={selectedCategory === "" || selectedCategory === "AUTHENTICATION"} />
                  </Form.Item>
                  <Form.Item
                    name="footerText"
                    label={t("label.footer")}
                  >
                    <Input id="create-template-footer-input" placeholder={t("placeholder.footer")} disabled={selectedCategory === "" || selectedCategory === "AUTHENTICATION"} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Form.Item
                  style={{ width: "100%" }}
                  name="bodyText"
                  label={
                    <>
                      {t("label.message")}
                      <HelperButton
                        id="create-template-message-body-tooltip"
                        message={t("tooltip.message")}
                        color={getGradientColor(purpleColor)}
                        placement='right'
                      />
                    </>
                  }
                  rules={[{ required: selectedCategory !== "AUTHENTICATION", message: t("rules.message-required") }]}
                  className='message-form-item'
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Col style={{ width: "100%" }}>
                      <MessageWildcard
                        style={{ width: "100%", background: (selectedCategory === "" || selectedCategory === "AUTHENTICATION") ? "#F5F5F5" : "" }}
                        disabled={selectedCategory === "" || selectedCategory === "AUTHENTICATION"}
                        contentEditable={true}
                        wildcardsFields={wcList.map(v => v.value)}
                        defaultMessage={{ message: messageBody, html: "" }}
                        defaultWildCards={[]}
                        onChangeMessage={handleChangeMessage}
                        placeholder={t("placeholder.message")}
                      />
                    </Col>
                  </div>
                </Form.Item>
              </Row>
              <label>{t("label.suggested-actions")}</label>
              <Row>
                <Col span={12} className='template-suggested-replies-field'>
                  <div className='action-container'>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Form.Item
                        name="suggestedReplies"
                        label={t("label.suggested-replies")}
                      >
                        <Checkbox
                          id="create-template-suggested-replies-input"
                          onChange={() => handleCheckboxChange("QUICK_REPLY")}
                          disabled={selectedCategory === "" || selectedCategory === "AUTHENTICATION"}
                          checked={selectedActions.includes("QUICK_REPLY")}
                        />
                      </Form.Item>
                      <div className='group-options'>
                        <Checkbox.Group
                          disabled
                          options={[t("options.yes"), t("options.no")]}
                          onChange={handleQuickReplyChange}
                          value={quickReplies}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={12} className='template-url-field'>
                  <div className='action-container'>
                    <Form.Item
                      name="url"
                      label={t("label.url")}
                    >
                      <Checkbox
                        id="create-template-url-input"
                        onChange={() => handleCheckboxChange("URL")}
                        disabled={selectedCategory === "" || selectedCategory === "AUTHENTICATION"}
                        checked={selectedActions.includes("URL")}
                      />
                    </Form.Item>
                    <div className='inputs'>
                      <Form.Item
                        name="url"
                        rules={[
                          {
                            type: 'url',
                            message: t("rules.valid-url"),
                          },
                          {
                            required: selectedActions.includes("URL"),
                            message: t("rules.url-required"),
                          },
                        ]}
                        style={{ width: "100%", marginBottom: "0px" }}
                      >
                        <Input
                          id="create-template-url-url-input"
                          style={{ width: "100%" }}
                          disabled={!selectedActions.includes("URL") || selectedCategory === "" || selectedCategory === "AUTHENTICATION"}
                          onChange={(e) => handleURLChange(e, 'url')}
                          value={urlInputs.url}
                          placeholder={t("placeholder.url")}
                          type='url'
                        />
                      </Form.Item>
                      <Form.Item
                        name="urltext"
                        rules={[
                          {
                            required: selectedActions.includes("URL"),
                            message: t("rules.url-text-required"),
                          },
                        ]}
                        style={{ width: "100%", marginBottom: "0px" }}
                      >
                        <Input
                          id="create-template-url-text-input"
                          disabled={!selectedActions.includes("URL") || selectedCategory === "" || selectedCategory === "AUTHENTICATION"}
                          onChange={(e) => handleURLChange(e, 'text')}
                          value={urlInputs.text}
                          placeholder={t("placeholder.url-text")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <div className="device-mockup">
                <div className="device-frame">
                  <div className="notch"></div>
                  <div className="device-header">
                    <div className='contact'>
                      <div className='person-avatar' />
                      <Text strong>{t("preview.contact")}</Text>
                    </div>
                    <div className="header-icons">
                      <PhoneOutlined />
                    </div>
                  </div>
                  <div className="chat-preview" bordered={false}>
                    <div className="message-card" bordered={false}>
                      <Text id="create-template-preview-header" strong>{formValues?.headerText}</Text>
                      <div className="chat-bubble">
                        <div className="inner-message-card" bordered={false}>
                          <Text id="create-template-preview-message-body">{previewMessageBody || t("placeholder.preview")}</Text>
                          <div className='message-footer'>
                            <div id="create-template-preview-footer" className="footer-text">{formValues?.footerText}</div>
                          </div>
                        </div>
                        <div className="bubble-arrow"></div>
                      </div>
                    </div>
                    {selectedActions.includes("QUICK_REPLY") && (
                      <div className="button-group">
                        {buttons
                          .filter((button) => button.type === "QUICK_REPLY")
                          .map((button, index) => (
                            <div id={`create-template-preview-button-${button.text}`} key={index} className="reply-button">
                              {button.text}
                            </div>
                          ))}
                      </div>
                    )}

                    {selectedActions.includes("URL") && (
                      <div className="url-group">
                        {buttons
                          .filter((button) => button.type === "URL")
                          .map((button, index) => (
                            <div key={index} className="url-button">
                              <a id="create-template-preview-url" href=" " style={{ pointerEvents: "none" }} target="_blank" rel="noopener noreferrer">
                                {button.text}
                              </a>
                              
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                  <div className="chat-input">
                    <input type="text" disabled placeholder={t("preview.input")} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles['step-buttons-container']}>
          <div className={styles['step-buttons']}>
            <Button id="create-template-list-button" className="yp-button yp-button-optional" style={{ width: 150, marginRight: "20px" }} href="/#/WhatsAppTemplates/List">
              {t("actions-buttons.template-list")}
            </Button>
            <Button id="create-template-create-button" className={`${styles['yp-button']} ${styles['yp-button-primary']}`} style={{ width: 150 }} type="primary" onClick={onFinish}>
              {t("actions-buttons.create-template")}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default CreateWhatsAppTemplate;
