import { useState } from "react";
import TemplateApi from "../../Api/TemplatesApi";
import useAuth from "../../Hooks/useAuth";
import useSpinner from "../../Hooks/useSpinner";
import ListTemplates from "../../components/HistoryTemplates/ListTemplates";
import TYPES from "../../reducers/types";
import CONSTANTS from "../../utils/const";
import styles from "./HistoryTemplates.module.css";
import { useEffect } from "react";
import { notificationError, notificationOk } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

const HistoryTemplates = () => {

  const { spinnerDispacth } = useSpinner();
  const [ templates, setTemplates] = useState([]);

  const { getTemplates, deleteTemplate } = TemplateApi();
  const { auth } =  useAuth();
  const [t] = useTranslation("templates-history");

  useEffect(() => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.CONTACTSGROUP }
    });

    getTemplates(auth.companyId)
    .then(response => {

      let accountList = auth.userAccounts;
      let templatesByUser = auth.role === CONSTANTS.ROLES.COMPANY_ADMIN ? response :
        response.filter(x => accountList.includes(x.accountId));

      setTemplates(templatesByUser);

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTSGROUP }
      });
    })
    .catch(error => {
      console.error(error)
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTSGROUP }
      });
    })
  }, []);

  const onDeleteTemplate = (templateId) => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.DELETETEMPLATE }
    });

    deleteTemplate(templateId)
    .then(() => {
      let result = templates.filter(x => x.templateId !== templateId);
      setTemplates(result);

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETETEMPLATE }
      });

      notificationOk(t("template-information.notification-delete-template-ok"));
    })
    .catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETETEMPLATE }
      });
      notificationError(t("template-information.notification-delete-template-error"));
    })
  }

  return (
    <div className={styles['content']}>
      <div className={styles['content-card']}>
        <div className={styles['content-card-left']}>
          <ListTemplates 
            templates={templates}
            onDeleteTemplate={onDeleteTemplate}
          />
        </div>
      </div>
    </div>
  );
}
 
export default HistoryTemplates;