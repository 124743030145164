import { Col, Form, Row, Select, Input } from "antd";
import { Fragment, useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import HelperButton from "../../HelperButton/HelperButton";
import { useTranslation } from 'react-i18next'
import propTypes from 'prop-types'
import RcsApi from "../../../Api/RcsApi";
import useSpinner from "../../../Hooks/useSpinner";
import TYPES from "../../../reducers/types";
import CONSTANTS from "../../../utils/const";
import { cleanErrorMessage, notificationError } from "../../../utils/helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const { Option } = Select;

const SelectAccount = ({accounts, account, onAccountChange, setAgentData }) => {
  const [brands, setBrands] = useState([])
  const [agents, setAgents] = useState([])
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedAgent, setSelectedAgent] = useState(null)
  const [t] = useTranslation("rcs-campaign");
  const history = useHistory();
  const [fromRcsChannel, setFromRcsChannel] = useState(history?.location?.state || null)

  const { getBrandsByAccountId, getAgentById, getBrandAgents } = RcsApi();
  const { spinnerDispacth } = useSpinner();

  useEffect(() => {
    if (fromRcsChannel) {
      onAccountChange({
        accountId: fromRcsChannel.accountId,
        brandId: fromRcsChannel.brandId,
        agentId: fromRcsChannel.agentId,
      })
      getAgentById(fromRcsChannel.agentId)
        .then((response) => {
          const { data: agentRes } = response || {}
          setAgentData(agentRes)
        })
        .catch(e => {
          notificationError(cleanErrorMessage(e))
        })
        .finally(() => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
          });
        })
    }
  }, [fromRcsChannel]);

  const handleAccountChange = (value) => {
    onAccountChange({ ...account, accountId: value })
    setSelectedBrand(null)
    setSelectedAgent(null)
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.DEFAULT }
    });
    getBrandsByAccountId(value)
      .then(res => {
        setBrands(res?.data || [])
      })
      .catch(e => {
        notificationError(cleanErrorMessage(e))
      })
      .finally(() => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
        });
      })
  };

  const handleBrandChange = (value) => {
    onAccountChange({ ...account, brandId: value })
    setSelectedBrand(value)
    setSelectedAgent(null)
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.DEFAULT }
    });
    getBrandAgents(value)
      .then(res => {
        setAgents(res?.data || [])
      })
      .catch(e => {
        notificationError(cleanErrorMessage(e))
      })
      .finally(() => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
        });
      })
  };

  const handleAgentChange = (value) => {
    onAccountChange({ ...account, agentId: value })
    setSelectedAgent(value)
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.DEFAULT }
    });
    getAgentById(value)
      .then((response) => {
        const { data: agentRes } = response || {}
        setAgentData(agentRes)
      })
      .catch(e => {
        notificationError(cleanErrorMessage(e))
      })
      .finally(() => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
        });
      })
  };

  const handleReset = () => {
    setFromRcsChannel(null);
    onAccountChange({
      accountId: null,
      brandId: null,
      agentId: null,
    })
  }

  const fromRcsChannelForm = (
    <Form data-testid="content" name="validate_other" layout="vertical" initialValues={{
      accountId: fromRcsChannel?.accountName,
      brandId: fromRcsChannel?.brandName,
      agentId: fromRcsChannel?.agentName,
    }}>
      <Row><Col><p>{t("account.title")}</p></Col></Row>
      <Row>
        <Col>
          <Form.Item name="accountId" label={t("account.label-select-account")}>
            <Input disabled style={{ width: 320 }} placeholder={t("account.place-holder-text-campaign-name")} />
          </Form.Item>
        </Col>
        <Col>
          <div style={{paddingTop: "34px"}}>
            <HelperButton
              message={t("account.tooltip-account")}
              color={getGradientColor(purpleColor)}
            />
          </div>
        </Col>
      </Row>
      <Row style={{width: "100%", flexWrap: "nowrap", justifyContent: "center"}}>
        <Col>
          <Form.Item name="brandId" label={t("account.label-select-brand")}>
            <Input disabled style={{ width: 320 }} placeholder={t("account.place-holder-text-campaign-name")} />
          </Form.Item>
        </Col>
        <Col>
          <div style={{paddingTop: "34px"}}>
            <HelperButton
              message={t("account.tooltip-brand")}
              color={getGradientColor(purpleColor)}
            />
          </div>
        </Col>
      </Row>
      <Row style={{width: "100%", flexWrap: "nowrap", justifyContent: "center"}}>
        <Col>
          <Form.Item name="agentId" label={t("account.label-select-agent")}>
            <Input disabled style={{ width: 320 }} placeholder={t("account.place-holder-text-campaign-name")} />
          </Form.Item>
        </Col>
        <Col>
          <div style={{paddingTop: "34px"}}>
            <HelperButton
              message={t("account.tooltip-agent")}
              color={getGradientColor(purpleColor)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item 
            name="campaignName"
            label={t("account.label-text-campaign-name")}
          >
            <Input
              style={{ width: 320 }}
              placeholder={t("account.place-holder-text-campaign-name")}
              onChange={(e) => onAccountChange({ ...account, campaignName: e.target.value })}
              value={account?.campaignName}
            />
          </Form.Item>
        </Col>
        <Col>
          <div style={{paddingTop: "34px"}}>
            <HelperButton
              message={t("account.tooltip-campaign-name")}
              color={getGradientColor(purpleColor)}
            />
          </div>
        </Col>
      </Row>
      <Button type="secondary" onClick={handleReset}>
        {t("actions-buttons.reset-fields")}
      </Button>
    </Form>
  )

  return (
    <Fragment>
      {fromRcsChannel ? fromRcsChannelForm : <Form data-testid="content" name="validate_other" layout="vertical" >
        <Row><Col><p>{t("account.title")}</p></Col></Row>
        <Row>
          <Col>
            <Row style={{width: "100%"}}>
              <Col>
                {t("account.label-select-account")}
              </Col>
            </Row>
            <Row style={{width: "100%", marginBottom: "20px", flexWrap: "nowrap", justifyContent: "center"}}>
              <Col>
                <Select
                  style={{ width: 320 }}
                  placeholder={t("account.place-holder-select-account")}
                  onChange={handleAccountChange}
                >
                  {accounts.map(item => <Option key={item.accountId} value={item.accountId}>{item.name}</Option>)}
                </Select>                
              </Col>
            </Row>
          </Col>
          <Col>
            <div style={{paddingTop: "26px"}}>
              <HelperButton
                message={t("account.tooltip-account")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
        <Row style={{width: "100%"}}>
          <Col>
            {t("account.label-select-brand")}
          </Col>
        </Row>
        <Row style={{width: "100%", marginBottom: "20px", flexWrap: "nowrap", justifyContent: "center"}}>
          <Col>
            <Select
              name="brandId"
              style={{ width: 320 }}
              placeholder={t("account.place-holder-select-brand")}
              onChange={handleBrandChange}
              disabled={!account?.accountId}
              value={selectedBrand}
            >              {(brands || []).map(item => <Option key={item.rcsBrandId} value={item.rcsBrandId}>{item.name}</Option>)}
            </Select>
          </Col>
          <Col>
            <div>
              <HelperButton
                message={t("account.tooltip-brand")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
        <Row style={{width: "100%"}}>
          <Col>
            {t("account.label-select-agent")}
          </Col>
        </Row>
        <Row style={{width: "100%", marginBottom: "20px", flexWrap: "nowrap", justifyContent: "center"}}>
          <Col>
            <Select
              name="agentId"
              style={{ width: 320 }}
              placeholder={t("account.place-holder-select-agent")}
              onChange={v => handleAgentChange(v)}
              disabled={!selectedBrand}
              value={selectedAgent}
            >
              {(agents || []).map(item => <Option key={item.rcsAgentId} value={item.rcsAgentId}>{item.name}</Option>)}
            </Select>
          </Col>
          <Col>
            <div>
              <HelperButton
                message={t("account.tooltip-agent")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item 
              name="campaignName"
              label={t("account.label-text-campaign-name")}
            >
              <Input
                style={{ width: 320 }}
                placeholder={t("account.place-holder-text-campaign-name")}
                onChange={(e) => onAccountChange({ ...account, campaignName: e.target.value })}
                value={account?.campaignName}
              />
            </Form.Item>
          </Col>
          <Col>
            <div style={{paddingTop: "34px"}}>
              <HelperButton
                message={t("account.tooltip-campaign-name")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
      </Form>}
    </Fragment>
  );
}

SelectAccount.propTypes = {
  accounts: propTypes.array.isRequired,
  onChange: propTypes.func,
  showValidations: propTypes.bool
}
 
export default SelectAccount;