import React from 'react';
import '../UserForm/userform.css'
import { Select, Drawer, Input, Form, InputNumber, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next'
import { orangeColor, getGradientColor, purpleColor } from "../../../utils/colorsCss";
import CONSTANTS from '../../../utils/const';
import Button from '../../Button/Button';
import HelperButton from "../../HelperButton/HelperButton";

const UserForm = ({ accounts, visible, onClose, onCreate }) => {

    const [t] = useTranslation("users");
    const { Option } = Select;
    const { TextArea } = Input;
    const [form] = Form.useForm();
    return (
        <Drawer
            visible={visible}
            title={t("create-user.title")}
            headerStyle={{background: getGradientColor(orangeColor)}}
            width={720}
            onClose={() => {
                form.resetFields();
                onClose();
            }}
            footer={
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <Button type="secondary" dataName="btnCancel" 
                        onClick={() => {
                            form.resetFields();
                            onClose();
                        }}
                        style={{ marginRight: 50 }}
                    >
                        {t("create-user.button-cancel")}
                    </Button>
                    <Popconfirm title={t("create-user.button-submit-confirm")}
                        okButtonProps={
                            {
                                "data-testid": "okButtonConfirm" //Important to do test
                            }
                        }
                        onConfirm={() => {
                            form
                                .validateFields()
                                .then(values => {
                                    onCreate(values);
                                    form.resetFields();
                                })
                                .catch((info) => {
                                });
                        }}
                        okText="yes"
                        cancelText="no"
                    >
                        <Button
                            type="primary"
                            dataName="btnSave">
                            {t("create-user.button-submit")}
                        </Button>
                    </Popconfirm>
                </div>
            }
        >
            <Form
                data-testid="drawer-content"
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    initialState: false,
                }}
            >
                <Form.Item
                    name="firstname"
                    label={t("create-user.label-firstname")}
                    rules={[
                        {
                            required: true,
                            message: 'Please input the first name!',
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="lastname"
                    label={t("create-user.label-lastname")}
                    rules={[
                        {
                            required: true,
                            message: 'Please input the last name!',
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label={t("create-user.label-email")}
                    rules={[
                        {
                            required: true,
                            message: 'Please input the email!',
                        },
                        { type: 'email' }
                    ]}
                >
                    <Input type='emailAccount' />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={t("create-user.label-description")}
                >
                <TextArea rows={4} type='phone' />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label={t("create-user.label-phone")}
                    rules={[
                        {
                            type: 'number',
                            min: 1,
                        },
                    ]}
                >
                <InputNumber className="input-phone" />
                </Form.Item>
                <Form.Item
                    name="role"
                    label={
                        <span>
                            {t("create-user.label-role")}
                            <HelperButton
                                 message={t("content-message.tooltip-role")}
                                color={getGradientColor(purpleColor)}
                            />
                        </span>
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Please select the role!',
                        }
                    ]}
                >
                    <Select
                        placeholder={t("create-user.placeholder-role")}
                    >
                        {
                            CONSTANTS.LIST.ROLES.map(item =>
                                <Option key={item.key} value={item.key}>{item.value}</Option>
                            )
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="accounts"
                    label={
                        <span>
                            {t("create-user.label-accounts")}
                            <HelperButton
                                message={t("content-message.tooltip-accounts")}
                                color={getGradientColor(purpleColor)}
                            />
                        </span>
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Please select the account!',
                        }
                    ]}
                >
                    <Select placeholder={t("create-user.placeholder-accounts")}>
                    {
                        accounts.map(item =>
                            <Option key={item.accountId} value={item.accountId}>{item.name}</Option>
                        )
                    }
                    </Select>
                </Form.Item>
            </Form>
        </Drawer>
    );
}

export default UserForm;