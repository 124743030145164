import React, { useReducer } from 'react';
import headerOptionsReducer, { initialHeaderOptionsState } from '../reducers/headerOptionsReducer';

export const HeaderOptionContext = React.createContext();

const HeaderOptionProvider = ({children})=> {

  const [headerOptionsState, headerOptionsDispacth] = useReducer(headerOptionsReducer, initialHeaderOptionsState);

  return (
    <HeaderOptionContext.Provider value={{headerOptionsState, headerOptionsDispacth}}>
      {children}
    </HeaderOptionContext.Provider>
  )
}

export default HeaderOptionProvider