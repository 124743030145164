import { Checkbox, Col, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import RcsApi from "../../../../../Api/RcsApi";
import useSpinner from "../../../../../Hooks/useSpinner";
import TYPES from "../../../../../reducers/types";
import CONSTANTS from "../../../../../utils/const";
import { cleanErrorMessage, notificationError } from "../../../../../utils/helpers";
import "./styles.css"
import HelperButton from "../../../../../components/HelperButton/HelperButton";
import { getGradientColor, purpleColor } from "../../../../../utils/colorsCss";

const { TextArea } = Input

const groupCarriersByInitial = (carrierList) => {
  const grouped = {};

  carrierList.forEach(carrier => {
    const initial = carrier.name[0].toUpperCase();
    const label = carrier.managementType === "CARRIER_MANAGED" ? `${carrier.name}*` : carrier.name;
    if (!grouped[initial]) {
      grouped[initial] = [];
    }
    grouped[initial].push({ ...carrier, label });
  });

  return grouped;
};

export const StepFour = ({ agentId, setStep4Data, step4Data, form }) => {
  const [t] = useTranslation("rcs-channel");
  const [carriers, setCarriers] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCarriers, setSelectedCarriers] = useState([]);

  const params = useParams()
  const { getCarriers } = RcsApi();
  const { spinnerDispacth } = useSpinner();

  useEffect(() => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.DEFAULT }
    });

    if (params?.brand !== null || params?.brand !== undefined) {
      getCarriers(params.brand)
        .then((res) => {
          setCarriers(res?.data || [])
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
          });
        })
        .catch(e => {
          notificationError(cleanErrorMessage(e))
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
          });
        })
    }
  }, [params?.brand]);

  useEffect(() => {
    setStep4Data({ ...step4Data, carriers: selectedCarriers.map(c => ({ rcsCarrierId: c.rcsCarrierId, name: c.name, managementType: c.managementType })) })
  }, [selectedCarriers]);

  useEffect(() => {
    if (step4Data) {
      form.setFieldsValue({
        contactName: step4Data.contactName,
        contactTitle: step4Data.contactTitle,
        contactEmailAddress: step4Data.contactEmailAddress,
        optinDescription: step4Data.optinDescription,
        triggerDescription: step4Data.triggerDescription,
        interactionsDescription: step4Data.interactionsDescription,
        optoutDescription: step4Data.optoutDescription,
        agentAccessInstructions: step4Data.agentAccessInstructions,
        videoUris: step4Data.videoUris,
        screenshotUris: step4Data.screenshotUris,
      })
    }
  }, [step4Data]);

  const groupedCarriers = groupCarriersByInitial(carriers);
  const filteredCarriers = Object.keys(groupedCarriers).reduce((acc, key) => {
    const filtered = groupedCarriers[key].filter(carrier =>
      carrier.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filtered.length > 0) {
      acc[key] = filtered;
    }
    return acc;
  }, {});

  const handleCheckboxChange = (carrier, checked) => {
    if (checked) {
      setSelectedCarriers((prev) => [...prev, carrier]);
    } else {
      setSelectedCarriers((prev) => prev.filter((c) => c.rcsCarrierId !== carrier.rcsCarrierId));
    }
  };

  const isCarrierSelected = (carrier) =>
    selectedCarriers.some((selected) => selected.rcsCarrierId === carrier.rcsCarrierId);


  return (
    <Form form={form} layout="vertical" style={{ marginTop: "40px" }}>
      <Row>
        <Col span={16} style={{ padding: "0px 15px 0px 0px" }}>
          <Row style={{ maxHeight: "calc(100vh - 380px)", overflowY: "scroll", padding: "0px 15px" }}>
            <Row style={{ marginTop: "20px" }}>
              <div style={{ display: "flex" }}>
                <h2>{t("step4.label.point-of-contact")}</h2>
                <HelperButton
                  message={t("step4.tooltip.point-of-contact")}
                  color={getGradientColor(purpleColor)}
                />
              </div>
            </Row>
            <Row style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                <Form.Item
                  name="contactName"
                  label={t("step4.label.name")}
                  rules={[{ required: true, message: t("step4.rules.name-required") }]}
                  style={{ width: '100%', marginRight: "10px" }}
                  onChange={(e) => setStep4Data({ ...step4Data, contactName: e.target.value })}
                >
                  <Input
                    id="create-template-name-input"
                    placeholder={t("step4.placeholder.name")}
                    maxLength={40}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                <Form.Item
                  name="contactTitle"
                  label={t("step4.label.title")}
                  rules={[{ required: true, message: t("step4.rules.title-required") }]}
                  style={{ width: '100%', marginRight: "10px" }}
                  onChange={(e) => setStep4Data({ ...step4Data, contactTitle: e.target.value })}
                >
                  <Input
                    id="create-template-name-input"
                    placeholder={t("step4.placeholder.title")}
                    maxLength={40}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                <Form.Item
                  name="contactEmailAddress"
                  label={t("step4.label.email")}
                  rules={[{ required: true, message: t("step4.rules.email-required") }, { type: "email", message: t("step4.rules.email-required") }]}
                  style={{ width: '100%' }}
                  onChange={(e) => setStep4Data({ ...step4Data, contactEmailAddress: e.target.value })}
                >
                  <Input
                    id="create-template-name-input"
                    placeholder={t("step4.placeholder.email")}
                  />
                </Form.Item>
              </div>
            </Row>
            <Col span={24} style={{ padding: "0px" }}>
              <Row>
                <Row style={{ marginTop: "20px" }}>
                  <div style={{ display: "flex",  }}>
                   <h2>{t("step4.label.agent-experience")}</h2>
                    <HelperButton
                      message={t("step4.tooltip.agent-experience")}
                      color={getGradientColor(purpleColor)}
                    />
                  </div>
                </Row>
                <Row style={{ width: "100%", gap: "10px", flexFlow: "unset" }}>
                  <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
                    <Form.Item
                      name="optinDescription"
                      label={
                        <div>
                          {t("step4.label.opt-in-description")}
                          <HelperButton
                            message={t("step4.tooltip.opt-in-description")}
                            color={getGradientColor(purpleColor)}
                          />
                        </div>
                      }
                      rules={[{ required: true, message: t("step4.rules.opt-in-description-required") }]}
                      style={{ width: '100%' }}
                      onChange={(e) => setStep4Data({ ...step4Data, optinDescription: e.target.value })}
                    >
                      <TextArea
                        id="create-template-name-input"
                        disabled={agentId}
                        placeholder={t("step4.placeholder.your-answer")}
                        maxLength={100}
                        rows={3}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
                    <Form.Item
                      name="triggerDescription"
                      label={
                        <div>
                          {t("step4.label.trigger-description")}
                          <HelperButton
                            message={t("step4.tooltip.trigger-description")}
                            color={getGradientColor(purpleColor)}
                          />
                        </div>
                      }
                      rules={[{ required: true, message: t("step4.rules.trigger-description-required") }]}
                      style={{ width: '100%' }}
                      onChange={(e) => setStep4Data({ ...step4Data, triggerDescription: e.target.value })}
                    >
                      <TextArea
                        id="create-template-name-input"
                        disabled={agentId}
                        placeholder={t("step4.placeholder.your-answer")}
                        maxLength={100}
                        rows={3}
                      />
                    </Form.Item>
                  </div>
                </Row>
                <Row style={{ width: "100%", gap: "10px", flexFlow: "unset" }}>
                  <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
                    <Form.Item
                      name="interactionsDescription"
                      label={
                        <div>
                          {t("step4.label.interactions-description")}
                          <HelperButton
                            message={t("step4.tooltip.interactions-description")}
                            color={getGradientColor(purpleColor)}
                          />
                        </div>
                      }
                      rules={[{ required: true, message: t("step4.rules.interactions-description-required") }]}
                      style={{ width: '100%' }}
                      onChange={(e) => setStep4Data({ ...step4Data, interactionsDescription: e.target.value })}
                    >
                      <TextArea
                        id="create-template-name-input"
                        disabled={agentId}
                        placeholder={t("step4.placeholder.your-answer")}
                        maxLength={100}
                        rows={3}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
                    <Form.Item
                      name="optoutDescription"
                      label={
                        <div>
                          {t("step4.label.opt-out-description")}
                          <HelperButton
                            message={t("step4.tooltip.opt-out-description")}
                            color={getGradientColor(purpleColor)}
                          />
                        </div>
                      }
                      rules={[{ required: true, message: t("step4.rules.opt-out-description-required") }]}
                      style={{ width: '100%' }}
                      onChange={(e) => setStep4Data({ ...step4Data, optoutDescription: e.target.value })}
                    >
                      <TextArea
                        id="create-template-name-input"
                        disabled={agentId}
                        placeholder={t("step4.placeholder.your-answer")}
                        maxLength={100}
                        rows={3}
                      />
                    </Form.Item>
                  </div>
                </Row>
              </Row>
              <Row>
                <Row style={{ marginTop: "20px" }}>
                  <div style={{ display: "flex" }}>
                   <h2>{t("step4.label.agent-preview")}</h2>
                    <HelperButton
                      message={t("step4.tooltip.agent-preview")}
                      color={getGradientColor(purpleColor)}
                    />
                  </div>
                </Row>
                <Row style={{ width: "100%" }}>
                  <Col span={12} style={{ padding: "0px 10px 0px 0px" }}>
                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                      <Form.Item
                        name="agentAccessInstructions"
                        label={
                          <div>
                            {t("step4.label.agent-access-instructions")}
                            <HelperButton
                              message={t("step4.tooltip.agent-access-instructions")}
                              color={getGradientColor(purpleColor)}
                            />
                          </div>
                        }
                        rules={[{ required: true, message: t("step4.rules.agent-access-instructions-required") }]}
                        style={{ width: '100%' }}
                        onChange={(e) => setStep4Data({ ...step4Data, agentAccessInstructions: e.target.value })}
                      >
                        <TextArea
                          id="create-template-name-input"
                          disabled={agentId}
                          placeholder={t("step4.placeholder.your-answer")}
                          maxLength={100}
                          rows={3}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12} style={{ padding: "0px 10px 0px 0px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Item
                        name="videoUris"
                        label={
                          <div>
                            {t("step4.label.video-url")}
                            <HelperButton
                              message={t("step4.tooltip.video-url")}
                              color={getGradientColor(purpleColor)}
                            />
                          </div>
                        }
                        style={{ width: '100%' }}
                        onChange={(e) => setStep4Data({ ...step4Data, videoUris: [e.target.value] })}
                      >
                        <Input
                          id="create-template-name-input"
                          disabled={agentId}
                          placeholder={t("step4.placeholder.video-url")}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Item
                        name="screenshotUris"
                        label={
                          <div>
                            {t("step4.label.screenshot-url")}
                            <HelperButton
                              message={t("step4.tooltip.screenshot-url")}
                              color={getGradientColor(purpleColor)}
                            />
                          </div>
                        }
                        style={{ width: '100%' }}
                        onChange={(e) => setStep4Data({ ...step4Data, screenshotUris: [e.target.value] })}
                      >
                        <Input
                          id="create-template-name-input"
                          disabled={agentId}
                          placeholder={t("step4.placeholder.screenshot-url")}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={8} style={{ padding: "0px" }}>
          <div>
            <label htmlFor="search">{t("step4.label.search-carriers")}</label>
            <HelperButton
              message={t("step4.tooltip.carriers-disclaimer")}
              color={getGradientColor(purpleColor)}
            />
          </div>
          <Input
            name="search"
            placeholder={t("step4.placeholder.search-carriers")}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            style={{ marginBottom: '16px', marginTop: "2px" }}
          />
          <div style={{ maxHeight: "calc(100vh - 380px)", overflowY: "auto", paddingRight: "10px" }}>
            {carriers.length > 0 ? Object.keys(filteredCarriers).sort().map(initial => (
              <div key={initial} style={{ marginBottom: "16px" }}>
                <h3>{initial}</h3>
                <Row gutter={[16, 16]}>
                  {(filteredCarriers[initial] || []).map((carrier, index) => (
                      <Col span={12} key={index}>
                        <Checkbox checked={isCarrierSelected(carrier)} onChange={(e) => handleCheckboxChange(carrier, e.target.checked)}>{carrier.label}</Checkbox>
                      </Col>
                    )
                  )}
                </Row>
              </div>
            )) : null}
          </div>
        </Col>
      </Row>
    </Form>
  );
};