import CardCustom from "../../../CardCustom/CardCustom";
import { Row, Col, Button, Popover } from 'antd';
import CampaignApi from '../../../../Api/CampaignApi';
import propTypes from 'prop-types'
import { currencyFormat, numberFormat } from '../../../../utils/helpers';
import styles from "./CardSummary.module.css";
import { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons'
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from "react-i18next";

const CardSummary = ({campaignId, data}) => {

  const [t] = useTranslation("campaign-bulk");

  const [ popoverVisible, setPopoverVisible ] = useState(false);
  const [ showSpinner, setShowSpinner ] = useState(false);
  const [ dataFile, setDataFile ] = useState(null);

  const { getDataFileByPefix } = CampaignApi();

  const handlerShowFileDetails = () => {

    if(popoverVisible) {
      setPopoverVisible(false);
    } else {
      if(dataFile) {
        setPopoverVisible(true);
      } else {
        setShowSpinner(true);
        getDataFileByPefix(campaignId, data.prefix)
        .then(response => {
          setDataFile(response);
          setPopoverVisible(true);
          setShowSpinner(false);
        })
        .catch(error => {
          console.error(error);
          setShowSpinner(false);
        });
      }
    }
  }

  return (
    <Fragment>
      <CardCustom
        minWidth='250px'
        extra={
          <Popover
            placement="bottom"
            content={
              <table className={styles['table']}>
                <thead>
                  <tr className={styles['table-header']}>
                    <th>{t("summary.card-label-destination-number")}</th>
                    <th>{t("summary.card-label-message")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    dataFile && (dataFile.map((data, index) =>
                      <tr key={index} className={styles['table-body']}>
                        <td>{data.destinationNumber}</td>
                        <td>{data.messageContent}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            }
            trigger="click"
            visible={popoverVisible}
          >
            <Button
              icon={<FontAwesomeIcon icon={ faBars } />}
              onClick={handlerShowFileDetails}
              loading={showSpinner}
            >
            </Button>
          </Popover>
        }
      >
        <Row>
          <Col span={24}>
            <div className={styles['falg']}>
              <ReactCountryFlag
                countryCode={data.countryCode}
                svg
                style={{
                    width: '2em',
                    height: '2em',
                }}
                title="US"
              />
              <span className={styles['prefix']}>+{data.prefix}</span>
            </div>
            <p className={`yp-subtitle ${styles['title-country']}`}>{data.countryName}</p>
            <p className={styles['detail']}>{t("summary.card-label-total-message")}: <span>{numberFormat(data.totalMessage, 0)}</span></p>
            <p className={styles['detail']}>{t("summary.card-label-rate-per-message")}: <span>{currencyFormat(data.unitRate, 5)}</span></p>
            <p className={styles['detail']}>{t("summary.card-label-total-cost")}: <span className={styles['total']}>{currencyFormat(data.totalMessage * data.unitRate, 5)}</span></p>
          </Col>
        </Row>
      </CardCustom>
    </Fragment>
  );
}

CardSummary.propTypes = {
  campaignId: propTypes.string.isRequired,
  data: propTypes.object.isRequired
}

export default CardSummary;