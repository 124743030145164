import { useEffect, useState } from "react";
import useSpinner from "../../Hooks/useSpinner";
import CampaignList from "../../components/HistoryCampaign/CampaignList/CampaignList";
import styles from "./HistoryCampaign.module.css";
import TYPES from "../../reducers/types";
import CONSTANTS, { CHANELLIST } from "../../utils/const";
import DataApi from "../../Api/DataApi";
import ReportApi from "../../Api/ReportApi";
import useAuth from "../../Hooks/useAuth";
import SmsDetails from "../../components/HistoryCampaign/CampaignDetails/SmsDetails/SmsDetails";
import VoiceDetails from "../../components/HistoryCampaign/CampaignDetails/VoiceDetails/VoiceDetails";
import ShortlinkDetails from "../../components/HistoryCampaign/CampaignDetails/ShortlinkDetails/ShortlinkDetails";
import WhatsAppDetails from "../../components/HistoryCampaign/CampaignDetails/WhatsAppDetails/WhatsAppDetails";
import useHeaderOptions from "../../Hooks/useHeaderOptions";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { notificationError } from "../../utils/helpers";
import RCSDetails from "../../components/HistoryCampaign/CampaignDetails/RCSDetails/RCSDetails";

const HistoryCampaign = () => {

  const [campaigns, setCampaigns] = useState([]);
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [chanel, setChanel] = useState("");
  const [t] = useTranslation("campaign-reports");

  const { getCampaignHistoryByCompany, getCampaignHistoryByAccounts, getCampaignDetails} = DataApi();
  const { GenerateCampaignReport} = ReportApi();
  const { spinnerDispacth } = useSpinner();
  const { auth } =  useAuth();
  const { headerOptionsDispacth } = useHeaderOptions();

  const history = useHistory();

  useEffect(() => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNSHISTORY }
    });

    getCampaignsHistory(auth.companyId)
    .then(response => {

      setCampaigns(response);

      headerOptionsDispacth({
        type: TYPES.HEADER_OPTIONS.CAMPAIGN_HISTORY_OPTION,
        payload: TYPES.HEADER_OPTIONS.CAMPAIGN_HISTORY_OPTION
      })

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNSHISTORY }
      });
    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNSHISTORY }
      });
      console.error(error);
    })

  }, [])

  const getCampaignsHistory = async () => {
    let accountList = auth.userAccounts;
    
    let result =  auth.role === CONSTANTS.ROLES.COMPANY_ADMIN  ? await getCampaignHistoryByCompany(auth.companyId)
      : await getCampaignHistoryByAccounts(accountList);
    
    return result;
  }

  const onDetailsClick = (campaign) => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNDETAILS }
    });

    getCampaignDetails(auth.companyId, campaign.campaignId, campaign.channelId)
    .then(response => {
      response.campaignName = campaign.campaignName;
      setCampaignDetails(response);
      setChanel(campaign.channelId);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNDETAILS }
      });
    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNDETAILS }
      });
      console.error(error);
    })
  }

  const onReloadCampaignDetails = async() => {
    if (campaignDetails) {
      await onDetailsClick(campaignDetails);
    }
  }

  const onGenerateReport = (value) => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.GENERATEREPORT }
    });

    if (value.status === 'Canceled') {
      notificationError(t("generate-report.validate-campaign"));

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GENERATEREPORT }
      });
    }else{
      GenerateCampaignReport(auth.companyId, auth.userName, value.campaignId, value.channelId)
      .then(() => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.GENERATEREPORT }
        });

        history.push("/Campaigns/Reports");
      })
      .catch(error => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.GENERATEREPORT }
        });
        console.error(error)
      })
    }
  }

  const render = () => {
    switch (chanel) {
      case CHANELLIST.SMS.key:
        return <SmsDetails data={campaignDetails} onReloadCampaignDetails={onReloadCampaignDetails}/>
      case CHANELLIST.VOICE.key:
        return <VoiceDetails data={campaignDetails} onReloadCampaignDetails={onReloadCampaignDetails}/>
      case CHANELLIST.SHORTLINK.key:
        return <ShortlinkDetails data={campaignDetails} onReloadCampaignDetails={onReloadCampaignDetails}/> 
        case CHANELLIST.RCS.key:
          return <RCSDetails data={campaignDetails} onReloadCampaignDetails={onReloadCampaignDetails}/> 
        case CHANELLIST.WHATSAPP.key:
          return <WhatsAppDetails data={campaignDetails} onReloadCampaignDetails={onReloadCampaignDetails}/> 
      default:
        return <></>;
    }
  }

  return (
    <div className={styles['content']}>
      <div className={styles['content-card']}>
        <div className={styles['content-card-left']}>
          <CampaignList 
            campaigns={campaigns}
            onDetailsClick={onDetailsClick}
            onGenerateReport={onGenerateReport}
          />
        </div>
      </div>
      <div className={styles['content-card']}>
        {
          render()
        }
      </div>
    </div>
  );
}
 
export default HistoryCampaign;