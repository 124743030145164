import { useContext, useState } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./LastCampaigns.module.css";
import { useEffect } from "react";
import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST } from "../../../../utils/const";
import { Link } from "react-router-dom";
import { dateFormat } from "../../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRadiation, faCheckCircle, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import DataApi from "../../../../Api/DataApi";
import { AuthContext } from "../../../../contexts/authRedirect";
import { Spin } from "antd";

const LastCampaigns = ({filter}) => {

  const [t] = useTranslation("home-account");
  const {getLastCampaigns} = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [period, setPiriod] = useState("");

  useEffect(() => {
    if(filter && filter.id) {
      setLoading(true);
      
      getLastCampaignsData()
      .then(response => {
        setLoading(false);

        const {chartData} = response;
        let chartDataFilter = chartData;

        if(filter.channel.key !== CHANELLIST.DEFAULT.key) {
          chartDataFilter = chartData.filter(x => x.channel.toUpperCase() ===  filter.channel.value.toUpperCase());
        }

        setData(chartDataFilter);

        if(filter && filter.time) {
          let resultPeriod = CONSTANTS.LIST.PERIODREPORT.find(x => x.key === filter.time);
          setPiriod(resultPeriod.value);
        }
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      })
    }
    
  }, [filter])

  const getLastCampaignsData = async () => {
    let data = {
      companyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      accountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    }
    
    let responseData = await getLastCampaigns(filter.time, data);
    return {chartData: responseData}; 
  }

  const getColorClass = (value) => {
    let css = "";

    switch (value.toUpperCase()) {
      case CHANELLIST.SMS.value.toUpperCase():
        css = styles['sms-color'];
        break;
      case CHANELLIST.VOICE.value.toUpperCase():
        css = styles['voice-color'];
        break;
      default:
        css = "";
        break;
    }
    return css;
  }

  const getVariationColor = (variation) => {
    if(variation <= 20) {
      return styles['variation-red'];
    }

    if(variation <= 99) {
      return styles['variation-orange'];
    }

    if(variation === 100) {
      return styles['variation-green'];
    }
  }

  const getVariationIcon = (variation) => {
    if(variation <= 20) {
      return <FontAwesomeIcon style={{ marginRight: "15px", fontSize: "0.9em" }} icon={faRadiation} />;
    }

    if(variation <= 99) {
      return <FontAwesomeIcon style={{ marginRight: "15px", fontSize: "0.8em" }} icon={faExclamationTriangle} />;
    }

    if(variation === 100) {
      return <FontAwesomeIcon style={{ marginRight: "15px", fontSize: "0.9em" }} icon={faCheckCircle} />;
    }
  }

  return (
    <div className={styles['dashboard-body-card-double']}>
      <CardCustom
        style={{height: "100%", widt:"100%"}}
        title={
        <div className={styles['card-title']}>
          <p>{t("card-last-campaigns.label-title")}</p>
          <HelperButton
            message={t("card-last-campaigns.tooltip-title")}
            color={getGradientColor(purpleColor)}
          />
        </div>
        }
      >
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              <div className={styles['dashboard-card-body-title']}>
                <p className={styles['dashboard-card-body-period']}>{period}</p>
                <div className={styles['dashboard-card-body-buttons']}>
                  <Link to="/Campaigns/Voz" className={styles['dashboard-card-body-button']}>{t("dashboard-header-buttons.voice-campaign")}</Link>
                  <Link to="/Campaigns/BulkCampaign" className={styles['dashboard-card-body-button']}>{t("dashboard-header-buttons.sms-campaign")}</Link>
                </div>
              </div>
              <table className={styles['dashboard-card-body-table']}>
                <thead>
                  <tr>
                    <th>{t("card-last-campaigns.table-head-label-campaign")}</th>
                    <th>{t("card-last-campaigns.table-head-label-channel")}</th>
                    <th>{t("card-last-campaigns.table-head-label-volume")}</th>
                    <th>{t("card-last-campaigns.table-head-label-convertion")}</th>
                    <th>{t("card-last-campaigns.table-head-label-creation-date")}</th>
                  </tr>
                </thead>
                <tbody>
                {
                  data.map((data, index) =>
                    <tr key={index}>
                      <td>{data.campaignName}</td>
                      <td className={getColorClass(data.channel)}>{data.channel}</td>
                      <td>{data.totalVolume}</td>
                      <td>
                        <div className={`${styles['dashboard-card-body-table-variation']} ${getVariationColor(data.conversionRate)}`}>
                          <p>{getVariationIcon(data.conversionRate)}</p>
                          <p>{data.conversionRate} %</p>   
                        </div>
                      </td>
                      <td>{dateFormat(data.creationDate, true)}</td>
                    </tr>
                  ) 
                }
                </tbody>
              </table>
            </div>
          </Spin>
        </div>
      </CardCustom>
    </div>
  );
}
 
export default LastCampaigns;