import useAuth from "../Hooks/useAuth";
import { balanceRequest } from "../utils/authConfig";

const BalanceApi = () => {
  const { passTokenToApi } = useAuth();

  return {
    GetAcocuntsBalanceByCompanyId: async (companyId) => {
      const accessToken = await passTokenToApi(balanceRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Balance/Company/${companyId}/Account`;
          const url = `${process.env.REACT_APP_BALANCE_API_URL}${resource}`;

          let response = await fetch(url, options);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the accounts balance by company id. Status Code: ${response.status}`);
          }

          let accountsBalance = await response.json();
          return accountsBalance;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the accounts balance by company id.");
      }
    },
    GetAccountBalanceByAccountId: async (companyId, accountId) => {
      const accessToken = await passTokenToApi(balanceRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Balance/Company/${companyId}/Account/${accountId}`;
          const url = `${process.env.REACT_APP_BALANCE_API_URL}${resource}`;

          let response = await fetch(url, options);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the account balance by company id and account id. Status Code: ${response.status}`);
          }

          let accountsBalance = await response.json();
          return accountsBalance;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the account balance by company id and account id.");
      }
    },
    SetAccountBalance: async (company) => {
      const accessToken = await passTokenToApi(balanceRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(company),
            redirect: 'follow'
          };

          const resource = "/api/Balance/Account";
          const url = `${process.env.REACT_APP_BALANCE_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);
          let result = await response.json();

          if (response.status !== 200) {
            let errorMessage = "";
            if (result.message && !result.listErrors) {
              errorMessage = result.message;
            } else if (result.message && result.listErrors) {
              errorMessage = result.listErrors.join(', ');
            }
            throw new Error(errorMessage);
          }

          
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error.message);
      }
    },
    GetCompanyBalance: async (companyId) => {
      const accessToken = await passTokenToApi(balanceRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Balance/Company/${companyId}/Accounts`;
          const url = `${process.env.REACT_APP_BALANCE_API_URL}${resource}`;

          let response = await fetch(url, options);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the company balance by company id. Status Code: ${response.status}`);
          }

          let companyBalance = await response.json();
          return companyBalance;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the company balance by company id.");
      }
    },
    getSmsAvailable: async (companyId, prefix) => {
      const accessToken = await passTokenToApi(balanceRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Balance/Company/${companyId}/Available/${prefix}`;
          const url = `${process.env.REACT_APP_BALANCE_API_URL}${resource}`;

          let response = await fetch(url, options);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the sms available. Status Code: ${response.status}`);
          }

          let companyBalance = await response.json();
          return companyBalance;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the sms available.");
      }
    },
    getSmsAccountAvailable: async (accountId, prefix) => {
      const accessToken = await passTokenToApi(balanceRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Balance/Account/${accountId}/Available/${prefix}`;
          const url = `${process.env.REACT_APP_BALANCE_API_URL}${resource}`;

          let response = await fetch(url, options);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the sms available. Status Code: ${response.status}`);
          }

          let companyBalance = await response.json();
          return companyBalance;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the sms available.");
      }
    },
    GetHistoricTransferByCompany : async (companyId, date) =>  {
      const accessToken = await passTokenToApi(balanceRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Balance/Company/${companyId}/HistoricTransfer/${date}`;
          const url = `${process.env.REACT_APP_BALANCE_API_URL}${resource}`;
          
          let response = await fetch(url, options);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the historic transfer balance. Status Code: ${response.status}`);
          }

          let companyBalance = await response.json();
          return companyBalance;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the historic transfer balance");
      }
    },
    GetHistoricTransferAdmin : async (date) =>  {
      const accessToken = await passTokenToApi(balanceRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Balance/HistoricTransfer/${date}`;
          const url = `${process.env.REACT_APP_BALANCE_API_URL}${resource}`;
          
          let response = await fetch(url, options);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the historic transfer balance. Status Code: ${response.status}`);
          }

          let companyBalance = await response.json();
          return companyBalance;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the historic transfer balance");
      }
    },
    TransferBalance : async (companyId, transferdata) =>  {
      const accessToken = await passTokenToApi(balanceRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'POST',
            body: JSON.stringify(transferdata),
            headers: myHeaders,
          };

          const resource = `/api/Balance/Company/${companyId}/Transfer`;
          const url = `${process.env.REACT_APP_BALANCE_API_URL}${resource}`;
          
          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred transfer balance. Status Code: ${response.status}`);
          }

          let message = await response.text();
          return message;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred transfer balance");
      }
    },
    getCompanyTotalBalance: async (companyId) => {
      const accessToken = await passTokenToApi(balanceRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Balance/Company/${companyId}`;
          const url = `${process.env.REACT_APP_BALANCE_API_URL}${resource}`;

          let response = await fetch(url, options);
          let result = await response.json();

          if (response.status !== 200) {
            let errorMessage = "";
            if (result.message && !result.listErrors) {
              errorMessage = result.message;
            } else if (result.message && result.listErrors) {
              errorMessage = result.listErrors.join(', ');
            }
            throw errorMessage;
          }

          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the company balance by company id.");
      }
    },
    addBalance: async (data) => {
      const accessToken = await passTokenToApi(balanceRequest);

      if (accessToken) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify(data),
          redirect: 'follow'
        };

        const resource = `/api/Balance/Company/${data.CompanyId}/Add`;
        const url = `${process.env.REACT_APP_BALANCE_API_URL}${resource}`;

        let response = await fetch(url, requestOptions);
        let result = await response.json();

        if (response.status !== 200) {
          let errorMessage = "";
          if (result.message && !result.listErrors) {
            errorMessage = result.message;
          } else if (result.message && result.listErrors) {
            errorMessage = result.listErrors.join(', ');
          }
          throw errorMessage;
        }

        return result;
      }
      else {
        console.log('Authentication Error');
        return [];
      }
    },
    getHistorybalanceBalance: async () => {
      const accessToken = await passTokenToApi(balanceRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Balance/Carrier`;
          const url = `${process.env.REACT_APP_BALANCE_API_URL}${resource}`;

          let response = await fetch(url, options);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the company balance by carrier. Status Code: ${response.status}`);
          }

          let companyBalance = await response.json();
          return companyBalance;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the company balance by carrier.");
      }
    },
  }
}

export default BalanceApi;
