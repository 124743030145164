import { Fragment, useState, useEffect, useContext } from 'react';
import { Row, Col } from 'antd';
import CompanyAccounts from "../../components/Account/CompanyAccounts/companyaccounts";
import AccountMetrics from "../../components/Account/AccountMetrics/accountmetrics";
import BalanceApi from '../../Api/BalanceApi';
import { AuthContext } from '../../contexts/authRedirect';
import AccountApi from '../../Api/AccountApi';
import CampaignApi from '../../Api/CampaignApi';
import useHeaderOptions from '../../Hooks/useHeaderOptions';
import TYPES from '../../reducers/types';

const initialAccountMetricsState = {
  name: "",
  totalBalance: 0,
  totalCampaigns: 0,
  totalContacts: 0,
  creationDate: null
}

const Account = () => {

  const [accountMetrics, setAccountMetrics] = useState(initialAccountMetricsState);
  const [accountsBalance, setAccountsBalance] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const { GetAcocuntsBalanceByCompanyId, GetAccountBalanceByAccountId } = BalanceApi();
  const { GetAccountsByCompanyId } = AccountApi();
  const { GetCampaignsByAccountId } = CampaignApi();
  const { auth } = useContext(AuthContext);
  const { headerOptionsDispacth } = useHeaderOptions();

  useEffect(() => {
    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.DEFAULT_OPTION,
      payload: TYPES.HEADER_OPTIONS.DEFAULT_OPTION
    });
    getInitailData();
  }, [])

  const onAccountSelected = metrics => {
    setAccountMetrics(metrics);
  }

  const getInitailData = async () => {

    let responseAccount = await GetAccountsByCompanyId(auth.companyId);
    setAccounts(responseAccount);

    let accountsBalance = await GetAcocuntsBalanceByCompanyId(auth.companyId);
    let data = [];

    accountsBalance.forEach(accountBalance => {
      let account = responseAccount.find(ac => ac.accountId === accountBalance.accountId);

      if(account) {
        let accountName = account ? account.name : '';
        data.push({
          id: accountBalance.accountId,
          accountName: accountName,
          lastAddedBalance: accountBalance.lastAddedBalanceDate,
          totalBalance: accountBalance.totalBalance,
          availableBalance: accountBalance.availableBalance,
          onHoldBalance: accountBalance.onHoldBalance,
          isDefaultAccount: account.isDefaultAccount
        });
      }
    });
    setAccountsBalance(data);
  }

  const handlerBalance = async (accountId) => {

    let responseBalance = await GetAccountBalanceByAccountId(auth.companyId, accountId, auth.accessToken);
    let responseCampaigns = await GetCampaignsByAccountId(accountId);

    let totalCampaigns = responseCampaigns ? responseCampaigns.length : 0;
    let account = accounts.find(x => x.accountId === accountId);

    let metrics = {
      id: accountId,
      name: account.name,
      totalBalance: responseBalance.availableBalance,
      totalCampaigns: totalCampaigns,
      totalContacts: 0, //TO DO: Get total contacts
      creationDate: account.creationDate
    }

    setAccountMetrics(metrics);
  }

  const clearAccountMetrics = () => {
    setAccountMetrics(initialAccountMetricsState);
  }

  const getDataAccounts = async () =>{
    let responseAccount = await GetAccountsByCompanyId(auth.companyId);
    setAccounts(responseAccount);    
  }

  return (
    <Fragment>
      <Row gutter={[0, 8]}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={14}
          xl={14}
        >
          <CompanyAccounts
            onAccountSelected={onAccountSelected}
            accountsData={accounts}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={10}
          xl={10}
        >
          <AccountMetrics
            accountMetrics={accountMetrics}
            clearAccountMetrics={clearAccountMetrics}
            getDataAccounts={getDataAccounts}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default Account;