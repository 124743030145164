import { Col, Empty, Row, Select } from "antd";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next'

const ContentForm = ({ template, campaignName, wildcards, handleCampaignMobilePreviewData, handleCampaignBodyExample, setCanVerifyCost, handleBodyExampleFields, selectedDynamicFields, handleCampaignSelectedDynamicFields }) => {
  const [values, setValues] = useState(selectedDynamicFields)
  const [fields, setFields] = useState(null)
  const [t] = useTranslation("campaign-bulk");

  const options = useMemo(() => (wildcards?.fields || []).map((f, i) => ({ value: wildcards?.values[i], label: f })), [template?.wabaTemplateId])

  const handleChange = (v, w, index) => {
    setValues((prev) => {
      const updatedValues = { ...prev, [w]: v };
      handleCampaignSelectedDynamicFields(updatedValues)
      return updatedValues;
    });
    setFields((prev) => {
      const _index = wildcards?.values.indexOf(v) 
      const updatedValues = { ...prev, [w]: wildcards?.fields[_index] };
      return updatedValues;
    });
  };

  useEffect(() => {
    if (values) {
      handleCampaignMobilePreviewData({ bodyExample: Object.values(values) })
      handleCampaignBodyExample(values)
      handleBodyExampleFields(fields)
      setCanVerifyCost(Object.values(values || {}).length === options.length)
    } else {
      setCanVerifyCost(true)
    }
  }, [values, options]);

  return (
    <Fragment>
      <Row>
        <Col style={{ marginBottom: "16px" }}>
          <p>{t("content-message.category")}{" "} {template?.category}</p>
          <p>{campaignName}</p>
        </Col>
      </Row>
      {(options || []).length > 0 ? <Row>
          {wildcards?.fields?.map((field, _index) => {
            const index = _index +1
            const name = `{{${index}}}`
            return (
              <Fragment key={index}>
                <Col span={24} style={{ padding: "0px 10px 0px 0px" }}>
                  <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                    <label><span style={{ color: "#ff4d4f" }}>*</span> {`${t("dynamic-field")} ${name}`}</label>
                    <Select
                      id={`dynamic-field-${name}`}
                      className={`dynamic-field-${name}`}
                      placeholder={`${t("content-message.placeholder-choose-dynamic-field")} ${name}`}
                      value={selectedDynamicFields?.[name] || null}
                      onChange={(v) => handleChange(v, name, _index)}
                    >
                      {options.map(option => (
                        <Select.Option
                          id={option.label}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              </Fragment>
            )
          })}
        </Row> :
        <div>
          <Empty
            description={t("content-message.no-wildcards")}
          />
        </div>}
    </Fragment>
  );
}

export default ContentForm;