import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TemplateApi from "../../Api/TemplatesApi";
import GroupData from "../../components/HistoryContacts/GroupData/GroupData";
import ListGroups from "../../components/HistoryContacts/ListGroups/ListGroups";
import useAuth from "../../Hooks/useAuth";
import useSpinner from "../../Hooks/useSpinner";
import TYPES from "../../reducers/types";
import CONSTANTS from "../../utils/const";
import { notificationError, notificationOk } from "../../utils/helpers";
import styles from "./HistoryContacts.module.css";

const HistoryContacts = () => {

  const { getListGorups, deleteGroup, getListGorupsByAccount } = TemplateApi();
  const { auth } =  useAuth();
  const { spinnerDispacth } = useSpinner();
  const [t] = useTranslation("contacts-history");

  const [listGroup, setListGroup] = useState([]);
  const [groupSelected, setGroupSelected] = useState(null);

  useEffect(() => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.CONTACTSGROUP }
    });

    if(auth.role === "CompanyAdmin"){
      getListGorups(auth.companyId)
        .then(response => {
          setListGroup(response);

          if(response && response.length > 0) {
            setGroupSelected({id: response[0].id, name: response[0].groupName})
          } 

          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTSGROUP }
          });
        })
        .catch(error => {
          console.error(error)
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTSGROUP }
          });
        })
    } 

    if(auth.role === "user"){
      getListGorupsByAccount(auth.userAccounts[0])
        .then(response => {

          console.log("response: ", response)
          setListGroup(response);

          if(response && response.length > 0) {
            setGroupSelected({id: response[0].id, name: response[0].groupName})
          } 

          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTSGROUP }
          });
        })
        .catch(error => {
          console.error(error)
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTSGROUP }
          });
        })
    }
  }, []);

  const onChangeGroupSelected = (contact) => {
    setGroupSelected(contact);
  } 

  const onDeleteGroup = (groupId) => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.DELETEGROUP }
    });

    deleteGroup(groupId)
    .then(() => {
      let result = listGroup.filter(x => x.id !== groupId);
      setListGroup(result);
  
      setGroupSelected(null);

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETEGROUP }
      });

      notificationOk(t("list-groups.notification-delete-group-ok"));
    })
    .catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETEGROUP }
      });
      notificationError(t("list-groups.notification-delete-group-error"));
    })
  }

  const onUpdateConntactsRows = (groupId, count) => {
    let result = listGroup.map(item => 
    {
      return {...item, fileRows: item.id === groupId ? count : item.fileRows};
    });

    setListGroup(result);
  }

  return (
    <div className={styles['content']}>
      <div className={styles['content-card']}>
        <div className={styles['content-card-left']}>
          <ListGroups
            groups={listGroup}
            onChangeGroupSelected={onChangeGroupSelected}
            onDeleteGroup={onDeleteGroup}
          />
        </div>
      </div>
      <div className={styles['content-card']}>
        <GroupData
          group={groupSelected}
          onDelete={onDeleteGroup}
          onUpdateConntactsRows={onUpdateConntactsRows}
        />
      </div>
    </div>
  );
}
 
export default HistoryContacts;