import { Alert, Button, Card, Col, Form, Input, Row, Table, Tabs, Tag, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import MobilePreview from "../../../MobilePreview";
import ReactCountryFlag from "react-country-flag";
import { PlusCircleOutlined } from "@ant-design/icons";
import RcsApi from "../../../../../Api/RcsApi";
import { useEffect, useMemo, useState } from "react";
import useSpinner from "../../../../../Hooks/useSpinner";
import TYPES from "../../../../../reducers/types";
import CONSTANTS from "../../../../../utils/const";
import { cleanErrorMessage, currencyFormat, notificationError, notificationOk } from "../../../../../utils/helpers";
import dayjs from "dayjs";
import { getGradientColor, purpleColor } from "../../../../../utils/colorsCss";
import "./styles.css"

export const StepTwo = ({ agentId, allDetails }) => {
  const [testDevices, setTestDevices] = useState([])
  const [phoneNumber, setPhoneNumber] = useState("")
  const [isValid, setIsValid] = useState(false);
  const [basicMessage, setBasicMessage] = useState("");
  const [richMessage, setRichMessage] = useState({});
  const [activeKey, setActiveKey] = useState(allDetails?.billingCategory === "SINGLE_MESSAGE" ? "rich-cards" : "basic-messaging");
  const [sendDisabled, setSendDisabled] = useState(true)
  const [addTesterEnabled, setAddTesterEnabled] = useState(false)
  const [tooltipMessage, setTooltipMessage] = useState("")
  const [disabledSendTooltipMessage, setDisabledSendTooltipMessage] = useState(null)

  const [t] = useTranslation("rcs-channel");
  const [form] = Form.useForm();
  const { spinnerDispacth } = useSpinner();
  const { getAgentTestDevices, createAgentTestDevice, sendRCSTestMessage } = RcsApi();

  useEffect(() => {
    if (agentId) {
      fetchTestDevices()
    }
  }, [agentId]);

  useEffect(() => {
    let interval;
    const enableButtonAfterTimeout = (targetTimeString) => {
      const targetTime = new Date(targetTimeString);
      const threeMinutes = 3 * 60 * 1000;
      const newTargetTime = new Date(targetTime.getTime() + threeMinutes);

      interval = setInterval(() => {
        const now = new Date();
        const timeLeft = Math.floor((newTargetTime - now) / 1000);

        if (timeLeft > 0) {
          setTooltipMessage(`${t("step2.tooltip.agent-creation-processing")} ${timeLeft} ${t("step2.tooltip.agent-creation-processing-seconds")}`)
          setAddTesterEnabled(false)
        } else {
          clearInterval(interval);
          setAddTesterEnabled(true)
          setTooltipMessage("")
        }
      }, 1000);

      const timeUntilEnable = newTargetTime - new Date();
      if (timeUntilEnable <= 0) {
        setAddTesterEnabled(true)
        setTooltipMessage("")
      }
    };

    enableButtonAfterTimeout(allDetails?.lastModifiedDate);

    return () => clearInterval(interval);
  }, [allDetails?.lastModifiedDate]);

  useEffect(() => {
    if (
      activeKey === "rich-cards" &&
      richMessage?.text?.length > 0 &&
      richMessage?.title?.length > 0 &&
      richMessage?.imageBase64?.length > 0 &&
      richMessage?.imageExtension?.length > 0 &&
      richMessage?.url?.length > 0
    ) {
      setSendDisabled(false)
      setDisabledSendTooltipMessage(null)
    } else if (activeKey === "basic-messaging" && basicMessage?.length > 0) {
      setSendDisabled(false)
      setDisabledSendTooltipMessage(null)
    } else {
      setSendDisabled(true)
      if (!richMessage?.imageBase64 || !richMessage?.imageExtension) {
        setDisabledSendTooltipMessage(t("step2.rules.image"))
        return
      }
      if (!richMessage?.title) {
        setDisabledSendTooltipMessage(t("step2.rules.title"))
        return
      }
      if (!richMessage?.text) {
        setDisabledSendTooltipMessage(t("step2.rules.text"))
        return
      }
      if (!richMessage?.url?.startsWith("https://")) {
        setDisabledSendTooltipMessage(t("step2.rules.valid-url"))
        return
      }
    }
  }, [activeKey, JSON.stringify(richMessage), JSON.stringify(basicMessage)]);

  const getStatus = (status) => {
    switch (status) {
      case "ACCEPTED":
        return { tag: t("step2.tag.accepted"), tooltip: t("step2.tooltip.accepted") }
      case "PENDING":
        return { tag: t("step2.tag.pending"), tooltip: t("step2.tooltip.pending") }
      case "DECLINED":
        return { tag: t("declined"), tooltip: t("step2.tooltip.declined") }
      case "INVITE_STATUS_UNSPECIFIED":
        return { tag: t("step2.tag.unspecified"), tooltip: t("step2.tooltip.unspecified") }
      default:
        return { tag: t("step2.tag.unknown"), tooltip: t("step2.tooltip.unknown-status") }
    }
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "ACCEPTED":
        return "#006978"
      case "PENDING":
        return "#FF9F30"
      case "DECLINED":
        return "#cf1322"
      default:
        return "gray"
    }
  }

  const _columns = [
    {
      title: t("step2.table-header.test-destination"),
      dataIndex: 'phone',
      key: 'phone',
      render: (_, { phone, countryCode }) => {
        return (
          <div>
            <ReactCountryFlag
              countryCode={countryCode}
              svg
              style={{
                width: '2em',
                height: '2em',
                marginRight: '2px'
            }}
              title="US"
            />
            <span>{phone}</span>
          </div>
        )
      },
    },
    {
      title: t("step2.table-header.submitted"),
      dataIndex: 'lastModifiedDate',
      key: 'lastModifiedDate',
      render: (_, { lastModifiedDate }) => <span>{dayjs(lastModifiedDate).format('MM/DD/YYYY h:mm a')}</span>
    },
    {
      title: t("step2.table-header.status"),
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => 
        <Tooltip id="template-list-cannot-update-pending-tooltip" title={getStatus(status).tooltip} color={getGradientColor(purpleColor)} overlayInnerStyle={{padding: 20}}>
          <Tag color={getStatusColor(status)}>{getStatus(status).tag}</Tag>
        </Tooltip>
    },
    {
      title: t("step2.table-header.test-cost"),
      dataIndex: 'testCost',
      key: 'testCost',
      render: (_, { testCost }) => <span>{currencyFormat(testCost, 5)}</span>
    },
    {
      title: t("step2.table-header.actions"),
      key: 'action',
      render: (row) => {
        return sendDisabled ?
          <Tooltip id="template-list-cannot-update-pending-tooltip" title={disabledSendTooltipMessage || t("step2.tooltip.send-button-disabled")} color={getGradientColor(purpleColor)} overlayInnerStyle={{padding: 20}}>
            <Button disabled={sendDisabled} onClick={() => send(row.phone)}>{t("step2.actions.send")}</Button>
          </Tooltip> :
          <Button disabled={sendDisabled} onClick={() => send(row.phone)}>{t("step2.actions.send")}</Button>
      }
    },
  ]

  const columns = useMemo(() => _columns, [activeKey, JSON.stringify(richMessage), JSON.stringify(basicMessage), sendDisabled, disabledSendTooltipMessage]) 

  const validateOnlyNumbers = (_, value) => {
    const regex = /^[0-9]+$/;
    const condition = value && !regex.test(value)
    const _isValid = !condition && value.length >= 7
    if (condition) {
      setIsValid(_isValid)
      return Promise.reject(new Error(t("step2.rules.phone-number")));
    }
    setIsValid(_isValid)
    return Promise.resolve();
  };

  const fetchTestDevices = () => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.TESTER }
    });
    getAgentTestDevices(agentId)
      .then(res => {
        setTestDevices(res?.data || [])
      })
      .catch(e => {
        notificationError(cleanErrorMessage(e))
      })
      .finally(() => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.TESTER }
        });
      })
  }

  const handleCreateTestDevice = async () => {
    const payload = {
      phone: `+${phoneNumber}`,
      rcsBrandId: allDetails.rcsBrandId,
      rcsAgentId: allDetails.rcsAgentId,
      lastModifiedBy: allDetails.lastModifiedBy,
    }
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.DEFAULT }
    });
    createAgentTestDevice(payload)
      .then(() => {
        setPhoneNumber("")
        form.setFieldsValue({ phone: "" })
        setIsValid(false)
        notificationOk(t("step2.api-result.test-device-created-successfully"))
        fetchTestDevices()
      })
      .catch(e => {
        notificationError(cleanErrorMessage(e))
      })
      .finally(() => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
        });
      })
  }

  const send = (phone) => {
    if (activeKey === "rich-cards") {
      const payload = {
        companyId: allDetails.companyId,
        accountId: allDetails.accountId,
        rcsBrandId: allDetails.rcsBrandId,
        rcsAgentId: allDetails.rcsAgentId,
        lastModifiedBy: allDetails.lastModifiedBy,
        isTestMessage: true,
        richCard: {
          title: richMessage.title,
          imageBase64: richMessage.imageBase64,
          imageExtension: richMessage.imageExtension,
          buttons: [{
            type: "URL",
            text: "Visit web",
            url: richMessage.url
          }]
        },
        message: richMessage.text,
        to: phone,
      }

      if (!richMessage?.imageBase64 || !richMessage?.imageExtension) {
        notificationError(t("step2.rules.image"))
        return
      }
      if (!richMessage?.title) {
        notificationError(t("step2.rules.title"))
        return
      }
      if (!richMessage?.text) {
        notificationError(t("step2.rules.text"))
        return
      }
      if (!richMessage?.url?.startsWith("https://")) {
        notificationError(t("step2.rules.valid-url"))
        return
      }

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.TESTERMESSAGE }
      });
      sendRCSTestMessage(payload)
        .then(() => {
          fetchTestDevices()
          notificationOk(t("step2.api-result.test-message-sent-successfully"))
        })
        .catch(e => {
          notificationError(cleanErrorMessage(e))
        })
        .finally(() => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.TESTERMESSAGE }
          });
        })
    } else {
      const payload = {
        companyId: allDetails.companyId,
        accountId: allDetails.accountId,
        rcsBrandId: allDetails.rcsBrandId,
        rcsAgentId: allDetails.rcsAgentId,
        lastModifiedBy: allDetails.lastModifiedBy,
        isTestMessage: true,
        richCard: null,
        message: basicMessage,
        to: phone,
      }
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.TESTERMESSAGE }
      });
      sendRCSTestMessage(payload)
        .then(() => {
          fetchTestDevices()
          notificationOk(t("step2.api-result.test-message-sent-successfully"))
        })
        .catch(e => {
          notificationError(cleanErrorMessage(e))
        })
        .finally(() => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.TESTERMESSAGE }
          });
        })
    }
  }

  return (
    <Tabs defaultActiveKey="rich-cards" style={{ marginTop: "20px" }} activeKey={activeKey} onChange={key => setActiveKey(key)}>
      {allDetails?.billingCategory === "SINGLE_MESSAGE" ? <Tabs.TabPane tab={t("step2.tabs.rich-cards")} key="rich-cards">
        <Row style={{ gap: "10px" }}>
          <Col span={7}>
            <Card style={{ height: "100%" }}>
              <div style={{ display: "flex", justifyContent: "center", marginBottom: "12px" }}>
                <Alert className="interactive-mobile-info" type="info" description={t("step2.label.mobile")} style={{ width: "250px" }} />
              </div>
              <MobilePreview
                type="rich-card"
                previewValues={allDetails}
                setMessageData={(v) => setRichMessage(v)}
                messageData={richMessage}
              />
            </Card>
          </Col>
          <Col span={16}>
            <Card style={{ height: "100%" }}>
              <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                <div>
                  <h3>{t("step2.label.add-test-devices")}</h3>
                  <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                    <Form form={form}>
                      <Form.Item name="phone" rules={[{ validator: validateOnlyNumbers }]}>
                        <Input
                          placeholder={t("step2.placeholder.add-test-devices")}
                          style={{ maxWidth: "180px" }}
                          onChange={e => setPhoneNumber(e.target.value)}
                          value={phoneNumber}
                        />
                      </Form.Item>
                    </Form>
                    <PlusCircleOutlined
                      style={{ fontSize: '24px', marginTop: "5px", color: !isValid || !addTesterEnabled ? "lightgray" : "", pointerEvents: !isValid || !addTesterEnabled ? "none" : "" }}
                      onClick={handleCreateTestDevice}
                    />
                  </div>
                </div>
                <Alert
                  className="custom-info-alert small-font-size"
                  type="info"
                  description={
                    <ul>
                      {tooltipMessage ? <li><strong>{tooltipMessage}</strong></li> : null}
                      <li>{t("step2.alert.test-device-1")}</li>
                      <li>{t("step2.alert.test-device-2")}</li>
                    </ul>
                  }
                />
              </div>
              <Table rowKey="rcsTesterId" columns={columns} dataSource={testDevices} size="small" pagination={{ pageSize: 5 }} />
            </Card>
          </Col>
        </Row>
        </Tabs.TabPane> :
        <Tabs.TabPane tab={t("step2.tabs.basic-message")} key="basic-messaging">
          <Row style={{ gap: "10px" }}>
            <Col span={7}>
              <Card style={{ height: "100%" }}>
                <div style={{ display: "flex", justifyContent: "center", marginBottom: "12px" }}>
                  <Alert className="interactive-mobile-info" type="info" description={t("step2.label.mobile")} style={{ width: "250px",  }} />
                </div>
                <MobilePreview
                  type="basic-message"
                  previewValues={allDetails}
                  setMessageData={(v) => setBasicMessage(v)}
                />
              </Card>
            </Col>
            <Col span={16}>
              <Card style={{ height: "100%" }}>
                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                  <div>
                    <h3>{t("step2.label.add-test-devices")}</h3>
                    <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                      <Form form={form}>
                        <Form.Item name="phone" rules={[{ validator: validateOnlyNumbers }]}>
                          <Input
                            placeholder={t("step2.placeholder.add-test-devices")}
                            style={{ maxWidth: "180px" }}
                            onChange={e => setPhoneNumber(e.target.value)}
                            value={phoneNumber}
                          />
                        </Form.Item>
                      </Form>
                      <PlusCircleOutlined
                        style={{ fontSize: '24px', marginTop: "5px", color: !isValid || !addTesterEnabled ? "lightgray" : "", pointerEvents: !isValid || !addTesterEnabled ? "none" : "" }}
                        onClick={handleCreateTestDevice}
                      />
                    </div>
                  </div>
                  <Alert
                    className="custom-info-alert small-font-size"
                    type="info"
                    description={
                      <ul>
                        {tooltipMessage ? <li><strong>{tooltipMessage}</strong></li> : null}
                        <li>{t("step2.alert.test-device-1")}</li>
                        <li>{t("step2.alert.test-device-2")}</li>
                      </ul>
                    }
                  />
                </div>
                <Table rowKey="rcsTesterId" columns={columns} dataSource={testDevices} size="small" pagination={{ pageSize: 5 }} />
              </Card>
            </Col>
          </Row>
        </Tabs.TabPane>
      }
    </Tabs>
  )
}