import { useEffect, useState } from "react";
import SelectNetworkRatesGrid from "../SelectNetworkRatesGrid/SelectNetworkRatesGrid";
import useHeaderOptions from '../../../Hooks/useHeaderOptions'
import useRatesAccount from '../../../Hooks/useRatesAccount'
import TYPES from '../../../reducers/types'
import CONST from "../../../utils/const"
import CONSTANTS from "../../../utils/const";

const SelectNetworkRates = ({data, onRatesSelected}) => {

  const { headerOptionsDispacth, headerOptionsState } = useHeaderOptions();
  const { ratesAccountState } = useRatesAccount();

  const [rates, setRates] = useState([]);
  const [filterRates, setFilterRates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(CONSTANTS.PAGINATION);

  useEffect(() => {
    if(data.length > 0) {
      setRates(data);
      setFilterRates(data);

      headerOptionsDispacth({
        type: TYPES.HEADER_OPTIONS.SELECT_ADMIN_RATES_CHANGES_OPTION,
        payload: TYPES.HEADER_OPTIONS.SELECT_ADMIN_RATES_CHANGES_OPTION
      })

      let countries = [...new Set(data.map(x => x.country))];
      countries.unshift(CONST.DEFAULTVALUES.ALLCOUNTRIES);

      headerOptionsDispacth({
        type: TYPES.HEADER_OPTIONS.SELECT_ADMIN_RATES_CHANGES_MODEL,
        payload: { countries: countries, network: "", selectedCountry: "", searchNetwork: "" }
      })
    }
  }
  , [data]);

  useEffect(() => {
    if(headerOptionsState.model) {
      switch (headerOptionsState.option) {
        case TYPES.HEADER_OPTIONS.SELECT_ADMIN_RATES_CHANGES_OPTION:
          selectedRatesProcess();
          break;
      }
    }
  }, [headerOptionsState])

  useEffect(() => {
    if(ratesAccountState.step === CONST.RATES_PROCESS_STEP.SAVE_SELECT_RATES) {
      let selectedRows = rates.filter(x => x.isCheked);
      onRatesSelected(selectedRows);
    }
  }, [ratesAccountState])

  const index = (currentPage - 1) * itemsPerPage;
  const currentRates = filterRates.slice(index, index + itemsPerPage);

  const selectedRatesProcess = () => {
    let ratesList = rates;
    let selectedCountry = headerOptionsState.model.selectedCountry; 
    let searchNetwork = headerOptionsState.model.searchNetwork;

    if(selectedCountry && selectedCountry !== "") {
      if(selectedCountry === CONST.DEFAULTVALUES.ALLCOUNTRIES) {
        ratesList = rates;
      } else {
        ratesList = ratesList.filter(x => x.country === selectedCountry);
      }
    }

    if(searchNetwork && searchNetwork !== "") {   
      ratesList = ratesList.filter(x => x.network.toLowerCase().includes(searchNetwork.toLowerCase()));
    }

    setFilterRates(ratesList);
    setCurrentPage(1);
  }

  const setSelectedState = (updatedRateList) => {
    let hasSelectedRows = updatedRateList.filter(x => x.isCheked).length > 0

    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.SELECT_ADMIN_RATES_CHANGES_MODEL,
      payload: { hasSelectedRows: hasSelectedRows }
    });
  }

  const onChangePagination = (page) => {
    setCurrentPage(page);
  }

  const onChangeRatesSelect = (cheked, id) => {
    let updatedFilterList = filterRates.map(item => 
    {
      if (item.mccMnc === id){
        return {...item, isCheked: cheked};
      }
      return item;
    });

    setFilterRates(updatedFilterList);


    let updatedRateList = rates.map(item => 
    {
      if (item.mccMnc === id){
        return {...item, isCheked: cheked};
      }
      return item;
    });

    setSelectedState(updatedRateList);
    setRates(updatedRateList)
  }

  const onChangeSelectAll = (cheked) => {

    if(cheked) {
      let updatedFilterList = filterRates.map(item => 
      {
        return {...item, isCheked: true};
      });
  
      setFilterRates(updatedFilterList);
  
      let chekedList = updatedFilterList.map(x => {
        if(x.isCheked) {
          return x.mccMnc;
        }
      });
  
      let updatedRateList = rates.map(item => 
      {
        if(chekedList.includes(item.mccMnc)) {
          return {...item, isCheked: true};
        } else {
          return item;
        }
      });

      setSelectedState(updatedRateList);
      setRates(updatedRateList)
    } else {
      let chekedList = filterRates.map(x => {
        if(x.isCheked) {
          return x.mccMnc;
        }
      });

      let updatedFilterList = filterRates.map(item => 
      {
        if(chekedList.includes(item.mccMnc)) {
          return {...item, isCheked: false};
        } else {
          return item;
        }
      });

      setFilterRates(updatedFilterList);

      let updatedRateList = rates.map(item => 
      {
        if(chekedList.includes(item.mccMnc)) {
          return {...item, isCheked: false};
        } else {
          return item;
        }
      });

      setSelectedState(updatedRateList);
      setRates(updatedRateList);
    }
  }

  const getIsSelectedAll = () => {
    const ratesCheked =  filterRates.filter(x => x.isCheked === true);
    return ratesCheked.length === filterRates.length;
  }
  
  return (
    <SelectNetworkRatesGrid 
      rates={currentRates} 
      currentPage={currentPage} 
      itemsPerPage={itemsPerPage}
      totalPages={filterRates.length}
      isSelectAll={getIsSelectedAll()}
      onChangePagination={onChangePagination}
      onChangeRatesSelect={onChangeRatesSelect}
      onChangeSelectAll={onChangeSelectAll}
    />
  );
}
 
export default SelectNetworkRates;