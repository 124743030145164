import { useContext, useState } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./ChannelExpenses.module.css";
import { useEffect } from "react";
import { Spin } from "antd";
import { Line } from "@ant-design/charts";
import { getGradientColor, purpleColor, violetColor, magentaColor, orangeColor } from "../../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import { currencyFormat } from "../../../../utils/helpers";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST } from "../../../../utils/const";
import DataApi from "../../../../Api/DataApi";
import { AuthContext } from "../../../../contexts/authRedirect";

const ChannelExpenses = ({filter}) => {

  const [t] = useTranslation("home-account");
  const {getExpensesChannelDate} = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalVoice, setTotalVoice] = useState(0);
  const [totalSms, setTotalSms] = useState(0);
  const [totalShortlink, setTotalShortlink] = useState(0);

  useEffect(() => {

    if(filter && filter.id) {
      setLoading(true);

      getChannelExpensesData()
      .then(response => {
        setLoading(false);

        const {chartData, totalVoiceValue, totalSmsValue, totalShortlinkValue} = response;
        let chartDataFilter = chartData;

        if(filter.channel.key !== CHANELLIST.DEFAULT.key) {
          if (filter.channel.key === CHANELLIST.VOICE.key) {
            chartDataFilter = chartData.filter(x => x.type.toUpperCase() ===  filter.channel.value.toUpperCase());
          }
        
          if (filter.channel.key === CHANELLIST.SMS.key) {
            chartDataFilter = chartData.filter(x => x.type.toUpperCase() ===  filter.channel.value.toUpperCase() || x.type.toUpperCase() ===  CHANELLIST.SHORTLINK.value.toUpperCase());
          }
        }

        setData(chartDataFilter);
        setTotalVoice(totalVoiceValue);
        setTotalSms(totalSmsValue);
        setTotalShortlink(totalShortlinkValue);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      })
    }
    
  }, [filter])

  const getChannelExpensesData = async () => {

    let data = {
      companyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      accountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    }

    let response = await getExpensesChannelDate(filter.time, data);

    let responseData = [];
    response.items.forEach(x => {
      let time = x.label;
      x.itemsLabel.forEach(y => {
        responseData.push({type: y.mode, value: y.expenses, time: time})
      });
    });
    
    let totalVoiceValue = response.voiceExpenses;
    let totalSmsValue = response.smsExpenses;
    let totalShortlinkValue = response.shortlinkExpenses;
    return {chartData: responseData, totalVoiceValue, totalSmsValue, totalShortlinkValue}; 
  }

  const getColors = () => {
    switch (filter.channel.key) {
      case CHANELLIST.DEFAULT.key:
        return [magentaColor, orangeColor, violetColor];
      case CHANELLIST.SMS.key:
        return [magentaColor, orangeColor];
      case CHANELLIST.VOICE.key:
        return [violetColor];
      default:
        return [magentaColor, orangeColor, violetColor];
    }
  }

  const config = {
    data,
    xField: 'time',
    yField: 'value',    
    seriesField: 'type',
    color: getColors(),
    lineStyle: ({ type }) => {
      if (type === 'register') {
        return {
          lineDash: [4, 4],
          opacity: 1,
        };
      }

      return {
        opacity: 0.5,
      };
    },
  };

  const render = () => {
    switch (filter.channel.key) {
      case CHANELLIST.DEFAULT.key:
        return <>
          <div className={styles['dashboard-card-body-totals-value']}>
            <span>{t("card-channel-expenses.label-expenses-voice")}</span>
            <p>{currencyFormat(totalVoice)}</p>
          </div>
          <div className={styles['dashboard-card-body-totals-value']}>
            <span>{t("card-channel-expenses.label-expenses-sms")}</span>
            <p>{currencyFormat(totalSms)}</p>
          </div>
          <div className={styles['dashboard-card-body-totals-value']}>
            <span>{t("card-channel-expenses.label-expenses-shortlink")}</span>
            <p>{currencyFormat(totalShortlink)}</p>
          </div>
        </>;
      case CHANELLIST.SMS.key:
        return <>
          <div className={styles['dashboard-card-body-totals-value']}>
            <span>{t("card-channel-expenses.label-expenses-sms")}</span>
            <p>{currencyFormat(totalSms)}</p>
          </div>
          <div className={styles['dashboard-card-body-totals-value']}>
            <span>{t("card-channel-expenses.label-expenses-shortlink")}</span>
            <p>{currencyFormat(totalShortlink)}</p>
          </div>
        </>;
      case CHANELLIST.VOICE.key:
        return <>
          <div className={styles['dashboard-card-body-totals-value']}>
            <span>{t("card-channel-expenses.label-expenses-voice")}</span>
            <p>{currencyFormat(totalVoice)}</p>
          </div>
        </>;   
      default:
        return <></>;
    }
  }

  return (
    <div className={styles['dashboard-body-card-double']}>
      <CardCustom
        style={{height: "100%", widt:"100%"}}
        title={
        <div className={styles['card-title']}>
          <p>{t("card-channel-expenses.label-title")}</p>
          <HelperButton
            message={t("card-channel-expenses.tooltip-title")}
            color={getGradientColor(purpleColor)}
          />
        </div>
        }
      >
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              <div className={styles['dashboard-card-body-totals']}>
                { 
                  render()
                }
              </div>
              <Line height={300} {...config} />
            </div>            
          </Spin>
        </div>
      </CardCustom>
    </div>
  );
}
 
export default ChannelExpenses;