import React from 'react';
import { Row, Col } from 'antd';
import styles from "./CardFilter.module.css";

const CardFilter = ({title, filter, body, widthPercent, style}) => {
  return (
    <div data-testid="card-filter" className={`${styles['card-filter']}`} style={style}>
      <div className={styles['card-filter-head']}>
        <div className={styles['card-filter-head-wrapper']}>
          <div className={styles['card-filter-head-title']}>
            <Row gutter={[0, 8]}>
              <Col span={12}>{title}</Col>
              <Col span={12}>{filter}</Col>
            </Row>
          </div>
        </div>
      </div>
      <div className={`${styles['card-filter-body']}`}>
        <div className="yp-flex-column">
          <div className="yp-content" style={{width: `${widthPercent}%`}}>
            {body}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardFilter;