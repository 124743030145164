import useAuth from "../Hooks/useAuth";
import { shortlinkRequest, campaignRequest } from "../utils/authConfig";

const ShortlinkApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    generateShortlink: async (url) => {
      const accessToken = await passTokenToApi(shortlinkRequest);
      try{
        
        var raw = JSON.stringify({
          url: url
        });

        if(accessToken) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${accessToken}`);
            var requestOptions = {
                method: 'POST',
                body: raw,
                headers: myHeaders,
            };
            const resource = "/api/Shortlink/Preview";
            const url = `${process.env.REACT_APP_SHORTLINK_API_URL}${resource}`;
        
            let response = await fetch(url, requestOptions);
            if (response.status !== 200) {
              throw new Error("An error occurred generate shortlink.");
            }
            let shortlink = await response.text();
            return shortlink;
        }
        else {
            console.log('Authentication Error');
            return [];
        }
      }catch (error) {
          console.error(error);
          throw new Error("An error occurred generate shortlink.");
      }
    },
    getReport: async (companyId) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try{
        
        if(accessToken) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${accessToken}`);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
            };
            const resource = `/api/Shortlinks/Report/${companyId}`;
            const url = `${process.env.REACT_APP_CAMPAIGN_API_URL}${resource}`;
        
            let response = await fetch(url, requestOptions);
            if (response.status !== 200) {
              throw new Error("An error occurred getting report of shortlink.");
            }
            let report = await response.json();
            return report;
        }
        else {
            console.log('Authentication Error');
            return [];
        }
      }catch (error) {
          console.error(error);
          throw new Error("An error occurred getting report of shortlink.");
      }
    }
  }
}

export default ShortlinkApi;