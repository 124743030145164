import { Card, Typography } from "antd";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from '@ant-design/icons';
import CreateBrandDrawer from "./CreateBrandDrawer";
import useSpinner from '../../../Hooks/useSpinner';
import TYPES from '../../../reducers/types';
import { AuthContext } from "../../../contexts/authRedirect";
import CONSTANTS from "../../../utils/const";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import RcsApi from "../../../Api/RcsApi";
import { cleanErrorMessage, notificationError } from "../../../utils/helpers";
import AccountApi from "../../../Api/AccountApi";

const { Meta } = Card;
const { Title } = Typography;

const RCSChannel = () => {
  const { spinnerDispacth } = useSpinner();
  const [showCreateBrand, setShowCreateBrand] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(true)
  const [brands, setBrands] = useState(null)
  const [t] = useTranslation("rcs-channel");
  const { auth } = useContext(AuthContext);
  const { getBrands } = RcsApi();
  const { GetAccountsByCompanyId } = AccountApi();
  const history = useHistory()

  useEffect(() => {
    if(shouldRefetch) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.COMPANYACCOUNTS }
      });
      getInitialData()
        .then(() => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.COMPANYACCOUNTS }
          });
          setShouldRefetch(false)
        })
    }
  }, [shouldRefetch]);

  const addAccountNameToBrands = (accounts, brands) => {
    return brands.map(brand => {
        const matchingAccount = accounts.find(account => account.accountId === brand.accountId);
        if (matchingAccount) {
            brand.accountName = matchingAccount.name;
        }
        return brand;
    });
  }

  const getInitialData = async () => {
    try {
      const _brands = await getBrands(auth.companyId);
      const _accounts = await GetAccountsByCompanyId(auth.companyId, auth.accessToken)
      const updatedBrands = addAccountNameToBrands(_accounts, _brands?.data || []);
      setBrands(updatedBrands)
    }
    catch (error) {
      console.error(error);
      notificationError(cleanErrorMessage(error))
    }
  }

  return (
    <Fragment>
      <div className="yp-workspace">
        <Title level={4} style={{ textAlign: "center", color: "#5E475E", marginBottom: "20px" }}>
          {t("brands-list.title")}
        </Title>
        <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
          <Card
            id='create-new-brand'
            key='new'
            style={{ width: 300 }}
            hoverable
            onClick={() => setShowCreateBrand(true)}
            cover={
              <div>
                <div style={{ backgroundColor: "#a28aa2", height: "10px" }} />
                <div style={{ backgroundColor: "#5E475E", minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div style={{ backgroundColor: "#a28aa2", padding: "10px", borderRadius: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <PlusOutlined style={{ fontSize: '42px', color: "white" }} />
                  </div>
                </div>
              </div>
            }
          >
            <Meta title={<p style={{ textAlign: "center" }}>{t("brands-list.create-new-brand")}</p>} />
          </Card>
          {(brands || []).map(a => (
            <Card
              key={a.rcsBrandId}
              style={{ minWidth: "300px", width: "300px", padding: "0px" }}
              hoverable
              onClick={() => history.push({ pathname: `/Company/RCS/${a.rcsBrandId}`, state: { brandName: a.name } })}
              headStyle={{ background: "#a28aa2", minHeight: "30px", padding: "0px 10px", color: "white", fontSize: "14px", height: "30px", }}
              bodyStyle={{ padding: "10px", display: "flex", flexDirection: "column", height: "calc(100% - 30px)" }}
              title={
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", height: "10px", alignItems: "center" }}>
                </div>
              }
            >
              <h3 style={{ textAlign: "center" }}>{a.name}</h3>
              <div style={{ paddingTop: "16px" }}>
                <p style={{ textAlign: "center" }}>{a.description}</p>
              </div>
              <p style={{ marginTop: "auto" }}>{t("brands-list.yp-account")}: {a.accountName}</p>
            </Card>
          ))}
        </div>
      </div>
      {showCreateBrand && <CreateBrandDrawer
        visible={showCreateBrand}
        onClose={() => setShowCreateBrand(false)}
        setShouldRefetch={(v) => setShouldRefetch(v)}
      />}
    </Fragment>
  );
}
 
export default RCSChannel;