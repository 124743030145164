import React, { useEffect, useState } from 'react';
import CardFilter from "../../CardFilter/CardFilter";
import { Image, Card } from 'antd';
import { currencyFormat } from "../../../utils/helpers";
import logo from "../../../assets/resources/icono-balance-01.svg";
import style from "./CompanyBalance.module.css";
import {notificationWarning} from '../../../utils/helpers';
import { Bar } from '@ant-design/charts';
import propTypes from 'prop-types'
import { purpleColor, grayColor, orangeColorOpacity } from "../../../utils/colorsCss";
import Button from '../../Button/Button'
import { useTranslation } from 'react-i18next'

const CompanyBalance = ({companyBalance, accountsBalance}) => {

  const [t] = useTranslation("balance");
  const [charData, setCharDataState] = useState([]);

  useEffect(() => {

    if(accountsBalance.length > 0) {
      let data = [];
      accountsBalance.forEach(account => {

        data.push({
          company: account.accountName,
          value: account.availableBalance,
          type: 'Available'
        });

        data.push({
          company: account.accountName,
          value: account.onHoldBalance,
          type: 'On Hold'
        });
      });

      setCharDataState(data);
    }

  }, [accountsBalance]);

  // Función para truncar texto y agregar puntos suspensivos
  const truncateLabel = (label, maxLength) => {
    if (label.length > maxLength) {
      return label.substring(0, maxLength - 3) + '...';
    } else {
      return label;
    }
  };

  return (
    <CardFilter
      title={t("top-menu.title-top-menu-balance")}
      widthPercent={90}
      body={
        <Card>
          <div className={`yp-content ${style.content}`}>
            <div className="yp-aling-vertical-center">
              <Image
                preview={false}
                width={150}
                src={logo}
              />
              <p className="yp-title">{t("company-balance-card.title-card")}</p>
              <p className="yp-strong-value">{currencyFormat(companyBalance)} USD</p>
            </div>
            <div className="yp-aling-vertical-center yp-mt-2">
              <p className="yp-subtitle yp-mt-3 yp-mb-2">{t("company-balance-card.title-chart")}</p>
              <Bar
                data={charData.reverse()}
                isStack={true}
                yField='value'
                xField='company'
                seriesField='type'
                colorField='type'
                scale={{
                  color: {
                    range: [orangeColorOpacity, grayColor]
                  }
                }}
                height={300}     
                className="bar-graph"    
                yAxis= {{
                  label: {
                    autoHide: true,
                    style: {
                      fontSize: 10,
                    },
                    formatter: (text) => truncateLabel(text, 7) // Aplicar truncamiento
                  }                  
                }} 
                legend={{
                  layout: 'horizontal',
                  position: 'top', 
                  align: 'center',
                }}          
              />
            </div>
          </div>
        </Card>
      }
    />
  );
}

CompanyBalance.propTypes = {
  companyBalance: propTypes.number.isRequired,
  accountsBalance: propTypes.array.isRequired
}

export default CompanyBalance;