import { useContext, useState } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./TotalCampaigns.module.css";
import { useEffect } from "react";
import { Spin } from "antd";
import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import CONSTANTS, { CONSTANPERIODREPORTLIST, CONSTANTUSERTYPE } from "../../../../utils/const";
import { Link } from "react-router-dom";
import DataApi from "../../../../Api/DataApi";
import { AuthContext } from "../../../../contexts/authRedirect";

const TotalCampaigns = ({filter}) => {

  const [t] = useTranslation("home-company");
  const {getTotalCampaignsByUser} = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [period, setPiriod] = useState("");

  useEffect(() => {
    setLoading(true);

    getTotalCampaignsData()
    .then(response => {
      setLoading(false);

      const {totalValue} = response;
      setTotal(totalValue);

      if(filter && filter.time) {
        let resultPeriod = CONSTANTS.LIST.PERIODREPORT.find(x => x.key === filter.time);
        setPiriod(resultPeriod.value);
      }
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    })
    
  }, [filter])

  const getTotalCampaignsData = async () => {
    let data = {
      companyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      accountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    }
    
    let userType = filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? CONSTANTUSERTYPE.COMPANY.key : CONSTANTUSERTYPE.ACCOUNT.key;
    let responseData = await getTotalCampaignsByUser(userType, filter.time, data)
    
    let totalValue = responseData.totalCampaigns;
    return {totalValue: totalValue}; 
  }

  return (
    <div className={styles['dashboard-body-card-single']}>
      <CardCustom
        style={{height: "100%", widt:"100%"}}
        title={
        <div className={styles['card-title']}>
          <p>{t("card-total-campaigns.label-title")}</p>
          <HelperButton
            message={t("card-total-campaigns.tooltip-title")}
            color={getGradientColor(purpleColor)}
          />
        </div>
        }
      >
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              <p>{t("card-total-campaigns.label-subtitle")}</p>
              <h1>{total}</h1>
              <p className={styles['dashboard-card-body-period']}>{period}</p>
              <Link to="/Campaigns/Reports" className={styles['dashboard-card-body-report-button']}>{t("card-total-campaigns.report-button")}</Link>
              <Link to="/Company/Balance" className={styles['dashboard-card-body-balance-button']}>{t("card-total-campaigns.balance-button")}</Link>
            </div>            
          </Spin>
        </div>
      </CardCustom>
    </div>
  );
}
 
export default TotalCampaigns;