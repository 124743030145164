import { Card, Col, Radio, Row } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import PhasesSchedule from "./PhasesSchedule/PhasesSchedule";
import styles from "./Schedule.module.css";

const initialData = {
  scheduletype: "Now",
  Schedule: [{
    percentage: 100,
    DateScheduled: new Date()
  }]
};

const Schedule = ({changeSchedule}) => {

  const [t] = useTranslation("campaign-voz");

  const [ isSendNow, setIsSendNow ] = useState(true);
  const [ selectValue, setSelectValue ] = useState(1);

  useEffect(() => {
    if(changeSchedule) {
      changeSchedule(initialData);
    }
  }, [])

  const onChangeSelect = (e) => {
    resetScheduleData();
    setSelectValue(e.target.value);

    if(e.target.value === 1) {
      setIsSendNow(true);
      changeSchedule(initialData);
    } else {
      setIsSendNow(false);
    }
  }

  const onChangePhases = data => {

    let schedule = {
      scheduletype: "Phases",
      Schedule: data
    };

    if(changeSchedule) {
      changeSchedule(schedule);
    }
  }

  const resetScheduleData = ()=> {
    if(changeSchedule) {
      changeSchedule(null);
    }
  }

  return (
    <Fragment>
      <Row gutter={16} justify="center">
        <Col>
          <Card className={styles['option-card']}>
            <Radio.Group onChange={onChangeSelect} value={selectValue}>
              <Radio value={1}>{t("schedule.title-radio-button-send-now")}</Radio>
              <Radio value={2}>{t("schedule.title-radio-button-schedule")}</Radio>
            </Radio.Group>
          </Card>
        </Col>
      </Row>
      {
        !isSendNow && (
          <PhasesSchedule
            onChangePhases={onChangePhases}
          />
        )
      }
    </Fragment>
  );
}
 
export default Schedule;