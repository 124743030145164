import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Row, Col } from 'antd';
import CompanyBalance from "../../components/Company/CompanyBalance/CompanyBalance";
import AccountsBalance from "../../components/Company/AccountsBalance/AccountsBalance";
import BalanceApi from "../../Api/BalanceApi";
import accountApi from "../../Api/AccountApi";
import AssingBalance from "../../components/AssingBalance/AssingBalance";
import { notificationError, notificationOk } from '../../utils/helpers';
import { AuthContext } from "../../contexts/authRedirect";
import TYPES from '../../reducers/types';
import useHeaderOptions from '../../Hooks/useHeaderOptions';
import CONSTANTS from '../../utils/const';
import useSpinner from '../../Hooks/useSpinner';

const Balance = () => {

  // Declare states
  const [totalBalance, setTotalBalanceState] = useState(0.00);
  const [accountsBalanceData, setAccountBalanceState] = useState([]);
  const [showAssingBalance, setShowAssingBalanceState] = useState(false);
  const [accountId, setAccountIdState] = useState('');
  const [accounts, setAccounts] = useState([]);

  const { spinnerDispacth } = useSpinner();

  const { TransferBalance } = BalanceApi();

  const assingBalance = (show, accountId) => {
    setAccountIdState(accountId);
    setShowAssingBalanceState(show);
  }

  const { headerOptionsDispacth } = useHeaderOptions();

  const { auth } = useContext(AuthContext);
  // Update Initial Data
  useEffect(() => {

    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.DEFAULT_OPTION,
      payload: TYPES.HEADER_OPTIONS.DEFAULT_OPTION
    });

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.BALANCEPROCCESS }
    });

    getBalanceProccess(auth.companyId)
      .then(() => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.BALANCEPROCCESS }
        });
      })

  }, [])

  const { GetAccountsByCompanyId } = accountApi();
  const { GetAcocuntsBalanceByCompanyId } = BalanceApi();

  const getBalanceProccess = async (companyId) => {
    try {

      const companyBalance = await getCompanyBalance(companyId);
      const responseAccountsBalance = await getAccountsBalance(companyId);
      const responseAccount = await getAccountsByCompanyId(companyId, auth.accesToken);

      let availableBalance = companyBalance.reduce((accumulator, current) => accumulator + current.availableBalance, 0);
      setTotalBalanceState(availableBalance);
      setAccounts(responseAccount);
      let data = [];

      responseAccountsBalance.forEach(accountBalance => {
        let account = responseAccount.find(ac => ac.accountId === accountBalance.accountId);

        if (account) {
          data.push({
            id: accountBalance.accountId,
            accountName: account.name,
            lastAddedBalance: accountBalance.lastAddedBalanceDate,
            totalBalance: accountBalance.totalBalance,
            availableBalance: accountBalance.availableBalance,
            onHoldBalance: accountBalance.onHoldBalance,
            isDefaultAccount: account.isDefaultAccount,
            createdDate: new Date().toISOString() 
          });
        }
      });

      setAccountBalanceState(data);

    } catch (error) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.BALANCEPROCCESS }
      });
      notificationError(error);
    }
  }

  const getCompanyBalance = async (companyId) => {
    try {

      const responseCompanyBalance = await GetAcocuntsBalanceByCompanyId(companyId);
      return responseCompanyBalance;

    } catch (error) {
      console.error(error);
      throw new Error("An error occurred to get the company balance");
    }
  }

  const getAccountsByCompanyId = async (companyId) => {
    try {

      const responseAccount = await GetAccountsByCompanyId(companyId, auth.accountAccessToken);
      return responseAccount;

    } catch (error) {
      console.error(error);
      throw new Error("An error occurred to get the company balance");
    }
  }

  const getAccountsBalance = async (companyId) => {
    try {

      const responseAccountsBalance = await GetAcocuntsBalanceByCompanyId(companyId, auth.accesToken);
      return responseAccountsBalance;

    } catch (error) {
      console.error(error);
      throw new Error("An error occurred to get the accounts balance");
    }
  }

  const addBalance = (companyId, transferData) => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.ADDBALANCE }
    });
    transferProccess(companyId, transferData);
  }

  const transferProccess = async (companyId, transferData) => {

    try {
      await TransferBalance(companyId, transferData);
      notificationOk("The balance transfer among accounts was successful");
      assingBalance(false);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.ADDBALANCE }
      });
    } catch (error) {
      notificationError("An error occurred while transferring balance among accounts");
      console.error(error)
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.ADDBALANCE }
      });
    }

    try {
      await getBalanceProccess(companyId);
    } catch (error) {
      console.error(error);
      notificationError("An error occurred while get balance");
    }
    
  }

  return (
    <Fragment>
      <Row gutter={[0, 8]}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={9}
          xl={9}
        >
          <CompanyBalance
            companyBalance={totalBalance}
            accountsBalance={accountsBalanceData}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={15}
          xl={15}
        >
          <AccountsBalance
            showAssingBalance={assingBalance}
            accountsBalance={accountsBalanceData}
          />
        </Col>
      </Row>
      <AssingBalance
        accountId={accountId}
        accounts={accounts}
        accountsBalance={accountsBalanceData}
        visible={showAssingBalance}
        onClose={() => assingBalance(false)}
        onSave={addBalance}
      />
    </Fragment>
  );
}

export default Balance;