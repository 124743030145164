import { Steps, Tabs } from "antd";
import { Fragment, useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button/Button";
import SelectAccount from "../../../components/CampaignRcs/SelectAccount/SelectAccount";
import styles from "./BulkRCS.module.css";
import useHeaderOptions from '../../../Hooks/useHeaderOptions';
import TYPES from '../../../reducers/types'
import useSpinner from '../../../Hooks/useSpinner';
import accountApi from "../../../Api/AccountApi";
import useAuth from '../../../Hooks/useAuth';
import CONSTANTS from '../../../utils/const';
import campaignReducer, { initialCampaignState } from '../../../reducers/campaignWhatsappReducer';
import Schedule from "../../../components/CampaignRcs/Schedule/Schedule";
import { cleanErrorMessage, filterdAccountsByUser, notificationError, notificationOk } from "../../../utils/helpers";
import UploadFile from "../../../components/CampaignRcs/UploadFile/UploadFile";
import HeaderMap from "../../../components/CampaignRcs/HeaderMap/HeaderMap";
import ContentMessage from "../../../components/CampaignRcs/ContentMessage/ContentMessage";
import ContentRichMessage from "../../../components/CampaignRcs/ContentMessage/ContentRichMessage";
import TestMessage from "../../../components/CampaignRcs/TestMessage/TestMessage";
import CountrySummary from "../../../components/CampaignRcs/CountrySummary/CountrySummary";
import CampaignSummary from "../../../components/CampaignRcs/CampaignSummary/CampaignSummary";
import ModalConfirmation from "../../../components/ModalConfirmation/ModalConfirmation";
import { useHistory } from "react-router-dom";
import TemplateApi from "../../../Api/TemplatesApi";
import * as XLSX from 'xlsx';
import RcsApi from "../../../Api/RcsApi";

const { Step } = Steps;

const BulkWhatsApp = () => {

  const [t] = useTranslation("rcs-campaign");
  const [campaingState, campaingDispacth] = useReducer(campaignReducer, initialCampaignState);

  const [current, setCurrent] = useState(0);
  const [steps, setSteps] = useState([
    {
      title: t("steper.one")
    },
    {
      title: t("steper.two")
    },
    {
      title: t("steper.three")
    },
    {
      title: t("steper.four")
    }
  ]);
  const [accounts, setAccounts] = useState([]);
  const [message, setMessage] = useState(null);
  const [modalCampaignVisible, setModalCampaignVisible] = useState(false);
  const [modalCanelVisible, setModalCanelVisible] = useState(false);
  const [modalBillingVisible, setModalBillingVisible] = useState(false);
  const [unavailableBalance, setUnavailableBalance] = useState(false);
  const [agentData, setAgentData] = useState(null);
  const [rcsCampaignData, setRcsCampaignData] = useState(null);
  const [messageType, setMessageType] = useState(agentData?.billingCategory === "SINGLE_MESSAGE" ? "RICH_CARD" : "BASIC");

  const { GetAccountsByCompanyId } = accountApi();
  const { createCampaignWithFile, createCampaignWithContacts, sendCampaign, deleteCampaign } = RcsApi();

  const { getContactsByGorup } = TemplateApi();

  const { headerOptionsDispacth } = useHeaderOptions();
  const { spinnerDispacth } = useSpinner();
  const { auth } =  useAuth();
  const history = useHistory();  

  const getInitalData = async () => {
    let accountByCompany = await GetAccountsByCompanyId(auth.companyId, auth.accountAccessToken);
    let accountByUser =  filterdAccountsByUser(accountByCompany, auth.userAccounts, auth.role);

    return {accountByUser: accountByUser }
  }

  useEffect(() => {

    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.DEFAULT_OPTION,
      payload: TYPES.HEADER_OPTIONS.DEFAULT_OPTION
    });

      getInitalData()
      .then(response => {

        const { accountByUser } =  response;   
        setAccounts(accountByUser);

        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
        });
      }).catch(error => {
        console.error(error);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
        });
      });
  }, [t]);

  useEffect(() => {
    setMessageType(agentData?.billingCategory === "SINGLE_MESSAGE" ? "RICH_CARD" : "BASIC")
  }, [agentData]);

  const handleChangeAccount = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_ACCOUNT,
      payload: value
    })
  };

  const handleCampaignMobilePreviewData = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.CAMPAIGN_MOBILE_PREVIEW_DATA,
      payload: { ...campaingState.campaignMobilePreviewData, ...value}
    })
  };

  const handleCampaignDestination = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.DESTINATION,
      payload: value
    })
  };

  const renderLeftCard = () => {
    switch (current) {
      case 0:
        return <SelectAccount 
          accounts={accounts}
          onAccountChange={handleChangeAccount}
          account={campaingState?.account}
          setAgentData={setAgentData}
        />
      case 1:
        return  <UploadFile
        accountId={campaingState.account?.accountId}
        onUploadFile={handleUploadFile}
        onRemoveFile={handleRemoveFile}
        defaultFile={campaingState.file}
        defaultContac={campaingState?.contactSelected}
        onChengeContact={onChengeContact}
        messageData={campaingState?.messageData}
      />
      case 2:
        return messageType === "BASIC" ?
          <ContentMessage
            customFields={campaingState.headersMap.customFields}
            defaultMessage={campaingState.message}
            onChangeMessage={handleChangeMessage}
            onChangeWildCards={handleChangeWildCards}
          /> :
          <ContentRichMessage
            customFields={campaingState.headersMap.customFields}
            defaultTitle={rcsCampaignData?.title || ""}
            defaultMessage={campaingState.message}
            onChangeMessage={handleChangeMessage}
            onChangeWildCards={handleChangeWildCards}
            rcsCampaignData={rcsCampaignData}
            setRcsCampaignData={setRcsCampaignData}
          />
      case 3:
        return <CountrySummary summary={campaingState.summary} />
    }    
  }

  const renderRightCard = () => {
    switch (current) {
      case 0:
        return <Schedule 
          changeSchedule={changeSchedule} />
      case 1:
        return <>
        <HeaderMap
          headers={campaingState.fileHeaders}
          fileData={campaingState.fileData}
          loadContacts={campaingState.loadContacts}
          defaultHeaderSelected={campaingState.headersMap.selectedHeaders ? campaingState.headersMap.selectedHeaders : null}
          defaultChecked={campaingState.headersMap.customFields}
          onChangeHeaders={handleChangeHeaders}
          onChangeMobileNumberField={handleMobileNumberField}
          onChangeCustomFields={handleChangeCustomFields}
          defaultPhoneFieldName={campaingState.headersMap.mobileNumberColumn}
          defaultWildcardSelected={campaingState.headersMap.customFields.length > 0 ? campaingState.headersMap.customFields.map(x => { return {label: x, value: x} }) : []}
          handleChangeWildCards={handleChangeWildCards}
          template={campaingState?.template}
        />
        </>
      case 2:
        return <TestMessage
        message={message}
        accountId={campaingState.account.accountId}
        agentId={campaingState.account.agentId}
        senderId={campaingState.senderId}
        customFields={campaingState.headersMap.customFields}
        fileHeaders={campaingState.fileHeaders}
        fileData={campaingState.fileData}
        agentData={agentData}
        messageType={messageType}
        rcsCampaignData={rcsCampaignData}
        setRcsCampaignData={setRcsCampaignData}
      />
      case 3:
        return <CampaignSummary
          accountId={campaingState.account.accountId}
          campaignName={campaingState.account?.campaignName}
          category={agentData?.billingCategory}
          totalMessage={campaingState.fileRows}
          summary={campaingState.summary}
          schedule={campaingState.scheduleData.schedule}
          onBalance={setUnavailableBalance}
        />
    }
  }

  const nextStep = () => {

    switch (current) {
      case 0:
        stepOne();
        break;
      case 1:
        stepTwo();
        break;
      case 2:
        stepThree();
        break;
      default:
        break;
    }
  };

  const prevStep = () => {
    switch (current) {
      case 1:
        handleRemoveFile();
        setCurrent(current - 1);
        setRcsCampaignData({ ...rcsCampaignData, imageBase64: null, [`previewimageBase64`]: null, imageExtension: null })
        break;
      case 2:
        handleChangeSender("");
        handleChangeMessage({
          message: "",
          html: ""
        });
        handleCampaignMobilePreviewData({ bodyExample: [] })
        let customFields = [...campaingState.headersMap.customFields];
       
        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS,
          payload: customFields
        });

        setCurrent(current - 1);
        break;
      case 3:
        handleCampaignDestination(null)
        handleChangeMessage({
          message: campaingState.message.message,
          html: ""
        });
        setRcsCampaignData({ ...rcsCampaignData, imageBase64: null, [`previewimageBase64`]: null, imageExtension: null })

        setCurrent(current - 1);
        break;
      default:
        break;
    }
  }

  const stepOne = () => {
    if (!campaingState.account?.accountId) {
      notificationError(t("account.required-yp-account-field"));
      return;
    }

    if (!campaingState.account?.brandId) {
      notificationError(t("account.required-brand-field"));
      return;
    }

    if (!campaingState.account?.agentId) {
      notificationError(t("account.required-agent-field"));
      return;
    }

    if (!campaingState.account?.campaignName) {
      notificationError(t("account.required-campaign-name-field"));
      return;
    }

    if (!campaingState.scheduleData) {
      notificationError(t("schedule.notification-schedule-configure"));
      return;
    }

    let percentage = 0;
    let hasNullSchedules = false;

    if (campaingState.scheduleData.scheduleType === "PHASES") {
      
      campaingState.scheduleData.schedule.forEach(x => {
        percentage += x.Percentage;
        if (!hasNullSchedules) {
          hasNullSchedules = x.DateScheduled === null;
        }
      });

      if (percentage < 100 || percentage > 100) {
        notificationError(t("schedule.notification-schedule-phases-percentage"));
        return;
      }

      if (hasNullSchedules) {
        notificationError(t("schedule.notification-schedule-phases-date"));
        return;
      }
    }

    changeStep();
  };

  const stepTwo = () => {
    if (!campaingState.loadContacts && !campaingState.file) {
      notificationError(t("upload-file.alert-mandatory-csv"));
      return;
    }

    if (!campaingState.headersMap.mobileNumberColumn) {
      notificationError(t("map-header.alert-mobile-number"));
      return;
    }

    changeStep();
  };

  const stepThree = () => {
    if (messageType === "BASIC") {
      if (message === null || message?.length === 0) {
        notificationError(t("content-message.notification-mandatory-message"));
        return
      }
    } else {
      if (rcsCampaignData === null) {
        notificationError(t("content-message.notification-mandatory-campaign-data"));
        return
      }
      if (!rcsCampaignData?.title || rcsCampaignData?.title?.length === 0) {
        notificationError(t("content-message.notification-mandatory-title"));
        return
      }
      if (message === null || message?.length === 0) {
        notificationError(t("content-message.notification-mandatory-message"));
        return
      }
      if (!rcsCampaignData?.imageBase64 || rcsCampaignData?.imageBase64?.length === 0) {
        notificationError(t("content-message.notification-mandatory-image"));
        return
      }
      if (!rcsCampaignData?.imageExtension || rcsCampaignData?.imageExtension?.length === 0) {
        notificationError(t("content-message.notification-mandatory-image-ext"));
        return
      }
      if (!rcsCampaignData?.buttons || rcsCampaignData?.buttons?.length === 0) {
        notificationError(t("content-message.notification-mandatory-buttons"));
        return
      }
    }
    
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.SUMMARY }
    });

    if(campaingState.loadContacts) {
      addDataFileByContacts();
    } else {
      createCampaignFile();
    }
  }

  const createCampaignFile = () => {

    const formData = new FormData();
    formData.append('campaignFile', campaingState.file);

    const wildCardOccurrences = message.match(/@#/g);

    const json = JSON.stringify({
      ...campaingState.headersMap, 
      message: message
    });
    const blob = new Blob([json], {
      type: 'application/json',
    });

    formData.append("campaignHeader", blob, "CampaignHeader.json");

    const base64 = rcsCampaignData?.imageBase64?.split(',')?.[1] || ""
    const rcsPayload = messageType === "BASIC"
      ? {
        rcsBrandId: agentData.rcsBrandId,
        rcsAgentId: agentData.rcsAgentId,
        title: null,
        imageBase64: null,
        imageExtension: null,
        buttons: null,
        richCard: null
      }
      : {
        ...rcsCampaignData,
        rcsBrandId: agentData.rcsBrandId,
        rcsAgentId: agentData.rcsAgentId,
        imageBase64: base64,
        buttons: rcsCampaignData?.buttons?.action?.data || [],
      }

    let campaign = {
      ...rcsPayload,
      messageType,
      CampaignName: campaingState.account?.campaignName,
      CompanyId: auth.companyId,
      AccountId: campaingState.account.accountId,
      UserId: auth.userName,
      WabaId: null,
      WabaPhoneId: null,
      WabaTemplateId: null,
      WabaTemplateCategory: null,
      Channel: "Rcs",
      CampaignSchedule: campaingState.scheduleData,
      hasBodyParameters: (wildCardOccurrences || []).length >= 2
    }
    
    const blobCampaign = new Blob([JSON.stringify(campaign)], {
      type: 'application/json'
    });

    formData.append("campaignInfo", blobCampaign, "CampaignInfo.json");

    createCampaignWithFile(formData)
      .then(res => {
        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_SUMMARY,
          payload: res.data
        })

        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_FILEROWS,
          payload: res.fileRows
        })

        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_CAMPAIGNID,
          payload: res.campaignId
        })

        changeStep()
      })
      .catch(error => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.SUMMARY }
        });
        notificationError(cleanErrorMessage(error) || t("summary.notification-error-upload-file"));
        console.error(error);
      });
  }

  const addDataFileByContacts = () => {
    const formData = new FormData();

    const json = JSON.stringify({
      ...campaingState.headersMap, 
      message: message
    });

    const blob = new Blob([json], {
      type: 'application/json'
    });

    formData.append("campaignHeader", blob, "CampaignHeader.json");

    const wildCardOccurrences = message.match(/@#/g);

    let campaign = {
      campaignName: campaingState.account.campaignName,
      companyId: auth.companyId,
      accountId: campaingState.account.accountId,
      userId: auth.userName,
      wabaId: campaingState.account.wabaId,
      wabaPhoneId: campaingState?.messageData?.wabaPhoneId,
      wabaTemplateId: campaingState.template?.wabaTemplateId,
      channel: "Rcs",
      wabaTemplateCategory: campaingState?.template?.category,
      contactGroupId: campaingState.contactSelected,
      campaignSchedule: campaingState.scheduleData,
      hasBodyParameters: (wildCardOccurrences || []).length >= 2
    }

    const blobCampaign = new Blob([JSON.stringify(campaign)], {
      type: 'application/json'
    });

    formData.append("campaignInfo", blobCampaign, "CampaignInfo.json");

    createCampaignWithContacts(formData, auth.accessToken).then(res => {

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_SUMMARY,
        payload: res.data
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_FILEROWS,
        payload: res.fileRows
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_CAMPAIGNID,
        payload: res.campaignId
      })

      changeStep()
    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.SUMMARY }
      });
      notificationError(cleanErrorMessage(error) || t("summary.notification-error-upload-file"));
      console.error(error);
    });
  }

  const changeStep = () => {
    let nextStepValue = current + 1;
    setCurrent(nextStepValue);
  }

  const changeSchedule = (schedule) => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_SCHEDULE,
      payload: schedule
    })
  }

  const handleRemoveFile = () => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_FILE,
      payload: null
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_FILEHEADERS,
      payload: []
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN,
      payload: null
    })
    
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS,
      payload: []
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_SELECTEDHEADERS,
      payload: []
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CONTACT,
      payload: null
    })
  };

  const handleUploadFile = (file, fileType) => {

    handleRemoveFile();

    if (file) {

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN,
        payload: ""
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_CONTACT,
        payload: null
      })
  
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_FILE,
        payload: file
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.LOAD_CONTACTS,
        payload: false
      })

      switch (fileType) {
        case CONSTANTS.FILETYPES.CSV.NAME:
          processCsvFile(file);
          break;
        case CONSTANTS.FILETYPES.EXCEL.NAME:
          processExcelFile(file);
          break;      
        default:
          break;
      }
    }
  };

  const processCsvFile = (file) => {
    const reader = new FileReader();
    reader.onload = onLoadCsvFileInMemory;
    reader.readAsText(file);
  }

  const processExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = onLoadExcelFileInMemory;
    reader.readAsBinaryString(file);
  }

  const onLoadCsvFileInMemory = event => {
    var csv = event.target.result;
    fileProcess(csv);
  }

  const onLoadExcelFileInMemory = event => {
    const bstr = event.target.result;
    const wb = XLSX.read(bstr, {type:'binary'});
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const csv = XLSX.utils.sheet_to_csv(ws, {header:1});
    fileProcess(csv);
  }

  const fileProcess = csv => {
    var rows = csv.split('\n');

    if (rows.length < 2) {
      notificationError(t("upload-file.alert-file-rows-validation"));
      handleRemoveFile();
      return;
    }

    let separator = rows[0].includes(',') ? ',' : ';'

    var cols = rows[0].split(separator);
    let headers = [];

    if(cols.length > 7) {
      notificationError(t("upload-file.alert-file-columns-validation"));
      handleRemoveFile();
      return;
    }

    for (let j = 0; j < cols.length; j++) {
      headers.push({
        name: cols[j].replace(/(\r\n|\n|\r)/gm, "").trim(),
        column: j
      });
    }

    let data = [];
    let count = rows.length > 4 ? 4 : rows.length

    for (let i = 1; i < count; i++) {

      cols = rows[i].split(separator);
      let row = [];

      if (cols.length !== headers.length) {
        notificationError(t("upload-file.alert-file-rows-columns-validation"));
        handleRemoveFile();
        return;
      }

      for (let j = 0; j < cols.length; j++) {
        row.push({
          value: cols[j].replace(/(\r\n|\n|\r)/gm, ""),
          column: j
        });
      }

      data.push({
        value: row,
        row: i
      });

    }

    campaingDispacth({
      type: TYPES.CAMPAIGN.FILE_ROWS_COUNT,
      payload: rows.length - 1
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_FILEDATA,
      payload: data
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_FILEHEADERS,
      payload: headers
    })
  }

  const handleChangeHeaders = (headerData) => {
    let headersMap = [...campaingState.headersMap.selectedHeaders];
    if(headersMap.length == 0) {
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_SELECTEDHEADERS,
        payload: headerData
      })
    } else {
      let phoneHeader = headersMap.find(x => x.name == campaingState.headersMap.mobileNumberColumn);
      headerData.push(phoneHeader);
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_SELECTEDHEADERS,
        payload: headerData
      })
    }
  }

  const handleMobileNumberField = (value) => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN,
      payload: value
    })
  };

  const handleChangeCustomFields = (value) => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS,
      payload: value
    })
  };

  const handleChangeMessage = value => {
    
    if(value.message) {
      setMessage(value.message);
    } else 
    {
      setMessage(" ");
    }

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_MESSAGE,
      payload: value
    })
  };

  const handleChangeSender = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_SENDERID,
      payload: value
    })
  }

  const handleChangeWildCards = (wildcardsList) => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_WILDCARDS,
      payload: wildcardsList
    })
  }

  const handleCancelConfirmCampaign = () => {
    setModalCampaignVisible(false);
  }

  const handleConfirmCampaign = () => {
    if(unavailableBalance === false) {
      
      setModalCampaignVisible(false);
    
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.SENDCAMPAIGN }
      });

      let campaign = {
        campaignId: campaingState.summary?.campaignId,
        campaignName: campaingState.account?.campaignName,
        accountId: campaingState.account.accountId,
        companyId: auth.companyId,
        userId: auth.userName,
        rcsBrandId: agentData.rcsBrandId,
        rcsAgentId: agentData.rcsAgentId,
        message: message,
        campaignSchedule: campaingState.scheduleData
      }

      sendCampaign(campaign, auth.accessToken)
        .then(() => {
          
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.SENDCAMPAIGN }
          });

          notificationOk(t("summary.notification-ok-campaign"));
          history.push("Credits");
        }).catch(error => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.SENDCAMPAIGN }
          });
          notificationError(t("summary.notification-error-campaign"));
          console.error(error);
        });

    } else {
      if(auth.role === CONSTANTS.ROLES.COMPANY_ADMIN) {
        setModalBillingVisible(true);
      } else {
        notificationError("Please contact your Administrator to buy credits");
        setTimeout(() => {
          history.go(0);
        }, 2000);
      }
    }
  }

  const handleCancelCampaign = () => {
    setModalCanelVisible(false);
  }

  const handleOkCancelCampaign = () => {

    if(campaingState.summary?.campaignId) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.DELETECAMPAIGN }
      });
      deleteCampaign(campaingState.summary?.campaignId)
        .then(response => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETECAMPAIGN }
          });
          notificationOk(t("campaign-deleted-successfully"));
          history.replace("/");
        })
        .catch(error => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETECAMPAIGN }
          });
          notificationError("An error occurred while deleting campaign");
          console.error(error);
          setModalCanelVisible(false);
        });
    } else {
      history.replace("/");
    }
  }

  const handleCancelBilling = () => {
    setModalBillingVisible(false);
    history.go(0);
  }

  const handleOkBilling = () => {
    setModalBillingVisible(false);
    history.push("Credits");
  }

  const convertJsonToWildcards = (data, fields) => {
    const fieldMap = {};
    fields.forEach(field => {
      fieldMap[field.column] = field.name;
    });
  
    const targetFields = fields
      .filter(field => field.name !== "Mobile Number")
      .map(field => field.name);
  
    const convertedData = {
      fields: targetFields,
      values: []
    };
  
    const firstRow = data[0].value;
    
    targetFields.forEach(fieldName => {
      const column = Object.keys(fieldMap).find(col => fieldMap[col] === fieldName);
      const cell = firstRow.find(cell => cell.column.toString() === column);
      if (cell) {
        convertedData.values.push(cell.value);
      }
    });
  
    return convertedData;
  }

  const onChengeContact = (groupId) => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.CONTACTLIST }
    });

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CONTACT,
      payload: groupId
    })

    getContactsByGorup(groupId)
    .then(response => {

      const mobileNumberName = "Mobile Number";

      let contacts = response;
      let firstRow = contacts[0];

      let fields = Object.keys(firstRow);
      var arrayContainsMobileNumber = (fields.indexOf("mobileNumber") > -1);

      if(arrayContainsMobileNumber) {

        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN,
          payload: mobileNumberName
        })

        let fileHeader = firstRow.wildCards.map((x, index) => { return {
          column: index + 1,
          name: x.key
        }});

        let customfield = fileHeader.map(x => { return x.name });

        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS,
          payload: customfield
        })

        fileHeader.unshift({column: 0, name: mobileNumberName});

        let count = contacts.length > 3 ? 3 : contacts.length;
        let fileData = [];

        for (let index = 0; index < count; index++) {
          const row = contacts[index];
          let data = { row: index, value: [] }

          let value = row.wildCards.map((x, index) => { return {
            column: index + 1,
            value: x.value
          }});

          value.unshift({column: 0, value: row.mobileNumber});
          data.value = value;
          fileData.push(data);          
        }

        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_WILDCARDS,
          payload: convertJsonToWildcards(fileData, fileHeader)
        })
        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_FILEDATA,
          payload: fileData
        })
        
        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_SELECTEDHEADERS,
          payload: fileHeader
        })
    
        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_FILEHEADERS,
          payload: fileHeader
        })

        campaingDispacth({
          type: TYPES.CAMPAIGN.LOAD_CONTACTS,
          payload: true
        })

      } else {
        console.error("The object doesn`t have mobileNumber property");
      }

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTLIST }
      });

    })
    .catch(error => {
      console.error("error", error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTLIST }
      });
    })
  }

  return (
    <Fragment>
      <div className="yp-workspace">
        <div className={styles['step-header']}>
          <Steps labelPlacement="horizontal" current={current} direction="horizontal">
            {steps.map(item => (
              <Step key={item.title} title={item.title}/>
            ))}
          </Steps>
        </div>
        {current === 2 ? (
          <Tabs
            defaultActiveKey="RICH_CARD"
            style={{ marginTop: "20px" }}
            activeKey={messageType}
            onChange={key => setMessageType(key)}
            className={styles['content-message-tabs']}
          >
            {agentData?.billingCategory === "SINGLE_MESSAGE" ? <Tabs.TabPane tab={t("tabs.rich-cards")} key="RICH_CARD">
              <div className={styles['step-content']}>
                <div className={styles['step-content-card']}>
                  {renderLeftCard()}
                </div>
                <div className={styles['step-content-card']}>
                  {renderRightCard()}
                </div>
              </div>    
            </Tabs.TabPane> :
            <Tabs.TabPane tab={t("tabs.basic-message")} key="BASIC">
              <div className={styles['step-content']}>
                <div className={styles['step-content-card']}>
                  {renderLeftCard()}
                </div>
                <div className={styles['step-content-card']}>
                  {renderRightCard()}
                </div>
              </div>    
            </Tabs.TabPane>
            }
          </Tabs>
        ) : (
          <div className={(current === 1) ? styles['step-content-2'] : styles['step-content']}>
            <div className={(current === 1) ? styles['step-content-card-2'] : styles['step-content-card']}>
              {
                renderLeftCard()
              }
            </div>
            <div className={(current === 1) ? styles['step-content-card-2'] : styles['step-content-card']}>
              {
                renderRightCard()
              }
            </div>
          </div>
        )}
      </div>
      <div className={styles['step-buttons-container']}>
        <div className={styles['step-buttons']}>
          {current < steps.length && (
            <Button style={{ width: 150, marginRight: '10px' }} type="secondary" onClick={() => setModalCanelVisible(true)}>
              {t("actions-buttons.cancel")}
            </Button>
          )}
          {current > 0 && current < steps.length  && (
            <Button style={{ width: 150, marginRight: '10px' }} type="optional" onClick={() => prevStep()}>
              {t("actions-buttons.previous")}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button style={{ width: 150 }} type="primary" onClick={() => setModalCampaignVisible(true)}>
              {t("actions-buttons.done")}
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button style={{ width: 150 }} type="primary" onClick={() => nextStep()}>
              {t("actions-buttons.next")}
            </Button>
          )}
        </div>
      </div>
      <ModalConfirmation
        key={1}
        visible={modalBillingVisible}
        title={t("modal-campaign-credits.title")}
        onCancel={handleCancelBilling}
        onOk={handleOkBilling}
        okBtnText={t("modal-campaign-credits.btn-ok")}
      >
        <p>{t("modal-campaign-credits.content")}</p>
      </ModalConfirmation>
      <ModalConfirmation
        key={2}
        visible={modalCanelVisible}
        title={t("modal-cancel-campaign.title")}
        onCancel={handleCancelCampaign}
        onOk={handleOkCancelCampaign}
      >
        <p>{t("modal-cancel-campaign.content")}</p>
      </ModalConfirmation>
      <ModalConfirmation
        key={3}
        visible={modalCampaignVisible}
        title={t("modal-generate-campaign.title")}
        onCancel={handleCancelConfirmCampaign}
        onOk={handleConfirmCampaign}
        okBtnText={t("modal-generate-campaign.btn-ok")}
      >
        <p style={{ textAlign: "center" }}>{t("modal-generate-campaign.content")}</p>
        <br />
      </ModalConfirmation>
    </Fragment>
  );
}
 
export default BulkWhatsApp;