import { Tabs } from "antd";
import styles from "./SmsApiKeysDocumentation.module.css";
import { orangeColor, purpleColor } from "../../utils/colorsCss";
import { CopyBlock } from 'react-code-blocks';
import copy from 'copy-to-clipboard';
import { useTranslation } from "react-i18next";

const items = {
  javascript: `
    var myHeaders = new Headers();
    myHeaders.append("XApiKey", "{{YP_API_KEY_VALUE}}");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "from": "{{YP_SENDER_ID_VALUE}}",
      "body": "{{YP_BODY_VALUE}}",
      "to": "{{YP_COUNTRY_CODE_VALUE}} + {{YP_PHONE_VALUE}}"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("${process.env.REACT_APP_API_KEY_API_URL}/api/Sms", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  `,
  node: `
    const axios = require('axios');
    let data = JSON.stringify({
      "from": "{{YP_SENDER_ID_VALUE}}",
      "body": "{{YP_BODY_VALUE}}",
      "to": "{{YP_COUNTRY_CODE_VALUE}} + {{YP_PHONE_VALUE}}"
    });

    let config = {
      method: 'post',
      url: '${process.env.REACT_APP_API_KEY_API_URL}/api/Sms',
      headers: { 
        'XApiKey': '{{YP_API_KEY_VALUE}}', 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
  `,
  python: `
    import requests

    url = "${process.env.REACT_APP_API_KEY_API_URL}/api/Sms"

    body = {
      "from": "{{YP_SENDER_ID_VALUE}}",
      "body": "{{YP_BODY_VALUE}}",
      "to": "{{YP_COUNTRY_CODE_VALUE}} + {{YP_PHONE_VALUE}}"
    }

    headers = {
      'XApiKey': '{{YP_API_KEY_VALUE}}',
      'Content-Type': 'application/json',
    }

    response = requests.post(url, headers=headers, json=body)

    print(response.text)
  `,
  csharp: `
    var options = new RestClientOptions("${process.env.REACT_APP_API_KEY_API_URL}") { MaxTimeout = -1 };
    var client = new RestClient(options);
    var request = new RestRequest("/api/Sms", Method.Post);
    request.AddHeader("XApiKey", "{{YP_API_KEY_VALUE}}");
    request.AddHeader("Content-Type", "application/json");

    var body = @"{
    @"  ""from"": ""{{YP_SENDER_ID_VALUE}}"",
    @"  ""body"": ""{{YP_BODY_VALUE}}"",
    @"  ""to"": ""{{YP_COUNTRY_CODE_VALUE}} + {{YP_PHONE_VALUE}}""
    @"}";

    request.AddStringBody(body, DataFormat.Json);
    RestResponse response = await client.ExecuteAsync(request);
    Console.WriteLine(response.Content);
  `,
  java: `
  Unirest.setTimeouts(0, 0);
  HttpResponse<String> response = Unirest.post("${process.env.REACT_APP_API_KEY_API_URL}/api/Sms")
    .header("XApiKey", "{{YP_API_KEY_VALUE}}")
    .header("Content-Type", "application/json")
    .body("{\r\n  \"from\": \"{{YP_SENDER_ID_VALUE}}\",\r\n  \"body\": \"{{YP_BODY_VALUE}}\",\r\n  \"to\": \"{{YP_COUNTRY_CODE_VALUE}} + {{YP_PHONE_VALUE}}\"\r\n}")
    .asString();
  `
}

const myCustomTheme = {
    lineNumberColor: "#ccc",
    lineNumberBgColor: "#222",
    backgroundColor: purpleColor,
    textColor: "#ccc",
    substringColor: "#00ff00",
    keywordColor: "#0077ff",
    attributeColor: "#ffaa00",
    selectorTagColor: "#0077ff",
    docTagColor: "#aa00ff",
    nameColor: "#f8f8f8",
    builtInColor: "#0077ff",
    literalColor: "#ffaa00",
    bulletColor: "#ffaa00",
    codeColor: "#ccc",
    additionColor: "#00ff00",
    regexpColor: "#f8f8f8",
    symbolColor: "#ffaa00",
    variableColor: "#ffaa00",
    templateVariableColor: "#ffaa00",
    linkColor: "#aa00ff",
    selectorAttributeColor: "#ffaa00",
    selectorPseudoColor: "#aa00ff",
    typeColor: "#0077ff",
    stringColor: "#00ff00",
    selectorIdColor: "#ffaa00",
    quoteColor: "#f8f8f8",
    templateTagColor: "#ccc",
    deletionColor: "#ff0000",
    titleColor: "#0077ff",
    sectionColor: "#0077ff",
    commentColor: "#777",
    metaKeywordColor: "#f8f8f8",
    metaColor: "#aa00ff",
    functionColor: orangeColor,
    numberColor: "#ffaa00",
  };

const getText = (language) => {
  let text = items[language];
  text = text.replaceAll("[url]", process.env.REACT_APP_API_KEY_API_URL);
  return text;
} 

const SmsApiKeysDocumentation = () => {
  const { t } = useTranslation("sms-api-key");
  return (
    <div className={styles['container']}>
      <div className={styles['container-header']}>
        <h2 className={styles['container-header-title']}>{t("developer.title")}</h2>
        <p>{t("developer.description")}</p>
      </div>
      <div className={styles['container-body']}>
        <Tabs>
          <Tabs.TabPane tab="Java Script" key="item-1">
            <CopyBlock
              text={getText("javascript")}
              language='javascript'
              showLineNumbers={true}
              theme={myCustomTheme}
              codeBlock
              onCopy={() => copy(getText("javascript"))}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Node JS" key="item-2">
            <CopyBlock
              text={getText("node")}
              language='javascript'
              showLineNumbers={true}
              theme={myCustomTheme}
              codeBlock
              onCopy={() => copy(getText("node"))}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Python" key="item-3">
            <CopyBlock
              text={getText("python")}
              language='python'
              showLineNumbers={true}
              theme={myCustomTheme}
              codeBlock
              onCopy={() => copy(getText("python"))}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="C#" key="item-4">
            <CopyBlock
              text={getText("csharp")}
              language="java"
              showLineNumbers={true}
              theme={myCustomTheme}
              codeBlock
              onCopy={() => copy(getText("csharp"))}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Java" key="item-5">
            <CopyBlock
              text={getText("java")}
              language='java'
              showLineNumbers={true}
              theme={myCustomTheme}
              codeBlock
              onCopy={() => copy(getText("java"))}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
      
    </div>
  );
}
 
export default SmsApiKeysDocumentation;