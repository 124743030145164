import TYPES from './types'
import CONST from "../utils/const"

const initialRatesAccountState = {
  step: CONST.RATES_PROCESS_STEP.SELECT_RATES,
}

const ratesAccountReducer = (state, action) => {
  switch (action.type) {
    case TYPES.RATES_PROCESS_STEP.SELECT_STEP:
    case TYPES.RATES_PROCESS_STEP.ADD_STEP:
    case TYPES.RATES_PROCESS_STEP.CONFIRM_STEP:
    case TYPES.RATES_PROCESS_STEP.DONE_STEP:
    case TYPES.RATES_PROCESS_STEP.RELOAD_STEP:
      return {
        ...state,
        step: action.payload
      }
  }
}

export { initialRatesAccountState }
export default ratesAccountReducer;