import useAuth from "../Hooks/useAuth";
import { companyRequest } from "../utils/authConfig";

const CompanyApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    SetCompany: async (company) => {
      const accessToken = await passTokenToApi(companyRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
    
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(company),
            redirect: 'follow'
          };
    
          const resource = "/api/Company";
          const url = `${process.env.REACT_APP_COMPANY_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
          let result = await response.json();
    
          if (response.status !== 200) {
            let errorMessage = "";
            if (result.message && !result.listErrors) {
              errorMessage = result.message;
            } else if (result.message && result.listErrors) {
              errorMessage = result.listErrors.join(', ');
            }
            throw new Error(errorMessage);
          }
    
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error.message);
      }
    },
    GetCompanies: async () => {
      const accessToken = await passTokenToApi(companyRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Company`;
          const url = `${process.env.REACT_APP_COMPANY_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the companies. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the companies.");
      }
    },
    GetCompanyById: async (companyId) => {
      const accessToken = await passTokenToApi(companyRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Company/${companyId}`;
          const url = `${process.env.REACT_APP_COMPANY_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the company. Status Code: ${response.status}`);
          }
    
          let company = await response.json();
          return company;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the company.");
      }
    },
    SetLinkMO: async (companyId, link) => {
      const accessToken = await passTokenToApi(companyRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
    
          var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({LinkMO: link}),
            redirect: 'follow'
          };
    
          const resource = `/api/Company/${companyId}/Link`;
          const url = `${process.env.REACT_APP_COMPANY_API_URL}${resource}`;
    
          let response = await fetch(url, requestOptions);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred adding the company. Status Code: ${response.status}`);
          }
    
          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred adding the company.");
      }
    },
    
    updateAvailableCompany: async (companyId, enabled) => {
      try {
        // Obtener el token de acceso
        let accessToken = await passTokenToApi(companyRequest);
        
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
    
          // Configurar las opciones para la solicitud PUT
          const requestOptions = {
            method: 'PUT',
            headers: {
              Authorization: bearer,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({    
               Enabled: enabled,
            }),
          };
    
          // Construir la URL del recurso
          const resource = `api/Company/DisableCompany/${companyId}`;
          const url = `${process.env.REACT_APP_COMPANY_API_URL}/${resource}`;
    
          // Realizar la solicitud PUT al servidor
          const response = await fetch(url, requestOptions);
    
          // Verificar el estado de la respuesta
          if (response.status !== 200) {
            let message=await response.text()            
            throw new Error(`An error occurred updating the company. Status Code: ${response.status}`);
          }
    
          // Retornar la respuesta o cualquier otro dato necesario
          return await response.json(); // O el formato de respuesta adecuado
        } else {
          console.error("User Error: No access token available.");
          return []; // O algún valor por defecto adecuado
        }
      } catch (error) {
        console.error('Error updating company:', error);
        throw new Error('An error occurred updating the company.');
      }
    },
    
  }
}

export default CompanyApi;
