import styles from "./TableBodyTrCustom.module.css";

const TableBodyTrCustom = ({children, isSelected}) => {
  return (
    <tr className={isSelected ? styles['selected'] : styles['unselected']}>
      {children}
    </tr>
  );
}
 
export default TableBodyTrCustom;