import { Checkbox, Pagination } from "antd";
import { useEffect, useState, Fragment } from "react";
import TableBodyCustom from "../../TableCustom/TableBodyCustom/TableBodyCustom";
import TableBodyTrCustom from "../../TableCustom/TableBodyTrCustom/TableBodyTrCustom";
import TableCustom from "../../TableCustom/TableCustom";
import TableHeadCustom from "../../TableCustom/TableHeadCustom/TableHeadCustom";
import styles from "./AddRatesGrid.module.css";
import { dateFormat, currencyFormat } from "../../../utils/helpers";
import TableBodyTdCheck from "../../TableCustom/TableBodyTdCheck/TableBodyTdCheck";

const AddRatesGrid = ({rates, currentPage, itemsPerPage, totalPages, isSelectAll, onChangePagination, onChangeRatesSelect, onChangeSelectAll}) => {
  
  const [ratesFiltered, setRatesFiltered] = useState([])

  useEffect(() => {
    if(rates) {
      setRatesFiltered(rates);
    }
  }, [rates])

  const getAllChekedName = () => {
    const name = isSelectAll ? "Unselect All" : "Select All"
    return name;
  }

  const onChangeSelect = (value, id) => {
    let updatedList = ratesFiltered.map(item => 
    {
      if (item.mccMnc === id){
        return {...item, isCheked: value};
      }
      return item;
    });
    setRatesFiltered(updatedList);
    onChangeRatesSelect(value, id);
  }
  
  return (
    <div>
      <div className={styles['pagination-container']}>
        <Pagination 
          defaultCurrent={currentPage}
          current={currentPage}
          onChange={(pageNumber) => onChangePagination(pageNumber)}
          total={totalPages}
          pageSize={itemsPerPage}
          showSizeChanger={false}
        />
      </div>
      <div className={styles['table-container']}>
        <TableCustom>
          <TableHeadCustom>
            <th><Checkbox checked={isSelectAll} onChange={(e) => onChangeSelectAll(e.target.checked)}>{getAllChekedName()}</Checkbox></th>
            <th>Country</th>
            <th>Network</th>
            <th>MCCMNC</th>
            <th>Current Rate</th>
            <th>New Rate</th>
            <th>Efective Rate</th>            
          </TableHeadCustom>
          <TableBodyCustom>
            {
              ratesFiltered.map(rate => (
                <TableBodyTrCustom isSelected={rate.isCheked} key={rate.mccMnc}>
                  <td>
                    <TableBodyTdCheck
                      id={rate.mccMnc}
                      onChangeCheck={onChangeSelect}
                      isCheked={rate.isCheked}
                    />
                  </td>
                  <td>{rate.country}</td>
                  <td>{rate.network}</td>
                  <td>{rate.mccMnc}</td>
                  <td>{currencyFormat(rate.rate, 5)}</td>
                  <td>{currencyFormat(rate.newRate, 5)}</td>
                  <td>{dateFormat(rate.effectiveDate)}</td>
                </TableBodyTrCustom>
              ))
            }
          </TableBodyCustom>
        </TableCustom>
      </div>
    </div>
  );
}
 
export default AddRatesGrid;