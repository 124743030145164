import { useEffect, useState } from "react";
import CompanyApi from "../../Api/CompanyApi";
import useAuth from "../../Hooks/useAuth";
import useSpinner from "../../Hooks/useSpinner";
import styles from "./WebHookUrlMO.module.css";
import CONSTANTS from "../../utils/const";
import TYPES from "../../reducers/types";
import { Input } from "antd";
import Button from "../../components/Button/Button";
import { isUrlValid, maskGeoIdWithAsterisks, notificationError } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "../../components/CopyToClipboard/CopyToClipboard";

const WebHookUrlMO = () => {

  const [t] = useTranslation("webhook-url-mo");

  const { GetCompanyById, SetLinkMO } = CompanyApi();
  const { auth } = useAuth();
  const { spinnerDispacth } = useSpinner();

  const [campany, setCompany] = useState(null)
  const [link, setLink] = useState("")

  useEffect(() => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.WEBHOOKURLMO }
    });

    GetCompanyById(auth.companyId)
    .then(response => {
      setCompany(response);

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.WEBHOOKURLMO }
      });
    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.WEBHOOKURLMO }
      });
      console.error(error);
    })
  }, [])

  const onSaveLink = () => {

    let validUrl = isUrlValid(link);

    if (!validUrl) {
      notificationError(t("alert-url"));
      return;
    }

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.WEBHOOKURLMO }
    });

    SetLinkMO(auth.companyId, link)
    .then(response => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.WEBHOOKURLMO }
      });
      setCompany(response);
      setLink("")
    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.WEBHOOKURLMO }
      });
      console.error(error);
    })
  }

  return ( 
    <div className="yp-workspace">
      <div className={styles['webhook-container']}> 
        <div className={styles['webhook-container-company']}>
          <p><span style={{fontWeight: "bold"}}>{t("label-companyId")}:</span> { maskGeoIdWithAsterisks(auth.companyId) } </p><CopyToClipboard textToCopy={auth.companyId} />
        </div>        
        <p className="text-shortlink"><span style={{fontWeight: "bold"}}>{t("label-link")}:</span> { campany && ( campany.linkMO ) } </p>
        <Input
          name="linkMO"
          style={{ width: 320 }}
          placeholder={t("input-text-placeholder")}
          onChange={(e) => { setLink(e.target.value) }}
          value={link}
        />
        <Button style={{ width: 320, margin: 0, marginTop: 10 }} type="primary" onClick={onSaveLink}>
          {t("button-name")}
        </Button>
      </div>
    </div>
  );
}
 
export default WebHookUrlMO;