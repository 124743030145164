import React from 'react';
import styles from './Spinner.module.css';
import logo from '../../assets/resources/Gif Loader FN.gif';

const Spinner = ({show}) => {

  if(!show) return null;

  return (
    <div id='spinner_background' className={`${styles['spin-background']}`}>
      <div className={`${styles['center']}`}>
        <img src={logo} alt="spiner" width="150" />
        <p>This may take a while</p>
      </div>
    </div>
  );
}

export default Spinner;