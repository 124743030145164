import { Steps } from "antd";
import { Fragment, useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button/Button";
import SelectAccount from "../../../components/CampaignWhatsapp/SelectAccount/SelectAccount";
import styles from "./BulkWhatsApp.module.css";
import useHeaderOptions from '../../../Hooks/useHeaderOptions';
import TYPES from '../../../reducers/types'
import useSpinner from '../../../Hooks/useSpinner';
import accountApi from "../../../Api/AccountApi";
import useAuth from '../../../Hooks/useAuth';
import CONSTANTS from '../../../utils/const';
import campaignReducer, { initialCampaignState } from '../../../reducers/campaignWhatsappReducer';
import Schedule from "../../../components/CampaignWhatsapp/Schedule/Schedule";
import { cleanErrorMessage, filterdAccountsByUser, notificationError, notificationOk } from "../../../utils/helpers";
import UploadFile from "../../../components/CampaignWhatsapp/UploadFile/UploadFile";
import HeaderMap from "../../../components/CampaignWhatsapp/HeaderMap/HeaderMap";
import TestMessage from "../../../components/CampaignWhatsapp/TestMessage/TestMessage";
import WhatsappApi from "../../../Api/WhatsappApi";
import CountrySummary from "../../../components/CampaignWhatsapp/CountrySummary/CountrySummary";
import CampaignSummary from "../../../components/CampaignWhatsapp/CampaignSummary/CampaignSummary";
import ModalConfirmation from "../../../components/ModalConfirmation/ModalConfirmation";
import { useHistory } from "react-router-dom";
import TemplateApi from "../../../Api/TemplatesApi";
import * as XLSX from 'xlsx';
import ApprovedTemplates from "../../../components/CampaignWhatsapp/ApprovedTemplates/ApprovedTemplates";
import ContentForm from "../../../components/CampaignWhatsapp/ContentForm/ContentForm";

const { Step } = Steps;

const BulkWhatsApp = () => {

  const [t] = useTranslation("whatsapp-campaign");
  const [campaingState, campaingDispacth] = useReducer(campaignReducer, initialCampaignState);

  const [current, setCurrent] = useState(0);
  const [steps, setSteps] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [wabas, setWabas] = useState([]);
  const [validationsStepOneAccount, setValidationsStepOneAccount] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalCampaignVisible, setModalCampaignVisible] = useState(false);
  const [modalCanelVisible, setModalCanelVisible] = useState(false);
  const [modalBillingVisible, setModalBillingVisible] = useState(false);
  const [unavailableBalance, setUnavailableBalance] = useState(false);
  const [canVerifyCost, setCanVerifyCost] = useState(false);

  const { GetAccountsByCompanyId } = accountApi();
  const { getAccounts, getCountSentMessagesByWabaPhoneId, createCampaignWithFile, createCampaignWithContacts, sendCampaign, deleteCampaign } = WhatsappApi();

  const { getContactsByGorup, addTemplates } = TemplateApi();

  const { headerOptionsDispacth } = useHeaderOptions();
  const { spinnerDispacth } = useSpinner();
  const { auth } =  useAuth();
  const history = useHistory();  

  const getInitalData = async () => {
    let accountByCompany = await GetAccountsByCompanyId(auth.companyId, auth.accountAccessToken);
    let accountByUser =  filterdAccountsByUser(accountByCompany, auth.userAccounts, auth.role);

    return {accountByUser: accountByUser }
  }

  useEffect(() => {

    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.DEFAULT_OPTION,
      payload: TYPES.HEADER_OPTIONS.DEFAULT_OPTION
    });

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.WABA }
    });
    
    getAccounts(auth.companyId)
      .then(response => {
        setWabas(response.data);

        setSteps([
          {
            title: t("steper.one")
          },
          {
            title: t("steper.two")
          },
          {
            title: t("steper.three")
          },
          {
            title: t("steper.four")
          }
        ]);

        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.WABA }
        });
      }).catch(error => {
        console.error(error);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.WABA }
        });
      });

      getInitalData()
      .then(response => {

        const { accountByUser } =  response;   
        setAccounts(accountByUser);

        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
        });
      }).catch(error => {
        console.error(error);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
        });
      });
  }, [t]);

  const handleChangeYPAccount = value => {
    const ac = accounts.find(x => x.accountId === value);
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_ACCOUNT_YP,
      payload: ac
    })
  };

  const handleChangeAccount = value => {
    const ac = wabas.find(x => x.wabaId === value);
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_ACCOUNT,
      payload: ac
    })
  };

  const handleBodyExampleQty = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.SELECT_TEMPLATE_BODYEXAMPLE,
      payload: value
    })
  };

  const handleBodyExampleFields = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.BODY_EXAMPLE_FIELDS,
      payload: value
    })
  };

  const handleCampaignNameChange = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CAMPAINGNAME,
      payload: value
    })
  };

  const handleCampaignMobilePreviewData = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.CAMPAIGN_MOBILE_PREVIEW_DATA,
      payload: { ...campaingState.campaignMobilePreviewData, ...value}
    })
  };

  const handleCampaignTemplateChange = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_TEMPLATES,
      payload: value
    })
  };

  const handleCampaignMessageData = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.MESSAGE_DATA,
      payload: {...campaingState.messageData, ...value}
    })
  };

  const handleCampaignBodyExample = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.BODY_EXAMPLE,
      payload: value
    })
  };

  const handleCampaignSelectedDynamicFields = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.SELECTED_DYNAMIC_FIELDS,
      payload: value
    })
  };

  const handleCampaignDestination = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.DESTINATION,
      payload: value
    })
  };

  const handleChangeUrl = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_URL,
      payload: value
    });
  }

  const renderLeftCard = () => {
    switch (current) {
      case 0:
        return <SelectAccount 
          accounts={accounts}
          onAccountChange={handleChangeAccount}
          onYPAccountChange={handleChangeYPAccount}
          onCampaignNameChange={handleCampaignNameChange}
          handleMessageData={handleCampaignMessageData}
          showValidations={validationsStepOneAccount}
          defaultSelected={campaingState.account?.wabaId}
          defaultSelectedYP={campaingState.accountYP?.accountId}
          defaultCampaignName={campaingState.campaignName}
        />
      case 1:
        return <ApprovedTemplates
          wabaId={campaingState.account.wabaId}
          onTemplateChange={handleCampaignTemplateChange}
          handleMessageData={handleCampaignMessageData}
          handleCampaignMobilePreviewData={handleCampaignMobilePreviewData}
          messageData={campaingState?.messageData}
          handleBodyExampleQty={handleBodyExampleQty}
        />
      case 2:
        return <ContentForm
          campaignName={campaingState.campaignName}
          template={campaingState.template}
          wildcards={campaingState.wildcards}
          campaignMobilePreviewData={campaingState.campaignMobilePreviewData}
          handleCampaignMobilePreviewData={handleCampaignMobilePreviewData}
          handleCampaignBodyExample={handleCampaignBodyExample}
          campaignBodyExample={campaingState?.bodyExample}
          setCanVerifyCost={setCanVerifyCost}
          handleBodyExampleFields={handleBodyExampleFields}
          selectedDynamicFields={campaingState?.selectedDynamicFields}
          handleCampaignSelectedDynamicFields={handleCampaignSelectedDynamicFields}
        />
      case 3:
        return <CountrySummary summary={campaingState.summary} />
    }    
  }

  const renderRightCard = () => {
    switch (current) {
      case 0:
        return <Schedule 
          changeSchedule={changeSchedule} />
      case 1:
        return <>
        <UploadFile
          accountId={campaingState.accountYP.accountId}
          onUploadFile={handleUploadFile}
          onRemoveFile={handleRemoveFile}
          defaultFile={campaingState.file}
          defaultContac={campaingState?.contactSelected}
          onChengeContact={onChengeContact}
          messageData={campaingState?.messageData}
        />
        <HeaderMap
          headers={campaingState.fileHeaders}
          fileData={campaingState.fileData}
          loadContacts={campaingState.loadContacts}
          defaultHeaderSelected={campaingState.headersMap.selectedHeaders ? campaingState.headersMap.selectedHeaders : null}
          defaultChecked={campaingState.headersMap.customFields}
          onChangeHeaders={handleChangeHeaders}
          onChangeMobileNumberField={handleMobileNumberField}
          onChangeCustomFields={handleChangeCustomFields}
          defaultPhoneFieldName={campaingState.headersMap.mobileNumberColumn}
          defaultWildcardSelected={campaingState.headersMap.customFields.length > 0 ? campaingState.headersMap.customFields.map(x => { return {label: x, value: x} }) : []}
          handleChangeWildCards={handleChangeWildCards}
          template={campaingState?.template}
        />
        </>
      case 2:
        return <TestMessage
          template={campaingState?.template}
          message={message}
          lang={campaingState.template ? campaingState.template.lan : null}
          templateName={campaingState.template ? campaingState.template.templateName: null}
          accountId={campaingState.accountYP.accountId}
          customFields={campaingState.headersMap.customFields}
          fileHeaders={campaingState.fileHeaders}
          fileData={campaingState.fileData}
          defualtShortLink={campaingState.shortlink}   
          messageData={campaingState.messageData}
          campaignMobilePreviewData={campaingState.campaignMobilePreviewData}
          wabaId={campaingState.account?.wabaId}
          setCanVerifyCost={setCanVerifyCost}
          canVerifyCost={canVerifyCost}
          destination={campaingState?.destination}
          setDestination={handleCampaignDestination}
          category={campaingState?.template?.category}
          wabaPhoneId={campaingState?.messageData?.wabaPhoneId}
        />
      case 3:
        return <CampaignSummary
          accountId={campaingState.accountYP.accountId}
          campaignName={campaingState.campaignName}
          category={campaingState?.template?.category}
          totalMessage={campaingState.fileRows}
          summary={campaingState.summary}
          schedule={campaingState.scheduleData.schedule}
          onBalance={setUnavailableBalance}
        />
    }
  }

  const nextStep = () => {

    switch (current) {
      case 0:
        stepOne();
        break;
      case 1:
        handleCampaignDestination(null)
        stepTwo();
        break;
      case 2:
        stepThree();
        break;
      default:
        console.log('deafult step case')
        break;
    }
  };

  const prevStep = () => {
    switch (current) {
      case 1:
        handleRemoveFile();
        handleCampaignMessageData({ ...campaingState?.messageData, wabaTemplateId: null })
        handleCampaignSelectedDynamicFields(null)
        setCurrent(current - 1);
        break;
      case 2:
        handleChangeSender("");
        handleChangeMessage({
          message: "",
          html: ""
        });
        handleCampaignMobilePreviewData({ bodyExample: [] })
        let customFields = [...campaingState.headersMap.customFields];
       
        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS,
          payload: customFields
        });

        setCurrent(current - 1);
        break;
      case 3:
        handleCampaignDestination(null)
        handleChangeMessage({
          message: campaingState.message.message,
          html: ""
        });

        setCurrent(current - 1);
        break;
      default:
        console.log('deafult step case')
        break;
    }
  }

  const stepOne = () => {
    if (!campaingState.accountYP) {
      notificationError(t("account.required-yp-account-field"));
      return;
    }

    if (!campaingState.account || !campaingState?.messageData?.wabaPhoneId) {
      notificationError(t("account.required-waba-account-field"));
      return;
    }

    if (!campaingState.campaignName) {
      notificationError(t("account.required-campaign-name-field"));
      return;
    }

    if (!campaingState.scheduleData) {
      notificationError(t("schedule.notification-schedule-configure"));
      return;
    }

    getCountSentMessagesByWabaPhoneId(campaingState?.messageData?.wabaPhoneId)
      .then((res) => {
        handleCampaignMessageData({ sentMessages: res?.data })
      })
      .catch(error => {
        notificationError(cleanErrorMessage(error) || t("account.get-count-error"));
        console.error(error);
      });

    let percentage = 0;
    let hasNullSchedules = false;

    if (campaingState.scheduleData.scheduleType === "PHASES") {
      
      campaingState.scheduleData.schedule.forEach(x => {
        percentage += x.Percentage;
        if (!hasNullSchedules) {
          hasNullSchedules = x.DateScheduled === null;
        }
      });

      if (percentage < 100 || percentage > 100) {
        notificationError(t("schedule.notification-schedule-phases-percentage"));
        return;
      }

      if (hasNullSchedules) {
        notificationError(t("schedule.notification-schedule-phases-date"));
        return;
      }
    }

    changeStep();
  };

  const stepTwo = () => {
    handleCampaignBodyExample(null)
    handleBodyExampleFields(null)
    handleCampaignSelectedDynamicFields(null)
    const { wildcards, bodyExampleQty, entriesInFile, messageData } = campaingState

    if (entriesInFile > messageData?.limit ) {
      notificationError(t("not-enough-remaining-messages-for-today"));
      return;
    }

    if (!wildcards?.fields) {
      if (wildcards?.length === 0 && bodyExampleQty > 0) {
        notificationError(t("must-select-wildcards-for-selected-template"));
        return;
      }
    } else if ((wildcards?.fields.length === wildcards?.values.length) && wildcards?.fields.length !== bodyExampleQty) {
      notificationError(t("same-number-of-body-example"));
      return;
    }

    if (!campaingState.template) {
      notificationError(t("template-required"));
      return;
    }

    if (!campaingState.loadContacts && !campaingState.file) {
      notificationError(t("upload-file.alert-mandatory-csv"));
      return;
    }

    if (!campaingState.headersMap.mobileNumberColumn) {
      notificationError(t("map-header.alert-mobile-number"));
      return;
    }

    changeStep();
  };

  const stepThree = () => {
    const { bodyExample, bodyExampleQty } = campaingState

    if (Object.values(bodyExample || {})?.length !== bodyExampleQty) {
      notificationError(t("all-fields-must-be-assigned"));
      return;
    }

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.SUMMARY }
    });

    if(campaingState.loadContacts) {
      addDataFileByContacts();
    } else {
      createCampaignFile();
    }
  }

  const replacePlaceholders = (bodyExampleFields, bodyText) => {
    if (!bodyText) return;

    let _message = bodyText;

    for (const [key, value] of Object.entries(bodyExampleFields || {})) {
      const replacement = `@#${value}@#`;  
      _message = _message.replaceAll(key, replacement);
    }

    return _message;
  };

  const createCampaignFile = () => {

    const formData = new FormData();
    formData.append('campaignFile', campaingState.file);
    const { bodyExampleFields, campaignMobilePreviewData } = campaingState

    const message = replacePlaceholders(bodyExampleFields, campaignMobilePreviewData.bodyText)

    const json = JSON.stringify({
      ...campaingState.headersMap, 
      message: message
    });
    const blob = new Blob([json], {
      type: 'application/json',
    });

    formData.append("campaignHeader", blob, "CampaignHeader.json");

    let campaign = {
      campaignName: campaingState.campaignName,
      companyId: auth.companyId,
      accountId: campaingState.accountYP.accountId,
      userId: auth.userName,
      wabaId: campaingState.account.wabaId,
      wabaPhoneId: campaingState?.messageData?.wabaPhoneId,
      wabaTemplateId: campaingState.template?.wabaTemplateId,
      channel: "Whatsapp",
      wabaTemplateCategory: campaingState?.template?.category,
      campaignSchedule: campaingState.scheduleData,
      hasBodyParameters: Object.entries(bodyExampleFields || {}).length > 0
    }
    
    const blobCampaign = new Blob([JSON.stringify(campaign)], {
      type: 'application/json'
    });

    formData.append("campaignInfo", blobCampaign, "CampaignInfo.json");

    createCampaignWithFile(formData).then(res => {
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_SUMMARY,
        payload: res.data
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_FILEROWS,
        payload: res.fileRows
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_CAMPAIGNID,
        payload: res.campaignId
      })

      changeStep()
    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.SUMMARY }
      });
      notificationError(cleanErrorMessage(error) || t("summary.notification-error-upload-file"));
      console.error(error);
    });
  }

  const addDataFileByContacts = () => {
    const formData = new FormData();
    const { bodyExampleFields, campaignMobilePreviewData } = campaingState

    const message = replacePlaceholders(bodyExampleFields, campaignMobilePreviewData.bodyText)

    const json = JSON.stringify({
      ...campaingState.headersMap, 
      message: message
    });

    const blob = new Blob([json], {
      type: 'application/json'
    });

    formData.append("campaignHeader", blob, "CampaignHeader.json");

    let campaign = {
      campaignName: campaingState.campaignName,
      companyId: auth.companyId,
      accountId: campaingState.accountYP.accountId,
      userId: auth.userName,
      wabaId: campaingState.account.wabaId,
      wabaPhoneId: campaingState?.messageData?.wabaPhoneId,
      wabaTemplateId: campaingState.template?.wabaTemplateId,
      channel: "Whatsapp",
      wabaTemplateCategory: campaingState?.template?.category,
      contactGroupId: campaingState.contactSelected,
      campaignSchedule: campaingState.scheduleData,
      hasBodyParameters: Object.entries(bodyExampleFields || {}).length > 0
    }

    const blobCampaign = new Blob([JSON.stringify(campaign)], {
      type: 'application/json'
    });

    formData.append("campaignInfo", blobCampaign, "CampaignInfo.json");

    createCampaignWithContacts(formData, auth.accessToken).then(res => {

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_SUMMARY,
        payload: res.data
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_FILEROWS,
        payload: res.fileRows
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_CAMPAIGNID,
        payload: res.campaignId
      })

      changeStep()
    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.SUMMARY }
      });
      notificationError(cleanErrorMessage(error) || t("summary.notification-error-upload-file"));
      console.error(error);
    });
  }

  const changeStep = () => {
    let nextStepValue = current + 1;
    setCurrent(nextStepValue);
  }

  const changeSchedule = (schedule) => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_SCHEDULE,
      payload: schedule
    })
  }

  const handleRemoveFile = () => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_FILE,
      payload: null
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_FILEHEADERS,
      payload: []
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN,
      payload: null
    })
    
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS,
      payload: []
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_SELECTEDHEADERS,
      payload: []
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CONTACT,
      payload: null
    })
  };

  const handleUploadFile = (file, fileType) => {

    handleRemoveFile();

    if (file) {

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN,
        payload: ""
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_CONTACT,
        payload: null
      })
  
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_FILE,
        payload: file
      })

      campaingDispacth({
        type: TYPES.CAMPAIGN.LOAD_CONTACTS,
        payload: false
      })

      switch (fileType) {
        case CONSTANTS.FILETYPES.CSV.NAME:
          processCsvFile(file);
          break;
        case CONSTANTS.FILETYPES.EXCEL.NAME:
          processExcelFile(file);
          break;      
        default:
          break;
      }
    }
  };

  const processCsvFile = (file) => {
    const reader = new FileReader();
    reader.onload = onLoadCsvFileInMemory;
    reader.readAsText(file);
  }

  const processExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = onLoadExcelFileInMemory;
    reader.readAsBinaryString(file);
  }

  const onLoadCsvFileInMemory = event => {
    var csv = event.target.result;
    fileProcess(csv);
  }

  const onLoadExcelFileInMemory = event => {
    const bstr = event.target.result;
    const wb = XLSX.read(bstr, {type:'binary'});
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const csv = XLSX.utils.sheet_to_csv(ws, {header:1});
    fileProcess(csv);
  }

  const fileProcess = csv => {
    var rows = csv.split('\n');

    if (rows.length < 2) {
      notificationError(t("upload-file.alert-file-rows-validation"));
      handleRemoveFile();
      return;
    }

    let separator = rows[0].includes(',') ? ',' : ';'

    var cols = rows[0].split(separator);
    let headers = [];

    if(cols.length > 7) {
      notificationError(t("upload-file.alert-file-columns-validation"));
      handleRemoveFile();
      return;
    }

    for (let j = 0; j < cols.length; j++) {
      headers.push({
        name: cols[j].replace(/(\r\n|\n|\r)/gm, "").trim(),
        column: j
      });
    }

    let data = [];
    let count = rows.length > 4 ? 4 : rows.length

    for (let i = 1; i < count; i++) {

      cols = rows[i].split(separator);
      let row = [];

      if (cols.length !== headers.length) {
        notificationError(t("upload-file.alert-file-rows-columns-validation"));
        handleRemoveFile();
        return;
      }

      for (let j = 0; j < cols.length; j++) {
        row.push({
          value: cols[j].replace(/(\r\n|\n|\r)/gm, ""),
          column: j
        });
      }

      data.push({
        value: row,
        row: i
      });

    }

    campaingDispacth({
      type: TYPES.CAMPAIGN.FILE_ROWS_COUNT,
      payload: rows.length - 1
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_FILEDATA,
      payload: data
    })

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_FILEHEADERS,
      payload: headers
    })
  }

  const handleChangeHeaders = (headerData) => {
    let headersMap = [...campaingState.headersMap.selectedHeaders];
    if(headersMap.length == 0) {
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_SELECTEDHEADERS,
        payload: headerData
      })
    } else {
      let phoneHeader = headersMap.find(x => x.name == campaingState.headersMap.mobileNumberColumn);
      headerData.push(phoneHeader);
      campaingDispacth({
        type: TYPES.CAMPAIGN.ADD_SELECTEDHEADERS,
        payload: headerData
      })
    }
  }

  const handleMobileNumberField = (value) => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN,
      payload: value
    })
  };

  const handleChangeCustomFields = (value) => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS,
      payload: value
    })
  };

  const handleChangeMessage = value => {
    
    if(value.message) {
      setMessage(value.message);
    } else 
    {
      setMessage(" ");
    }

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_MESSAGE,
      payload: value
    })
  };

  const handleChangeSender = value => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_SENDERID,
      payload: value
    })
  }

  const handleChangeWildCards = (wildcardsList) => {
    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_WILDCARDS,
      payload: wildcardsList
    })
  }


  const handleCancelConfirmCampaign = () => {
    setModalCampaignVisible(false);
  }

  const handleConfirmCampaign = () => {
    const { bodyExampleFields, campaignMobilePreviewData } = campaingState
    const message = replacePlaceholders(bodyExampleFields, campaignMobilePreviewData.bodyText)

    if(unavailableBalance === false) {
      
      setModalCampaignVisible(false);
    
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.SENDCAMPAIGN }
      });

      let campaign = {
        campaignId: campaingState.summary?.campaignId,
        campaignName: campaingState.campaignName,
        accountId: campaingState.accountYP.accountId,
        companyId: auth.companyId,
        userId: auth.userName,
        wabaId: campaingState.account.wabaId,
        wabaPhoneId: campaingState?.messageData?.wabaPhoneId,
        wabaTemplateId: campaingState.template?.wabaTemplateId,
        wabaTemplateCategory: campaingState?.template?.category,
        message: message,
        campaignSchedule: campaingState.scheduleData
      }

      sendCampaign(campaign, auth.accessToken)
        .then(() => {
          
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.SENDCAMPAIGN }
          });

          notificationOk(t("summary.notification-ok-campaign"));
          history.push("Credits");
        }).catch(error => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.SENDCAMPAIGN }
          });
          notificationError(t("summary.notification-error-campaign"));
          console.error(error);
        });

    } else {
      if(auth.role === CONSTANTS.ROLES.COMPANY_ADMIN) {
        setModalBillingVisible(true);
      } else {
        notificationError("Please contact your Administrator to buy credits");
        setTimeout(() => {
          history.go(0);
        }, 2000);
      }
    }
  }

  const handleCancelCampaign = () => {
    setModalCanelVisible(false);
  }

  const handleOkCancelCampaign = () => {

    if(campaingState.summary?.campaignId) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.DELETECAMPAIGN }
      });
      deleteCampaign(campaingState.summary?.campaignId)
        .then(response => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETECAMPAIGN }
          });

          notificationOk(t("campaign-deleted-successfully"));
          history.replace("/");
        })
        .catch(error => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETECAMPAIGN }
          });
          notificationError("An error occurred while deleting campaign");
          console.error(error);
          setModalCanelVisible(false);
        });
    } else {
      history.replace("/");
    }
  }

  const handleCancelBilling = () => {
    setModalBillingVisible(false);
    history.go(0);
  }

  const handleOkBilling = () => {
    setModalBillingVisible(false);
    history.push("Credits");
  }

  const convertJsonToWildcards = (data, fields) => {
    const fieldMap = {};
    fields.forEach(field => {
      fieldMap[field.column] = field.name;
    });
  
    const targetFields = fields
      .filter(field => field.name !== "Mobile Number")
      .map(field => field.name);
  
    const convertedData = {
      fields: targetFields,
      values: []
    };
  
    const firstRow = data[0].value;
    
    targetFields.forEach(fieldName => {
      const column = Object.keys(fieldMap).find(col => fieldMap[col] === fieldName);
      const cell = firstRow.find(cell => cell.column.toString() === column);
      if (cell) {
        convertedData.values.push(cell.value);
      }
    });
  
    return convertedData;
  }

  const onChengeContact = (groupId) => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.CONTACTLIST }
    });

    campaingDispacth({
      type: TYPES.CAMPAIGN.ADD_CONTACT,
      payload: groupId
    })

    getContactsByGorup(groupId)
    .then(response => {

      const mobileNumberName = "Mobile Number";

      let contacts = response;
      let firstRow = contacts[0];

      let fields = Object.keys(firstRow);
      var arrayContainsMobileNumber = (fields.indexOf("mobileNumber") > -1);

      if(arrayContainsMobileNumber) {

        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_MOBILENUMBERCOLUMN,
          payload: mobileNumberName
        })

        let fileHeader = firstRow.wildCards.map((x, index) => { return {
          column: index + 1,
          name: x.key
        }});

        let customfield = fileHeader.map(x => { return x.name });

        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_CUSTOMEFIELDS,
          payload: customfield
        })

        fileHeader.unshift({column: 0, name: mobileNumberName});

        let count = contacts.length > 3 ? 3 : contacts.length;
        let fileData = [];

        for (let index = 0; index < count; index++) {
          const row = contacts[index];
          let data = { row: index, value: [] }

          let value = row.wildCards.map((x, index) => { return {
            column: index + 1,
            value: x.value
          }});

          value.unshift({column: 0, value: row.mobileNumber});
          data.value = value;
          fileData.push(data);          
        }

        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_WILDCARDS,
          payload: convertJsonToWildcards(fileData, fileHeader)
        })
        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_FILEDATA,
          payload: fileData
        })
        
        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_SELECTEDHEADERS,
          payload: fileHeader
        })
    
        campaingDispacth({
          type: TYPES.CAMPAIGN.ADD_FILEHEADERS,
          payload: fileHeader
        })

        campaingDispacth({
          type: TYPES.CAMPAIGN.LOAD_CONTACTS,
          payload: true
        })

      } else {
        console.error("The object doesn`t have mobileNumber property");
      }

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTLIST }
      });

    })
    .catch(error => {
      console.error("error", error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTLIST }
      });
    })
  }

  return (
    <Fragment>
      <div className="yp-workspace">
        <div className={styles['step-header']}>
          <Steps labelPlacement="horizontal" current={current} direction="horizontal">
            {steps.map(item => (
              <Step key={item.title} title={item.title}/>
            ))}
          </Steps>
        </div>
        <div className={(current === 3) ? styles['step-content-3'] : styles['step-content']}>
        <div className={(current === 3) ? styles['step-content-card-3'] : styles['step-content-card']}>
            {
              renderLeftCard()
            }
          </div>
          <div className={(current === 3) ? styles['step-content-card-3'] : styles['step-content-card']}>
            {
              renderRightCard()
            }
          </div>
        </div>
      </div>
      <div className={styles['step-buttons-container']}>
        <div className={styles['step-buttons']}>
          {current < steps.length && (
            <Button style={{ width: 150, marginRight: '10px' }} type="secondary" onClick={() => setModalCanelVisible(true)}>
              {t("actions-buttons.cancel")}
            </Button>
          )}
          {current > 0 && current < steps.length  && (
            <Button style={{ width: 150, marginRight: '10px' }} type="optional" onClick={() => prevStep()}>
              {t("actions-buttons.previous")}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button style={{ width: 150 }} type="primary" onClick={() => setModalCampaignVisible(true)}>
              {t("actions-buttons.done")}
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button style={{ width: 150 }} type="primary" onClick={() => nextStep()}>
              {t("actions-buttons.next")}
            </Button>
          )}
        </div>
      </div>
      <ModalConfirmation
        key={1}
        visible={modalBillingVisible}
        title={t("modal-campaign-credits.title")}
        onCancel={handleCancelBilling}
        onOk={handleOkBilling}
        okBtnText={t("modal-campaign-credits.btn-ok")}
      >
        <p>{t("modal-campaign-credits.content")}</p>
      </ModalConfirmation>
      <ModalConfirmation
        key={2}
        visible={modalCanelVisible}
        title={t("modal-cancel-campaign.title")}
        onCancel={handleCancelCampaign}
        onOk={handleOkCancelCampaign}
      >
        <p>{t("modal-cancel-campaign.content")}</p>
      </ModalConfirmation>
      <ModalConfirmation
        key={3}
        visible={modalCampaignVisible}
        title={t("modal-generate-campaign.title")}
        onCancel={handleCancelConfirmCampaign}
        onOk={handleConfirmCampaign}
        okBtnText={t("modal-generate-campaign.btn-ok")}
      >
        <p style={{ textAlign: "center" }}>{t("modal-generate-campaign.content")}</p>
        <br />
        <p>{t("modal-generate-campaign.disclaimer")}</p>
      </ModalConfirmation>
    </Fragment>
  );
}
 
export default BulkWhatsApp;