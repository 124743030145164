import useAuth from "../Hooks/useAuth";
import { ratesRequest } from "../utils/authConfig";

const RatesApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    getCountries: async () => {
      let accessToken = await passTokenToApi(ratesRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "GET",
          headers: {
            Authorization: bearer,
          },
        };
        const resource = `/api/Rate/Country`;
        const url = `${process.env.REACT_APP_RATE_API_URL}${resource}`;

        let response = await fetch(url, options);
        let accounts = await response.json();
        return accounts;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getRatesByCompany: async (companyId, channelId) => {
      let accessToken = await passTokenToApi(ratesRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "GET",
          headers: {
            Authorization: bearer,
          },
        };
        const resource = `/api/Rate/Custom/${companyId}/Channel/${channelId}`;
        const url = `${process.env.REACT_APP_RATE_API_URL}${resource}`;

        let response = await fetch(url, options);
        let rates = await response.json();
        return rates;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getRatesAzBase: async () => {
      let accessToken = await passTokenToApi(ratesRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "GET",
          headers: {
            Authorization: bearer,
          },
        };
        const resource = `/api/Rate/Base`;
        const url = `${process.env.REACT_APP_RATE_API_URL}${resource}`;

        let response = await fetch(url, options);
        let rates = await response.json();
        return rates;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    addCustomRates: async (rates) => {
      let accessToken = await passTokenToApi(ratesRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "POST",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(rates)
        };
        const resource = `/api/Rate/Custom`;
        const url = `${process.env.REACT_APP_RATE_API_URL}${resource}`;

        let response = await fetch(url, options);
        let message = await response.text();
        return message;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    addBaseRates: async (rates) => {
      let accessToken = await passTokenToApi(ratesRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "PUT",
          headers: {
            Authorization: bearer,
          },
          body: JSON.stringify(rates)
        };
        const resource = `/api/Rate`;
        const url = `${process.env.REACT_APP_RATE_API_URL}${resource}`;

        let response = await fetch(url, options);
        let message = await response.text();
        return message;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    getRatestemplate: async (channel,typefile) => {
      let accessToken = await passTokenToApi(ratesRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "GET",
          headers: {
            Authorization: bearer,
          },
        };
        const resource = `/api/Rate/TemplateAZ/Channel/${channel}/TypeFile/${typefile}`;
        const url = `${process.env.REACT_APP_RATE_API_URL}${resource}`;

        let response = await fetch(url, options);
        let templateUrl = await response.text();
        return templateUrl;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    addRates: async (formData) => {
      let accessToken = await passTokenToApi(ratesRequest);
      try {
      
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "POST",
            headers: {
              Authorization: bearer,
            },
            body: formData
          };
          const resource = `/api/Rate`;
          const url = `${process.env.REACT_APP_RATE_API_URL}${resource}`;

          let response = await fetch(url, options);
          let result = await response.json();

          if (response.status === 200) {
            return { isSuccessful: true, message: result.message };
          } else if(response.status === 400) {
            let errorMessage = "";
            if (result.message && !result.listErrors) {
              errorMessage = result.message;
            } else if (result.message && result.listErrors) {
              errorMessage = result.listErrors.join(', ');
            }
            return { isSuccessful: false, error: errorMessage };
          }

        } else {
          console.log("Authentication Error");
          return [];
        }
      } catch (error) {
        console.error('Fetch Error en CATCH:', error);
        return { isSuccessful: false, error: error.message };
      }
    },
    updateRates: async (formData) => {
      let accessToken = await passTokenToApi(ratesRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "PUT",
            headers: {
              Authorization: bearer,
            },
            body: formData
          };
          const resource = `/api/Rate/Custom`;
          const url = `${process.env.REACT_APP_RATE_API_URL}${resource}`;
  
          let response = await fetch(url, options);
          let result = await response.json();

          console.log('Actualiza rates desde result: ' + JSON.stringify(result))

          if (response.status === 200) {
            return { isSuccessful: true, message: result.message };
          }else if(response.status === 400){
            let errorMessage = "";
            if (result.message && !result.listErrors) {
              errorMessage = result.message;
            } else if (result.message && result.listErrors) {
              errorMessage = result.listErrors.join(', ');
            }
            return { isSuccessful: false, error: errorMessage };
          }
     
        } else {
          console.log("Authentication Error");
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error);
      }
    },
  }
}

export default RatesApi;