import { Alert, Col, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import HelperButton from "../../HelperButton/HelperButton";
import style from "./SelectAccount.module.css";

const SelectAccount = ({accounts,
  showValidations, 
  defaultListGroupName,
  defaultAccountsSelected,
  onAccountChange,
  onListGroupNameChange}) => {
  
  const [t] = useTranslation("create-contacts");
  const [ accountsValue, setAccountsValue] = useState([]);
  const [ validationListGroupName , setValidationListGroupName ] = useState(true);

  const handleAccountsChange = (value) => {
    setAccountsValue(value);
    onAccountChange(value);
  }

  const handleListGroupName = (e) => {
    let value = e.target.value;

    if(value) {
      setValidationListGroupName(false);
    } else {
      setValidationListGroupName(true);
    }

    onListGroupNameChange(value);
  }
 
  return (
    <>
      <Form
        data-testid="content"
        name="validate_accounts"
        initialValues={{
          'listGroupName': defaultListGroupName,
          'accountsSelected': defaultAccountsSelected
        }}
        layout="vertical"
      >
        <Row className="title-contact">
          <Col style={{textAlign: "center", width: "100%", marginBottom:"10px"}}>
            <p>{t("account.title")}</p>
          </Col>
        </Row>
        <Row style={{flexFlow:"nowrap"}}>
          <Col>
            <Form.Item 
              name="listGroupName"
              label={t("account.label-text-list-group-name")}
              extra={
                (
                  showValidations && validationListGroupName && (
                    <Alert message={t("account.list-group-required-field")} type="error" showIcon />
                  )
                )
              }
            >
              <Input
                style={{ width: 320 }}
                placeholder={t("account.place-holder-list-group-name")}
                onChange={handleListGroupName}
                value={defaultListGroupName}
              />
            </Form.Item>
          </Col>
          <Col>
            <div style={{paddingTop: "34px"}}>
              <HelperButton
                message={t("account.tooltip-list-group-name")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item 
              name="accountsSelected"
              label={t("account.label-text-accounts-selected")}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: 320 }}
                placeholder={t("account.placeholder-accounts-selected")}
                onChange={handleAccountsChange}
                options={accounts}
                value={accountsValue}
              />
            </Form.Item>
          </Col>
          <Col>
            <div style={{paddingTop: "34px"}}>
              <HelperButton
                message={t("account.tooltip-accounts-selected")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}
 
export default SelectAccount;