import React, { useState, useEffect } from 'react';
import AccountBalance from './AccountBalance/AccountBalance';
import CardFilter from "../../CardFilter/CardFilter";
import { Input } from 'antd';
import propTypes from 'prop-types'
import { filterByField } from "../../../utils/helpers";
import { useTranslation } from 'react-i18next'

const { Search } = Input;

const AccountsBalance = ({accountsBalance, showAssingBalance}) => {

  const [t, i18n] = useTranslation("balance");
  const [accountsFiltered, setAccountsFilteredState] = useState([]);

  useEffect(() => {
    setAccountsFilteredState(accountsBalance);
  }, [accountsBalance]);

  const changeFilter = e => {
    let value = e.target.value;
    let result = filterByField(accountsBalance, "accountName", value);
    setAccountsFilteredState(result);
  };

  return (

    <CardFilter
      style={{borderLeft: 0}}
      title={t("top-menu.title-top-menu-accounts")}
      filter={
        <Search
          size="small"
          placeholder={t("top-menu.placeholder-top-menu")}
          onChange={changeFilter}
        />
      }
      body={
        accountsFiltered.map(accountBalance => (
          <AccountBalance
            key={accountBalance.id}
            data={accountBalance}
            showAssingBalance={showAssingBalance}
          />
        ))
      }
    />
  );
}

AccountsBalance.propTypes = {
  accountsBalance: propTypes.array.isRequired
}

export default AccountsBalance;