import { Button, Col, Form, Input, Row, Select, Upload } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import useAuth from "../../../../../Hooks/useAuth";
import RcsApi from "../../../../../Api/RcsApi";
import useSpinner from "../../../../../Hooks/useSpinner";
import TYPES from "../../../../../reducers/types";
import CONSTANTS, { COLOR_PICKER_COLORS, RCS_BANNER_MAX_FILE_SIZE, RCS_BANNER_MAX_HEIGHT, RCS_BANNER_MAX_WIDTH, RCS_LOGO_MAX_FILE_SIZE, RCS_LOGO_MAX_HEIGHT, RCS_LOGO_MAX_WIDTH } from "../../../../../utils/const";
import { cleanErrorMessage, isValidImageFileType, notificationError } from "../../../../../utils/helpers";
import HelperButton from "../../../../../components/HelperButton/HelperButton";
import { getGradientColor, purpleColor } from "../../../../../utils/colorsCss";
import { TwitterPicker } from "react-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/pro-solid-svg-icons";
import MobilePreview from "../../../MobilePreview";
import "./styles.css"

const { TextArea } = Input
const { Dragger } = Upload
const { Option } = Select

export const StepOne = ({ agentId, setAllDetails, step1Data, setStep1Data, form }) => {
  
  const [t] = useTranslation("rcs-channel");
  const [fileData, setFileData] = useState(null)
  const [colorPickerOpen, setColorPickerOpen] = useState(false)

  const params = useParams()

  const { auth } =  useAuth();
  const { getBrandById, getAgentById } = RcsApi();
  const { spinnerDispacth } = useSpinner();

  useEffect(() => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.DEFAULT }
    });

    if (params?.brand !== null || params?.brand !== undefined) {
      getBrandById(params.brand)
        .then((res) => {
          const { rcsBrandId, companyId, accountId } = res?.data || {}
          const data = {
            ...step1Data,
            rcsBrandId,
            companyId,
            accountId,
            lastModifiedBy: auth.userName
          }
          setStep1Data(data)
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
          });
        })
        .catch(e => {
          notificationError(cleanErrorMessage(e))
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
          });
        })
    }
  }, [params?.brand]);

  useEffect(() => {
    if (agentId) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.DEFAULT }
      });
      getAgentById(agentId)
        .then((agentRes) => {
          setStep1Data({ ...step1Data, ...agentRes?.data })
          setAllDetails({ ...step1Data, ...agentRes?.data })
        })
        .catch(e => {
          notificationError(cleanErrorMessage(e))
        })
        .finally(() => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
          });
        })
    }
  }, [agentId]);

  useEffect(() => {
    if (agentId && step1Data) {
      form.setFieldsValue({
        name: step1Data.name,
        description: step1Data.description,
        hostingRegion: step1Data.hostingRegion,
        billingCategory: step1Data.billingCategory,
        agentUseCase: step1Data.agentUseCase,
        phone: step1Data.phone,
        email: step1Data.email,
        website: step1Data.website,
        privacy: step1Data.privacy,
        termsConditions: step1Data.termsConditions,
      })
    }
  }, [step1Data, agentId]);

  const getBase64 = (file, field) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const extensionFieldName = field === "bannerBase64" ? "bannerExtension" : "logoExtension"
      const extension = file?.name?.split(".")[1]
      const base64 = reader.result.split(',')[1]
      setStep1Data({ ...step1Data, [field]: base64, [`preview${field}`]: reader.result, [extensionFieldName]: `.${extension}` })
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const validateFile = (file, type) => {
    const maxFileSize = type === "banner" ? RCS_BANNER_MAX_FILE_SIZE : RCS_LOGO_MAX_FILE_SIZE
    const maxWidth = type === "banner" ? RCS_BANNER_MAX_WIDTH : RCS_LOGO_MAX_WIDTH
    const maxHeight = type === "banner" ? RCS_BANNER_MAX_HEIGHT : RCS_LOGO_MAX_HEIGHT
    return new Promise((resolve) => {
      if (file.size > maxFileSize) {
        notificationError(type === "banner" ? t("step1.validation-error.banner-file-size-exceeds-limit") : t("step1.validation-error.logo-file-size-exceeds-limit"));
        resolve(false);
        return;
      }

      const img = new Image();
      const reader = new FileReader();

      reader.onload = function (e) {
        img.src = e.target.result;
      };

      img.onload = function () {
        if (img.width > maxWidth || img.height > maxHeight) {
          notificationError(type === "banner" ? t("step1.validation-error.banner-resolution-exceeds-limit") : t("step1.validation-error.logo-resolution-exceeds-limit"));
          resolve(false);
          return;
        }
        resolve(true);
      };

      reader.onerror = function () {
        notificationError(t("step1.validation-error.file-error"));
        resolve(false);
      };

      reader.readAsDataURL(file);
    });
  };

  const bannerProps = {
    key: "banner",
    onRemove: file => {
      const index = fileData?.banner?.fileList.indexOf(file);
      const newFileList = fileData?.banner?.fileList.slice();
      newFileList.splice(index, 1);
      setFileData({ ...fileData, banner: { fileList: newFileList, name: "" }})
      setStep1Data({ ...step1Data, bannerBase64: null, [`previewbannerBase64`]: null, bannerExtension: null })
    },
    beforeUpload: file => {
      return validateFile(file, "banner").then(isValid => {
        if (isValid) {
          getBase64(file, "bannerBase64");
          setFileData({ ...fileData, banner: { fileList: [file], name: file.name }});
        }
        return false;
      });
    },
    onDrop: async e => {
      const file = e.dataTransfer.files[0];
      if(!isValidImageFileType(file.type)) {
        let text = t("step1.rules.valid-image-file-type");
        notificationError(text);
        return
      }
      const isValid = await validateFile(file, "banner");
      if (!isValid) {
        return;
      }
    },
    fileList: fileData?.banner?.fileList || [],
    accept: ".jpeg,.jpg,.png",
    disabled: agentId
  };

  const logoProps = {
    key: "logo",
    onRemove: file => {
      const index = fileData?.logo?.fileList.indexOf(file);
      const newFileList = fileData?.logo?.fileList.slice();
      newFileList.splice(index, 1);
      setFileData({ ...fileData, logo: { fileList: newFileList, name: "" }})
      setStep1Data({ ...step1Data, logoBase64: null, [`previewlogoBase64`]: null, logoExtension: null })
    },
    beforeUpload: file => {
      return validateFile(file, "logo").then(isValid => {
        if (isValid) {
          getBase64(file, "logoBase64")
          setFileData({ ...fileData, logo: { fileList: [file], name: file.name }})
        }
        return false;
      })
    },
    onDrop: async e => {
      const file = e.dataTransfer.files[0];
      if(!isValidImageFileType(file.type)) {
        let text = t("step1.rules.valid-image-file-type");
        notificationError(text);
        return
      }
      const isValid = await validateFile(file, "logo");
      if (!isValid) {
        return;
      }
    },
    fileList: fileData?.logo?.fileList || [],
    accept: ".jpeg,.jpg,.png",
    disabled: agentId
  };

  const selectorOptions = {
    hostingRegions: [{
      value: "NORTH_AMERICA", label: t("step1.label.hosting-region-option.north-america"),
    }, {
      value: "EUROPE", label: t("step1.label.hosting-region-option.europe"),
    }, {
      value: "ASIA_PACIFIC", label: t("step1.label.hosting-region-option.asia-pacific"),
    }],
    billingCategories: [{
      value: "SINGLE_MESSAGE", label: t("step1.label.billing-category-option.single-message"),
    }, {
      value: "BASIC_MESSAGE", label: t("step1.label.billing-category-option.basic-message"),
    }],
    useCases: [{
      value: "TRANSACTIONAL", label: t("step1.label.use-case-option.transactional"),
    }, {
      value: "PROMOTIONAL", label: t("step1.label.use-case-option.promotional"),
    }, {
      value: "OTP", label: t("step1.label.use-case-option.otp"),
    }], 
  }
  
  const validateOnlyNumbers = (_, value) => {
    const regex = /^[0-9]+$/;
    const condition = value && !regex.test(value)
    if (condition) {
      return Promise.reject(new Error(t("step2.rules.phone-number")));
    }
    return Promise.resolve();
  };

  const validateUrl = (_, value) => {
    const startsWithHttps = value && value.startsWith('https://');
    if (!startsWithHttps) {
      return Promise.reject(new Error(t("step1.rules.valid-url")));
    }
    return Promise.resolve();
  };

  return (
    <Form form={form} layout="vertical" style={{ marginTop: "40px" }}>
      <Row>
        <Col span={6} style={{ padding: "0px 15px" }}>
          <Row>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Form.Item
                name="name"
                label={t("step1.label.display-name")}
                rules={[{ required: true, message: t("step1.rules.agent-name-required") }]}
                style={{ width: '100%' }}
                onChange={(e) => setStep1Data({ ...step1Data, name: e.target.value.trim() })}
              >
                <Input
                  id="create-template-name-input"
                  disabled={agentId}
                  placeholder={t("step1.placeholder.enter-display-name")}
                  value={step1Data?.name}
                  maxLength={18}
                />
              </Form.Item>
            </div>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Form.Item
                name="description"
                label={t("step1.label.description")}
                rules={[{ required: true, message: t("step1.rules.description-required") }]}
                style={{ width: '100%' }}
                onChange={(e) => setStep1Data({ ...step1Data, description: e.target.value.trim() })}
              >
                <TextArea id="create-template-name-input" disabled={agentId} placeholder={t("step1.placeholder.enter-description")} maxLength={63} rows={3} />
              </Form.Item>
            </div>
          </Row>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Item
              name="hostingRegion"
              label={
                <div>
                  {t("step1.label.hosting-region")}
                  <HelperButton
                    message={
                      <>
                        {t("step1.tooltip.info")}
                        <a href={t("step1.tooltip.hosting-region-url")} target="_blank" rel="noopener noreferrer">
                          {t("step1.tooltip.page")}
                        </a>
                      </>
                    }
                    color={getGradientColor(purpleColor)}
                  />
                </div>
              }
              rules={[{ required: true, message: t("step1.rules.hosting-region-required") }]}
              style={{ width: '100%' }}
            >
              <Select
                disabled={agentId}
                placeholder={t("step1.placeholder.select-hosting-region")}
                onChange={(v) => setStep1Data({ ...step1Data, hostingRegion: v })}
                value={step1Data?.hostingRegion || null}
              >
                {
                  selectorOptions.hostingRegions.map(item =>
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  )
                }
              </Select>
            </Form.Item>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Item
              name="billingCategory"
              label={
                <div>
                  {t("step1.label.billing-category")}
                  <HelperButton
                    message={
                      <>
                        {t("step1.tooltip.info")}
                        <a href={t("step1.tooltip.billing-category-url")} target="_blank" rel="noopener noreferrer">
                          {t("step1.tooltip.page")}
                        </a>
                      </>
                    }
                    color={getGradientColor(purpleColor)}
                  />
                </div>
              }
              rules={[{ required: true, message: t("step1.rules.billing-category-required") }]}
              style={{ width: '100%' }}
            >
              <Select
                disabled={agentId}
                placeholder={t("step1.placeholder.select-billing-category")}
                onChange={(v) => setStep1Data({ ...step1Data, billingCategory: v })}
                value={step1Data?.billingCategory || null}
              >
                {
                  selectorOptions.billingCategories.map(item =>
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  )
                }
              </Select>
            </Form.Item>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Item
              name="agentUseCase"
              label={
                <div>
                  {t("step1.label.use-case")}
                  <HelperButton
                    message={
                      <>
                        {t("step1.tooltip.info")}
                        <a href={t("step1.tooltip.use-case-url")} target="_blank" rel="noopener noreferrer">
                          {t("step1.tooltip.page")}
                        </a>
                      </>
                    }
                    color={getGradientColor(purpleColor)}
                  />
                </div>
              }
              rules={[{ required: true, message: t("step1.rules.use-case-required") }]}
              style={{ width: '100%' }}
            >
              <Select
                disabled={agentId}
                placeholder={t("step1.placeholder.select-use-case")}
                onChange={(v) => setStep1Data({ ...step1Data, agentUseCase: v })}
                value={step1Data?.agentUseCase || null}
              >
                {
                  selectorOptions.useCases.map(item =>
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  )
                }
              </Select>
            </Form.Item>
          </div>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Form.Item
              name="color"
              label={t("step1.label.color")}
              rules={[{ required: true, message: t("step1.rules.color-required") }]}
              style={{ width: '100%' }}
            >
              <div style={{ border: "1px solid #cac4c9", borderRadius: "2px", padding: "3px", height: "32px", width: "100%", cursor: agentId ? "not-allowed" : "pointer" }} onClick={() => !agentId && setColorPickerOpen(!colorPickerOpen)}>
                <div style={{ background: step1Data?.color || "white", borderRadius: "2px", height: "100%" }} />
              </div>
              {colorPickerOpen ? <TwitterPicker
                style={{ border: "1px solid #cac4c9", zIndex: 10 }}
                className='color-picker'
                color={step1Data?.color || "#fff"}
                onChangeComplete={(color) => {
                  setStep1Data({ ...step1Data, color: color.hex })
                  form.setFieldsValue({ color: color.hex })
                }}
                colors={COLOR_PICKER_COLORS}
                triangle="top-left"
              /> : null}
            </Form.Item>
          </div>
        </Col>
        <Col span={12} style={{ padding: "0px" }}>
          <Row>
            {!agentId ? <>
              <Col span={12} style={{ padding: "0px 10px 0px 0px" }}>
                <div style={{ marginBottom: "15px" }}>
                  <label>{t("step1.label.banner")}</label>
                  <HelperButton
                    message={<p>{t("step1.tooltip.banner")}</p>}
                    color={getGradientColor(purpleColor)}
                  />
                </div>
                <div className='file-dragger'>
                  <Dragger {...bannerProps}>
                    <Button
                      className='file-dragger-button' shape="circle" icon={<FontAwesomeIcon icon={ faUpload } />} size="large"
                    />
                    <p className="ant-upload-hint">
                      {t("step1.label.label-drag-file-subtitle-one")}
                    </p>
                  </Dragger>
                </div>
              </Col>
              <Col span={12} style={{ padding: "0px 10px 0px 0px" }}>
                <div style={{ marginBottom: "15px" }}>
                  <label>{t("step1.label.logo")}</label>
                  <HelperButton
                    message={<p>{t("step1.tooltip.logo")}</p>}
                    color={getGradientColor(purpleColor)}
                  />
                </div>
                <div className='file-dragger'>
                  <Dragger {...logoProps}>
                    <Button
                      className='file-dragger-button' shape="circle" icon={<FontAwesomeIcon icon={ faUpload } />} size="large"
                    />
                    <p className="ant-upload-hint">
                      {t("step1.label.label-drag-file-subtitle-one")}
                    </p>
                  </Dragger>
                </div>
              </Col>
            </> : null}
            <Row style={{ marginTop: "20px" }}>
              <h2>{t("step1.label.company-contact-information")}</h2>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col span={12} style={{ padding: "0px 10px 0px 0px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Item
                    name="phone"
                    label={t("step1.label.phone-number")}
                    rules={[{ required: true, message: t("step1.rules.phone-number-required") }, { validator: validateOnlyNumbers }]}
                    style={{ width: '100%' }}
                    onChange={(e) => setStep1Data({ ...step1Data, phone: e.target.value.trim() })}
                  >
                    <Input
                      id="create-template-name-input"
                      disabled={agentId}
                      placeholder={t("step1.placeholder.enter-phone-number")}
                      value={step1Data?.phone}
                    />
                  </Form.Item>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Item
                    name="website"
                    label={t("step1.label.website")}
                    rules={[{ required: true, message: t("step1.rules.website-required") }, { validator: validateUrl }]}
                    style={{ width: '100%' }}
                    onChange={(e) => setStep1Data({ ...step1Data, website: e.target.value.trim() })}
                  >
                    <Input id="create-template-name-input" disabled={agentId} placeholder={t("step1.placeholder.enter-website-url")} />
                  </Form.Item>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Item
                    name="email"
                    label={t("step1.label.email")}
                    rules={[{ required: true, message: t("step1.rules.email-required") }, { type: "email", message: t("step1.rules.email-required") }]}
                    style={{ width: '100%' }}
                    onChange={(e) => setStep1Data({ ...step1Data, email: e.target.value.trim() })}
                  >
                    <Input id="create-template-name-input" disabled={agentId} placeholder={t("step1.placeholder.enter-email")} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12} style={{ padding: "0px 10px 0px 0px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Item
                    name="privacy"
                    label={t("step1.label.privacy-policy")}
                    rules={[{ required: true, message: t("step1.rules.privacy-policy-required") }, { validator: validateUrl }]}
                    style={{ width: '100%' }}
                    onChange={(e) => setStep1Data({ ...step1Data, privacy: e.target.value.trim() })}
                  >
                    <Input id="create-template-name-input" disabled={agentId} placeholder={t("step1.placeholder.enter-privacy-policy-url")} />
                  </Form.Item>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Item
                    name="termsConditions"
                    label={t("step1.label.terms-of-service")}
                    rules={[{ required: true, message: t("step1.rules.terms-of-service-required") }, { validator: validateUrl }]}
                    style={{ width: '100%' }}
                    onChange={(e) => setStep1Data({ ...step1Data, termsConditions: e.target.value.trim() })}
                  >
                    <Input id="create-template-name-input" disabled={agentId} placeholder={t("step1.placeholder.enter-terms-of-service-url")} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Row>
        </Col>
        <Col span={6}>
          <MobilePreview type="business-info" previewValues={step1Data} />
        </Col>
      </Row>
    </Form>
  );
};