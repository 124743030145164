
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Input, Pagination } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TemplateApi from "../../../Api/TemplatesApi";
import useSpinner from "../../../Hooks/useSpinner";
import TYPES from "../../../reducers/types";
import CONSTANTS from "../../../utils/const";
import { getStyleImportant, notificationError, notificationOk } from "../../../utils/helpers";
import ModalConfirmation from "../../ModalConfirmation/ModalConfirmation";
import TableBodyCustom from "../../TableCustom/TableBodyCustom/TableBodyCustom";
import TableBodyTdCheck from "../../TableCustom/TableBodyTdCheck/TableBodyTdCheck";
import TableBodyTrCustom from "../../TableCustom/TableBodyTrCustom/TableBodyTrCustom";
import TableCustom from "../../TableCustom/TableCustom";
import TableHeadCustom from "../../TableCustom/TableHeadCustom/TableHeadCustom";
import styles from "./GroupData.module.css";
import Button from "../../Button/Button";
import ContactForm from "../../Contact/ContactForm/contactform"

const GroupData = ({group, onDelete, onUpdateConntactsRows}) => {

  const { getContactsByGorup, deleteContacts, AddContactToGroup } = TemplateApi();
  const { spinnerDispacth } = useSpinner();
  const [t] = useTranslation("contacts-history");

  const [showModifyHead, setShowModifyHead] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(CONSTANTS.PAGINATION);
  const [totalPage, setTotalPage] = useState(0);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [modalContactsVisible, setModalContacsVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isDeleteAll, setIsDeleteAll] = useState(false);
  const [showCreateContact, setShowCreateContact] = useState(false);

  useEffect(() => {
    if(group) {
      fetchContacts(group.id);
    }
  }, [group])

  const fetchContacts = async (groupId) => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.CONTACTLIST }
    });
  
    try {
      let response = await getContactsByGorup(groupId);
      let responseMap = response.map(x => { return { id: x.id, mobileNumber: x.mobileNumber, wildCards: x.wildCards, isCheked: false } });
      
      setCurrentPage(1);
      setData(responseMap);
      setTotalPage(responseMap.length);
      setDataFilteredByPage(responseMap, 1);
      setShowModifyHead(false);
  
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTLIST }
      });
    } catch (error) {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTLIST }
      });
    }
  };

  const setDataFilteredByPage = (items, page) => {
    const index = (page - 1) * itemsPerPage;
    const currentData = items.slice(index, index + itemsPerPage);
    setDataFiltered(currentData);
  }

  const onDeleteClick = () => {

    let dataToDelete = data.filter(x => x.isCheked === true);

    if(dataToDelete.length === 0) {
      notificationError(t("group-data.notification-contacts-delete-validation"));
      return;
    }

    if(dataToDelete.length === data.length) {
      setModalMessage(t("group-data.modal-delete-contacts-all-content"));
      setIsDeleteAll(true);
    } else {
      setModalMessage(t("group-data.modal-delete-contacts-content"));
      setIsDeleteAll(false);
    }

    setModalContacsVisible(true);
  }

  const onChangeSelect = (isCheked, id) => {
    let updatedList = data.map(item => 
    {
      if (item.id === id){
        return {...item, isCheked: isCheked};
      }
      return item;
    });
    
    setData(updatedList);

    let updatedFilteredList = dataFiltered.map(item => 
    {
      if (item.id === id){
        return {...item, isCheked: isCheked};
      }
      return item;
    });
    
    setDataFiltered(updatedFilteredList);
  }

  const onChengeAllSelect = (isCheked) => {
    let updatedList = data.map(item => 
    {
      return {...item, isCheked: isCheked};
    });
    setData(updatedList);

    let updatedFilteredList = dataFiltered.map(item => 
    {
      return {...item, isCheked: isCheked};
    });
    setDataFiltered(updatedFilteredList);
  }

  const onChengeShowModify = (e) => {
    let isCheked = e.target.checked;
    setShowModifyHead(isCheked)
    if(isCheked == false) {
      onChengeAllSelect(false);
    }
  }

  const onChangePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    setDataFilteredByPage(data, pageNumber);
  }

  const onSearch = (e) => {
    let value = e.target.value;
    let result = data.filter(x => x.mobileNumber.toLowerCase().includes(value.toLowerCase()));
    setDataFiltered(result);
    setTotalPage(result.length);
  }

  const handleConfirmModal = () => {
    setModalContacsVisible(false);

    if(isDeleteAll) {
      onDelete(group.id);
    } else {

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.DELETECONTACTS }
      });

      let dateToDelete = data.filter(x => x.isCheked === true);
      let mobileNumbers = dateToDelete.map(x => {return x.mobileNumber});

      deleteContacts(group.id, mobileNumbers)
      .then(() => {

        let newData = data.filter(x => x.isCheked !== true);
        setData(newData);

        setDataFilteredByPage(newData, 1);
        setCurrentPage(1);
        setShowModifyHead(false);

        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETECONTACTS }
        });

        onUpdateConntactsRows(group.id, newData.length);
        notificationOk(t("group-data.notification-delete-group-ok"));

      })
      .catch(error => {
        console.error(error);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETECONTACTS }
        });
        notificationError(t("group-data.notification-delete-group-error"));
      })
    }
  }

  const showDrawer = (value) => {
    setShowCreateContact(value);
  };

  const onCreateContact = async (contact) => {
    try {

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.CREATECONTACT }
      });
      
      let groupId = group.id;
      const { Phone, WildCards } = contact;
      let newContact = {
        Contacts:[{
            MobileNumber: Phone,
            WildCards: WildCards
        }]
      }

      let requestBody = JSON.stringify(newContact);
      showDrawer(false);
      await AddContactToGroup(groupId, requestBody, Phone).then((response)=>{
        if (response.error) {
          throw new Error(response.message);
        }
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.CREATECONTACT }
        });
        fetchContacts(groupId);
        notificationOk("The contact was created successfully");
      }).catch(err=>{
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.CREATECONTACT }
        });
        notificationError(t(`An ocurred an error: ${err.message}`))
      })
    }
    catch (error) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CREATECONTACT}
      });
      notificationError(t(`An ocurred an error: ${error}`))
    }
  }

  return (
    <div className={styles['content']}>
      <div className={styles['content-title']}>
        <div className={styles['content-formTitle']}>
          <p>{t("group-data.label-title")} {group?.name}</p>
          <div>
            <Input placeholder={t("group-data.input-placeholder-search")} onChange={onSearch}/>
          </div>
        </div>
        <Pagination 
          defaultCurrent={currentPage}
          current={currentPage}
          onChange={(pageNumber) => onChangePagination(pageNumber)}
          total={totalPage}
          pageSize={itemsPerPage}
          showSizeChanger={false}
        />
      </div>
      <div className={styles['content-grid']}>
        {
          group && dataFiltered.length > 0 && (
            <TableCustom>
              {
                showModifyHead && (
                  <thead>
                    <tr className={styles['table-modify']}>
                      <th key={-1} colSpan={data[0].wildCards.length + 2}>
                        <Checkbox onChange={(e) => onChengeAllSelect(e.target.checked)}></Checkbox>
                        <a onClick={onDeleteClick}><FontAwesomeIcon icon={ faTrash } /> {t("group-data.label-modify-header-delete")}</a>
                      </th>
                    </tr>
                  </thead>
                )
              }
              <TableHeadCustom>
                <th key={-1}
                  ref={(element) => getStyleImportant(element, "text-align", "center")}
                  className={styles['table-fields-phone-header']}
                >
                  <Checkbox checked={showModifyHead} onChange={onChengeShowModify}></Checkbox>
                </th>
                <th key={0} className={styles['table-fields-phone-header']}>
                  {t("group-data.table-header-label-phone")}
                </th>
                {
                  dataFiltered && (dataFiltered[0].wildCards.map((item, index) =>
                    <th key={index}>
                      {item.key}
                    </th>
                  ))
                }
              </TableHeadCustom>
              <TableBodyCustom>
                {
                  dataFiltered.map(item =>
                    <TableBodyTrCustom key={item.id}>
                      <td
                        ref={(element) => getStyleImportant(element, "text-align", "center")}
                      >
                        {
                          showModifyHead && (
                            <TableBodyTdCheck
                              id={item.id}
                              onChangeCheck={onChangeSelect}
                              isCheked={item.isCheked}
                            />
                          )
                        }
                      </td>
                      <td>
                        {item.mobileNumber}
                      </td>
                      {
                        item.wildCards.map((item, index) => (
                          <td key={index}>
                            {item.value}
                          </td>
                        ))
                      }
                    </TableBodyTrCustom>
                  )
                }
              </TableBodyCustom>
            </TableCustom>
          )
        }
        {
          group && dataFiltered.length > 0 && (
            <div className={styles['btn-contact-container']}>
              <Button 
                type="primary" 
                dataName="btn-create" 
                onClick={() => showDrawer(true)}
                style={{ padding: '5px 13px' }}
              >
              {t("group-data.create-contact.title-button-contact")}
              </Button>
            </div>
          )
        }
        <ContactForm
          visible={showCreateContact}
          onClose={() => showDrawer(false)}
          onCreate={onCreateContact}
          labels={data[0]}
        />
      </div>
      <ModalConfirmation
        visible={modalContactsVisible}
        title={t("group-data.modal-delete-contacts-title")}
        onCancel={() => setModalContacsVisible(false)}
        onOk={handleConfirmModal}
        okBtnText={t("group-data.modal-delete-contacts-btn-ok")}
      >
        <p>{modalMessage}</p>
      </ModalConfirmation>
    </div>
   );
}
 
export default GroupData;