import { useState } from "react";
import { Row, Col, Form, InputNumber, DatePicker, TimePicker, Card, Button } from 'antd';
import propTypes from 'prop-types'
import moment from 'moment';
import styles from './PhaseSchedule.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { notificationError } from '../../../../../utils/helpers';
import { useTranslation } from "react-i18next";

const PhaseSchedule = ({id, lastDate, onChangeSchedule, onAddPhase}) => {

  const [t] = useTranslation("campaign-bulk");

  const [ percentage, setPercentage ] = useState(0);
  const [ date, setDate ] = useState(null);
  const [ dateValue, setDateValue ] = useState(null);
  const [ hour, setHour ] = useState(null);
  const [ hourValue, setHourValue ] = useState(null);

  const onChangePercentage = value => {
    setPercentage(value);

    var schedule = date && hour ? new Date(`${date} ${hour}`) : null;

    if(onChangeSchedule) {
      onChangeSchedule({
        id,
        date: schedule,
        percentage: value
      })
    }
  }

  const onChangeDate = (date, dateString) => {

    setDateValue(date);

    if(dateString === "") {
      setDate(null);
      if(onChangeSchedule) {
        onChangeSchedule({
          id,
          date: null,
          percentage
        });
      }
      return;
    }

    var schedule = hour ? new Date(`${dateString} ${hour}`) : null;

    setDate(dateString);
    if(onChangeSchedule) {
      onChangeSchedule({
        id,
        date: schedule,
        percentage
      });
    }
  }

  const onChangeHour = (dateHour, hourString) => {

    if (!isValidTime(hourString)) {
      setHourValue(null);
      setHour(null);
      if(onChangeSchedule) {
        onChangeSchedule({
          id,
          date: null,
          percentage
        });
      }
      return;
    }
    
    if(hourString === "") {
      setHour(null);
      if(onChangeSchedule) {
        onChangeSchedule({
          id,
          date: null,
          percentage
        });
      }
      return;
    }

    setHourValue(dateHour);
    var schedule = date ? new Date(`${date} ${hourString}`) : null;

    setHour(hourString);

    if(onChangeSchedule) {
      onChangeSchedule({
        id,
        date: schedule,
        percentage
      });
    }
  }

  const isValidTime = (hourString) => {
    const [selectedHour, selectedMinute] = hourString.split(':').map(Number);
    const currentDate = lastDate ? lastDate : new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();

    if (dateValue) {
      const selectedDate = dateValue.toDate();
      if (selectedDate.getDate() === currentDate.getDate()) {
        if (selectedHour < currentHour || (selectedHour === currentHour && selectedMinute <= currentMinute)) {
          return false;
        }
      }
    }

    return true;
  };

  const disabledDate = current => {
    if (lastDate) {
      return current && current < moment(lastDate).startOf('day');
    } else {
      return current && current < moment().startOf('day');
    }
  }

  const disabledHours = () => {
    let currentDate = lastDate ? lastDate : new Date();
    let day = dateValue?.toDate().getDate();
    let currentDay = currentDate.getDate();
    let hour = currentDate.getHours();
    return day === currentDay ? range(0, hour) : range(0, 0);
  }

  const disabledMinutes = (hour) => {
    let currentDate = lastDate ? lastDate : new Date();
    let day = dateValue?.toDate().getDate();
    let currentDay = currentDate.getDate();
    let currentHour = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    return hour === currentHour && day === currentDay ? range(0, minutes + 1) : range(0, 0);
  }

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const handleAddPhase = () => {
    if(onAddPhase) {
      if (percentage === 0) {
        notificationError("The percentage can not be zero")
      } else if(!date || !hour) {
        notificationError("the date and hour can not null");
      } else {
        if(lastDate) {
          var newDate = new Date(`${date} ${hour}`);
          if(newDate > lastDate) {
            onAddPhase();
          } else {
            notificationError("The date must be greater than the previous Schedulle");
            setDate(null);
            setDateValue(null);
            setHour(null);
            setHourValue(null);
          }          
        } else {
          onAddPhase();
        }
      }
    }
  }

  return (
    <Card className={styles['schedule-card']}>
      <Row gutter={16}>
        <Col span={3}>
          <p className={styles['name']}>{t("schedule.label-phase")} {id}</p>
        </Col>
        <Col span={6}>
          <Form
            layout="horizontal"
          >
            <Form.Item label={t("schedule.label-percentage")}>
              <Row>
                <Col span={24}>
                  <InputNumber
                    style={{width:"100%"}}
                    defaultValue={0}
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                    value={percentage}
                    onChange={onChangePercentage}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
        <Col span={7}>
          <Form
            layout="horizontal"
          >
            <Form.Item label={t("schedule.label-date")}>
              <Row>
                <Col span={24}>
                  <DatePicker
                    placeholder={t("schedule.placeholder-date")}
                    value={dateValue}
                    style={{width:"100%"}}
                    disabledDate={disabledDate}
                    onChange={onChangeDate}
                    disabled={!percentage}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
        <Col span={6}>
          <Form
            layout="horizontal"
          >
            <Form.Item label={t("schedule.label-hour")}>
              <Row>
                <Col span={24}>
                  <TimePicker
                    placeholder={t("schedule.placeholder-hour")}
                    style={{width:"100%"}}
                    format="HH:mm"
                    disabledHours={disabledHours}
                    disabledMinutes={disabledMinutes}
                    value={hourValue}
                    onChange={onChangeHour}
                    disabled={!date}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
        <Col span={2} className={styles['addPhase']}>
          <Button
            className="yp-button-optional"
            shape="circle"
            icon={<FontAwesomeIcon style={{marginTop:"1px"}} icon={ faPlus } />}
            onClick={handleAddPhase}
          >
          </Button>
        </Col>
      </Row>
    </Card>
  );
}


PhaseSchedule.propTypes = {
  id: propTypes.number.isRequired,
  lastDate: propTypes.any,
  onAddPhase: propTypes.func,
  onChangeSchedule: propTypes.func
}

export default PhaseSchedule;