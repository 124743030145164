import { useState, useEffect } from "react";
import useHeaderOptions from "../../../Hooks/useHeaderOptions";
import TYPES from "../../../reducers/types";
import CONST from "../../../utils/const";
import RatesGrid from "../RatesGrid/RatesGrid"
import CONSTANTS from "../../../utils/const";

const Rates = ({data, channelId, onChangeChannel}) => {

  const { headerOptionsDispacth, headerOptionsState } = useHeaderOptions();

  const [rates, setRates] = useState([]);
  const [filterRates, setFilterRates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(CONSTANTS.PAGINATION);

  useEffect(() => {
    if(data.length > 0) {
      setRates(data);
      setFilterRates(data);

      headerOptionsDispacth({
        type: TYPES.HEADER_OPTIONS.SELECT_COMPANY_RATES_CHANGES_OPTION,
        payload: TYPES.HEADER_OPTIONS.SELECT_COMPANY_RATES_CHANGES_OPTION
      })

      let countries = [...new Set(data.map(x => x.country))];
      countries.unshift(CONST.DEFAULTVALUES.ALLCOUNTRIES);

      headerOptionsDispacth({
        type: TYPES.HEADER_OPTIONS.SELECT_COMPANY_RATES_CHANGES_MODEL,
        payload: { countries: countries, network: "", selectedCountry: "", searchNetwork: "" }
      })
    }
  }
  , [data]);

  useEffect(() => {
    if(headerOptionsState.model) {
      let ratesList = rates;
      let selectedCountry = headerOptionsState.model.selectedCountry; 
      let searchNetwork = headerOptionsState.model.searchNetwork;

      if(selectedCountry && selectedCountry !== "") {
        if(selectedCountry === CONST.DEFAULTVALUES.ALLCOUNTRIES) {
          ratesList = rates;
        } else {
          ratesList = ratesList.filter(x => x.country === selectedCountry);
        }
      }

      if(searchNetwork && searchNetwork !== "") {   
        ratesList = ratesList.filter(x => x.network.toLowerCase().includes(searchNetwork.toLowerCase()));
      }

      setFilterRates(ratesList);
      setCurrentPage(1);
    }
  }, [headerOptionsState])

  const index = (currentPage - 1) * itemsPerPage;
  const currentRates = filterRates.slice(index, index + itemsPerPage);

  const onChangePagination = (page) => {
    setCurrentPage(page);
  }

  return (
    <RatesGrid 
      rates={currentRates} 
      currentPage={currentPage} 
      itemsPerPage={itemsPerPage}
      totalPages={filterRates.length}
      channelId={channelId}
      onChangePagination={onChangePagination}
      onChangeChannel={onChangeChannel}
    />
  );
}
 
export default Rates;