import { Drawer, Form, Input, Popconfirm, Select } from "antd";
import { useTranslation } from "react-i18next";
import { getGradientColor, orangeColor } from "../../../utils/colorsCss";
import Button from "../../Button/Button";

const { Option } = Select;

const SmsApiKeyForm = ({accounts, visible, onClose, onCreate}) => {

  const [form] = Form.useForm();
  const [t] = useTranslation("sms-api-key");

  return (
    <Drawer
      visible={visible}
      title={t("create-api-key.title")}
      headerStyle={{background: getGradientColor(orangeColor)}}
      width={420}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      footer={
        <div style={{ textAlign: 'center' }}>
          <Button type="secondary" dataName="btnCancel" 
            onClick={() => {
              form.resetFields();
              onClose();
            }}
            style={{ marginRight: 50 }}
          >
            {t("create-api-key.button-cancel")}
          </Button>
          <Popconfirm title={t("create-api-key.title-confirm")}
              okButtonProps={
                {
                  "data-testid": "okButtonConfirm" //Important to do test
                }
              }
              onConfirm={() => {
                form
                .validateFields()
                .then(values => {
                    onCreate(values);
                    form.resetFields();
                })
                .catch((info) => {
                });
              }}
              okText="yes"
              cancelText="no"
          >
          <Button
            type="primary"
            dataName="btnSave">
            {t("create-api-key.button-submit")}
          </Button>
          </Popconfirm>
        </div>
      }
    >
      <Form
        data-testid="drawer-content"
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
            apikeyName: ""
        }}
      >
        <Form.Item
          name="apikeyName"
          label={t("create-api-key.label-api-key-name")}
          rules={[
              {
                  required: true,
                  message: t("create-api-key.validation-api-key-name"),
              }
          ]}
        >
          <Input 
            placeholder={t("create-api-key.placeholder-api-key-name")}
          />
        </Form.Item>
        <Form.Item
          name="account"
          label={t("create-api-key.label-account")}
          rules={[
            {
              required: true,
              message: t("create-api-key.validation-account"),
            }
          ]}
        >
          <Select
            placeholder={t("create-api-key.placeholder-account")}
          >
            {
              accounts && accounts.map(item =>
                <Option key={item.accountId} value={item.accountId}>{item.name}</Option>
              )
            }
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
 
export default SmsApiKeyForm;