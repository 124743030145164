import  { useState, useEffect } from 'react'
import { Card } from 'antd';
import styles from "./CardHorizontal.module.css";

const CardHorizontal = ({children, borderColor}) => {

  const [borderStyle, setBorderStyle] = useState('');

  useEffect(() => {
    if(borderColor) {
      setBorderStyle(`10px solid ${borderColor}`);
    }
  }, [borderColor]);

  return (
    <Card
      className={styles['margin-bottom']}
      style={{'borderLeft': borderStyle }}
    >
      {children}
    </Card>
  );}

export default CardHorizontal;