import React, { useReducer } from 'react';
import ratesAccountReducer, { initialRatesAccountState } from '../reducers/ratesAccountReducer';

export const RatesAccountContext = React.createContext();

const RatesAccountProvider = ({children})=> {

  const [ratesAccountState, ratesAccountDispacth] = useReducer(ratesAccountReducer, initialRatesAccountState);

  return (
    <RatesAccountContext.Provider value={{ratesAccountState, ratesAccountDispacth}}>
      {children}
    </RatesAccountContext.Provider>
  )
}

export default RatesAccountProvider