import { Input, Pagination } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CONSTANTS, { CHANELLIST } from "../../../utils/const";
import styles from "./CampaignList.module.css";
import useHeaderOptions from "../../../Hooks/useHeaderOptions";
import TableCardCustom from "../../TableCardCustom/TableCardCustom"
import TableCardHeadCustom from "../../TableCardCustom/TableCardHeadCustom/TableCardHeadCustom"
import TableCardBodyCustom from "../../TableCardCustom/TableCardBodyCustom/TableCardBodyCustom";
import TableCardBodyTrCustom from "../../TableCardCustom/TableCardBodyTrCustom/TableCardBodyTrCustom";
import TableCardButtons from "../../TableCardCustom/TableCardButtons/TableCardButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileChartLine, faPlay } from "@fortawesome/pro-solid-svg-icons";
import { dateFormat } from "../../../utils/helpers";
import { v4 as uuidv4 } from "uuid";

const CampaignList = ({campaigns, onDetailsClick, onGenerateReport}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(CONSTANTS.PAGINATION);
  const [totalPage, setTotalPage] = useState(0);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [chanelSelected, setChanelSelected] = useState(null);
  const [search, setSearch] = useState('');
  const [campaignSelected, setCampaignSelected] = useState(null);

  const [t] = useTranslation("campaign-history");

  const { headerOptionsState } = useHeaderOptions();

  const MAX_CHARACTERS = 30;

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  }; 

  useEffect(() => {
    setTotalPage(campaigns.length);
    setDataFilteredByPage(campaigns, 1);
  }, [campaigns]);

  useEffect(() => {
    if(headerOptionsState.model.chanelId) {
      setChanelSelected(headerOptionsState.model.chanelId);
      let result = filter(search, headerOptionsState.model.chanelId);
      setDataFilteredByPage(result, 1)
      setTotalPage(result.length);
    }
  }, [headerOptionsState]);

  const setDataFilteredByPage = (items, page) => {
    const index = (page - 1) * itemsPerPage;
    const currentData = items.slice(index, index + itemsPerPage);
    setDataFiltered(currentData);
  }

  const onChangePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    setDataFilteredByPage(campaigns, pageNumber);
  }

  const onSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
    let result = filter(value, chanelSelected);
    setDataFiltered(result);
    setTotalPage(result.length);
  }

  const filter = (name, chanel) => {
    let result = campaigns;

    if(name !== '') {
      result = campaigns.filter(x => x.campaignName.toLowerCase().includes(name.toLowerCase()));
    }

    if(chanel && chanel !== CHANELLIST.DEFAULT.key) {
      result = result.filter(x => x.channelId ===  chanel);
    }

    return result;
  }

  const onHandleDetails = async (value) => {
    setCampaignSelected(value.campaignId);
    onDetailsClick(value);
  }

  const isCampaignSelected = (campaignId) => {
    return campaignSelected === campaignId;
  }

  const getStatusClass = (status) => {
    switch (status) {
      case 'Completed':
        return styles['status-completed'];
      case 'Ongoing':
        return styles['status-ongoing'];
      case 'Scheduled':
        return styles['status-schedule'];
      case 'Canceled':
        return styles['status-canceled'];
      case 'Closing':
        return styles['status-ongoing'];
      default:
        return '';
    }
  };

  return (
    <div className={styles['content']}>
      <div className={styles['content-title']}>
        <div className={styles['content-formTitle']}>
          <p>{t("campaign-list.label-title")}</p>
          <div>
            <Input placeholder={t("campaign-list.input-placeholder-search")} onChange={onSearch} style={{width:300}}/>
          </div>
        </div>
        <Pagination
          defaultCurrent={currentPage}
          current={currentPage}
          onChange={(pageNumber) => onChangePagination(pageNumber)}
          total={totalPage}
          pageSize={itemsPerPage}
          showSizeChanger={false}
        />
      </div>
      <div className={styles['content-grid']}>
        {
          dataFiltered.length > 0 && (
            <TableCardCustom>
              <TableCardHeadCustom>
                <th>
                </th>
                <th>{t("campaign-list.label-name")}</th>
                <th>{t("campaign-list.label-chanel")}</th>
                <th>{t("campaign-list.label-creation-date")}</th>
                <th>{t("campaign-list.label-status")}</th>     
              </TableCardHeadCustom>
              <TableCardBodyCustom>
              {
                dataFiltered.map((item, index) => (
                  <TableCardBodyTrCustom key={item.campaignId} isSelected={isCampaignSelected(item.campaignId)}>
                    <td>
                      <TableCardButtons>
                        <a onClick={() => onHandleDetails(item)}><FontAwesomeIcon icon={ faEye } id={`eye-icon-${index}`}/></a>
                        <a onClick={() => onGenerateReport(item)}><FontAwesomeIcon icon={ faFileChartLine } id={`file-icon-${index}`}/></a>
                      </TableCardButtons>
                    </td>
                    <td className={styles['name-campaign-list-body']}>
                      {truncateText(item.campaignName, MAX_CHARACTERS)}                   
                    </td>
                    <td>
                      {item.channel}
                    </td>
                    <td>
                      {dateFormat(item.creationDate, true, 'UTC')}
                    </td>
                    <td>
                      <div className={getStatusClass(item.status)}>
                        {item.status}
                      </div>
                    </td>
                  </TableCardBodyTrCustom>            
              ))}
            </TableCardBodyCustom>
            </TableCardCustom>
          )
        }
      </div>   
    </div>
  );
}
 
export default CampaignList;