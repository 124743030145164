import React, { useReducer } from 'react';
import breadcrumbReducer, { initialBreadcrumbState } from "../reducers/breadcrumbReducer";

export const SteperContext = React.createContext();

const BreadcrumbProvider = ({children})=> {

  const [ breadcrumbState, breadcrumbDispacth] = useReducer(breadcrumbReducer, initialBreadcrumbState);

  return (
    <SteperContext.Provider value={{breadcrumbState, breadcrumbDispacth}}>
      {children}
    </SteperContext.Provider>
  )
}

export default BreadcrumbProvider