import { useState } from "react";
import { CHANELLIST } from "../../utils/const";
import styles from "./ChannelSelect.module.css";
import { useTranslation } from "react-i18next";

const ChannelSelect = ({onSelect}) => {

  const [t] = useTranslation("dashboard-channel-select");
  const [channelSelected, setChannelSelected] = useState(CHANELLIST.DEFAULT.key);

  const getStyleSelected = (channel) => {
    return channel === channelSelected ? styles['channel-select-container-selected'] : '';
  }

  const onSelectChannel = (channel) => {
    setChannelSelected(channel.key);
    if(onSelect) {
      onSelect(channel);
    }
  }

  return (
    <div className={styles['channel-select-container']}>
      <div onClick={() => onSelectChannel(CHANELLIST.DEFAULT)} className={getStyleSelected(CHANELLIST.DEFAULT.key)}>{t("options.all")}</div>
      <div onClick={() => onSelectChannel(CHANELLIST.VOICE)} className={getStyleSelected(CHANELLIST.VOICE.key)}>{t("options.voice")}</div>
      <div onClick={() => onSelectChannel(CHANELLIST.SMS)} className={getStyleSelected(CHANELLIST.SMS.key)}>{t("options.sms")}</div>
      <div className={styles['channel-select-container-disabled']}>{t("options.rcs")}</div>
      <div className={styles['channel-select-container-disabled']}>{t("options.whatsapp")}</div>
    </div>
  );
}
 
export default ChannelSelect;