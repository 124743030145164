import React, { useEffect, useState } from 'react';
import { Drawer, Input, Form, Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { orangeColor, getGradientColor } from "../../../utils/colorsCss";
import styles from "./contactform.module.css";

const ContactForm = ({visible, onClose, onCreate, labels}) => {
    
    const [t] = useTranslation("contacts-history");
    const [form] = Form.useForm();
    const [formLabels, setFormLabels] = useState([]);
    
    useEffect(() => {
       if (labels) {
           const fields = labels.wildCards.map(wildCard => ({
              key: wildCard.key,
              name: wildCard.key,
              required: true,
              message: `This field is required`
           }));

           setFormLabels(fields);
        } else {
           console.warn("Labels o id de labels no está disponible");
        }
    }, [labels]);

    const handleConfirm = () => {
        form
            .validateFields()
            .then(values => {
                const wildCards = formLabels.map(label => ({
                    Key: label.key,
                    Value: values[label.name]
                }));
                const payload = {
                    Phone: values.Phone,
                    WildCards: wildCards
                };
                onCreate(payload);
                form.resetFields();
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <Drawer
            visible={visible}
            title={t("group-data.create-contact.title")}
            headerStyle={{background: getGradientColor(orangeColor)}}
            width={480}
            onClose={() => {
                form.resetFields();
                onClose();
            }}
            footer={
                <div style={{ textAlign: 'center', }} >
                    <Button onClick={() => {
                        form.resetFields();
                        onClose();
                        }}
                        style={{ marginRight: 50 }}>
                        {t("group-data.create-contact.button-cancel")}
                    </Button>
                    <Popconfirm title={t("group-data.create-contact.button-submit-confirm")}
                      okButtonProps= {{ "data-testid": "okButtonConfirm" }}
                        onConfirm={handleConfirm}
                        okText="yes"
                        cancelText="no"
                    >
                        <Button type="primary" dataname="btnSave">
                            {t("group-data.create-contact.button-submit")}
                        </Button>
                    </Popconfirm>
                </div >
            }
        >
            <Form
                data-testid="drawer-content"
                form={form}
                layout="vertical"
                name="form_in_modal"
                className={styles['custom-form']}
            >
                <Form.Item
                    name="Phone"
                    label={t("group-data.table-header-label-phone")}
                    rules={[
                        {
                            required: true,
                            message: 'Please input the Phone!',
                        }
                    ]}
                >
                <Input />
                </Form.Item>
                {
                formLabels.map((label) => 
                    <Form.Item
                        key={label.key}
                        name={label.name}
                        label={label.key}
                        rules={[
                            {
                                required: label.required,
                                message: label.message,
                            }
                        ]}
                    >
                    <Input/>
                    </Form.Item>
                )}
            </Form>
        </Drawer>
    );
}

export default ContactForm;