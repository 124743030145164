import React from 'react';

export const CurrencyContext = React.createContext();

// TO DO: Traer la data de algun repositorio
const initialData = [{
  id: "922E3D0B-D2E2-4E97-840A-850B033B6E47",
  name: "USD"
}];

const CurrencyProvider = (props)=> {
  return (
    <CurrencyContext.Provider value={{currencies: initialData}}>
      {props.children}
    </CurrencyContext.Provider>
  )
}

export default CurrencyProvider