import styles from "./LeftCard.module.css";

const LeftCard = ({children}) => {
  return (
    <div className={styles['workspace-card-left']}>
      {children}
    </div>
  );
}
 
export default LeftCard;