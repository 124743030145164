import useAuth from "../Hooks/useAuth";
import { templateRequest } from "../utils/authConfig";

const TemplateApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    addContacts: async (formData) => {
      const accessToken = await passTokenToApi(templateRequest);

      if (accessToken) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken}`);
        var requestOptions = {
          method: 'POST',
          body: formData,
          headers: myHeaders,
        };

        const resource = "/api/Contacts";
        const url = `${process.env.REACT_APP_TEMPLATE_API_URL}${resource}`;

        let response = await fetch(url, requestOptions);
        let result = await response.json();

        if (response.status !== 200) {
          let errorMessage = "";
          if (result.message && !result.listErrors) {
            errorMessage = result.message;
          } else if (result.message && result.listErrors) {
            errorMessage = result.listErrors.join(', ');
          }
          // remove replace for completed message
          //errorMessage = errorMessage.replace(/ in line \d+/g, '');
          throw new Error(errorMessage);
        }

        return result;
      }
      else {
        console.log('Authentication Error');
        return [];
      }
    },
    AddContactToGroup: async (groupId, data, phone) =>{
      const accessToken = await passTokenToApi(templateRequest)
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          myHeaders.append("Content-Type", "application/json");
          var requestOptions = {
            method: 'PUT',
            body: data,
            headers: myHeaders,
          };

          const resource = `/api/Contact/Group/${groupId}`;
          const url = `${process.env.REACT_APP_TEMPLATE_API_URL}${resource}`;
          let response = await fetch(url, requestOptions);
          let result = await response.json();

          if (response.status !== 200) {
            if (result.message === "Invalid Prefix") {
              return { error: true, message: `Invalid Prefix for Number: ${phone}` };
            } else {
              return { error: true, message: result.message || result };
            }
          }

          return result;
        }
        else{
          console.log('Authentication Error');
          return [];
        }
        
      } catch (error) {
        console.error(error)
        throw new Error("An error ocurred add new contact.")
      }
    },
    getListGorups: async (companyId) => {
      const accessToken = await passTokenToApi(templateRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };

          const resource = `/api/Group/Company/${companyId}`;
          const url = `${process.env.REACT_APP_TEMPLATE_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred getting groups.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting groups.");
      }
    },
    getListGorupsByAccount: async (accountId) => {
      const accessToken = await passTokenToApi(templateRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };

          const resource = `/api/Group/Account/${accountId}`;
          const url = `${process.env.REACT_APP_TEMPLATE_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred getting groups.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting groups.");
      }
    },
    getContactsByGorup: async (groupId) => {
      const accessToken = await passTokenToApi(templateRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };

          const resource = `/api/Contact/${groupId}`;
          const url = `${process.env.REACT_APP_TEMPLATE_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred getting contacts.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting contacts.");
      }
    },
    deleteGroup: async (groupId) => {
      const accessToken = await passTokenToApi(templateRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
          };

          const resource = `/api/Group/${groupId}`;
          const url = `${process.env.REACT_APP_TEMPLATE_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred deleting group.");
          }

          let result = await response.text();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred deleting group.");
      }
    },
    deleteContacts: async (groupId, mobileNumbers) => {
      const accessToken = await passTokenToApi(templateRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'DELETE',
            body: JSON.stringify({MobileNumbers: mobileNumbers}),
            headers: myHeaders,
          };

          const resource = `/api/Contact/Group/${groupId}`;
          const url = `${process.env.REACT_APP_TEMPLATE_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred deleting contacts.");
          }

          let result = await response.text();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred deleting contacts.");
      }
    },
    addTemplates: async (formData) => {
      const accessToken = await passTokenToApi(templateRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'POST',
            body: formData,
            headers: myHeaders,
          };

          const resource = "/api/Template";
          const url = `${process.env.REACT_APP_TEMPLATE_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred adding template.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred adding template.");
      }
    },
    getTemplates: async (companyId) => {
      const accessToken = await passTokenToApi(templateRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };

          const resource = `/api/Template/Company/${companyId}`;
          const url = `${process.env.REACT_APP_TEMPLATE_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred getting templates.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting templates.");
      }
    },
    getTemplatesByChannel: async (companyId, channelId) => {
      const accessToken = await passTokenToApi(templateRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };

          const resource = `/api/Template/Company/${companyId}/Channel/${channelId}`;
          const url = `${process.env.REACT_APP_TEMPLATE_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred getting templates.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting templates.");
      }
    },
    deleteTemplate: async (templateId) => {
      const accessToken = await passTokenToApi(templateRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
          };

          const resource = `/api/Template/${templateId}`;
          const url = `${process.env.REACT_APP_TEMPLATE_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred deleting template.");
          }

          let result = await response.text();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred deleting template.");
      }
    }
  }
}

export default TemplateApi;