import { useState, useEffect } from "react";
import useHeaderOptions from "../../../Hooks/useHeaderOptions";
import useRatesAccount from "../../../Hooks/useRatesAccount";
import TYPES from "../../../reducers/types";
import CONST from "../../../utils/const";
import ConfirmRatesGrid from "../ConfirmRatesGrid/ConfirmRatesGrid";
import CONSTANTS from "../../../utils/const";

const ConfirmRates = ({data, onConfirmRates}) => {

  const { headerOptionsDispacth, headerOptionsState } = useHeaderOptions();
  const { ratesAccountState } = useRatesAccount();

  const [rates, setRates] = useState([]);
  const [ratesSummary, setRatesSummary] = useState([]);
  const [filterRatesSummary, setFilterRatesSummary] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(CONSTANTS.PAGINATION);

  useEffect(() => {
    if(data.length > 0) {

      let newData = data.map(r => {
        return {
          prefix: r.prefix,
          country: r.country,
          network: r.network,
          mccMnc: r.mccMnc,
          rate: r.rate,
          newRate: r.newRate,
          effectiveDate: new Date()
        }
      });

      setRates(newData);

      let result = data.map(r => {
        return {
          country: r.country,
          network: r.network,
          mccMnc: r.mccMnc,
          rate: r.rate,
          newRate: r.newRate,
          effectiveDate: new Date(),
          isIncrease: r.rate < r.newRate
        }
      })

      let countries = [...new Set(data.map(x => x.country))];
      let summary = [];

      countries.forEach(country => {
        let ratesByCountry = result.filter(r => r.country === country)        
        summary.push({
          country: country,
          increase: ratesByCountry.filter(r => r.isIncrease).length,
          decrease: ratesByCountry.filter(r => !r.isIncrease).length,
          changeDate: new Date()
        })
      })

      setRatesSummary(summary);
      setFilterRatesSummary(summary);

      headerOptionsDispacth({
        type: TYPES.HEADER_OPTIONS.CONFIRM_ADMIN_RATES_CHANGES_OPTION,
        payload: TYPES.HEADER_OPTIONS.CONFIRM_ADMIN_RATES_CHANGES_OPTION
      })

      countries.unshift(CONST.DEFAULTVALUES.ALLCOUNTRIES);
      headerOptionsDispacth({
        type: TYPES.HEADER_OPTIONS.CONFIRM_ADMIN_RATES_CHANGES_MODEL,
        payload: { countries: countries }
      })
    }
  }
  , [data]);

  useEffect(() => {
    if(headerOptionsState.model) {
      let summaryList = ratesSummary;
      let selectedCountry = headerOptionsState.model.selectedCountry; 

      if(selectedCountry && selectedCountry !== "") {
        if(selectedCountry === CONST.DEFAULTVALUES.ALLCOUNTRIES) {
          summaryList = ratesSummary;
        } else {
          summaryList = summaryList.filter(x => x.country === selectedCountry);
        }
      }

      setFilterRatesSummary(summaryList);
      setCurrentPage(1);
    }
  }, [headerOptionsState])

  useEffect(() => {
    if(ratesAccountState.step === CONST.RATES_PROCESS_STEP.DONE_RATES) {
      onConfirmRates(rates);
    }
  }, [ratesAccountState])

  const onChangePagination = (page) => {
    setCurrentPage(page);
  }

  const index = (currentPage - 1) * itemsPerPage;
  const currentSummary = filterRatesSummary.slice(index, index + itemsPerPage);

  return (
    <ConfirmRatesGrid
      summary={currentSummary}
      currentPage={currentPage} 
      itemsPerPage={itemsPerPage}
      totalPages={filterRatesSummary.length}
      onChangePagination={onChangePagination}
    />
  );
}
 
export default ConfirmRates;