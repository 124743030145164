const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_signin",
        forgotPassword: "B2C_1_resetpassword",
        editProfile: "b2c_1_edit_profile"
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${process.env.REACT_APP_B2C_LOGIN}/${process.env.REACT_APP_B2C_TENANT}/B2C_1_signin`,
        },
        forgotPassword: {
            authority: `https://${process.env.REACT_APP_B2C_LOGIN}/${process.env.REACT_APP_B2C_TENANT}/B2C_1_resetpassword`,
        }
    },
    authorityDomain: `${process.env.REACT_APP_B2C_LOGIN}`
}

export { b2cPolicies }