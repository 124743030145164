import { Select } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useHeaderOptions from "../../../Hooks/useHeaderOptions";
import TYPES from "../../../reducers/types";
import CONSTANTS from "../../../utils/const";
import styles from "./HistoryBalanceHeaderOption.module.css";

const { Option } = Select;

const HistoryBalanceHeaderOption = () => {
 
  const [t] = useTranslation("history-balance");
  const [defaultSelected, setDefaultSelected] = useState(CONSTANTS.LIST.PERIOD[0].key)

  const { headerOptionsDispacth } = useHeaderOptions();

  const handlePeriodChange = (value) => {
    setDefaultSelected(value);
    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.HISTORY_BALANCE_MODEL,
      payload: { selectedDate: value }
    })
  };

  return (
    <div className={styles['filters']}>
      <p className={styles['label-filter']}>{t(`filter`)}</p>     
      <Select
        style={{ width: 200 }}
        placeholder="Year / Month / Week"
        onChange={handlePeriodChange}
        defaultValue={defaultSelected}
        className={styles['select-filter']}
      >
        {
          CONSTANTS.LIST.PERIOD.map(item =>
            <Option key={item.key} value={item.key}>{t(`filter-${item.value}`)}</Option>
          )
        }
      </Select>
    </div>
  );
}
 
export default HistoryBalanceHeaderOption;