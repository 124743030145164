import { Row, Col } from 'antd';
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { notificationWarning } from '../../../../utils/helpers';
import PhaseSchedule from './PhaseSchedule/PhaseSchedule';

const phaseInitialData = [{
  id: 1,
  percentage: 0,
  DateScheduled: null,
  lastDate: null
}];

const PhasesSchedule = ({onChangePhases}) => {

  const [t] = useTranslation("shortlink-campaign");

  const [ phasesData, setPhasesData ] = useState(phaseInitialData);
  const [ percentage, setPercentage ] = useState(0);

  const onAddPhase = () => {

    if(percentage < 100) {
      let size = phasesData.length;
      let lastId = phasesData[size - 1].id;

      const newPhase = {
        id: lastId + 1,
        percentage: null,
        DateScheduled: null,
        lastDate: null
      }
      let phases = [...phasesData, newPhase];
      setPhasesData(phases);
    } else {
      notificationWarning(t("schedule.notification-phase"));
    }
  }

  const onChangeSchedule = ({id, date, percentage}) => {
    let newData = phasesData.filter(x => x.id !== id);

    newData.push({
      id,
      percentage,
      DateScheduled: date,
      lastDate: newData.length === 0 ? null : newData[newData.length - 1].DateScheduled
    });

    newData = newData.sort((a, b) => {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      return 0;
    });

    let sumPercentage = 0;
    newData.forEach(x => {
      sumPercentage += x.percentage ? x.percentage : 0;
    });

    setPhasesData(newData);
    setPercentage(sumPercentage);

    if(onChangePhases) {
      onChangePhases(newData);
    }
  }

  return (
    <Row gutter={16} justify="center">
      <Col>
        {
          phasesData.map(phase => (
            <PhaseSchedule
              key={phase.id}
              id={phase.id}
              lastDate={phase.lastDate}
              onAddPhase={onAddPhase}
              onChangeSchedule={onChangeSchedule}
            />
          ))
        }
      </Col>
    </Row>
  );
}
 
export default PhasesSchedule;