import { useState } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./ChannelExpenses.module.css";
import { useEffect } from "react";
import { Spin } from "antd";
import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST } from "../../../../utils/const";
import { currencyFormat } from "../../../../utils/helpers";
import DataApi from "../../../../Api/DataApi";

const ChannelExpenses = ({filter}) => {

  const [t] = useTranslation("home-carrier");
  const {getExpensesChannel} = DataApi()

  const [loading, setLoading] = useState(true);
  const [totalSms, setTotalSms] = useState(0);
  const [totalVoice, setTotalVoice] = useState(0);
  const [totalShortlink, setTotalShortlink] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setLoading(true);

    getSmsStatusData()
    .then(response => {
      setLoading(false);

      const {totalSmsValue, totalVoiceValue, totalShortlinkValue, totalValue} = response;

      setTotalSms(totalSmsValue);
      setTotalVoice(totalVoiceValue);
      setTotalShortlink(totalShortlinkValue);
      setTotal(totalValue);
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    })
    
  }, [filter])

  const getSmsStatusData = async () => {

    let data = {
      CompanyId: filter.id === CONSTANTS.COMPANYDEFAULT.companyId ? null : filter.id,
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    }

    let response = await getExpensesChannel(filter.time, data);

    let totalSmsValue = response.find(x => x.channel.toUpperCase() === CHANELLIST.SMS.value.toUpperCase())?.expenses;
    let totalVoiceValue = response.find(x => x.channel.toUpperCase() === CHANELLIST.VOICE.value.toUpperCase())?.expenses;
    let totalShortlinkValue = response.find(x => x.channel.toUpperCase() === CHANELLIST.SHORTLINK.value.toUpperCase())?.expenses;
    
    totalSmsValue = totalSmsValue ? totalSmsValue : 0; 
    totalVoiceValue = totalVoiceValue ? totalVoiceValue : 0; 
    totalShortlinkValue = totalShortlinkValue ? totalShortlinkValue : 0; 

    let totalValue = totalSmsValue + totalVoiceValue + totalShortlinkValue;

    if (filter.channel.key === CHANELLIST.SMS.key) {
      totalValue = totalSmsValue +  totalShortlinkValue;
    }

    if (filter.channel.key === CHANELLIST.VOICE.key) {
      totalValue = totalVoiceValue;
    }
    
    return {totalSmsValue, totalVoiceValue, totalShortlinkValue, totalValue}; 
  }

  const render = () => {
    switch (filter.channel.key) {
      case CHANELLIST.DEFAULT.key:
        return <>
          <div className={styles['dashboard-card-body-metric']}>
            <p>{t("card-channel-expenses.label-sms")}</p>
            <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-sms']}`}>{currencyFormat(totalSms, 2)}</div>
          </div>
          <div className={styles['dashboard-card-body-metric']}>
            <p>{t("card-channel-expenses.label-voice")}</p>
            <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-voice']}`}>{currencyFormat(totalVoice, 2)}</div>
          </div>
          <div className={styles['dashboard-card-body-metric']}>
            <p>{t("card-channel-expenses.label-shortlink")}</p>
            <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-shortlink']}`}>{currencyFormat(totalShortlink, 2)}</div>
          </div>
        </>;
      case CHANELLIST.SMS.key:
        return <>
          <div className={styles['dashboard-card-body-metric']}>
            <p>{t("card-channel-expenses.label-sms")}</p>
            <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-sms']}`}>{currencyFormat(totalSms, 2)}</div>
          </div>
          <div className={styles['dashboard-card-body-metric']}>
            <p>{t("card-channel-expenses.label-shortlink")}</p>
            <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-shortlink']}`}>{currencyFormat(totalShortlink, 2)}</div>
          </div>
        </>;
      case CHANELLIST.VOICE.key:
        return <>
          <div className={styles['dashboard-card-body-metric']}>
            <p>{t("card-channel-expenses.label-voice")}</p>
            <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-voice']}`}>{currencyFormat(totalVoice, 2)}</div>
          </div>
        </>;   
      default:
        return <></>;
    }
  }

  return (
    <div className={styles['dashboard-body-card-single']}>
      <CardCustom
        style={{height: "100%", widt:"100%"}}
        title={
        <div className={styles['card-title']}>
          <p>{t("card-channel-expenses.label-title")}</p>
          <HelperButton
            message={t("card-channel-expenses.tooltip-title")}
            color={getGradientColor(purpleColor)}
          />
        </div>
        }
      >
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              {
                render()
              }
              <div style={{marginTop: "50px"}} className={styles['dashboard-card-body-metric']}>
                <p>{t("card-channel-expenses.label-total")}</p>
                <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-total']}`}>{currencyFormat(total, 2)}</div>
              </div>
            </div>
          </Spin>
        </div>
      </CardCustom>
    </div>
  );
}
 
export default ChannelExpenses;