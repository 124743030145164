import { useContext, useState } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./AccountConsumption.module.css";
import { useEffect } from "react";
import { Spin } from "antd";
import { Pie } from "@ant-design/charts";
import { getGradientColor, purpleColor, orangeColor, grayColor, violetColor, magentaColor } from "../../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import DataApi from "../../../../Api/DataApi";
import { AuthContext } from "../../../../contexts/authRedirect";
import CONSTANTS, { CONSTANPERIODREPORTLIST } from "../../../../utils/const";

const AccountConsumption = ({filter}) => {

  const [t] = useTranslation("home-account");
  const {getCostAccount} = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if(filter && filter.id) {
      setLoading(true);

      getTotalSmsData()
      .then(response => {
        setLoading(false);
  
        const {chartData} = response;
        setData(chartData);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      })
    }
  }, [filter])

  const getTotalSmsData = async () => {

    let data = {
      companyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      accountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    }

    let response = await getCostAccount(filter.time, data);
    let responseData = response.map(x => { return { type: x.accountName, value: x.expensesPrct }});


    return {chartData: responseData}; 
  }

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.6,
    legend: false,
    color: [purpleColor, orangeColor, violetColor, magentaColor, grayColor],
    label: {
      type: 'outer',
      content: '{name}\n{percentage}',
    },
    interactions: [
      {
        type: 'pie-legend-active',
      },
      {
        type: 'element-active',
      },
    ],
  };

  return (
    <div className={styles['dashboard-body-card-single']}>
      <CardCustom
        style={{height: "100%", widt:"100%"}}
        title={
        <div className={styles['card-title']}>
          <p>{t("card-account-consumption.label-title")}</p>
          <HelperButton
            message={t("card-account-consumption.tooltip-title")}
            color={getGradientColor(purpleColor)}
          />
        </div>
        }
      >
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              <Pie {...config} />
            </div>            
          </Spin>
        </div>
      </CardCustom>
    </div>
  );
}
 
export default AccountConsumption;