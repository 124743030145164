import { DatePicker, Select } from "antd";
import HelperButton from "../../HelperButton/HelperButton";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import styles from "./CompanyFilters.module.css";
import { useState } from "react";
import CONSTANTS, { CONSTANPERIODREPORTLIST } from "../../../utils/const";
import { useEffect } from "react";

const { Option } = Select;
const { RangePicker } = DatePicker;

const CompanyFilters = ({accounts, onChangeFilter}) => {

  const [t] = useTranslation("home-company");

  const [defaultSelected , setDefaultSelected ] = useState();
  const [time , setTime ] = useState(CONSTANPERIODREPORTLIST.CURRENTWEEK.key);
  const [isTimeCustom , setIsTimeCustom ] = useState(false);
  const [date , setDate ] = useState([]);

  useEffect(() => {
    if(accounts && accounts.length > 0) {
      setDefaultSelected(accounts[0].accountId);
      onChangeFilter({id: accounts[0].accountId, time: time, date: date}); 
    }    
  }, [accounts])

  const handlePeriod = (value) => {
    setTime(value);
    if(value === CONSTANPERIODREPORTLIST.CUSTOM.key) {
      setIsTimeCustom(true);
    } else { 
      setDate([])
      setIsTimeCustom(false);

      if (onChangeFilter) {
        onChangeFilter({id: defaultSelected, time: value, date: date});
      }
    }
  }

  const handleDate = (value) => {
    setDate(value);
    if (onChangeFilter) {
      onChangeFilter({id: defaultSelected, time: time, date: value});
    }
  }

  const handleAccount = (value) => {
    setDefaultSelected(value);
    if (onChangeFilter) {
      onChangeFilter({id: value, time: time, date: date}); 
    }
  }
  
  return (
    <div className={styles['dashboard-menu-filters']}>
      <div className={styles['dashboard-menu-filters-form']}>
        {
          defaultSelected ? (
            <>
              <p>{t("dashboard-menu-filters.label-accounts")}</p>
              <div>
                <Select
                  size="small"
                  style={{width: "250px"}}
                  placeholder="Please select an account"
                  defaultValue={defaultSelected}
                  onChange={handleAccount}
                >
                  {
                    accounts.map(item =>
                      <Option key={item.accountId} value={item.accountId}>{item.name}</Option>
                    )
                  }
                </Select>
                <HelperButton
                  message={t("dashboard-menu-filters.tootltip-accounts")}
                  color={getGradientColor(purpleColor)}
                />
              </div>
            </>
          ) : <></>                   
        }
      </div>
      <div className={styles['dashboard-menu-filters-form']}>
        <p>{t("dashboard-menu-filters.label-time")}</p>
        <div>
          <Select
            size="small"
            onChange={handlePeriod}
            defaultValue={time}
            style={{width: "250px"}}
          >
            {
              CONSTANTS.LIST.PERIODREPORT.map(item =>
                <Option key={item.key} value={item.key}>{item.value}</Option>
              )
            }
          </Select>
          <HelperButton
            message={t("dashboard-menu-filters.tootltip-time")}
            color={getGradientColor(purpleColor)}
          />
        </div>
      </div>
      {
        isTimeCustom && (
          <div className={styles['dashboard-menu-filters-form']}>
            <div>
              <RangePicker
                size="small"
                disabled={!isTimeCustom}
                onChange={handleDate}
                value={date}
                format="MM/DD/YYYY"/>
            </div>
          </div>
        )
      }
      
    </div>
  );
}
 
export default CompanyFilters;