import useAuth from "../Hooks/useAuth";
import { whatsappRequest, campaignManagerRequest } from "../utils/authConfig";

const WhatsappApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    getWabaAccounts: async (companyId) => {
      const accessToken = await passTokenToApi(whatsappRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };
  
        const resource = `/api/WABA/Account/Company/${companyId}`;
        const url = `${process.env.REACT_APP_WHATSAPP_API_URL}${resource}`;
  
        let response = await fetch(url, requestOptions);
  
        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred get WhatsApp accounts by company id.");
        }
  
        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred get WhatsApp accounts by company id.");
      }
    },
    getWabaAccountsByYPAccount: async (ypAccountId) => {
      const accessToken = await passTokenToApi(whatsappRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };

        const resource = `/api/WABA/Account/${ypAccountId}`;
        const url = `${process.env.REACT_APP_WHATSAPP_API_URL}${resource}`;
  
        let response = await fetch(url, requestOptions);
  
        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred get WhatsApp accounts by Yellow Push account.");
        }
  
        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred get WhatsApp accounts by Yellow Push account.");
      }
    },
    getAccounts: async (companyId) => {
      const accessToken = await passTokenToApi(whatsappRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };
  
        const resource = `/api/waba/company/${companyId}`;
        const url = `${process.env.REACT_APP_WHATSAPP_API_URL}${resource}`;
  
        let response = await fetch(url, requestOptions);
  
        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred get WhatsApp accounts by company id.");
        }
  
        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred get WhatsApp accounts by company id.");
      }
    },
    getWabaDetails: async (token) => {
      const accessToken = await passTokenToApi(whatsappRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: 'POST',
          headers: myHeaders
        };

        const resource = `/api/WABAToken/${token}`;
        const url = `${process.env.REACT_APP_WHATSAPP_API_URL}${resource}`;

        let response = await fetch(url, requestOptions);

        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred getting the Meta account details.");
        }

        let result = await response.text();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the Meta account details.");
      }
    },
    createUpdateWaba: async (data, type) => {
      const accessToken = await passTokenToApi(whatsappRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: type,
          headers: myHeaders,
          body: JSON.stringify(data)
        };

        const resource = `/api/WABA`;
        const url = `${process.env.REACT_APP_WHATSAPP_API_URL}${resource}`;
        
        let response = await fetch(url, requestOptions);

        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred saving the account details.");
        }

        let result = await response.text();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred saving the account details.");
      }
    },
    getTemplatesByWabaId: async (companyId, wabaId) => {
      const accessToken = await passTokenToApi(whatsappRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };
  
        const resource = wabaId ? `/api/WABATemplates/Company/${companyId}/WABA/${wabaId}` : `/api/WABATemplates/Company/${companyId}`;
        const url = `${process.env.REACT_APP_WHATSAPP_API_URL}${resource}`;
  
      
        let response = await fetch(url, requestOptions);
  
        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred getting templates by WhatsApp account id.");
        }
  
        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting templates by WhatsApp account id.");
      }
    },
    getCountSentMessagesByWabaPhoneId: async (wabaPhoneId) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };
  
        const resource = `/api/Campaign/WABA/Limit/${wabaPhoneId}`;
        const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;
  
        let response = await fetch(url, requestOptions);
  
        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred getting the sent messages count.");
        }
  
        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the sent messages count.");
      }
    },
    getApprovedTemplatesByWabaId: async (wabaId) => {
      const accessToken = await passTokenToApi(whatsappRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };
  
        const resource = `/api/WABATemplates/Approved/${wabaId}`;
        const url = `${process.env.REACT_APP_WHATSAPP_API_URL}${resource}`;
  
      
        let response = await fetch(url, requestOptions);
  
        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred getting templates by WhatsApp account id.");
        }
  
        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting templates by WhatsApp account id.");
      }
    },
    getTemplateById: async (templateId) => {
      const accessToken = await passTokenToApi(whatsappRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };
  
        const resource = `/api/WABATemplate/${templateId}`;
        const url = `${process.env.REACT_APP_WHATSAPP_API_URL}${resource}`;
  
      
        let response = await fetch(url, requestOptions);
  
        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred getting the template.");
        }
  
        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the template.");
      }
    },
    deleteWabaTemplate: async (wabaId) => {
      const accessToken = await passTokenToApi(whatsappRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: 'DELETE',
          headers: myHeaders,
        };

        const resource = `/api/WABATemplate/${wabaId}`;
        const url = `${process.env.REACT_APP_WHATSAPP_API_URL}${resource}`;

        let response = await fetch(url, requestOptions);

        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred deleting the WhatsApp template.");
        }

        let result = await response.text();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred deleting the WhatsApp template.");
      }
    },
    createUpdateTemplate: async (data, type) => {
      const accessToken = await passTokenToApi(whatsappRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: type,
          headers: myHeaders,
          body: JSON.stringify(data)
        };

        const resource = `/api/WABATemplate`;
        const url = `${process.env.REACT_APP_WHATSAPP_API_URL}${resource}`;
        
        let response = await fetch(url, requestOptions);

        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred saving the template.");
        }

        let result = await response.text();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred saving the template.");
      }
    },
    getMessageCost: async (data) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(data)
        };

        const resource = `/api/Campaing/WhatsappMessageCost`;
        const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;
        
        let response = await fetch(url, requestOptions);

        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred getting the message cost.");
        }

        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred getting the message cost.");
      }
    },
    sendWhatsAppMessage: async (data) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(data)
        };

        const resource = `/api/Campaing/SendIndividualWhatsappMessage`;
        const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;
        
        let response = await fetch(url, requestOptions);

        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred sending the message.");
        }

        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred sending the message.");
      }
    },
    createCampaignWithFile: async (formData) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formData
        };

        const resource = `/api/Campaing/CreateCampaignWithFile`;
        const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;
        
        let response = await fetch(url, requestOptions);

        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred creating the campaign.");
        }

        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred creating the campaign.");
      }
    },
    createCampaignWithContacts: async (formData) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if(accessToken)
        {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formData
        };

        const resource = `/api/Campaing/CreateCampaignWithContacts`;
        const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;
        
        let response = await fetch(url, requestOptions);

        if (response.status !== 200) {
          const res = await response.json()
          throw new Error(res?.message || "An error occurred creating the campaign.");
        }

        let result = await response.json();
        return result;
      }else{
        console.log('Authentication Error');
        return [];
      }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred creating the campaign.");
      }
    },
    getDataFileByPrefix: async (campaignId, prefix) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };

          const resource = `/api/campaign/${campaignId}/datafile/${prefix}`;
          const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred get data file by prefix.");
          }

          let result = await response.json();
          return result;
       }
        else {
          console.log('Authentication Error');
          return null;
        } 
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred get data file by prefix.");
      }
    },
    sendCampaign: async (campaign) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(campaign)
          };

          const resource = "/api/Campaing/SendWABACampaign";
          const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred sending the campaign.");
          }
  
          let result = await response.json();
          return result;
        }else{
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred sending the campaign.");
      }
    },
    deleteCampaign: async (campaignId) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
          };

          const resource = `/api/Campaign/Delete/${campaignId}`;
          const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            const res = await response.json()
            throw new Error(res?.message || "An error occurred deleting the campaign.");
          }
  
          let result = await response.json();
          return result;
         }
        else {
          console.log('Authentication Error');
          return null;
        } 
      } catch (error) {
        console.error(error);
        throw new Error(error || "An error occurred deleting campaign.");
      }
    },
    
    schedule: async (campaignId, data) => {
      const accessToken = await passTokenToApi(campaignManagerRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data)
          };

          const resource = `/api/campaign/${campaignId}/scheduled`;
          const url = `${process.env.REACT_APP_CAMPAIGN_MANAGER_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred generating the schedule");
          }

          let result = await response.text();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred generating the schedule");
      }
    },
  }
}

export default WhatsappApi;