import { Alert, Button, Form, Steps } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./AgentDetailsStepper.module.css";
import TYPES from '../../../reducers/types'
import useSpinner from '../../../Hooks/useSpinner';
import useAuth from '../../../Hooks/useAuth';
import CONSTANTS from '../../../utils/const';
import { cleanErrorMessage, notificationError, notificationOk, validateFields } from "../../../utils/helpers";
import ModalConfirmation from "../../../components/ModalConfirmation/ModalConfirmation";
import { StepFour, StepOne, StepThree, StepTwo } from "./RCSAgent/Details";
import RcsApi from "../../../Api/RcsApi";

const { Step } = Steps;

const AgentDetailsStepper = ({ agentId, setAgentDetails, setShouldRefetch }) => {
  const [t] = useTranslation("rcs-channel");
  const [form1] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const steps = [
    { title: t("stepper.one") },
    { title: t("stepper.two") },
    { title: t("stepper.three") },
    { title: t("stepper.four") }
  ];
  const [modalCampaignVisible, setModalCampaignVisible] = useState(false);
  const [modalCanelVisible, setModalCanelVisible] = useState(false);
  const [modalBeforeLaunchVisible, setModalBeforeLaunchVisible] = useState(false);
  const [hasLaunchedCarriers, setHasLaunchedCarriers] = useState(false);
  const [allDetails, setAllDetails] = useState(null)
  const [step1Data, setStep1Data] = useState(null)
  const [step3Data, setStep3Data] = useState(null)
  const [step4Data, setStep4Data] = useState(null)
  const { getAgentById, createAgent, createVerification, getAgentCarriers, createLaunch } = RcsApi();

  const { spinnerDispacth } = useSpinner();
  const { auth } =  useAuth();
  
  useEffect(() => {
    if (current === 2) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.DEFAULT }
      });
      getAgentById(agentId)
        .then((agentRes) => {
          setStep1Data({ ...step1Data, ...agentRes?.data })
          setAllDetails({ ...step1Data, ...agentRes?.data })
        })
        .catch(e => {
          notificationError(cleanErrorMessage(e))
        })
        .finally(() => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
          });
        })
    }
  }, [current]);

  useEffect(() => {
    if (current === 3) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.DEFAULT }
      });
    getAgentCarriers(agentId)
      .then((response) => {
        const has = (response?.data || []).length > 0
        setHasLaunchedCarriers(has);
        setModalBeforeLaunchVisible(!has)
      })
      .catch((e) => {
        notificationError(cleanErrorMessage(e), 15)
      })
      .finally(() => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
        });
      })
    }
  }, [current]);

  const renderLeftCard = () => {
    switch (current) {
      case 0:
        return <StepOne
          agentId={step1Data?.rcsAgentId || agentId}
          setAllDetails={(v) => setAllDetails(v)}
          setStep1Data={setStep1Data}
          step1Data={step1Data}
          form={form1}
        />
      case 1:
        return <StepTwo agentId={step1Data?.rcsAgentId || agentId} allDetails={allDetails} />
      case 2:
        return allDetails.status === "UNKNOWN" ?
          <StepThree
            setStep3Data={setStep3Data}
            step3Data={step3Data}
            form={form3}
          /> :
          <Alert
            style={{ marginTop: "16px" }}
            className="custom-info-alert small-font-size"
            type="info"
            description={t("step3.alert.verification-form-submitted")}
          />
      case 3:
        return !modalBeforeLaunchVisible ?
          !hasLaunchedCarriers ?
            <StepFour setStep4Data={setStep4Data} step4Data={step4Data} form={form4} /> :
            <Alert
              style={{ marginTop: "16px" }}
              className="custom-info-alert small-font-size"
              type="info"
              description={t("step3.alert.launch-form-submitted")}
            /> :
          null
      default:
        return null
    }    
  }

  const nextStep = () => {

    switch (current) {
      case 0:
        stepOne();
        break;
      case 1:
        stepTwo();
        break;
      case 2:
        stepThree();
        break;
      default:
        break;
    }
  };

  const prevStep = () => {
    switch (current) {
      case 1:
        setCurrent(current - 1);
        break;
      case 2:
        setCurrent(current - 1);
        break;
      case 3:
        setCurrent(current - 1);
        break;
      default:
        break;
    }
  }

  const stepOne = () => {
    const requiredFields = [
      { field: "rcsBrandId", errorMessage: t("step1.rules.missing-hidden-field")},
      { field: "companyId", errorMessage: t("step1.rules.missing-hidden-field")},
      { field: "accountId", errorMessage: t("step1.rules.missing-hidden-field")},
      { field: "lastModifiedBy", errorMessage: t("step1.rules.missing-hidden-field")},
      { field: "name", errorMessage: t("step1.rules.display-name-required")},
      { field: "description", errorMessage: t("step1.rules.description-required")},
      { field: "hostingRegion", errorMessage: t("step1.rules.hosting-region-required")},
      { field: "billingCategory", errorMessage: t("step1.rules.billing-category-required")},
      { field: "agentUseCase", errorMessage: t("step1.rules.use-case-required")},
      { field: "color", errorMessage: t("step1.rules.color-required")},
      { field: "logoBase64", errorMessage: t("step1.rules.logo-required")},
      { field: "logoExtension", errorMessage: t("step1.rules.logo-file-type-required")},
      { field: "bannerBase64", errorMessage: t("step1.rules.banner-required")},
      { field: "bannerExtension", errorMessage: t("step1.rules.banner-file-type-required")},
      { field: "phone", errorMessage: t("step1.rules.phone-number-required")},
      { field: "website", errorMessage: t("step1.rules.website-required")},
      { field: "email", errorMessage: t("step1.rules.email-required")},
      { field: "privacy", errorMessage: t("step1.rules.privacy-policy-required")},
      { field: "termsConditions", errorMessage: t("step1.rules.terms-of-service-required")},
    ]
    const payload = { ...step1Data, companyId: auth.companyId }
    const validation = validateFields(payload, requiredFields)
    if (agentId) {
      changeStep();
      return
    }
    if (validation.isValid) {
      form1
        .validateFields()
        .then(() => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: true, item: CONSTANTS.SPINNERITEM.DEFAULT }
          });
          createAgent(payload)
            .then((response) => {
              const res = JSON.parse(response)
              notificationOk(t("step1.api-result.agent-created-successfully"))
              setAgentDetails({ visible: true, agentId: res?.data?.rcsAgentId })
              setStep1Data(res?.data)
              setStep3Data({ ...step3Data, rcsAgentId: res?.data?.rcsAgentId })
              setStep4Data({ ...step4Data, rcsAgentId: res?.data?.rcsAgentId })
              setAllDetails(res?.data || null)
              changeStep();
            })
            .catch(e => {
              notificationError(cleanErrorMessage(e))
            })
            .finally(() => {
              spinnerDispacth({
                type: TYPES.SPINNER.SHOW,
                payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
              });
            })
        })
        .catch(() => {
          notificationError(t("step1.rules.form-not-valid"))
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
          });
        });
    } else {
      notificationError(validation.message)
    }
  };

  const stepTwo = () => {
    changeStep();
  };

  const stepThree = () => {
    if (allDetails.status === "UNKNOWN") {
      const requiredFields = [
        { field: "brandContactName", errorMessage: t("step3.rules.name-required")},
        { field: "brandContactEmailAddress", errorMessage: t("step3.rules.email-required")},
        { field: "brandWebsiteUrl", errorMessage: t("step3.rules.website-required")},
      ]
      const payload = {
        ...step3Data,
        rcsAgentId: allDetails.rcsAgentId,
        partnerName: "Andrés Chavez",
        partnerEmailAddress: "achavez@identidadtech.com",
      }

      const validation = validateFields(payload, requiredFields)
      if (validation.isValid) {
        form3
          .validateFields()
          .then(() => {
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: true, item: CONSTANTS.SPINNERITEM.DEFAULT }
            });
            createVerification(payload)
              .then(() => {
                notificationOk(t("step3.api-result.verification-information-submitted-successfully"))
                changeStep();
                setModalBeforeLaunchVisible(true);
              })
              .catch(e => {
                notificationError(cleanErrorMessage(e))
              })
              .finally(() => {
                spinnerDispacth({
                  type: TYPES.SPINNER.SHOW,
                  payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
                });
              })
          })
          .catch(() => {
            notificationError(t("step3.rules.form-not-valid"))
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
            });
          });
      } else {
        notificationError(validation.message)
      }
    } else {
      changeStep();
    }
  };

  const changeStep = () => {
    let nextStepValue = current + 1;
    setCurrent(nextStepValue);
  }

  const handleCompleteAgentLaunch = () => {
    const requiredFields = [
      { field: "contactName", errorMessage: t("step4.rules.name-required")},
      { field: "contactTitle", errorMessage: t("step4.rules.title-required")},
      { field: "contactEmailAddress", errorMessage: t("step4.rules.email-required")},
      { field: "optinDescription", errorMessage: t("step4.rules.opt-in-description-required")},
      { field: "triggerDescription", errorMessage: t("step4.rules.trigger-description-required")},
      { field: "interactionsDescription", errorMessage: t("step4.rules.interactions-description-required")},
      { field: "optoutDescription", errorMessage: t("step4.rules.opt-out-description-required")},
      { field: "rcsAgentId", errorMessage: t("step4.rules.missing-hidden-field")},
      { field: "lastModifiedBy", errorMessage: t("step4.rules.missing-hidden-field")},
    ]

    const payload = {
      ...step4Data,
      rcsAgentId: allDetails?.rcsAgentId,
      lastModifiedBy: allDetails?.lastModifiedBy
    }

    const validation = validateFields(payload, requiredFields)
    if (validation.isValid) {
      if (step4Data?.carriers?.length === 0) {
        notificationError(t("step4.validation-error.select-at-least-one-carrier"))
      } else {
        form4
          .validateFields()
          .then(() => {
            setModalCampaignVisible(true)
          })
          .catch(() => {
            notificationError(t("step4.rules.form-not-valid"))
          });
      }
    } else {
      notificationError(validation.message)
    }
  }

  const handleCancelAgentLaunch = () => {
    setModalCampaignVisible(false);
  }

  const handleConfirmLaunch = () => {
    const payload = {
      ...step4Data,
      rcsAgentId: allDetails.rcsAgentId,
      lastModifiedBy: allDetails.lastModifiedBy
    }

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.DEFAULT }
    });
    createLaunch(payload)
      .then(() => {
        notificationOk(t("step4.api-result.launch-information-submitted-successfully"))
        setAgentDetails({ visible: false, agentId: null })
        setShouldRefetch(true)
      })
      .catch(e => {
        notificationError(cleanErrorMessage(e))
      })
      .finally(() => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
        });
      })
  }

  const handleConfirmCanLaunch = () => {
    setModalBeforeLaunchVisible(false);
  }

  const handleCancelCanLaunch = () => {
    setModalBeforeLaunchVisible(false);
    prevStep()
  }

  const handleCancelCampaign = () => {
    setModalCanelVisible(false);
  }

  const handleOkCancelCampaign = () => {
    setAgentDetails({ visible: false, agentId: null })
    setShouldRefetch(true)
  }

  return (
    <Fragment>
      <div className="yp-workspace">
        <div className={styles['step-header']}>
          <Steps labelPlacement="horizontal" current={current} direction="horizontal">
            {steps.map(item => (
              <Step key={item.title} title={item.title}/>
            ))}
          </Steps>
        </div>
        <div className={styles['step-content']}>
            {
              renderLeftCard()
            }
        </div>
      </div>
      <div className={styles['step-buttons-container']}>
        <div className={styles['step-buttons']}>
          {current < steps.length && (
            <Button style={{ width: 150, marginRight: '10px' }} type="secondary" onClick={() => setModalCanelVisible(true)}>
              {t("actions-buttons.cancel")}
            </Button>
          )}
          {current > 0 && current < steps.length  && (
            <Button style={{ width: 150, marginRight: '10px' }} type="optional" onClick={() => prevStep()}>
              {t("actions-buttons.previous")}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button style={{ width: 150 }} type="primary" disabled={hasLaunchedCarriers} onClick={() => handleCompleteAgentLaunch()}>
              {t("actions-buttons.done")}
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button style={{ width: 150 }} type="primary" onClick={() => nextStep()}>
              {t("actions-buttons.next")}
            </Button>
          )}
        </div>
      </div>
      <ModalConfirmation
        key={1}
        visible={modalCanelVisible}
        title={t("stepper.modal.cancel.title")}
        onCancel={handleCancelCampaign}
        onOk={handleOkCancelCampaign}
        okBtnText={t("stepper.modal.cancel.btn-ok")}
        cancelBtnText={t("stepper.modal.cancel.btn-cancel")}
      >
        <p>{t("stepper.modal.cancel.content")}</p>
      </ModalConfirmation>
      <ModalConfirmation
        key={2}
        visible={modalBeforeLaunchVisible}
        title={t("stepper.modal.before-launch.title")}
        onCancel={handleCancelCanLaunch}
        onOk={handleConfirmCanLaunch}
        okBtnText={t("stepper.modal.before-launch.btn-ok")}
      >
        <Alert
          className="custom-info-alert small-font-size"
          type="info"
          description={
            <ul>
              <li>{t("stepper.modal.before-launch.item1")}</li>
              <li>{t("stepper.modal.before-launch.item2")}</li>
              <li>{t("stepper.modal.before-launch.item3")}</li>
            </ul>
          }
        />
      </ModalConfirmation>
      <ModalConfirmation
        key={3}
        visible={modalCampaignVisible}
        title={t("stepper.modal.confirm-launch.title")}
        onCancel={handleCancelAgentLaunch}
        onOk={handleConfirmLaunch}
        okBtnText={t("stepper.modal.confirm-launch.btn-ok")}
      >
        <p style={{ textAlign: "center" }}>{t("stepper.modal.confirm-launch.content")}</p>
      </ModalConfirmation>
    </Fragment>
  );
}
 
export default AgentDetailsStepper;