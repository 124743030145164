import React from 'react';
import styles from "./MiniCard.module.css";

const MiniCard = ({title, value, borderRight, titleColor}) => {

  const detailsStile = borderRight === true ? `${styles['details']} ${styles['border-right']}` : `${styles['details']}`

  return (
    <div className={detailsStile}>
      {
        titleColor ? (<p className='yp-subtitle' style={{color: titleColor}}>{title}</p>) :
          <p className='yp-subtitle'>{title}</p>
      }
      <p
        className={styles['details-value']}
      >
        {value}
      </p>
    </div>
  );
}

export default MiniCard;