import styles from "./BestPracticeButton.module.css";
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next";

const BestPracticeButton = () => {

  const [t] = useTranslation("best-practice");

  return (
    <div className={styles['container']}>
      <a href="https://google.com" target="_blank"><FontAwesomeIcon style={{marginRight: "10px", marginTop: "4px"}} icon={ faInfoCircle } /> {t("text-button")}</a>
    </div>
  );
}
 
export default BestPracticeButton;