import styles from "./TableCustom.module.css";

const TableCustom = ({children}) => {
  return (
    <table className={styles['table']}>
      {children}
    </table>
  );
}
 
export default TableCustom;