import { Avatar, Switch } from "antd";
import CardCustom from "../../CardCustom/CardCustom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faCopy } from "@fortawesome/pro-solid-svg-icons";
import { grayColorOpacity, orangeColor } from "../../../utils/colorsCss";
import styles from "./ApiKeyCard.module.css";

const ApiKeyCard = ({apikey, accounts}) => {

  const [t] = useTranslation("sms-api-key");
  const [ enableUser, setEnableUser ] = useState(true);

  const onClickUserSettings = (switchValue ) => {
    setEnableUser(switchValue);
  }

  return (
    <CardCustom
      key={apikey.Id}
      minWidth="350px"
      width="100%"
      extra={
        <Switch
          checked={enableUser}
          onClick={onClickUserSettings} />
      }
    >
      <div className={styles['content']}>
        <div className={styles['avatar']}>
          <Avatar
            style={{ backgroundColor: enableUser ? orangeColor : grayColorOpacity }}
            shape='circle'
            size={60}
            icon={<FontAwesomeIcon icon={ faKey }/>}
          />
          <p className='yp-title'>{apikey.name}</p>
        </div>
        <div className={styles['api-key-container']}>
          <p>{apikey.apiKey}</p>
          <p onClick={() => {navigator.clipboard.writeText(apikey.apiKey)}}><FontAwesomeIcon icon={ faCopy }/></p>
        </div>
        <div className={styles['api-key-account']}>
        <p className="yp-subtitle">{t("create-api-key.label-account")}:</p>
        <ul>
          {
            accounts.map(account => {
              return <li>{account.name}</li>;
            })  
          }
        </ul>
        </div>
      </div>
    </CardCustom>
  );
}
 
export default ApiKeyCard;