import { Pagination } from "antd";
import { Fragment, useState, useEffect } from "react";
import { dateFormat } from "../../../utils/helpers";
import TableBodyCustom from "../../TableCustom/TableBodyCustom/TableBodyCustom";
import TableBodyTrCustom from "../../TableCustom/TableBodyTrCustom/TableBodyTrCustom";
import TableCustom from "../../TableCustom/TableCustom";
import TableHeadCustom from "../../TableCustom/TableHeadCustom/TableHeadCustom";
import styles from "./ConfirmRatesGrid.module.css";

const ConfirmRatesGrid = ({summary, currentPage, itemsPerPage, totalPages, onChangePagination}) => {
  
  const [summaryFiltered, setSummaryFiltered] = useState([])

  useEffect(() => {
    if(summary) {
      setSummaryFiltered(summary);
    }
  }, [summary])

  return (
    <Fragment>
      <div className={styles['pagination-container']}>
        <Pagination 
          defaultCurrent={currentPage}
          current={currentPage}
          onChange={(pageNumber) => onChangePagination(pageNumber)}
          total={totalPages}
          pageSize={itemsPerPage}
          showSizeChanger={false}
        />
      </div>
      <div className={styles['table-container']}>
        <TableCustom>
          <TableHeadCustom>
            <th>Country</th>
            <th>Increase</th>
            <th>Decrease</th>
            <th>Change Date</th>         
          </TableHeadCustom>
          <TableBodyCustom>
            {
              summaryFiltered.map(item => (
                <TableBodyTrCustom key={item.country}>
                  <td>{item.country}</td>
                  <td>{item.increase}</td>
                  <td>{item.decrease}</td>
                  <td>{dateFormat(item.changeDate)}</td>
                </TableBodyTrCustom>
              ))
            }
          </TableBodyCustom>
        </TableCustom>
      </div>
    </Fragment>
  );
}
 
export default ConfirmRatesGrid;