import useAuth from "../Hooks/useAuth";
import { configurationRequest } from "../utils/authConfig";

const ConfigurationApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    getMenus: async (role) => {
      const accessToken = await passTokenToApi(configurationRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Configuration/Menu/${role}`;
          const url = `${process.env.REACT_APP_CONFIGURATION_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the menus. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the menus.");
      }
    },
    getRoutes: async (role) => {
      const accessToken = await passTokenToApi(configurationRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Configuration/Route/${role}`;
          const url = `${process.env.REACT_APP_CONFIGURATION_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the routes. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the routes.");
      }
    },
    getTemplateCampaign: async () => {
      const accessToken = await passTokenToApi(configurationRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Configuration/Template`;
          const url = `${process.env.REACT_APP_CONFIGURATION_API_URL}${resource}`;
    
          let response = await fetch(url, options);
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the template. Status Code: ${response.status}`);
          }
    
          let link = await response.json();
          return link;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the template.");
      }
    },
    pqr: async (formdata) => {
      const accessToken = await passTokenToApi(configurationRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "POST",
            body: formdata,
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Configuration/PQR`;
          const url = `${process.env.REACT_APP_CONFIGURATION_API_URL}${resource}`;
    
          let response = await fetch(url, options);
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred send pqr. Status Code: ${response.status}`);
          }
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred sending PQR.");
      }
    },
    getTimeZones: async () => {
      const accessToken = await passTokenToApi(configurationRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Configuration/Timezone`;
          const url = `${process.env.REACT_APP_CONFIGURATION_API_URL}${resource}`;
    
          let response = await fetch(url, options);
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the time zones. Status Code: ${response.status}`);
          }
    
          let timezones = await response.json();
          return timezones;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the time zones.");
      }
    }
  }
}

export default ConfigurationApi;
