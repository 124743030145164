import React, { Fragment, useContext, useState, useReducer, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, DatePicker, Popconfirm, Select, Table, Tag, Tooltip, Typography, Empty, Spin } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import dayjs from "dayjs";
import styles from "./WhatsAppTemplates.module.css";
import WhatsappApi from "../../../../Api/WhatsappApi";
import useSpinner from '../../../../Hooks/useSpinner';
import { AuthContext } from "../../../../contexts/authRedirect";
import TYPES from '../../../../reducers/types';
import CONSTANTS from "../../../../utils/const";
import { useHistory } from "react-router-dom";
import { cleanErrorMessage, notificationError, notificationOk } from "../../../../utils/helpers";
import "./WhatsAppTemplates.css";
import MobilePreview from "./MobilePreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import campaignReducer, { initialCampaignState } from "../../../../reducers/campaignWhatsappReducer";

const { Title } = Typography

const WhatsAppTemplatesList = ({ setIdToEdit }) => {
  const [t] = useTranslation("whatsapp-templates");
  const { getAccounts, getTemplatesByWabaId, deleteWabaTemplate, getTemplateById } = WhatsappApi();
  const [campaingState, campaingDispacth] = useReducer(campaignReducer, initialCampaignState);

  const { spinnerDispacth } = useSpinner();
  const { auth } = useContext(AuthContext);
  const [accounts, setAccounts] = useState([])
  const [templates, setTemplates] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [previewData, setPreviewData] = useState(null)
  const history = useHistory()

  const initialFilterState = {
    categories: [],
    date: '',
    status: [],
  };

  const filterReducer = (state, action) => {
    switch (action.type) {
      case 'SET_ACCOUNT':
        return { ...state, account: action.payload };
      case 'SET_CATEGORIES':
        return { ...state, categories: action.payload };
      case 'SET_DATE':
        return { ...state, date: action.payload };
      case 'SET_STATUS':
        return { ...state, status: action.payload };
      default:
        return state;
    }
  };
  const [filterState, dispatch] = useReducer(filterReducer, initialFilterState);

  const handleAccountsChange = (value, approvedAccounts) => {
    if (typeof value === "object") {
      dispatch({ type: 'SET_ACCOUNT', payload: value?.phone });
      campaingDispacth({
        type: TYPES.CAMPAIGN.SELECTED_PHONE_FOR_WA_TEMPLATE,
        payload: value
      })
      sessionStorage.setItem("SELECTED_PHONE_FOR_WA_TEMPLATE", JSON.stringify(value));
      getTemplates(value?.wabaId)
    } else if (typeof value === "string") {
      const data = (approvedAccounts || accounts).find(a => a.phone === value) || ""
      dispatch({ type: 'SET_ACCOUNT', payload: value });
      campaingDispacth({
        type: TYPES.CAMPAIGN.SELECTED_PHONE_FOR_WA_TEMPLATE,
        payload: data
      })
      sessionStorage.setItem("SELECTED_PHONE_FOR_WA_TEMPLATE", JSON.stringify(data));
      getTemplates(data?.wabaId)
    } else {
      dispatch({ type: 'SET_ACCOUNT', payload: "" });
      campaingDispacth({
        type: TYPES.CAMPAIGN.SELECTED_PHONE_FOR_WA_TEMPLATE,
        payload: ""
      })
      sessionStorage.removeItem("SELECTED_PHONE_FOR_WA_TEMPLATE");
      getTemplates("")

    }
  };
  
  const handleCategoriesChange = (value) => {
    dispatch({ type: 'SET_CATEGORIES', payload: value });
  };

  const handleDateChange = (e) => {
    dispatch({ type: 'SET_DATE', payload: e || "" });
  };

  const handleStatusChange = (value) => {
    dispatch({ type: 'SET_STATUS', payload: value });
  };

  const filteredData = (templates || []).filter(item => {
    const categoryMatch = !filterState.categories.length || filterState.categories.includes(item.category);
    const dateMatch = !filterState.date || dayjs(item.lastModifiedDate).isSame(dayjs(filterState.date), 'day');
    const statusMatch = !filterState.status.length || filterState.status.includes(item.status);

    return categoryMatch && dateMatch && statusMatch;
  });

  useEffect(() => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.COMPANYACCOUNTS }
      });
      getInitialData()
        .then(() => {
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.COMPANYACCOUNTS }
          });
        })
    
  }, []);

  const getInitialData = async () => {
    try {
      const _accounts = await getAccounts(auth.companyId);
      const approvedAccounts = (_accounts?.data || []).filter(a => a.status === "APPROVED")
      setAccounts(approvedAccounts)
      const item = sessionStorage.getItem("SELECTED_PHONE_FOR_WA_TEMPLATE");
      const parsedItem = JSON.parse(item)
      handleAccountsChange(parsedItem, approvedAccounts)
    }
    catch (error) {
      console.error(error);
    }
  }

  const getTemplates = useCallback(async (wabaId) => {
    try {
      setIsLoading(true)
      const _templates = await getTemplatesByWabaId(auth.companyId, wabaId);
      setTemplates(_templates.data || [])
      setIsLoading(false)
    }
    catch (error) {
      console.error(error);
      setIsLoading(false)
    }
  }, [])

  const [key, setKey] = useState(null);

  const showDeleteModal = (key) => {
    setKey(key);
  };

  const confirm = async () => {
    setIsDeleting(true)
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.DELETETEMPLATE }
    });
    try {
      setIsDeleting(true)
      if (key) {
        await deleteWabaTemplate(key);
        const item = sessionStorage.getItem("SELECTED_PHONE_FOR_WA_TEMPLATE");
        const parsedItem = JSON.parse(item)
        getTemplates(parsedItem?.wabaId || "")
        notificationOk(t("delete-ok"));
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETETEMPLATE }
        });
      } else {
        setTemplates([])
      }
      setKey(null);
      setIsDeleting(false)
    }
    catch (error) {
      console.error(error);
      notificationError(cleanErrorMessage(error), 15);
      setKey(null);
      setIsDeleting(false)
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.DELETETEMPLATE }
      });
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case "APPROVED":
        return t("status.approved")
      case "PENDING":
        return t("status.pending")
      case "REJECTED":
        return t("status.rejected")
      default:
        return t("status.unknown")
    }
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "APPROVED":
        return "#bfd9de"
      case "PENDING":
        return "#ffe7c0"
      case "REJECTED":
        return "#efd2d1"
      default:
        return "gray"
    }
  }

  const columns = [
    {
      title: t("table-header.category"),
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: t("table-header.name"),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t("table-header.date"),
      dataIndex: 'lastModifiedDate',
      key: 'lastModifiedDate',
      render: (value) => dayjs(value).format("DD/MM/YYYY")
    },
    {
      title: t("table-header.last-modified-by"),
      dataIndex: 'lastModifiedBy',
      key: 'lastModifiedBy',
      align: "center"
    },
    {
      title: t("table-header.preview"),
      key: 'information',
      render: row => {
        const templateId = row.wabaTemplateId
        return (
          <Tooltip
            id="template-list-preview-tooltip"
            title={
              previewData?.loading ? <Spin spinning={true} /> : <MobilePreview previewValues={previewData?.data} />
            }
            color={getGradientColor(purpleColor)}
            overlayInnerStyle={{padding: 20}}
            onVisibleChange={async visible => {
              setPreviewData({ key: templateId, loading: true, data: null })
              if (visible) {
                const templateById = await getTemplateById(templateId)
                setPreviewData({ key: templateId, loading: false, data: templateById?.data })
              } else {
                setPreviewData({ key: templateId, loading: false, data: null })
              }
            }}
          >
            <FontAwesomeIcon id="template-list-preview-icon" style={{ marginRight: "5px" }} icon={faMobileScreen} /> 
          </Tooltip>
        );
      },
      align: "center"
    },
    {
      title: t("table-header.edit"),
      key: 'edit',
      render: row => {
        return (
          row.status === "PENDING" ?
            <Tooltip id="template-list-cannot-update-pending-tooltip" title={t("cannot-update-pending")} color={getGradientColor(purpleColor)} overlayInnerStyle={{padding: 20}}>
              <EditOutlined />
            </Tooltip> :
            <EditOutlined id="template-list-edit-icon" onClick={() => setIdToEdit(row.wabaTemplateId)} />
        );
      },
      align: "center"
    },
    {
      title: t("table-header.delete"),
      key: 'delete',
      render: (text, record) => (
        <Popconfirm
          title={t("confirmation-delete-template")}
          onConfirm={confirm}
          okText={t("options.confirm")}
          cancelText={t("options.cancel")}
          okButtonProps={{ id: "templates-list-popconfirm-conform-button" }}
          cancelButtonProps={{ id: "templates-list-popconfirm-cancel-button" }}
        >
          {
            isDeleting && record.wabaTemplateId === key ?
              <Spin spinning={true} /> :
              <DeleteOutlined id="templates-list-delete-icon" onClick={() => showDeleteModal(record.wabaTemplateId)} style={{ cursor: 'pointer' }} />
          }
        </Popconfirm>
      ),
      align: "center"
    },
    {
      title: t("table-header.status"),
      key: 'status',
      render: row => {
        return (
          row.rejectedReason === "NONE" || !row.rejectedReason ?
          <Tag key={row.status} style={{ color: "black" }} color={getStatusColor(row.status)}>{getStatus(row.status)}</Tag> :
          <Tooltip id="templates-list-rejected-reason-tooltip" title={row.rejectedReason} color={getGradientColor(purpleColor)} overlayInnerStyle={{padding: 20}}>
            <Tag key={row.status} style={{ color: "black" }} color={getStatusColor(row.status)}>{getStatus(row.status)}</Tag>
          </Tooltip>
        );
      },
      align: "center"
    },
  ];


  const categories = [{
    id: "1",
    label: t("category.marketing"),
    value: "MARKETING"
  },{
    id: "2",
    label: t("category.utility"),
    value: "UTILITY"
  },{
    id: "3",
    label: t("category.authentication"),
    value: "AUTHENTICATION"
  }]

  const status = [{
    id: "1",
    label: t("status.approved"),
    value: "APPROVED"
  },{
    id: "2",
    label: t("status.pending"),
    value: "PENDING"
  },{
    id: "3",
    label: t("status.rejected"),
    value: "REJECTED"
  }]

  return (
    <Fragment>
      <div className="yp-workspace">
        <Title level={4} type="secondary" style={{ textAlign: "center" }}>{t("list-title")}</Title>
        <div style={{ display: "flex", gap: "20px", width: "100%", justifyContent: "space-between", marginBottom: "20px" }}>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <label>{t("label.whatsapp-accounts")}</label>
            <Select
              id="templates-list-wabas-filter"
              style={{ width: '100%' }}
              optionFilterProp="label"
              options={(accounts || []).map(a => ({ value: a.phone, label: a.name }))}
              onChange={v => handleAccountsChange(v, accounts)}
              value={campaingState?.selectedPhoneForTemplate?.phone || null}
              placeholder={t("placeholder.select-whatsapp-account")}
              allowClear
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <label>{t("label.category")}</label>
            <Select
              id="templates-list-category-filter"
              mode="multiple"
              style={{ width: '100%' }}
              options={categories}
              onChange={handleCategoriesChange}
              placeholder={t("placeholder.filter-by-category")}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <label>{t("label.date")}</label>
            <DatePicker
              id="templates-list-date-filter"
              onChange={handleDateChange}
              placeholder={t("placeholder.filter-by-date")}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <label>{t("label.status")}</label>
            <Select
              id="templates-list-status-filter"
              mode="multiple"
              style={{ width: '100%' }}
              options={status}
              onChange={handleStatusChange}
              placeholder={t("placeholder.filter-by-status")}
            />
          </div>
        </div>
        <Table
          rowKey={({ wabaTemplateId }) => wabaTemplateId}
          className="whatsapp-templates-table"
          columns={columns}
          dataSource={filteredData}
          loading={isLoading}
          locale={{ 
            emptyText:
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t("placeholder.select-account-to-continue")}
              />
          }}
        />
      </div>
      <div className={styles['step-buttons-container']}>
        <div className={styles['step-buttons']}>
          <Button id="templates-list-create-campaign-button" className="yp-button yp-button-optional" style={{ width: 150 }} onClick={() => history.push("/Campaigns/WhatsApp")}>
            {t("actions-buttons.create-campaign")}
          </Button>
          <Button id="templates-list-create-templates-button" className={`${styles['yp-button']} ${styles['yp-button-primary']}`} style={{ width: 150 }} type="primary" onClick={() => history.push("/WhatsAppTemplates/Create")}>
            {t("actions-buttons.create-template")}
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export default WhatsAppTemplatesList;