import { Pagination, Select } from "antd";
import { useState, useEffect, Fragment } from "react";
import TableCustom from "../../TableCustom/TableCustom";
import TableHeadCustom from "../../TableCustom/TableHeadCustom/TableHeadCustom";
import TableBodyTrCustom from "../../TableCustom/TableBodyTrCustom/TableBodyTrCustom";
import TableBodyCustom from "../../TableCustom/TableBodyCustom/TableBodyCustom";
import styles from "./RatesGrid.module.css";
import { currencyFormat, dateFormat } from "../../../utils/helpers";
import CONSTANTS, { CHANELLIST } from "../../../utils/const";

const { Option } = Select;

const RatesGrid = ({rates, currentPage, itemsPerPage, totalPages, channelId, onChangePagination, onChangeChannel}) => {

  const [ratesFiltered, setRatesFiltered] = useState([])
  const [ defaultSelected , setDefaultSelected ] = useState();

  useEffect(() => {
    if (channelId) {
      let result = CONSTANTS.LIST.CHANELS.find(X => X.key == channelId);
      setDefaultSelected(result.key); 
    }
  }, [channelId]) 

  useEffect(() => {
    if(rates) {
      setRatesFiltered(rates);
    }
  }, [rates]);

  return (
    <Fragment>
      <div className={styles['pagination-container']}>
        {
          defaultSelected ?  (
            <>
              <Select
                style={{width: 200}}
                placeholder="Please select chanel"
                onChange={onChangeChannel}
                defaultValue={defaultSelected}
              >
                {
                  CONSTANTS.LIST.CHANELS.map(item =>
                    item.key !== CHANELLIST.DEFAULT.key &&
                    item.key !== CHANELLIST.SHORTLINK.key &&
                      <Option key={item.key} value={item.key}>{item.value}</Option>
                  )
                }
              </Select>
              <Pagination 
                defaultCurrent={currentPage}
                current={currentPage}
                onChange={(pageNumber) => onChangePagination(pageNumber)}
                total={totalPages}
                pageSize={itemsPerPage}
                showSizeChanger={false}
              />
            </>
          ) : <></>
        }
      </div>
      <div className={styles['table-container']}>
        <TableCustom>
          <TableHeadCustom>
            <th>Country</th>
            <th>Current Rate</th>
            <th>Efective Rate</th>
          </TableHeadCustom>
          <TableBodyCustom>
            {
              ratesFiltered.map(rate => (
                <TableBodyTrCustom key={rate.mccMnc}>
                  <td>{rate.country}</td>
                  <td>{currencyFormat(rate.rate, 5)}</td>
                  <td>{dateFormat(rate.effectiveDate)}</td>
                </TableBodyTrCustom>
              ))
            }
          </TableBodyCustom>
        </TableCustom>
      </div>
    </Fragment>
  );
}
 
export default RatesGrid;