import styles from "./HeaderMap.module.css";
import { Select } from 'antd';
import { useTranslation } from "react-i18next";
import { useState } from "react";
import HelperButton from "../../HelperButton/HelperButton";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import { useEffect } from "react";
import TableCustom from "../../TableCustom/TableCustom";
import TableHeadCustom from "../../TableCustom/TableHeadCustom/TableHeadCustom";
import TableBodyCustom from "../../TableCustom/TableBodyCustom/TableBodyCustom";
import TableBodyTrCustom from "../../TableCustom/TableBodyTrCustom/TableBodyTrCustom";

const { Option } = Select;

const HeaderMap = ({headers,
  fileData,
  loadContacts,
  onChangeMobileNumberField,
  onChangeCustomFields,
  onChangeHeaders,
  defaultHeaderSelected,
  defaultWildcardSelected,
  defaultPhoneFieldName}) => {

  const [t] = useTranslation("campaign-bulk");

  const [ phoneHeader, setPhoneHeader] = useState([]);
  const [ defaultPhoneSelected, setDefaultPhoneSelected] = useState([]);
  const [ phone, setPhone] = useState(null);
  const [ wildCards, setWildCards] = useState([]);
  const [ wildCardsSelected, setWildCardsSelected] = useState([]);
  const [ wildCardsValue, setWildCardsValue] = useState([]);
  const [ phoneFieldName, setPhoneFieldName] = useState(defaultPhoneFieldName ? defaultPhoneFieldName : "");

  useEffect(() => {
    
    setPhoneFieldName(defaultPhoneFieldName);
    
    if(defaultPhoneFieldName === "") {
      setPhoneFieldName("");
      setDefaultPhoneSelected("");
      setPhone(null);
    }

    if(defaultPhoneFieldName && defaultPhoneFieldName !== "" && headers.length > 0) {
      setPhoneFieldName(defaultPhoneFieldName);
      setDefaultPhoneSelected(defaultPhoneFieldName);
      setPhone(defaultPhoneFieldName);
    }

    if(defaultWildcardSelected && defaultWildcardSelected.length > 0) {
      setWildCardsSelected(defaultWildcardSelected);
      let result = defaultWildcardSelected.map(x => { return x.value });
      setWildCardsValue(result);

      let customFields = headers.map(x => { return {label: x.name, value: x.name }}).filter(x => x.label !== phoneFieldName);
      setWildCards(customFields);
    } else if(phoneFieldName !== "") {
      let customFields = headers.map(x => { return {label: x.name, value: x.name }}).filter(x => x.label !== phoneFieldName);
      setWildCards(customFields);
    }

  }, [defaultPhoneFieldName, headers])

  useEffect(() => {
    let defaultHeader = defaultHeaderSelected.length > 0 ? defaultHeaderSelected : headers;
    setPhoneHeader(defaultHeader);

    if(defaultHeaderSelected.length === 0) {
      setWildCards([]);
      setWildCardsSelected([]);
      setWildCardsValue([]);
    }
    
  }, [defaultHeaderSelected])
  
  const handlePhoneNumberColumn = (value) => {
    setPhoneFieldName(value);

    let temp = headers.find(h => h.name === value);
    setPhoneHeader(temp);
    onChangeHeaders([temp]);

    let wildcardtFilter = headers.filter(h => h.name !== value);
    let wildCardMap = wildcardtFilter.map(x => { return { label: x.name, value: x.name } })

    setWildCards(wildCardMap);

    setWildCardsSelected([]);
    setWildCardsValue([]);
    
    onChangeMobileNumberField(value);
    onChangeCustomFields([]);
  }

  const getFieldHeaderValue = (data) => {
    if(headers.length > 0 && phoneFieldName !== "") {
      let header = headers.find(x => x.name === phoneFieldName);
      if(header) {
        let column = headers.find(x => x.name === phoneFieldName).column;
        let row = data.value.find(x => x.column === column);
        return row.value;
      }
    }
  };

  const getFieldBodyValue = (wild, data) => {
    let header = headers.find(x => x.name === wild.value);
    let row = data.value.find(x => x.column === header.column);
    return row.value;
  };

  const handleWildCardsChange = (value) => {
    let newWildCards = value.map(x => { return { label: x, value: x } });
    setWildCardsSelected(newWildCards);
    setWildCardsValue(value);

    let newHeader = [];

    newWildCards.forEach(item => {
      let temp = headers.find(h => h.name === item.value);
      newHeader.push(temp);
    });

    onChangeHeaders(newHeader);
    onChangeCustomFields(value);
  };

  return (
    <>
      <div className={styles['phone']}>
        <div className={styles['phone-field-content']}>
          <p>{t("map-header.phone-title")}</p>
          <div className={styles['phone-field']}>
            <Select
              id="mobile_number_select"
              style={{ width: "100%" }}
              placeholder={t("map-header.placeholder-phone")}
              onChange={handlePhoneNumberColumn}
              defaultValue={defaultPhoneSelected}
              value={phone}
              disabled={headers.length === 0 || loadContacts}
            >
              {
                headers.map(item =>
                  <Option key={item.name} value={item.name}>{item.name}</Option>
                )
              }
            </Select>
            <HelperButton
              message={t("map-header.tooltip-phone")}
              color={getGradientColor(purpleColor)}
            />
          </div>        
        </div>
        <div className={styles['phone-content']}>
          {
            phoneHeader.length > 0 && phoneFieldName !== "" && (
              <TableCustom>
                <TableHeadCustom>
                  <th className={styles['table-fields-header']}>
                    {phoneFieldName}
                  </th>
                </TableHeadCustom>
                <TableBodyCustom>
                {
                  fileData.map(data =>
                    <TableBodyTrCustom key={data.row}>
                      <td>
                        {
                          getFieldHeaderValue(data)
                        }
                      </td>
                    </TableBodyTrCustom>
                  )
                }
                </TableBodyCustom>
              </TableCustom>
            )
          }
        </div>
      </div>
      <div className={styles['wildcards']}>
        <div className={styles['wildcard-select']}>
          <div className={styles['wildcard-title']}>
            <p>{t("map-header.wildcards-title")}</p>
            <HelperButton
              message={t("map-header.tooltip-wildcard")}
              color={getGradientColor(purpleColor)}
            />
          </div>
          
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder={t("map-header.placeholder-wildcards")}
            defaultValue={defaultWildcardSelected}
            onChange={handleWildCardsChange}
            options={wildCards}
            disabled={wildCards.length === 0}
            value={wildCardsValue}
          />  
        </div>
        <div className={styles['wildcard-content']}>
          <TableCustom>
            <TableHeadCustom>
              {
                wildCardsSelected && (wildCardsSelected.map((data, index) =>
                  <th key={index}>
                    {data.value}
                  </th>
                ))
              }
            </TableHeadCustom>
            <TableBodyCustom>
              {
                wildCardsSelected.length > 0 && fileData.map(data =>
                  <TableBodyTrCustom key={data.row}>
                    {
                      headers.length > 0 && wildCardsSelected.map((item, index) => (
                        <td key={index}>
                          {getFieldBodyValue(item, data)}
                        </td>
                      ))
                    }
                  </TableBodyTrCustom>
                )
              }
            </TableBodyCustom>
          </TableCustom>
        </div>
      </div>
    </>
  );
}
 
export default HeaderMap