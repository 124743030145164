import { Alert, Col, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";
import "./styles.css"
import { useEffect } from "react";

export const StepThree = ({ setStep3Data, step3Data, form }) => {
  const [t] = useTranslation("rcs-channel");

  useEffect(() => {
    if (step3Data) {
      form.setFieldsValue({
        brandContactName: step3Data.brandContactName,
        brandContactEmailAddress: step3Data.brandContactEmailAddress,
        brandWebsiteUrl: step3Data.brandWebsiteUrl
      })
    }
  }, [step3Data]);

  const validateUrl = (_, value) => {
    const startsWithHttps = value && value.startsWith('https://');
    if (!startsWithHttps) {
      return Promise.reject(new Error(t("step1.rules.valid-url")));
    }
    return Promise.resolve();
  };

  return (
    <Form form={form} layout="vertical">
      <Row>
        <Col span={6} style={{ padding: "0px" }}>
          <Row>
            <Row style={{ marginTop: "20px" }}>
              <h2>{t("step3.label.brand-contact")}</h2>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col span={24} style={{ padding: "0px 10px 0px 0px" }}>
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <Form.Item
                    name="brandContactName"
                    label={t("step3.label.name")}
                    rules={[{ required: true, message: t("step3.rules.name-required") }]}
                    style={{ width: '100%' }}
                    onChange={(e) => setStep3Data({ ...step3Data, brandContactName: e.target.value.trim() })}
                  >
                    <Input id="create-template-name-input" placeholder={t("step3.placeholder.name")} maxLength={40} />
                  </Form.Item>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Item
                    name="brandContactEmailAddress"
                    label={t("step3.label.email")}
                    rules={[{ required: true, message: t("step3.rules.email-required") }, { type: "email", message: t("step3.rules.email-required") }]}
                    style={{ width: '100%' }}
                    onChange={(e) => setStep3Data({ ...step3Data, brandContactEmailAddress: e.target.value.trim() })}
                  >
                    <Input id="create-template-name-input" placeholder={t("step3.placeholder.email")} />
                  </Form.Item>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Item
                    name="brandWebsiteUrl"
                    label={t("step3.label.website")}
                    rules={[{ required: true, message: t("step3.rules.website-required") }, { validator: validateUrl }]}
                    style={{ width: '100%' }}
                    onChange={(e) => setStep3Data({ ...step3Data, brandWebsiteUrl: e.target.value.trim() })}
                  >
                    <Input id="create-template-name-input" placeholder={t("step3.placeholder.website")} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Row>
        </Col>
        <Col span={12} style={{ padding: "0px" }}>
          <Alert
            className="custom-info-alert"
            type="info"
            showIcon
            description={t("step3.alert.disclaimer")}
            style={{ marginTop: "20px" }}
          />
        </Col>
      </Row>
    </Form>
  );
};