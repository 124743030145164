import { Checkbox } from "antd";
import { useEffect, useState } from "react";

const TableBodyTdCheck = ({id, text, isCheked, onChangeCheck}) => {

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isCheked);
  }, [isCheked]);

  const onChange = (e) => {
    setChecked(e.target.checked);

    if(onChangeCheck) {
      onChangeCheck(e.target.checked, id)
    }
  };

  return (
    <Checkbox checked={checked} onChange={onChange}>
      {text}
    </Checkbox>
  );
}
 
export default TableBodyTdCheck;