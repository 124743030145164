import styles from "./Options.module.css";

const Options = ({children}) => {
  return (
    <div className={styles['card-options']}>
      {children}
    </div>
  );
}
 
export default Options;