import { Col, Row, Select, Input } from "antd";
import { Fragment } from "react";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import HelperButton from "../../HelperButton/HelperButton";
import { useTranslation } from 'react-i18next'
import propTypes from 'prop-types'

const { Option } = Select;

const SelectAccount = ({accounts, templates, onAccountChange, onTemplateChange, onCampaignNameChange, defaultSelected, defaultCampaignName}) => {

  const [t] = useTranslation("campaign-bulk");

  const handleAccountChange = (value) => {
    onAccountChange(value);
  };

  const handleCampaignNameChange = (e) => {

    let value = e.target.value;

    if(value) {
      onCampaignNameChange(value);
    } else {
      onCampaignNameChange(null);
    }
  };


  return (
    <Fragment>
      <Row>
        <Col>
          <p>{t("account.title")}</p>
        </Col>
      </Row>
      <div className="content-form">
        <Row style={{width: "100%"}}>
          <Col>
            {t("account.label-select-template")}
          </Col>
        </Row>
        <Row style={{width: "100%", marginBottom: "20px", flexWrap: "nowrap", justifyContent: "center"}}>
          <Col>
            <Select
              name="Template"
              style={{ width: 320 }}
              placeholder={t("account.place-holder-select-template")}
              onChange={onTemplateChange}
            >
              {
                templates.map(item =>
                  <Option key={item.id} value={item.id}>{item.templateName}</Option>
                )
              }
            </Select>
          </Col>
          <Col>
            <div>
              <HelperButton
                message={t("account.tooltip-template")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="content-form">
        <Row style={{width: "100%"}}>
          <Col>
            {t("account.label-select-account")}
          </Col>
        </Row>
        <Row style={{width: "100%", marginBottom: "20px", flexWrap: "nowrap", justifyContent: "center"}}>
          <Col>
            <Select
              name="Account"
              style={{ width: 320 }}
              placeholder={t("account.place-holder-select-account")}
              onChange={handleAccountChange}
              value={defaultSelected}
            >
              {
                accounts.map(item =>
                  <Option key={item.accountId} value={item.accountId}>{item.name}</Option>
                )
              }
            </Select>
          </Col>
          <Col>
            <div>
              <HelperButton
                message={t("account.tooltip-account")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="content-form">
        <Row style={{width: "100%"}}>
          <Col>
            {t("account.label-text-campaign-name")}
          </Col>
        </Row>
        <Row style={{width: "100%", marginBottom: "20px", flexWrap: "nowrap", justifyContent: "center"}}>
          <Col>
            <Input
              name="CampaignName"
                style={{ width: 320 }}
                placeholder={t("account.place-holder-text-campaign-name")}
                onChange={handleCampaignNameChange}
                value={defaultCampaignName}
              />
          </Col>
          <Col>
            <div>
              <HelperButton
                message={t("account.tooltip-campaign-name")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

SelectAccount.propTypes = {
  accounts: propTypes.array.isRequired,
  onChange: propTypes.func,
  showValidations: propTypes.bool
}
 
export default SelectAccount;