import { useTranslation } from "react-i18next";
import TableBodyCustom from "../../TableCustom/TableBodyCustom/TableBodyCustom";
import TableBodyTrCustom from "../../TableCustom/TableBodyTrCustom/TableBodyTrCustom";
import TableCustom from "../../TableCustom/TableCustom";
import TableHeadCustom from "../../TableCustom/TableHeadCustom/TableHeadCustom";
import styles from "./ContactsPreview.module.css";

const AccountPreview = ({headers, fileData}) => {

  const [t] = useTranslation("create-contacts");

  const getFieldBodyValue = (wild, data) => {
    let row = data.value.find(x => x.column === wild.column);
    return row.value;
  };

  return (
    <div className={styles['content']}>
      <p>{t("contacts-preview")}</p>
      <TableCustom>
        <TableHeadCustom>
          {
            headers && (headers.map((data, index) =>
              <th key={index} className={ index == 0 ? styles['table-fields-phone-header'] : ''}>
                {data.name}
              </th>
            ))
          }
        </TableHeadCustom>
        <TableBodyCustom>
          {
            fileData.map(data =>
              <TableBodyTrCustom key={data.row}>
                {
                  headers.length > 0 && headers.map((item, index) => (
                    <td key={index}>
                      {getFieldBodyValue(item, data)}
                    </td>
                  ))
                }
              </TableBodyTrCustom>
            )
          }
        </TableBodyCustom>
      </TableCustom>
    </div>
    
  );
}
 
export default AccountPreview;