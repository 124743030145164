import React, { useReducer } from 'react';
import spinerReducer, { initialSpinnerState } from '../reducers/spinnerReducer';

export const SpinnerContext = React.createContext();

const SpinnerProvider = ({children})=> {

  const [spinnerState, spinnerDispacth] = useReducer(spinerReducer, initialSpinnerState);

  return (
    <SpinnerContext.Provider value={{spinnerState, spinnerDispacth}}>
      {children}
    </SpinnerContext.Provider>
  )
}

export default SpinnerProvider