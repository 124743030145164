import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./VoiceDetails.module.css";
import { faTag, faBars, faDonate, faGlobe, faCalendarCheck, faPhone, faMicrophone, faCalendarWeek } from "@fortawesome/pro-solid-svg-icons";
import { currencyFormat, dateFormat, notificationError, notificationOk } from "../../../../utils/helpers";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import AudioPlayer from "../../../AudioPlayer/AudioPlayer";
import ButtonCustom from "../../../Button/Button";
import { useState } from "react";
import TextToSpeechApi from "../../../../Api/TextToSpeechApi";
import useSpinner from "../../../../Hooks/useSpinner";
import TYPES from "../../../../reducers/types";
import CONSTANTS, { CONSTVOICELIST } from "../../../../utils/const";
import { Button, Popconfirm } from 'antd';
import DataApi from "../../../../Api/DataApi";

const VoiceDetails = ({data , onReloadCampaignDetails}) => {

  const [audio, setAudio] = useState(null);

  const [t] = useTranslation("campaign-history");
  const { getAudio } = TextToSpeechApi();
  const { spinnerDispacth } = useSpinner();
  const { cancelCampaign } = DataApi();

  const onClickPreview = () => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.GETAUDIOMESSAGE }
    });

    let raw = {
      Language: data.language,
      Gender: data.gender,
      ShortName: data.shortName,
      Message: data.firstMessage
    }

    getAudio(raw)
    .then(result => {
      const blob = new Blob([result], { type: "audio/wav" });
      const ctx = new AudioContext();
      
      ctx.decodeAudioData(result)
      .then(decode => {
        //setAudioDuration(decode.duration);
      });

      const url = window.URL.createObjectURL(blob);
      setAudio(url);

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETAUDIOMESSAGE }
      });
    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETAUDIOMESSAGE }
      });
      console.error(error);
    });
  }

  const cancelCampaignByCampaignId= () => {
    try {

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNDETAILS }
      });

      if (data.status === 'Completed' || data.status === 'Canceled') {
        notificationError('An ocurred a error: The campaign is already cancel');
      }

      cancelCampaign(data.campaignId).then( () => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNDETAILS }
        });

        if(data.schedules.length > 1){
          notificationOk(t('Your cancel the Campaign by phases'));  
        }else{
          notificationOk(t('Your cancel Campaign'));
        }
        
        onReloadCampaignDetails();
      }).catch(err =>{
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNDETAILS }
        });
        notificationError(`An ocurred a error: ${err}`);
      })
    } catch (err) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETCAMPAIGNDETAILS }
      });
      notificationError(`An ocurred a error: ${err}`);
    }
  }

  return (
    <>
      <div className={styles['summary-content']}>
        <div className={styles['summary-info']}>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.voice-chanel.label-name")}</p>
              <p className={styles['message-information-text-value']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faTag} /> {data.campaignName}
              </p>
            </div>
          </div>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.voice-chanel.label-total-characters")}</p>
              <p className={styles['message-information-text-value']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faBars} /> {data.totalCharacters}
              </p>
            </div>
          </div>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.voice-chanel.label-total-cost")}</p>
              <p className={styles['message-information-text-value']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faDonate} /> {currencyFormat(data.campaignTotalCost, 4)}
              </p>
            </div>
          </div>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.voice-chanel.label-countries")}</p>
              <p className={styles['message-information-text-value']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faGlobe} /> 
                {
                  data.countries.map(country => (
                    <ReactCountryFlag
                      key={country}
                      countryCode={country}
                      svg
                      style={{
                        width: '1.5em',
                        height: '1.5em',
                        marginRight: 5
                      }}
                      title={country}
                    />
                  ))
                }
              </p>
            </div>
          </div>
        </div>
        <div className={styles['summary-info']}>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.voice-chanel.label-programing-date")}</p>
              {
                data.schedules.map((item, index) => (
                  <p key={index} className={styles['message-information-text-value']}>
                    <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCalendarWeek} /> {dateFormat(item.dateScheduled, true, 'UTC')} - {item.percentage}%
                  </p>
                ))
              }
            </div>
          </div>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.voice-chanel.label-start-date")}</p>
              <p className={styles['message-information-text-value']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCalendarCheck} /> {dateFormat(data.schedules[0].dateScheduled, true, 'UTC')}
              </p>
            </div>
          </div>
          <div className={styles['message-information']}>
            <div className={styles['message-information-text']}>
              <p>{t("campaign-details.voice-chanel.label-total-calls")}</p>
              <p className={styles['message-information-total-calls']}>
                <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faPhone} /> {data.totalCalls}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['message-information-preview']}>
        <div className={styles['message-information-text']}>
          <p>{t("campaign-details.voice-chanel.label-preview")}</p>
          <div className={styles['message-information-preview-container']}>
            <p>
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faMicrophone} /> 
            </p>
            <ButtonCustom
              style={{ marginRight: "10px", marginLeft: "10px" ,height: "50px" }}
              type="primary" 
              onClick={onClickPreview}
            >
              {t("campaign-details.voice-chanel.button-audio-preview")}
            </ButtonCustom>
            <AudioPlayer src={audio} />
          </div>
        </div>
      </div>
      {(data.status !== 'Completed' && data.status !== 'Canceled' && data.status !== 'Created' && data.status !== 'Ongoing') && (
        <div style={{ textAlign: 'center', marginTop: '20px' }} >
          <Popconfirm title={t("Are you sure you want to cancel Campaign")}
            okButtonProps= {{ "data-testid": "okButtonConfirm" }}
            onConfirm={cancelCampaignByCampaignId}
            okText="yes"
            cancelText="no"
          >
          <Button type="secondary" dataname="btnSave">
            {t("Cancel Campaign")}
          </Button>
          </Popconfirm>
        </div>
      )}
    </>
  );
}
 
export default VoiceDetails;