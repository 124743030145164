import useAuth from "../Hooks/useAuth";
import { campaignRequest } from "../utils/authConfig";

const CampaignApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    sendCampaign: async (campaign) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(campaign)
          };

          const resource = "/api/SendCampaign";
          const url = `${process.env.REACT_APP_CAMPAIGN_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred sending the campaign");
          }

          let result = await response.text();
          return result;
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred sending the campaign.");
      }
    },
    addDataFile: async (formData) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'POST',
            body: formData,
            headers: myHeaders,
          };

          const resource = "/api/AddDataFile";
          const url = `${process.env.REACT_APP_CAMPAIGN_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status === 400) {
            let result = await response.json();
            console.log("errors", result);
            let listErrors = result.listErrors.map(x => `${x}\n`);
            throw new Error(`Errors in data file: \n ${listErrors}`);
          } else if (response.status !== 200){
            console.error(await response.text());
            throw new Error("An error occurred uploading the file.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error.message);
      }
    },
    addDataFileWithContacts: async (formData) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'POST',
            body: formData,
            headers: myHeaders,
          };

          const resource = "/api/AddDataFileContacts";
          const url = `${process.env.REACT_APP_CAMPAIGN_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred uploading the file.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred uploading the file.");
      }
    },
    getMessageCost: async (companyId, mobileNumber, message) => {
      let accessToken = await passTokenToApi(campaignRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;

        var myHeaders = new Headers();
        myHeaders.append('Authorization', bearer);
        myHeaders.append('Content-Type', 'application/json');

        var raw = JSON.stringify({
          "body": message,
          "to": mobileNumber,
          "companyId": companyId
        });

        const options = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
        };

        const resource = `/api/campaign/smscost`;
        const url = `${process.env.REACT_APP_CAMPAIGN_API_URL}${resource}`;

        let response = await fetch(url, options);
        let mesmCost = await response.json();
        
        if (response.status !== 200) {
          throw new Error(mesmCost.message);
        }

        return mesmCost;
      } else {
        console.log("Authentication Error");
        return null;
      }
    },
    sendMessage: async (companyId, accountId, user, body) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(body)
          };

          const resource = `/api/campaign/company/${companyId}/account/${accountId}/user/${user}/message`;
          const url = `${process.env.REACT_APP_CAMPAIGN_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);
          let result = await response.json();

          if (response.status !== 200) {
            let errorMessage = "";
            if (result.message && !result.listErrors) {
              errorMessage = result.message;
            } else if (result.message && result.listErrors) {
              errorMessage = result.listErrors.join(', ');
            }
            throw new Error(errorMessage);
          }


          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.log(error.message);
        throw new Error(error.message);
      }
    },
    schedule: async (campaignId, data) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data)
          };

          const resource = `/api/campaign/${campaignId}/scheduled`;
          const url = `${process.env.REACT_APP_CAMPAIGN_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred generating the schedule");
          }

          let result = await response.text();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred generating the schedule");
      }
    },
    campaigBudget: async (campaignId) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };

          const resource = `/api/Campaign/${campaignId}/Budget`;
          const url = `${process.env.REACT_APP_CAMPAIGN_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred calculating campaign cost.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return null;
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred calculating campaign cost.");
      }
    },
    GetCampaignsByAccountId: async (accountId) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };

          const resource = `/api/campaign/account/${accountId}`;
          const url = `${process.env.REACT_APP_CAMPAIGN_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred get campaigns bt account Id.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return null;
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred get campaigns bt account Id.");
      }
    },
    getDataFileByPefix: async (companyId, prefix) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };

          const resource = `/api/campaign/${companyId}/datafile/${prefix}`;
          const url = `${process.env.REACT_APP_CAMPAIGN_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred get data file by prefix.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return null;
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred get data file by prefix.");
      }
    },
    deleteCampaign: async (companyId) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
          };

          const resource = `/api/campaign/${companyId}`;
          const url = `${process.env.REACT_APP_CAMPAIGN_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred deleting campaign.");
          }

          let result = await response.text();
          return result;
        }
        else {
          console.log('Authentication Error');
          return null;
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred deleting campaign.");
      }
    },
    GetCostByAccounts: async (companyId, period) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Company/${companyId}/Costaccounts/${period}`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the cost by accounts. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the cost by accounts.");
      }
    },
    GetCompanyMessages: async (companyId, period) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Company/${companyId}/Messages/${period}`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting te messages by company. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting te messages by company.");
      }
    },
    GetMonthlySpending: async (companyId) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Company/${companyId}/Cost`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting te monthly spending. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting te monthly spending.");
      }
    },
    GetReachByMotnh: async (companyId) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Company/${companyId}/Sms`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the reach by month. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the reach by month.");
      }
    },
    GetCompanyStatus: async (companyId) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Company/${companyId}/Status`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the company status. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the company status.");
      }
    },
    GetTopCountries: async (companyId, accountId) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Account/${accountId}/Company/${companyId}/Country`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting top countries. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting top countries.");
      }
    },
    GetAccountMessage: async (companyId, accountId, period) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Account/${accountId}/Company/${companyId}/Messages/${period}`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting account message. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting account message.");
      }
    },
    GetAccountStatus: async (accountId) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Account/${accountId}/Status`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting account message. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting account message.");
      }
    },
    GetAccountReachByMotnh: async (companyId, accountId) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Account/${accountId}/Company/${companyId}/Sms`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting the reach by month. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the reach by month.");
      }
    },
    GetCampiagnsAccount: async (companyId, accountId) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Account/${accountId}/Company/${companyId}/Campaigns`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting campaigns account. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting campaigns account.");
      }
    },
    GetTopCountries: async (companyId, accountId) => {
      const accessToken = await passTokenToApi(campaignRequest);
      try {
        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              'Authorization': bearer
            }
          };
          const resource = `/api/Account/${accountId}/Company/${companyId}/Country`;
          const url = `${process.env.REACT_APP_DATA_API_URL}${resource}`;
    
          let response = await fetch(url, options);
    
          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred getting top countries. Status Code: ${response.status}`);
          }
    
          let companies = await response.json();
          return companies;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting top countries.");
      }
    },
  }
}

export default CampaignApi;