import TYPES from './types'

const initialHeaderOptionsState = {
  model: {},
  option: "",
}

const headerOptionsReducer = (state, action) => {
  switch (action.type) {
    case TYPES.HEADER_OPTIONS.CONFIRM_ADMIN_RATES_CHANGES_MODEL:
    case TYPES.HEADER_OPTIONS.HISTORY_BALANCE_MODEL:
      return {
        ...state,
        model: action.payload
      }
    case TYPES.HEADER_OPTIONS.DEFAULT_OPTION:
    case TYPES.HEADER_OPTIONS.SELECT_ADMIN_RATES_CHANGES_OPTION:
    case TYPES.HEADER_OPTIONS.ADD_ADMIN_RATES_CHANGES_OPTION:
    case TYPES.HEADER_OPTIONS.CONFIRM_ADMIN_RATES_CHANGES_OPTION:
    case TYPES.HEADER_OPTIONS.CONFIRM_ADMIN_RATES_CHANGES_OPTION:
    case TYPES.HEADER_OPTIONS.SELECT_COMPANY_RATES_CHANGES_OPTION:
    case TYPES.HEADER_OPTIONS.HISTORY_BALANCE_OPTION:
    case TYPES.HEADER_OPTIONS.CAMPAIGN_HISTORY_OPTION:
      return {
        ...state,
        option: action.payload
      }
    case TYPES.HEADER_OPTIONS.CAMPAIGN_HISTORY_MODEL:
      var payload = action.payload;
      var newModel = { ...state.model, ...payload };
      return {
        ...state,
        model: { ...newModel }
      }
    case TYPES.HEADER_OPTIONS.SELECT_ADMIN_RATES_CHANGES_MODEL:
    case TYPES.HEADER_OPTIONS.SELECT_COMPANY_RATES_CHANGES_MODEL:
      var payload = action.payload;
      var newModel = { ...state.model, ...payload };
      return {
        ...state,
        model: { ...newModel }
      }
    case TYPES.HEADER_OPTIONS.ADD_ADMIN_RATES_CHANGES_MODEL:
      var payload = action.payload;
      var newModel = { ...state.model, ...payload };
      return {
        ...state,
        option: TYPES.HEADER_OPTIONS.ADD_ADMIN_RATES_CHANGES_OPTION,
        model: { ...newModel }
      }
    case TYPES.HEADER_OPTIONS.APLY_ADMIN_RATES_CHANGES_OPTION:
      var payload = action.payload;
      var newModel = { ...state.model, ...payload };
      return {
        ...state,
        option: TYPES.HEADER_OPTIONS.APLY_ADMIN_RATES_CHANGES_OPTION,
        model: { ...newModel }
      }
    default:
      return state;
  }
}

export { initialHeaderOptionsState }
export default headerOptionsReducer;