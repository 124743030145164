import { faWallet } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popconfirm, Form, Row, Col, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TestMessage.module.css";
import useSpinner from '../../../Hooks/useSpinner';
import TYPES from "../../../reducers/types";
import CONSTANTS from "../../../utils/const";
import useAuth from "../../../Hooks/useAuth";
import encodingSevenBits, { cleanErrorMessage, currencyFormat, notificationError, notificationOk } from "../../../utils/helpers";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import HelperButton from "../../HelperButton/HelperButton";
import ReactCountryFlag from "react-country-flag"
import { useEffect } from "react";
import MobilePreview from "../../../pages/RCS/MobilePreview";
import RcsApi from "../../../Api/RcsApi";
import BalanceApi from "../../../Api/BalanceApi";

const TestMessage = ({message,
  accountId,
  customFields,
  fileHeaders,
  fileData,
  defualtShortLink,
  agentData,
  messageType,
  rcsCampaignData
}) => {

  useEffect(() => {
    if(message) {
      getMessage();
    }
  }, [message])

  const [t] = useTranslation("rcs-campaign");
  const { auth } =  useAuth();
  const [form] = Form.useForm();
  const { spinnerDispacth } = useSpinner();

  const [messagePartsTest, setMessagePartsTest] = useState([]);
  const [isValidateMessage, setIsValidateMessage] = useState(true);
  const [_mobileNumber, _setMobileNumber] = useState(null);
  const [destination, setDestination] = useState(null);
  const [messageTest, setMessageTest] = useState(null);
  const [costMessageTest, setCostMessageTest] = useState(0);
  const [isBasicEncoding, setIsBasicEncoding] = useState(true);
  const [messageCount, setMessageCount] = useState(0);
  const [charUsed, setCharUsed] = useState(0);
  const [alertMessageCount, setAlertMessageCount] = useState(false);
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);
  const { sendRCSTestMessage, getMessageCost, getAgentById } = RcsApi();
  const { GetAcocuntsBalanceByCompanyId } = BalanceApi();

  useEffect(() => {
    setIsValidateMessage(true);
    setDestination(null);
    setCostMessageTest(0);
  }, [_mobileNumber]);

  const testMessage = () => {
    form.validateFields()
      .then(values => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: true, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
        });

        const wildCardOccurrences = message.match(/@#/g);
        const { mobileNumber } = values;
        const common = {
          companyId: agentData.companyId,
          accountId: agentData.accountId,
          rcsBrandId: agentData.rcsBrandId,
          rcsAgentId: agentData.rcsAgentId,
          lastModifiedBy: agentData.lastModifiedBy,
          isTestMessage: false,
          message: messageTest,
          to: mobileNumber,
          hasBodyParameters: (wildCardOccurrences || []).length >= 2
        }

        const base64 = rcsCampaignData?.imageBase64?.split(',')?.[1] || ""
        const payload = messageType === "BASIC" ? {
          ...common,
          richCard: null,
        } : {
          ...common,
          richCard: {
            ...rcsCampaignData,
            imageBase64: base64,
            buttons: rcsCampaignData?.buttons?.action?.data || [],
          },
        }

        GetAcocuntsBalanceByCompanyId(auth.companyId, auth.accesToken)
        .then(response => {
          const availableBalance = response.find(b => b.accountId === accountId)?.availableBalance || 0
          if (availableBalance >= costMessageTest) {
            sendRCSTestMessage(payload)
              .then(response => {
                form.resetFields();
                notificationOk(t("content-message.notification-ok-message"));
                setIsValidateMessage(true);
                setDestination(null);
                setCostMessageTest(0);
                spinnerDispacth({
                  type: TYPES.SPINNER.SHOW,
                  payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
                });
              }).catch(error => {
                console.error(error);
                spinnerDispacth({
                  type: TYPES.SPINNER.SHOW,
                  payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
                });
      
                notificationError(cleanErrorMessage(error) || t("content-message.notification-error-message"));
              });
          } else {
            notificationError(t("content-message.notification-error-unsufficient-balance-account"));
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
            });
          }
        })
        .catch(error => {
          console.error(error);
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
          });
          notificationError(cleanErrorMessage(error) || t("content-message.notification-error-message"));
        })
      });
  }

  const validateMessage = () => {
    form.validateFields()
      .then(values => {

        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: true, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
        });

        const { mobileNumber } = values;
        const firstRow = fileData[0].value;

        let msg = message;
        customFields.forEach(field => {
          if(field !== CONSTANTS.SHORTLINK) {
            let column = fileHeaders.find(x => x.name === field);
            let row = firstRow.find(x => x.column === column.column);
            msg = msg.replaceAll(`${CONSTANTS.WILDCARD}${field}${CONSTANTS.WILDCARD}`, row.value);
          }
        });

        const payload = {
          to: mobileNumber,
          companyId: auth.companyId,
          billingCategory: agentData.billingCategory,
          agentUseCase: agentData.agentUseCase
        }

        getMessageCost(payload)
          .then(response => {
            setIsValidateMessage(false);
            setDestination({
              countryName: response?.data?.countryName,
              countryCode: response?.data?.countryCode,
              prefix: response?.data?.prefix
            });
            let cost = response?.data?.unitRate * (response?.data?.totalMessage === 0 ? 1 : response?.data?.totalMessage);
            setCostMessageTest(cost);
          }).catch(error => {
            console.error(error);
            
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
            });
            notificationError(cleanErrorMessage(error) || t("content-message.notification-error-validation"));
          }).finally(() => {
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
            });
          })
      });
  }

  const getMessage = async () => {
    if(message.length === 1 && message == " ") {
      setMessagePartsTest([]);
      setMessageCount(0);
      setMessageTest("");
      setCharUsed(0);
    } else {
      const firstRow = fileData[0].value;

      let msg = message.replaceAll(`${CONSTANTS.WILDCARD}${CONSTANTS.SHORTLINK}${CONSTANTS.WILDCARD}`, defualtShortLink);
      customFields.forEach(field => {
          let column = fileHeaders.find(x => x.name === field);
          let row = firstRow.find(x => x.column === column.column);
          msg = msg.replaceAll(`${CONSTANTS.WILDCARD}${field}${CONSTANTS.WILDCARD}`, row.value);
      });

      let smsParts = getSmsParts(msg);

      let startPosition = 0;
      let endPosition = isGsm7Bit(msg) ? (msg.length > 160 ? 153 : 160) : (msg.length > 70 ? 67 : 70);
      let arrayMessages = [];

      for (let index = 0; index < smsParts; index++) {
        let newMessage = msg.substring(startPosition, endPosition);
        arrayMessages.push(newMessage);
        startPosition = endPosition;
        endPosition = isGsm7Bit(msg) ? (msg.length > 160 ? endPosition + 153 : endPosition + 160) : (msg.length > 70 ? endPosition + 67 : endPosition + 70);
      }

      setMessagePartsTest(arrayMessages);
      setMessageCount(arrayMessages.length);      

      setAlertMessageCount(arrayMessages.length > 1);
      
      let ms = arrayMessages.join("");
      setMessageTest(ms);
      setCharUsed(ms.length);
      
      var isBasic = isBasicEncoding;

      let chart = ms.charAt(ms.length - 1);
      isBasic = encodingSevenBits.includes(chart) ? true : false;
      let hasSpecialCharacters = !isGsm7Bit(msg);

      if (isBasic && !hasSpecialCharacters) {
        setIsBasicEncoding(true);
      } else {
        setIsBasicEncoding(false);
      }
    }
  }

  const isGsm7BitChar = (char) => encodingSevenBits.includes(char);

  const isGsm7Bit = (message) => {
    for (let i = 0; i < message.length; i++) {
      if (!isGsm7BitChar(message[i])) {
        return false;
      }
    }
    return true;
  };

  const getSmsParts = (message) => {
    let maxCharsPerPart;
    let isGsm = isGsm7Bit(message);
  
    if (isGsm) {
      maxCharsPerPart = 160;
      if (message.length > 160) {
        maxCharsPerPart = 153; // Para mensajes concatenados.
      }
    } else {
      maxCharsPerPart = 70;
      if (message.length > 70) {
        maxCharsPerPart = 67; // Para mensajes concatenados UCS-2
      }
    }
  
    let parts = Math.ceil(message.length / maxCharsPerPart);
    return parts;
  };

  return (
    <div className={styles['test-message-content']}>
      <div className={styles['test-message-info']}>
        <Form layout="vertical" form={form}>   
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="mobileNumber"
                label={
                  <div>
                    {t("content-message.label-test-mobile-number")}
                    <HelperButton 
                      message={t("content-message.tooltip-phone")}
                      color={getGradientColor(purpleColor)}
                    />
                  </div>
                }
                rules={[{ required: true, message: 'Please enter the mobile number' }]}
              >                
                <Input
                  placeholder={t("content-message.label-test-mobile-number-placeholder")}
                  disabled={isFieldDisabled}
                  className={isFieldDisabled ? styles.customInputDisabled : styles.customInputEnabled}
                  onChange={(e) => _setMobileNumber(e.target.value)}
                  value={_mobileNumber}
                />                
              </Form.Item>
            </Col>  
          </Row>
        </Form>
        {
          destination && (
            <div className={styles['message-information']}>
              <div className={styles['message-information-text']}>
                <p>{t("content-message.label-test-destination")}</p>
                <p className={styles['message-information-text-cuntry-value']}>
                  <ReactCountryFlag
                    countryCode={destination?.countryCode}
                    svg
                    style={{
                      width: '1.5em',
                      height: '1.5em',
                      marginRight: 5
                    }}
                    title={destination.countryCode}
                  />
                  +{destination?.prefix} {destination?.countryName}
                </p>
              </div>
            </div>
          )
        }
        {
          costMessageTest !== 0 && (
            <div className={styles['message-information']}>
              <div className={styles['message-information-text']}>
                <p>{t("content-message.label-test-message-cost")}</p>
                <p className={styles['message-information-text-value']}>
                  <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faWallet} /> {currencyFormat(costMessageTest, 4)}
                </p>
              </div>
            </div>
          )
        }
        {
          isValidateMessage ? (
            <Button
              type="secondary"
              onClick={validateMessage}
              style={{ width: 210 }}
              disabled={messageTest && messageTest?.length > 0 ? false : true}
            >
              {t("content-message.button-test-validate")}
            </Button>
          ) : (
            <Popconfirm title={t("content-message.confirm-test")}
              onConfirm={testMessage}
              okText={t("content-message.confirm-test-button")}
              cancelText={t("content-message.cancel-test-button")}
            >
              <Button
                type="secondary"
                style={{ width: 210, marginTop: "30px" }}
              >
                {t("content-message.button-test-send")}
              </Button>
            </Popconfirm>
          )
        } 
      </div>
      <div className={styles['test-message-phone']}>
        <Row>
          <Col span={24}>
            <MobilePreview
              type={messageType === "BASIC" ? "campaign-basic-message" : "campaign-rich-card"}
              previewValues={{
                message: messageTest,
                logoBase64: agentData?.logoBase64,
                bannerBase64: agentData?.bannerBase64,
                description: agentData?.description,
                name: agentData?.name,
                title: rcsCampaignData?.title || "",
                imageBase64: rcsCampaignData?.imageBase64 || "",
                buttons: rcsCampaignData?.buttons || null,
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
 
export default TestMessage;