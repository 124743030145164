import styles from "./HeaderOptions.module.css";
import TYPES from '../../reducers/types'
import useHeaderOptions from '../../Hooks/useHeaderOptions'
import RatesByCompanyHeaderOption from "./RatesByCompanyHeaderOption/RatesByCompanyHeaderOption";
import AddRatesByCompanyHeaderOption from "./AddRatesByCompanyHeaderOption/AddRatesByCompanyHeaderOption";
import ConfirmRatesHeaderOptions from "./ConfirmRatesHeaderOptions/ConfirmRatesHeaderOptions";
import RatesHeaderOption from "./RatesHeaderOption/RatesHeaderOption";
import HistoryBalanceHeaderOption from "./HistoryBalanceHeaderOption/HistoryBalanceHeaderOption";
import CampaignHistoryHeaderOptions from "./CampaignHistoryHeaderOptions/CampaignHistoryHeaderOptions";
import { useLocation } from 'react-router-dom';

const HeaderOptions = () => {

  const { headerOptionsState } = useHeaderOptions();
  const { option } = headerOptionsState;

  const location = useLocation();

  // Lógica para determinar si mostrar HistoryBalanceHeaderOption
  const shouldShowHistoryBalance = option === TYPES.HEADER_OPTIONS.HISTORY_BALANCE_OPTION
  && (location.pathname === '/HistoryBalance' || location.pathname === '/Company/HistoryBalance');
  
  const shouldShowHistoryCampaign = option === TYPES.HEADER_OPTIONS.CAMPAIGN_HISTORY_OPTION
  && (location.pathname === '/History' || location.pathname === '/Campaigns/History');

  const render = () => {
    switch (headerOptionsState.option) {
      case TYPES.HEADER_OPTIONS.SELECT_ADMIN_RATES_CHANGES_OPTION:
        return <RatesByCompanyHeaderOption />
      case TYPES.HEADER_OPTIONS.ADD_ADMIN_RATES_CHANGES_OPTION:
      case TYPES.HEADER_OPTIONS.APLY_ADMIN_RATES_CHANGES_OPTION:
        return <AddRatesByCompanyHeaderOption />
      case TYPES.HEADER_OPTIONS.CONFIRM_ADMIN_RATES_CHANGES_OPTION:
        return <ConfirmRatesHeaderOptions />
      case TYPES.HEADER_OPTIONS.SELECT_COMPANY_RATES_CHANGES_OPTION:
        return <RatesHeaderOption />
      case TYPES.HEADER_OPTIONS.HISTORY_BALANCE_OPTION:
        return shouldShowHistoryBalance ? <HistoryBalanceHeaderOption /> : null; // Mostrar solo si se cumple la condición
      case TYPES.HEADER_OPTIONS.CAMPAIGN_HISTORY_OPTION:
        return shouldShowHistoryCampaign ? <CampaignHistoryHeaderOptions /> : null; 
      default:
        return null;
    }
  }

  return (
    <div className={styles['container']}>
      { render() }
    </div>
  );
}
 
export default HeaderOptions;