import { Checkbox, Pagination } from "antd";
import { useEffect, useState } from "react";
import TableBodyCustom from "../../TableCustom/TableBodyCustom/TableBodyCustom";
import TableBodyTdCheck from "../../TableCustom/TableBodyTdCheck/TableBodyTdCheck";
import TableCustom from "../../TableCustom/TableCustom";
import TableHeadCustom from "../../TableCustom/TableHeadCustom/TableHeadCustom";
import { dateFormat, currencyFormat } from "../../../utils/helpers";
import styles from "./SelectNetworkRatesGrid.module.css";
import TableBodyTrCustom from "../../TableCustom/TableBodyTrCustom/TableBodyTrCustom";
import CONST from "../../../utils/const";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-solid-svg-icons'

const SelectNetworkRatesGrid = ({rates, currentPage, itemsPerPage, totalPages, isSelectAll, onChangePagination, onChangeRatesSelect, onChangeSelectAll}) => {

  const [ratesFiltered, setRatesFiltered] = useState([])

  useEffect(() => {
    if(rates) {
      setRatesFiltered(rates);
    }
  }, [rates])

  const onChangeSelect = (value, id) => {
    let updatedList = ratesFiltered.map(item => 
    {
      if (item.mccMnc === id){
        return {...item, isCheked: value};
      }
      return item;
    });
    setRatesFiltered(updatedList);
    onChangeRatesSelect(value, id);
  }

  const getAllChekedName = () => {
    const name = isSelectAll ? "Unselect All" : "Select All"
    return name;
  }

  return (
    <div>
      <div className={styles['pagination-container']}>
        <Pagination 
          defaultCurrent={currentPage}
          current={currentPage}
          onChange={(pageNumber) => onChangePagination(pageNumber)}
          total={totalPages}
          pageSize={itemsPerPage}
          showSizeChanger={false}
        />
      </div>
      <div className={styles['table-container']}>
        <TableCustom>
          <TableHeadCustom>
            <th><Checkbox checked={isSelectAll} onChange={(e) => onChangeSelectAll(e.target.checked)}>{getAllChekedName()}</Checkbox></th>
            <th>Country</th>
            <th>Network</th>
            <th>MCCMNC</th>
            <th>Current Rate</th>
            <th>Efective Rate</th>
            <th>Rate Type</th>
          </TableHeadCustom>
          <TableBodyCustom>
            {
              ratesFiltered.map(rate => (
                <TableBodyTrCustom isSelected={rate.isCheked} key={rate.mccMnc}>
                  <td>
                    <TableBodyTdCheck
                      id={rate.mccMnc}
                      onChangeCheck={onChangeSelect}
                      isCheked={rate.isCheked}
                    />
                  </td>
                  <td>{rate.country}</td>
                  <td>{rate.network}</td>
                  <td>{rate.mccMnc}</td>
                  <td>{currencyFormat(rate.rate, 5)}</td>
                  <td>{dateFormat(rate.effectiveDate)}</td>
                  <td>{rate.isStandard ? CONST.RATES_TYPE.STANDAR : 
                    <p className={styles['field-custom']}>{CONST.RATES_TYPE.CUSTOM} <span><FontAwesomeIcon icon={ faStar } /></span></p>}
                  </td>
                </TableBodyTrCustom>
              ))
            }
          </TableBodyCustom>
        </TableCustom>
      </div>
    </div>
  );
}
 
export default SelectNetworkRatesGrid;