const ApiKeyApi = () => {
  return {
    addApiKey: async (apiKeyName, userName, companyId, accountId) => {
      try {
        var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            "userId": userName,         
            "name": apiKeyName,
            "companyId": companyId,
            "accountId": accountId
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          
          const resource = "/ApiKey/User/";
          const url = `${process.env.REACT_APP_API_KEY_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(`An error occurred adding the api key. Status Code: ${response.status}`);
          }

          let result = await response.text();
          return result;
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred adding api key.");
      }
    },
    getApiKeys: async (userName) => {
      try {
        
        var requestOptions = {
          method: 'GET',
        };
        
        const resource = `/ApiKey/User/${userName}`;
        const url = `${process.env.REACT_APP_API_KEY_API_URL}${resource}`;
        let response = await fetch(url, requestOptions);

        if (response.status !== 200) {
          console.error(await response.text());
          throw new Error(`An error occurred getting the api keys. Status Code: ${response.status}`);
        }

        let apiKeys = await response.json();
        return apiKeys;
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting api keys.");
      }
    },
  }
}

export default ApiKeyApi;