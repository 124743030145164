import useAuth from "../Hooks/useAuth";
import { accountRequest, balanceRequest } from "../utils/authConfig";

const AccountApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    GetAccounts: async () => {
      let accessToken = await passTokenToApi(accountRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "GET",
          headers: {
            Authorization: bearer,
          },
        };
        const resource = `/api/Account`;
        const url = `${process.env.REACT_APP_ACCOUNT_API_URL}${resource}`;

        let response = await fetch(url, options);
        let accounts = await response.json();

        return accounts;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    GetAccountsByCompanyId: async (companyId) => {

      let accessToken = await passTokenToApi(accountRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;
        const options = {
          method: "GET",
          headers: {
            Authorization: bearer,
          },
        };
        const resource = `/api/Account/Company/${companyId}`;
        const url = `${process.env.REACT_APP_ACCOUNT_API_URL}${resource}`;

        let response = await fetch(url, options);
        let accounts = await response.json();
        return accounts;
      } else {
        console.log("Authentication Error");
        return [];
      }
    },
    GetAccountByAccountId: async (accountId) => {
      try {
        let accessToken = await passTokenToApi(accountRequest);

        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              Authorization: bearer,
            },
          };
          const resource = `/api/Account/${accountId}`;
          const url = `${process.env.REACT_APP_ACCOUNT_API_URL}${resource}`;

          let response = await fetch(url, options);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(
              `An error occurred getting the accounts by id. Status Code: ${response.status}`
            );
          }

          let account = await response.json();
          return account;
        } else {
          console.log("Authentication Error");
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the account.");
      }
    },
    SetAccount: async (account) => {
      try {
        let accessToken = await passTokenToApi(accountRequest);

        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "POST",
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(account),
          };          

          const resource = "/api/Account";
          const url = `${process.env.REACT_APP_ACCOUNT_API_URL}${resource}`;

          let response = await fetch(url, options);
          let result = await response.json();

          if (response.status !== 200) {
            let errorMessage = "";
            if (result.message && !result.listErrors) {
              errorMessage = result.message;
            } else if (result.message && result.listErrors) {
              errorMessage = result.listErrors.join(', ');
            }
            throw new Error(errorMessage);
          }

          return result;
        } else {
          console.log("Authentication Error");
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error.message);
      }
    },
    GetAccountNumbers: async (companyId) => {
      try {
        let accessToken = await passTokenToApi(accountRequest);

        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "GET",
            headers: {
              Authorization: bearer,
            },
          };
          const resource = `/api/Account/Company/${companyId}/Number`;
          const url = `${process.env.REACT_APP_ACCOUNT_API_URL}${resource}`;

          let response = await fetch(url, options);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error(
              `An error occurred getting the accounts number. Status Code: ${response.status}`
            );
          }

          let account = await response.json();
          return account;
        } else {
          console.log("Authentication Error");
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred getting the accounts number.");
      }
    },
    PutAccount: async (account, accountIdPrimary, companyId) =>{
      try {
        let accessToken = await passTokenToApi(balanceRequest);

        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "POST",
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(account),
          };          

          const resource = `/api/Balance/Account/${accountIdPrimary}/Company/${companyId}`;
          const url = `${process.env.REACT_APP_BALANCE_API_URL}${resource}`;

          let response = await fetch(url, options);
          let result = await response.json();

          if (response.status !== 200) {
            let errorMessage = "";
            if (result.message && !result.listErrors) {
              errorMessage = result.message;
            } else if (result.message && result.listErrors) {
              errorMessage = result.listErrors.join(', ');
            }
            throw new Error(errorMessage);
          }

          return result;
        } else {
          console.log("Authentication Error");
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error.message);
      }
    },
    UpdateAccount: async (values, accountId) =>{
      try {
        let accessToken = await passTokenToApi(accountRequest);

        if (accessToken) {
          const bearer = `Bearer ${accessToken}`;
          const options = {
            method: "PUT",
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          };          

          const resource = `/api/AccountUpdateData/${accountId}`;
          const url = `${process.env.REACT_APP_ACCOUNT_API_URL}${resource}`;

          let response = await fetch(url, options);
          let result = await response.json();

          if (response.status !== 200) {
            let errorMessage = "";
            if (result.message && !result.listErrors) {
              errorMessage = result.message;
            } else if (result.message && result.listErrors) {
              errorMessage = result.listErrors.join(', ');
            }
            throw new Error(errorMessage);
          }

          return result;
        } else {
          console.log("Authentication Error");
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error.message);
      }
    }
  };
};

export default AccountApi;
