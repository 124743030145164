import { Select } from "antd";
import { useState, useEffect } from "react";
import useHeaderOptions from "../../../Hooks/useHeaderOptions";
import useRatesAccount from "../../../Hooks/useRatesAccount";
import TYPES from "../../../reducers/types";
import CONST from "../../../utils/const";
import Button from "../../Button/Button";
import styles from "./ConfirmRatesHeaderOptions.module.css";

const { Option } = Select;

const ConfirmRatesHeaderOptions = () => {

  const { headerOptionsState, headerOptionsDispacth } = useHeaderOptions();
  const { ratesAccountDispacth } = useRatesAccount();

  const [ countries , setCountries ] = useState([]);
  const [ defaultSelected ] = useState(CONST.DEFAULTVALUES.ALLCOUNTRIES);
  const [ countrySelected, setCountrySelected ] = useState(CONST.DEFAULTVALUES.ALLCOUNTRIES);

  useEffect(() => {
    if(headerOptionsState.model.countries) {
      setCountries(headerOptionsState.model.countries);
    }
  }, [headerOptionsState])

  const handleCountryChange = (country) => {
    setCountrySelected(country);
    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.CONFIRM_ADMIN_RATES_CHANGES_MODEL,
      payload: { selectedCountry: country }
    })
  };

  const onConfirm = () => {
    ratesAccountDispacth({
      type: TYPES.RATES_PROCESS_STEP.DONE_STEP,
      payload: CONST.RATES_PROCESS_STEP.DONE_RATES
    })
  }

  return (
    <div className={styles['filters']}>
      <p>Country</p>
      <Select
        style={{width: 250}}
        className={styles['country-select']}
        placeholder="Please select a country"
        onChange={handleCountryChange}
        defaultValue={defaultSelected}
        value={countrySelected}
      >
        {
          countries.map(item =>
            <Option key={item} value={item}>{item}</Option>
          )
        }
      </Select>
      <Button type="primary" onClick={() => onConfirm()}>
        Confirm
      </Button>
    </div>
  );
}
 
export default ConfirmRatesHeaderOptions;