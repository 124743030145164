import { useContext, useState } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./VoiceStatus.module.css";
import { useEffect } from "react";
import { Spin } from "antd";
import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import CONSTANTS, { CONSTANPERIODREPORTLIST } from "../../../../utils/const";
import { Link } from "react-router-dom";
import { numberFormat } from "../../../../utils/helpers";
import DataApi from "../../../../Api/DataApi";
import { AuthContext } from "../../../../contexts/authRedirect";

const VoiceStatus = ({filter}) => {

  const [t] = useTranslation("home-company");
  const {getVoiceStatus} = DataApi();
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [successful, setSuccessfull] = useState(0);
  const [error, setError] = useState(0);
  const [convertion, setConvertion] = useState(0);
  const [period, setPiriod] = useState("");

  useEffect(() => {
    setLoading(true);

    getVoiceStatusData()
    .then(response => {
      setLoading(false);

      const {successfulValue, errorValue, convertionValue} = response;

      setSuccessfull(successfulValue);
      setError(errorValue);
      setConvertion(convertionValue);

      if(filter && filter.time) {
        let resultPeriod = CONSTANTS.LIST.PERIODREPORT.find(x => x.key === filter.time);
        setPiriod(resultPeriod.value);
      }
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    })
    
  }, [filter])

  const getVoiceStatusData = async () => {
    let data = {
      companyId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? auth.companyId : null,
      accountId: filter.id === CONSTANTS.ACCOUNTDEFAULT.accountId ? null : filter.id,
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    }
    
    let responseData = await getVoiceStatus(filter.time, data);
    return {successfulValue: responseData.succesfull, errorValue: responseData.error, convertionValue: responseData.conversionRate}; 
  }

  return (
    <div className={styles['dashboard-body-card-single']}>
      <CardCustom
        style={{height: "100%", widt:"100%"}}
        title={
        <div className={styles['card-title']}>
          <p>{t("card-voice-status.label-title")}</p>
          <HelperButton
            message={t("card-voice-status.tooltip-title")}
            color={getGradientColor(purpleColor)}
          />
        </div>
        }
      >
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              <div className={styles['dashboard-card-body-metric']}>
                <p>{t("card-voice-status.label-successful")}</p>
                <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-succesfull']}`}>{numberFormat(successful, 0)}</div>
              </div>
              <div className={styles['dashboard-card-body-metric']}>
                <p>{t("card-voice-status.label-error")}</p>
                <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-error']}`}>{numberFormat(error, 0)}</div>
              </div>
              <div className={styles['dashboard-card-body-metric']}>
                <p>{t("card-voice-status.label-convertion")}</p>
                <div className={`${styles['dashboard-card-body-metric-value']} ${styles['metric-value-convertion']}`}>{numberFormat(convertion, 0)}</div>
              </div>
              <p className={styles['dashboard-card-body-period']}>{period}</p>
              <Link to="/Campaigns/Reports" className={styles['dashboard-card-body-report-button']}>{t("card-total-campaigns.report-button")}</Link>
              <Link to="/Campaigns/Voz" className={styles['dashboard-card-body-balance-button']}>{t("dashboard-header-buttons.voice-campaign")}</Link>
            </div>
          </Spin>
        </div>
      </CardCustom>
    </div>
  );
}
 
export default VoiceStatus;