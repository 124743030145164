import { useState, useEffect } from 'react';
import { Card } from 'antd';
import { whiteColor, purpleColor ,getOpacityColor, blackColor } from "../../utils/colorsCss";
import styles from "./CardCustom.module.css";

const CardCustom = ({children, style, extra, isCheked, title, borderColor, textColor}) => {

  const [headBakground, setHeadBakground] = useState(whiteColor);
  const [borderBackground, setBorderBackground] = useState(borderColor ? borderColor : purpleColor);
  const [textColorBackground, setTextColor] = useState(blackColor);

  useEffect(() => {
    const back = isCheked ? purpleColor : whiteColor;
    setHeadBakground(back);

    let color = borderColor ? borderColor : purpleColor;
    const backBord = isCheked ? getOpacityColor(color) : color;
    setBorderBackground(backBord);

  }, [isCheked])

  const onMouseEnter = () => {
    if(!isCheked) {
      let color = borderColor ? borderColor : purpleColor;
      setHeadBakground(color);
      let borderCol = getOpacityColor(color);
      setBorderBackground(borderCol);
      color = textColor ? textColor : whiteColor;
      setTextColor(color);
    }
  }

  const onMouseLeave = () => {
    if(!isCheked) {
      setHeadBakground(whiteColor);
      let color = borderColor ? borderColor : purpleColor;
      setBorderBackground(color);
      setTextColor(blackColor);
    }
  }

  return (
    <Card
      hoverable
      headStyle={{'background': headBakground, 'borderTop': `10px solid ${borderBackground}`, 'color': textColorBackground }}
      style={style}
      title={title}
      extra={extra}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
    >
      <div className={styles["body"]}>
        {children}
      </div>
    </Card>
  );
}

export default CardCustom;