
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LeftCard from "./LeftCard/LeftCard";
import Options from "./Options/Options";
import styles from "./TableCardButtons.module.css";
import { faBars } from "@fortawesome/pro-solid-svg-icons";

const TableCardButtons = ({children}) => {
  return (
    <div className={styles['table-card-buttons']}>
      <LeftCard>
       
      </LeftCard>
      <Options>
        {children}
      </Options>
    </div>
  );
}
 
export default TableCardButtons;