import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-solid-svg-icons'
import styles from "./CopyToClipboard.module.css";

const CopyToClipboard = ({textToCopy}) => {

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
    }).catch(err => {
      console.error('Error in copy text: ', err);
    });
  };

  return (
    <FontAwesomeIcon className={styles['copy']} icon={faCopy} onClick={copyToClipboard}/>
  );
}
 
export default CopyToClipboard;