import TYPES from './types'
import { useTranslation } from "react-i18next";

const initialBreadcrumbState = {
  step: ""
  
}

const breadcrumbReducer = (state, action) => {
  switch (action.type) {
    case TYPES.BREADCRUMB.SELECT_STEP:
      return {
        ...state,
        step: action.payload
      }
    default:
      return state;
  }
}

export { initialBreadcrumbState }
export default breadcrumbReducer;