import { Select, Input, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import useHeaderOptions from '../../../Hooks/useHeaderOptions'
import useRatesAccount from '../../../Hooks/useRatesAccount'
import TYPES from '../../../reducers/types'
import Button from '../../Button/Button';
import styles from "./AddRatesByCompanyHeaderOption.module.css";
import CONST from "../../../utils/const"

const { Option } = Select;
const { Search } = Input;

const AddRatesByCompanyHeaderOption = () => {

  const { headerOptionsState, headerOptionsDispacth } = useHeaderOptions();  
  const { ratesAccountDispacth } = useRatesAccount();

  const [ defaultSelected ] = useState(CONST.DEFAULTVALUES.ALLCOUNTRIES);
  const [ countries , setCountries ] = useState([]);
  const [ newRate , setNewRate ] = useState(0);
  const [ searchValue , setSearchValue ] = useState("");
  const [ countrySelected, setCountrySelected ] = useState(CONST.DEFAULTVALUES.ALLCOUNTRIES);
  const [ disableButton, setDisableButton ] = useState(true);

  useEffect(() => {
    if(headerOptionsState.model.countries) {
      setCountries(headerOptionsState.model.countries);
      setDisableButton(headerOptionsState.model.hasRowsInZero);
    }
  }, [headerOptionsState])

  const handleCountryChange = (country) => {
    setCountrySelected(country);
    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.ADD_ADMIN_RATES_CHANGES_MODEL,
      payload: { selectedCountry: country }
    })
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.ADD_ADMIN_RATES_CHANGES_MODEL,
      payload: { searchNetwork: e.target.value }
    })
  };

  const onChangeRate = () => {
    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.APLY_ADMIN_RATES_CHANGES_OPTION,
      payload: { price: newRate }
    })

    setCountrySelected(CONST.DEFAULTVALUES.ALLCOUNTRIES);
    setNewRate(0);
    setSearchValue("");
  }

  const nextStep = () => {    
    ratesAccountDispacth({
      type: TYPES.RATES_PROCESS_STEP.CONFIRM_STEP,
      payload: CONST.RATES_PROCESS_STEP.SAVE_ADD_RATES
    })
  }

  const onChageNewRate = (value) => {
    setNewRate(value);
  }

  return (
    <div className={styles['filters']}>
      <p>Country</p>
      <Select
        style={{width: 250}}
        className={styles['country-select']}
        placeholder="Please select a country"
        onChange={handleCountryChange}
        defaultValue={defaultSelected}
        value={countrySelected}
      >
        {
          countries.map(item =>
            <Option key={item} value={item}>{item}</Option>
          )
        }
      </Select>
      <p>Network</p>
      <Search 
        className={styles['network-search']} 
        placeholder="Search by network" 
        onChange={onSearch} style={{ width: 200 }}
        value={searchValue}
      />
      <p>Rate</p>
      <InputNumber className={styles['rate']} onChange={onChageNewRate} value={newRate}/>
      <Button  type="secondary" onClick={() => onChangeRate()}>
        Change Rate
      </Button>
      <Button disabled={disableButton}  type="primary" onClick={() => nextStep()}>
        Save Rates
      </Button>
    </div>
  );
}
 
export default AddRatesByCompanyHeaderOption;