import React from 'react';

export const TimezoneContext = React.createContext();

// TO DO: Traer la data de algun repositorio
const initialData = [{
  id: "B8A3523C-4F6E-4CE0-ADAA-344392D4E118",
  name: "Eastern Standard Time"
}, {
  id: "7447D629-43F4-4600-B57C-D2EC478B6A20",
  name: "Central Standard Time"
}];

const TimezoneProvider = (props)=> {
  return (
    <TimezoneContext.Provider value={{timezones: initialData}}>
      {props.children}
    </TimezoneContext.Provider>
  )
}

export default TimezoneProvider