import styles from "./RatesProcess.module.css";
import { useEffect, useState } from "react";
import RatesApi from '../../Api/RatesApi';
import useRatesAccount from '../../Hooks/useRatesAccount'
import CONST from "../../utils/const"
import TYPES from '../../reducers/types'
import SelectNetworkRates from "../../components/RatesProcess/SelectNetworkRates/SelectNetworkRates";
import AddRates from "../../components/RatesProcess/AddRates/AddRates";
import ConfirmRates from "../../components/RatesProcess/ConfirmRates/ConfirmRates";
import { notificationError, notificationOk } from "../../utils/helpers";
import CONSTANTS from "../../utils/const";
import useSpinner from "../../Hooks/useSpinner";

const RatesProcess = ({match}) => {

  const { getRatesByCompany,  getRatesAzBase, addCustomRates, addBaseRates } = RatesApi();
  const { ratesAccountDispacth, ratesAccountState } = useRatesAccount();

  const { spinnerDispacth } = useSpinner();
  const [rates, setRates] = useState([]);
  const [selectedRates, setSelectedRates] = useState([]);
  const [isAZBase, setIsAZBase] = useState(null);

  useEffect(() => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.RATESPROCCESS }
    });

    let isAZ = match.params.companyId ? false : true;
    setIsAZBase(isAZ);

    dataProccess(match.params.companyId, isAZ)
    .then(response => {
      var ratesMap = response.rates.map((r) => {
        return {
          prefix: r.prefix,
          isCheked: false,
          country: r.country,
          network: r.network,
          mccMnc: r.mccMnc,
          rate: r.rate,
          effectiveDate: r.effectiveDate,
          isStandard: r.isStandard
        };
      });

      setRates(ratesMap);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.RATESPROCCESS }
      });
    })
    .catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.RATESPROCCESS }
      });
    })
  }
  , []);

  useEffect(() => {
    if(ratesAccountState.step === CONST.RATES_PROCESS_STEP.RELOAD_RATES) {
      
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.RATESPROCCESS }
      });

      dataProccess(match.params.companyId, isAZBase)
      .then(response => {
        var ratesMap = response.rates.map((r) => {
          return {
            prefix: r.prefix,
            isCheked: false,
            country: r.country,
            network: r.network,
            mccMnc: r.mccMnc,
            rate: r.rate,
            effectiveDate: r.effectiveDate,
            isStandard: r.isStandard
          };
        });
  
        setRates(ratesMap);
        
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.RATESPROCCESS }
        });

        ratesAccountDispacth({
          type: TYPES.RATES_PROCESS_STEP.SELECT_STEP,
          payload: CONST.RATES_PROCESS_STEP.SELECT_RATES
        })
      })
      .catch(error => {
        console.error(error);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.RATESPROCCESS }
        });
      })
    }
  }, [ratesAccountState])

  const dataProccess = async (companyId, isAZ) => {
    let ratesResponse = isAZ ? await getRatesAzBase() : await getRatesByCompany(companyId);
    return { rates: ratesResponse };
  }

  const onRatesSelected = (selected) => {

    let data = selected.map(r => {
      return {
        prefix: r.prefix,
        isCheked: false,
        country: r.country,
        network: r.network,
        mccMnc: r.mccMnc,
        rate: r.rate,
        newRate: 0,
        effectiveDate: r.effectiveDate
      }
    });
    setSelectedRates(data);

    ratesAccountDispacth({
      type: TYPES.RATES_PROCESS_STEP.ADD_STEP,
      payload: CONST.RATES_PROCESS_STEP.ADD_RATES
    })
  }

  const onNewRatesSave = (newRates) => {
    setSelectedRates(newRates);

    ratesAccountDispacth({
      type: TYPES.RATES_PROCESS_STEP.CONFIRM_STEP,
      payload: CONST.RATES_PROCESS_STEP.CONFIRM_RATES
    })
  }

  const onConfirmRates = (newRates) => {
    
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.CONFIRMRATES }
    });
    
    let data = newRates.map(r => {
      return {
        prefix: r.prefix,
        country: r.country,
        network: r.network,
        mccMnc: r.mccMnc,
        rate: r.newRate,
        effectiveDate: r.effectiveDate,
        companyId: match.params.companyId
      }
    });

    addRatesProccess(data, isAZBase)
    .then(response => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONFIRMRATES }
      });
      notificationOk("The rates was load successful");

      ratesAccountDispacth({
        type: TYPES.RATES_PROCESS_STEP.RELOAD_STEP,
        payload: CONST.RATES_PROCESS_STEP.RELOAD_RATES
      })
    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONFIRMRATES }
      });
      notificationError("An error occurred while changing rates");
      console.error(error);
    })
  }

  const addRatesProccess = async (data, isAZ) => {
    let ratesResponse = isAZ ? await addBaseRates(data) : await addCustomRates(data);
    return { rates: ratesResponse };
  }

  const render = () => {
    switch (ratesAccountState.step) {
      case CONST.RATES_PROCESS_STEP.SELECT_RATES:
      case CONST.RATES_PROCESS_STEP.SAVE_SELECT_RATES:
        return <SelectNetworkRates 
          data={rates}
          onRatesSelected={onRatesSelected}
        />
      case CONST.RATES_PROCESS_STEP.ADD_RATES:
      case CONST.RATES_PROCESS_STEP.SAVE_ADD_RATES:
        return <AddRates 
          data={selectedRates}
          onNewRatesSave={onNewRatesSave}
        />
      case CONST.RATES_PROCESS_STEP.CONFIRM_RATES:
      case CONST.RATES_PROCESS_STEP.DONE_RATES:
        return <ConfirmRates 
          data={selectedRates}
          onConfirmRates={onConfirmRates}
        />
      default:
        break;
    }
  }

  return (
    <div className={styles['rates-container']}>
      {
        render()
      }
    </div>
  );
}
 
export default RatesProcess;