import { useState, useEffect ,Fragment } from 'react';
import 'antd/dist/antd.css';
import './App.less';
import { Switch, Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Col, Row, Button, Dropdown, Image, Select } from 'antd';
import logo from "./assets/resources/logo.svg";
import {
  UserOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { faUserRobot, faUserTag, faUser, faBuilding } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAuth from './Hooks/useAuth';
import HeaderOptions from './components/HeaderOptions/HeaderOptions';
import CONSTANTS from './utils/const'
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag';
import MenuCustom from './components/MenuCustom/MenuCustom';
import Routers from './components/Routers/Routers';
import ConfigurationApi from './Api/ConfigurationApi';
import Spinner from './components/Spinner/Spinner';
import TYPES from './reducers/types'
import useSpinner from './Hooks/useSpinner';
import Feedback from './components/Feedback/Feedback';
import { notificationError, notificationOk } from './utils/helpers';
import CompanyApi from './Api/CompanyApi';
import Breadcrum from './components/Breadcrum/Breadcrum';
import UserApi from './Api/UserApi';

const { Header, Content, Sider } = Layout;
const { Option } = Select;

const App = () => {

  /* const { breadcrumbState } = useSteper(); */
  const { auth, signOut, addUserData } = useAuth();

  const { getMenus, getRoutes, pqr } = ConfigurationApi();
  const { GetCompanies, GetCompanyById } = CompanyApi();
  const { getUserData } =  UserApi();

  const [, i18n] = useTranslation("campaign-bulk");
  const [tFeedback, ] = useTranslation("feedback");
  const { spinnerState, spinnerDispacth } = useSpinner();
  const location = useLocation()
  const [ collapsed, setCollapsed ] = useState(false);
  const [ showChatbot, setShowChatbot ] = useState(false);
  const [ defaultLanguage, setDefaultLanguage ] = useState(CONSTANTS.LIST.LANGUAGES[0].key)
  const [ menuData, setMenuData ] = useState([])
  const [ routerData, setRouterData ] = useState([])
  const [ userCompany, setUserCompany ] = useState(null)

  useEffect(() => {
    if(auth && auth.accountId && auth.role === '' && menuData.length === 0) {
      console.log("auth", auth);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.MENU }
      });

      getInitialData()
      .then(response => {
        console.log("Initial Data Response:", response);
        setRouterData(response.routeList);
        setMenuData(response.menuList);
        setUserCompany(response.company);
        console.log("User Company:", response.company);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.MENU }
        });
      })
      .catch(error => {
        console.error(error);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.MENU }
        });
      })
    }
  }, [auth]);

  useEffect(() => {
    if (location.pathname !== "/WhatsAppTemplates/List") sessionStorage.removeItem("SELECTED_PHONE_FOR_WA_TEMPLATE");
  }, [location]);

  useEffect(() => {
    const language = localStorage.getItem(CONSTANTS.LANGUAGEKEY);
    if(!language) {
      localStorage.setItem(CONSTANTS.LANGUAGEKEY, CONSTANTS.LIST.LANGUAGES[0].key);
    } else if (language !== defaultLanguage) {
      localStorage.setItem(CONSTANTS.LANGUAGEKEY, language);
      i18n.changeLanguage(language)
      setDefaultLanguage(language);
    } 
  }, [defaultLanguage]);

  const getInitialData = async () => {

    let userData = await getUserData(auth.userId);
    auth.companyId = userData.companyId;
    addUserData(userData);
    

    let menuList = await getMenus(userData.role);
    let routeList = await getRoutes(userData.role);
    //let companies = await GetCompanies();   
    console.log(auth.companyId)
    let company = await GetCompanyById(auth.companyId);
    return { menuList: menuList, routeList: routeList, company: company }
  }

  const onClickMenu = (menu) => {
    if (menu.key === '1') {
      signOut();
    };
  }

  const handleLanguageChange = (value) => {
    localStorage.setItem(CONSTANTS.LANGUAGEKEY, value);
    setDefaultLanguage(value)
    i18n.changeLanguage(value)
  }

  const onSendFeedback = (feedback) => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.FEEDBACK }
    });
    
    let request = {
      Email: auth.userName,
      CompanyId: auth.companyId,
      Description: feedback.message,
      Subject: feedback.subject
    }

    const formData = new FormData();
    let file = feedback.file;
    formData.append('file', file);

    const json = JSON.stringify(request);
    formData.append("request", json);

    pqr(formData)
      .then(() => {
        notificationOk(tFeedback("notification-ok"));

        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.FEEDBACK }
        });

        setShowChatbot(false);
      })
      .catch(error => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.FEEDBACK }
        });
        notificationError(tFeedback("notification-error"));
        console.error(error);
      })
  }

  return (
    auth.isLogged ? (
      <Fragment>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            breakpoint="lg"
            collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
          >
            <Link to="/">
              <Button
                type="primary"
                shape="circle"
                icon={<HomeOutlined />}
                style={{ margin: '25px' }}
              >
              </Button>
            </Link>
            <MenuCustom data={menuData}/>
          </Sider>
          <Layout>
            <Header>
              <Row>
                <Col span={3}>
                  <Image
                    className="logo"
                    width={180}
                    preview={false}
                    src={logo}
                    style={{marginTop: "3px"}}
                  />
                </Col>
                <Col span={11} offset={6} className="user-box">
                  <div style={{display: "flex"}} className="user-header">                     
                    <p className="user-item" style={{margin: 0, color: "var(--main-color-purple)"}}><FontAwesomeIcon className="item-icon" icon={ faUser } /> {auth.userName}</p>
                    <p className="user-item user-item-character" id="rol_company" style={{margin: 0, marginLeft: 20, color: "var(--main-color-purple)"}}><FontAwesomeIcon className="item-icon" icon={ faUserTag } /> {auth.role}</p>
                    <p className="user-item user-item-character" style={{margin: 0, marginLeft: 20, color: "var(--main-color-purple)"}}><FontAwesomeIcon className="item-icon" icon={ faBuilding } /> {userCompany?.companyName}</p>
                  </div>
                </Col>
                <Col style={{ textAlign: "right" }} span={3}>
                  <Select
                    style={{ width: 100 }}
                    className="lengauage-selector"
                    onChange={handleLanguageChange}
                    defaultValue={defaultLanguage}
                    bordered={false}
                  >
                    {
                      CONSTANTS.LIST.LANGUAGES.map(item =>
                        <Option key={item.key} value={item.key}>
                          <ReactCountryFlag
                            countryCode={item.countriCode}
                            svg
                            style={{
                                width: '1.5em',
                                height: '1.5em',
                                marginRight: 10
                            }}
                            title="US"
                          />
                          {
                            item.value
                          }
                        </Option>
                      )
                    }
                  </Select>
                </Col>
                <Col style={{ textAlign: "right" }} span={1}>
                  <Dropdown
                    overlay={
                      <Menu onClick={onClickMenu}>
                        <Menu.Item key="1">Logout</Menu.Item>
                      </Menu>
                    }
                  >
                    <Button style={{ marginRight: "20px" }} shape='circle' icon={<UserOutlined />}></Button>
                  </Dropdown>
                </Col>
              </Row>
            </Header>
            <Content>
              <Row>
                <Col span={24}>
                  <div className="subheader">
                    <Breadcrum data={routerData} />
                    <HeaderOptions />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Switch>
                    <Routers data={routerData} />
                  </Switch>
                </Col>
              </Row>
            </Content>
          </Layout>
          <div className="chatBot">
            <div className="chatBot-button"
              onClick={() => setShowChatbot(true)}
            >
              <FontAwesomeIcon style={{fontSize:"18px"}} icon={ faUserRobot } />
            </div>
            <Feedback
              visible={showChatbot}
              onClose={() => setShowChatbot(false)}
              onSend={onSendFeedback}
            />
          </div>
        </Layout>
        <Spinner show={spinnerState.show} />
      </Fragment>
    ) : (
      <div></div>
    )
  );
}

export default App;