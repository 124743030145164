import { Card } from 'antd';
import styles from "./CardCreate.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons'

const CardCreate = ({onClick, title, content}) => {
  return (
    <Card
      hoverable
      cover={
        <div className={styles.cover}>
          <div id="button_create" className={styles['button-create']} onClick={onClick}>
            <span>
              <FontAwesomeIcon style={{ fontSize: "20px"}} icon={faPlus} />
            </span>
          </div>
        </div>
      }
    >
      <div className={styles['boddy-card']}>
        <p className='yp-title'>{title}</p>
        <p>{content}</p>
      </div>
    </Card>
  );
}

export default CardCreate;