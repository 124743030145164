import styles from "./CompanyHome.module.css";
import AssignedCredit from "./AssignedCredit/AssignedCredit";
import OnHoldCredit from "./OnHoldCredit/OnHoldCredit";
import AvailableCredit from "./AvailableCredit/AvailableCredit";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ChannelSelect from "../../ChannelSelect/ChannelSelect";
import { useContext, useEffect, useState } from "react";
import CONSTANTS, { CHANELLIST, CONSTANPERIODREPORTLIST } from "../../../utils/const";
import AccountApi from "../../../Api/AccountApi";
import { AuthContext } from "../../../contexts/authRedirect";
import CompanyFilters from "../../DashboardFilters/CompanyFilters/CompanyFilters";
import TotalSms from "./TotalSms/TotalSms";
import TotalVoice from "./TotalVoice/TotalVoice";
import TotalConversionRate from "./TotalConversionRate/TotalConversionRate";
import TotalCampaigns from "./TotalCampaigns/TotalCampaigns";
import LastCampaigns from "./LastCampaigns/LastCampaigns";
import SmsStatus from "./SmsStatus/SmsStatus";
import VoiceStatus from "./VoiceStatus/VoiceStatus";
import AccountConsumption from "./AccountConsumption/AccountConsumption";
import ChannelExpenses from "./ChannelExpenses/ChannelExpenses";

const FILTERINITIALDATA = {channel: CHANELLIST.DEFAULT, id: CONSTANTS.ACCOUNTDEFAULT.accountId, time: CONSTANPERIODREPORTLIST.CURRENTWEEK.key, date: []};

const CompanyHome = () => {

  const [t] = useTranslation("home-company");
  const [ channelSelected, setChannelSelected ] = useState(CHANELLIST.DEFAULT);
  const [ accounts, setAccounts]  = useState([]);
  const [ filter, setFilter ] = useState(FILTERINITIALDATA)
  
  const { GetAccountsByCompanyId } = AccountApi();
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    getAccountsProccess(auth.companyId)
      .then(response => {
        if(response) {
          response.unshift(CONSTANTS.ACCOUNTDEFAULT);
          setAccounts(response);
        }
      })
  }, [])


  const onSelectChannel = (channel) => {
    setChannelSelected(channel);
    filter.channel = channel;
    setFilter(filter);
  }

  const getAccountsProccess = async (companyId) => {
    let response = await GetAccountsByCompanyId(companyId);
    return response
  }

  const onChangeFilter = (filter) => {
    filter.channel = channelSelected;
    setFilter(filter);
  }

  const render = () => {
    switch (channelSelected.key) {
      case CHANELLIST.DEFAULT.key:
        return <>
            <TotalSms filter={filter}/>
            <TotalVoice filter={filter}/>
            <TotalConversionRate filter={filter}/>
            <TotalCampaigns filter={filter} />
            <LastCampaigns filter={filter} />
            <SmsStatus filter={filter} />
            <VoiceStatus filter={filter} />
            <AccountConsumption filter={filter}/>
            <ChannelExpenses filter={filter} />
          </>;
      case CHANELLIST.VOICE.key:
        return <>
            <TotalVoice filter={filter}/>
            <LastCampaigns filter={filter} />
            <VoiceStatus filter={filter} />
            <ChannelExpenses filter={filter} />
          </>; 
      case CHANELLIST.SMS.key:
        return <>
            <TotalSms filter={filter}/>
            <TotalConversionRate filter={filter}/>
            <LastCampaigns filter={filter} />
            <SmsStatus filter={filter} />
            <ChannelExpenses  filter={filter} />
          </>;     
      default:
        return <></>
    }
  }

  return (
    <div className={styles['dashboard-home']}>
      <div className={styles['dashboard-header']}>
        <div className={styles['dashboard-header-cards']}>
          <AssignedCredit />
          <OnHoldCredit />   
          <AvailableCredit />       
        </div>
        <div className={styles['dashboard-header-buttons']}>
          <Link id={`button-link-to-balance`} to="/Company/Balance" className={styles['dashboard-header-button']}>{t("dashboard-header-buttons.add-balance")}</Link>
          <Link id={`button-link-to-channel-voice`} to="/Campaigns/Voz" className={styles['dashboard-header-button']}>{t("dashboard-header-buttons.voice-campaign")}</Link>
          <Link id={`button-link-to-channel-sms`} to="/Campaigns/BulkCampaign" className={styles['dashboard-header-button']}>{t("dashboard-header-buttons.sms-campaign")}</Link>
        </div>
      </div>
      <div className={styles['dashboard-menu']}>
        <ChannelSelect onSelect={onSelectChannel}/>
        <CompanyFilters accounts={accounts} onChangeFilter={onChangeFilter} />
      </div>
      <div className={styles['dashboard-body']}>
      {
        render()
      }
      </div>
    </div>
  );
}
 
export default CompanyHome;