import React, { useContext, useEffect, useState } from "react";
import { Drawer, Input, Form, Button, Popconfirm, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { orangeColor, getGradientColor } from "../../../utils/colorsCss";
import { cleanErrorMessage, notificationOk } from '../../../utils/helpers';
import { notificationError } from "../../../utils/helpers";
import { AuthContext } from "../../../contexts/authRedirect";
import useSpinner from "../../../Hooks/useSpinner";
import TYPES from "../../../reducers/types";
import CONSTANTS from "../../../utils/const";
import AccountApi from "../../../Api/AccountApi";
import RcsApi from "../../../Api/RcsApi";
import "./RCSChannel.css"

const { TextArea } = Input;

const CreateAgentDrawer = ({ visible, onClose, setShouldRefetch }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [ypAccounts, setYPAccounts] = useState([])

  const [form] = Form.useForm();
  const [t] = useTranslation("rcs-channel");
  const { auth } = useContext(AuthContext);
  const { spinnerDispacth } = useSpinner();
  const { createBrand } = RcsApi();
  const { GetAccountsByCompanyId } = AccountApi();

  useEffect(() => {
    setIsLoading(true)
    GetAccountsByCompanyId(auth.companyId)
      .then((response) => {
        setYPAccounts(response?.map(item => ({ value: item.accountId, label: item.name })) || []);
        setIsLoading(false)
      })
      .catch((e) => {
        notificationError(cleanErrorMessage(e), 15)
        setIsLoading(false)
      })
  }, []);

  const footer = (
    <div
      style={isCreating || isLoading ? {
        pointerEvents: 'none',
        opacity: 0.5,
        textAlign: "center",
      } : {
        textAlign: "center",
      }}
    >
      <Button
        id="create-brand-cancel-button"
        onClick={() => {
          form.resetFields();
          onClose();
        }}
        style={{ marginRight: 50 }}
        disabled={isLoading || isCreating}
      >
        {t("brands-list.action-buttons.cancel")}
      </Button>
      <Popconfirm
        title={t("brands-list.action-buttons.pop-confirm-title")}
        okButtonProps={{ id: "create-brand-popconfirm-yes-button" }}
        cancelButtonProps={{ id: "create-brand-popconfirm-no-button" }}
        disabled={isCreating}
        onConfirm={() => {
          setIsCreating(true)

          form
            .validateFields()
            .then((values) => {

              spinnerDispacth({
                type: TYPES.SPINNER.SHOW,
                payload: { show: true, item: CONSTANTS.SPINNERITEM.DEFAULT }
              });
              createBrand({
                ...values,
                companyId: auth.companyId,
                lastModifiedBy: auth.userName,
              })
                .then((v) => {
                  notificationOk(t("brands-list.api-result.brand-created-successfully"));
                  setShouldRefetch(true)
                  form.resetFields();
                  onClose()
                  setIsCreating(false)
                  spinnerDispacth({
                    type: TYPES.SPINNER.SHOW,
                    payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
                  });
                })
                .catch(e => {
                  notificationError(cleanErrorMessage(e) || t("brands-list.api-result.error-creating-brand"), 15)
                  setIsCreating(false)
                  spinnerDispacth({
                    type: TYPES.SPINNER.SHOW,
                    payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
                  });
                })
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
              setIsCreating(false)
              spinnerDispacth({
                type: TYPES.SPINNER.SHOW,
                payload: { show: false, item: CONSTANTS.SPINNERITEM.DEFAULT }
              });
            });
        }}
        okText={t("brands-list.action-buttons.yes")}
        cancelText={t("brands-list.action-buttons.no")}
      >
        <Button type="primary" disabled={isLoading || isCreating} id="create-brand-confirm-button">
          {t("brands-list.action-buttons.confirm")}
        </Button>
      </Popconfirm>
    </div>
  )

  return (
    <Drawer
      visible={visible}
      title={t("brands-list.create-brand-title")}
      headerStyle={{ background: getGradientColor(orangeColor) }}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      footer={footer}
    >
      <div
        style={{
          pointerEvents: isCreating ? 'none' : 'auto',
          opacity: isCreating ? 0.5 : 1,
        }}
      >
        <Spin spinning={isLoading}>
          <Form
            data-testid="drawer-content"
            form={form}
            layout="vertical"
            name="form_in_modal"
          >
            <Form.Item
              name="name"
              label={t("brands-list.label.name")}
              style={{ display: "flex", flexDirection: "column", width: "100%" }}
              rules={[
                {
                  required: true,
                  message: t("brands-list.rules.name-required"),
                },
              ]}
            >
              <Input id="create-brand-name-input" placeholder={t("brands-list.placeholder.name")} maxLength={100} />
            </Form.Item>
            <Form.Item
              name="description"
              label={t("brands-list.label.description")}
              rules={[
                {
                  required: true,
                  message: t("brands-list.rules.description-required"),
                },
              ]}
            >
              <TextArea id="create-brand-description-input" placeholder={t("brands-list.placeholder.description")} rows={4} />
            </Form.Item>
            <Form.Item
              label={t("brands-list.label.yp-account")}
              name="accountId"
              required
              rules={[
                {
                  required: true,
                  message: t("brands-list.rules.yp-account-required"),
                },
              ]}
            >
              <Select
                id="create-brand-ypaccount-input"
                loading={isLoading}
                style={{ width: '100%' }}
                placeholder={t("brands-list.placeholder.yp-account")}
                options={ypAccounts}
                allowClear
              />
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </Drawer>
  );
};

export default CreateAgentDrawer;
