import { useState } from "react";
import CardCustom from "../../../CardCustom/CardCustom";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./CampaignsByCountry.module.css";
import { useEffect } from "react";
import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import { useTranslation } from "react-i18next";
import CONSTANTS, { CONSTANPERIODREPORTLIST } from "../../../../utils/const";
import { numberFormat } from "../../../../utils/helpers";
import DataApi from "../../../../Api/DataApi";
import { Spin } from "antd";

const CampaignsByCountry = ({filter}) => {

  const [t] = useTranslation("home-carrier");
  const {getCampaignsTopCountries} = DataApi();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [period, setPiriod] = useState("");

  useEffect(() => {
    setLoading(true);

    getCampaignsByCountryData()
    .then(response => {
      setLoading(false);

      const {chartData} = response;

      setData(chartData);

      if(filter && filter.time) {
        let resultPeriod = CONSTANTS.LIST.PERIODREPORT.find(x => x.key === filter.time);
        setPiriod(resultPeriod.value);
      }
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    })
    
  }, [filter])

  const getCampaignsByCountryData = async () => {

    let data = {
      CompanyId: filter.id === CONSTANTS.COMPANYDEFAULT.companyId ? null : filter.id,
      StartDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[0].toDate() : null,
      EndDate: filter.time === CONSTANPERIODREPORTLIST.CUSTOM.key ? filter.date[1].toDate() : null
    }

    let responseData = await getCampaignsTopCountries(filter.time, data);
    return {chartData: responseData}; 
  }


  return (
    <div className={styles['dashboard-body-card-double']}>
      <CardCustom
        style={{height: "100%", widt:"100%"}}
        title={
        <div className={styles['card-title']}>
          <p>{t("card-campaigns-countries.label-title")}</p>
          <HelperButton
            message={t("card-campaigns-countries.tooltip-title")}
            color={getGradientColor(purpleColor)}
          />
        </div>
        }
      >
        <div className={styles['dashboard-card-spinner']}>
          <Spin spinning={loading}>
            <div className={styles['dashboard-card-body']}>
              <div className={styles['dashboard-card-body-title']}>
                <p className={styles['dashboard-card-body-period']}>{period}</p>
              </div>
              <table className={styles['dashboard-card-body-table']}>
                <thead>
                  <tr>
                    <th>{t("card-campaigns-countries.table-head-label-country")}</th>
                    <th>{t("card-campaigns-countries.table-head-label-messages")}</th>
                    <th>{t("card-campaigns-countries.table-head-label-voice-minutos")}</th>
                    <th>{t("card-campaigns-countries.table-head-label-voice-time")}</th>
                    <th>{t("card-campaigns-countries.table-head-label-total-campaigns")}</th>
                  </tr>
                </thead>
                <tbody>
                {
                  data.map((data, index) =>
                    <tr key={index}>
                      <td>{data.country}</td>
                      <td className={styles['sms-color']}>{numberFormat(data.totalMessages, 0)}</td>
                      <td>{data.totalMinutes}</td>
                      <td>{numberFormat(data.costCallVoice, 2)}</td>
                      <td>{data.totalCampaigns}</td>
                    </tr>
                  ) 
                }
                </tbody>
              </table>
            </div>
          </Spin>
        </div>
      </CardCustom>
    </div>
  );
}
 
export default CampaignsByCountry;