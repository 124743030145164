import React, { useEffect, useState } from 'react';
import * as msal from "@azure/msal-browser";
import { msalConfig, loginRequest } from '../utils/authConfig';
import { b2cPolicies } from '../utils/policies';

export const AuthContext = React.createContext();

const AuthProvider = (props) => {

  const myMSALObj = new msal.PublicClientApplication(msalConfig);

  const [auth, setAuthContextState] = useState({
    userId: "",
    userName: '',
    accountId: '',
    userAccounts: [],
    isLogged: false,
    companyId: '',
    role: '',
    accessToken: null
  });

  const signOut = () => {
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */

    // Choose which account to logout from by passing a homeAccountId.
    const logoutRequest = {
      account: myMSALObj.getAccountByHomeId(auth.accountId)
    };

    myMSALObj.logoutRedirect(logoutRequest);
  }

  // Acquires and access token and then passes it to the API call
  const passTokenToApi = async (apiRequest) => {
    return await getTokenRedirect(apiRequest);
  }

  const getTokenRedirect = async (request) => {

    /**
    * See here for more info on account retrieval:
    * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
    */
     
     let autResult;
    try {
      request.account = myMSALObj.getAccountByHomeId(auth.accountId);
      autResult = await myMSALObj.acquireTokenSilent(request);
    } catch (error) {
      if (error.errorMessage.indexOf("AADB2C90077") > -1) {
        signOut();
      }
    }

    if (!autResult.accessToken || autResult.accessToken === "") {
      throw new Error(msal.InteractionRequiredAuthError);
    } else {
      return autResult.accessToken;
    }
  }

  const addUserData = (data) => {
    if(data) {
      setAuthContextState({
        ...auth,
        userAccounts: data.accounts,
        companyId: data.companyId,
        role: data.role
      });
    }
  }

  useEffect(() => {

    const ValidateLogin = () => {
      myMSALObj.handleRedirectPromise()
        .then(response => {
          if (response) {
            /**
             * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
             * from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy policies may use "acr" instead of "tfp").
             * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
             */
            if(response.idTokenClaims['acr']){
              if (response.idTokenClaims['acr'].toUpperCase() === b2cPolicies.names.signUpSignIn.toUpperCase()) {
                handleResponse(response);
              }
            }
            else {
              if (response.idTokenClaims['tfp'].toUpperCase() === b2cPolicies.names.forgotPassword.toUpperCase()) {
                signOut();
              }
            }
          }
          else {
            const accounts = myMSALObj.getAllAccounts();
            if (accounts.length === 0) {
              // No user signed in
              signIn();
            } else {
              selectAccount();
            }
          }
        })
        .catch(error => {
          console.log(error);

          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
          if (error.errorMessage.indexOf("AADB2C90118") > -1) {
            try {
              myMSALObj.loginRedirect(b2cPolicies.authorities.forgotPassword);
            } catch (err) {
              console.log(err);
            }
          }

          if (error.errorMessage.indexOf("AADB2C90091") > -1) {
            try {
              signIn();
            } catch (err) {
              console.log(err);
            }
          }
        });
    };

    function setAccount(account) {
      setAuthContextState({
        ...auth,
        userId: account.localAccountId,
        userName: account.username,
        accountId: account.homeAccountId,
        isLogged: true
      });
    }

    const handleResponse = (response) => {
      if (response !== null) {
        setAccount(response.account);
      } else {
        selectAccount();
      }
    };

    const selectAccount = (accessToken) => {
      /**
    * See here for more information on account retrieval: 
    * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
    */

      const currentAccounts = myMSALObj.getAllAccounts();

      if (currentAccounts.length < 1) {
        return;
      } else if (currentAccounts.length > 1) {

        /**
         * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
         * is cached as a new account, which results in more than one account in the cache. Here we make
         * sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow, 
         * as this is the default flow the user initially signed-in with.
         */
        const accounts = currentAccounts.filter(account =>
          account.homeAccountId.toUpperCase().includes(b2cPolicies.names.signUpSignIn.toUpperCase())
          &&
          account.idTokenClaims.iss.toUpperCase().includes(b2cPolicies.authorityDomain.toUpperCase())
          &&
          account.idTokenClaims.aud === msalConfig.auth.clientId
        );

        if (accounts.length > 1) {
          // localAccountId identifies the entity for which the token asserts information.
          if (accounts.every(account => account.localAccountId === accounts[0].localAccountId)) {
            // All accounts belong to the same user
            setAccount(accounts[0]);
          } else {
            // Multiple users detected. Logout all to be safe.
            signOut();
          };
        } else if (accounts.length === 1) {
          setAccount(accounts[0]);
        }

      } else if (currentAccounts.length === 1) {
        setAccount(currentAccounts[0]);
      }
    };

    const signIn = () => {
      /**
    * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
    * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
    */
      myMSALObj.loginRedirect(loginRequest);
    };

    ValidateLogin();
  }, []);

  return (
    <AuthContext.Provider value={{ auth, signOut, passTokenToApi, addUserData }}>
      {props.children}
    </AuthContext.Provider>
  );
}
export default AuthProvider