import { Col, Row } from "antd";
import CardSummary from "./CardSummary/CardSummary";
import styles from "./CountrySummary.module.css";

const CountrySummary = ({campaignId, summary}) => {
  return (
    <div className={styles['grid-cards']}>
      {
        summary.costDetails.map(detail => (
          <Col key={detail.countryName}>
            <CardSummary
              data={detail}
              campaignId={campaignId}
            />
          </Col>
        ))
      }
    </div>
  );
}
 
export default CountrySummary;