import { useContext } from 'react';
import './home.css'
import { AuthContext } from "../../contexts/authRedirect";
import CompanyHome from '../../components/Home/CompanyHome/CompanyHome';
import AccountHome from '../../components/Home/AccountHome/AccountHome';
import CONSTANTS from '../../utils/const'
import CarrierHome from '../../components/Home/CarrierHome/CarrierHome';

function Home() {

  const { auth } = useContext(AuthContext);

  const render = () => {

    if(auth.role === CONSTANTS.ROLES.ADMIN) {
      return <CarrierHome />
    } 

    if(auth.role === CONSTANTS.ROLES.COMPANY_ADMIN) {
      return <CompanyHome />
    } 

    if(auth.role === CONSTANTS.ROLES.USER) {
      return <AccountHome />
    }

    return <div></div>
  }

  return (
    render()
  )
}
export default Home;