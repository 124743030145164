import styles from "./TableCardCustom.module.css";

const TableCardCustom = ({children}) => {
  return (
    <table className={styles['table']}>
      {children}
    </table>
  );
}
 
export default TableCardCustom;